import gql from 'graphql-tag';

export const LIST_REPORTS = gql`
    query {
        listReports {
            id
            name
            description
            isReusable
            pbiWorkspaceId
            pbiReportId
            sequence
            isEditable
            category
            units
        }
    }
`;

export const LIST_CATEGORIES = gql`
    query {
        listCategories {
            id
            name
            isActive
            sequence
        }
    }
`;

export const CREATE_REPORT = gql`
    mutation createReport($input: UpdateReportInput) {
        createReport(input: $input) {
            id
            name
            isReusable
            pbiWorkspaceId
            pbiReportId
            sequence
            isEditable
        }
    }
`;

export const UPDATE_REPORT = gql`
    mutation updateReport($id: ID, $input: UpdateReportInput) {
        updateReport(id: $id, input: $input) {
            id
            name
            isReusable
            pbiWorkspaceId
            pbiReportId
            sequence
            isEditable
        }
    }
`;

export const DELETE_REPORT = gql`
    mutation deleteReport($id: ID) {
        deleteReport(id: $id) {
            id
            name
            isReusable
            pbiWorkspaceId
            pbiReportId
            sequence
            isEditable
        }
    }
`;

export const REPORT_CATEGORIES = {
    Audience: 'Audience',
    BrandAndCompetitors: 'Brand & Competitors',
    Ecommerce: 'Ecommerce',
    Executive: 'Executive summary',
    LeadsAndConversions: 'Leads & conversions',
    MarketAndTrends: 'Market & Trends',
    MarketAndEffect: 'Marketing & Effect',
    SalesAndRevenue: 'Sales & revenue',
    WebsiteAndSEO: 'Website & SEO'
};

export const REPORT_DATASOURCES = {
    BidTeathre: 'bidtheatre',
    DeltaProjects: 'deltaProjects',
    HubSpot: 'hubspot',
    FacebookAds: 'facebookAds',
    FacebookPages: 'facebookPages',
    GoogleAds: 'googleAds',
    GoogleAnalyticsGA4: 'googleAnalyticsGA4',
    GoogleAnalyticsGAU: 'googleAnalyticsGAU',
    GoogleDisplayAndVideo360: 'googleDisplayAndVideo360',
    InstagramPages: 'instagramPages',
    KeywordPlanner: 'keywordPlanner',
    LinkedinAds: 'linkedinAds',
    LinkedinPages: 'linkedinPages',
    MicrosoftAds: 'microsoftAds',
    PinterestAds: 'pinterestAds',
    PinterestPages: 'pinterestPages',
    PiwikPro: 'piwikPro',
    SearchConsole: 'searchConsole',
    SERankings: 'seRankings',
    SnapchatAds: 'snapchatAds',
    SnapchatPages: 'snapchatPages',
    Stripe: 'stripe',
    TikTokAds: 'tiktokAds',
    TikTokPages: 'tiktokPages',
    TwitterX: 'twitterX',
    Xandr: 'xandr'
};
