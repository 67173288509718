import React from 'react';
import PropTypes from 'prop-types';

import { Card, CardActions, CardContent, CardMedia, IconButton, Typography } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

import FallbackImage from 'assets/fallbackimage.png';

const useStyles = makeStyles(() => ({
    cardRoot: {
        maxWidth: 345
    },
    media: {
        height: 140
    }
}));

export const ImageUploadCard = ({ image, title, index, isLoading, updateFunc, removeFunc }) => {
    const classes = useStyles();

    return (
        <Card className={classes.cardRoot}>
            <CardMedia
                className={classes.media}
                image={image}
                title={title}
                component='img'
                onError={(e) => (e.target.src = FallbackImage)}
            />
            <CardContent>
                <Typography gutterBottom variant='h5' component='h2'>
                    {title}
                </Typography>
            </CardContent>
            <CardActions>
                <IconButton
                    size='small'
                    color='primary'
                    variant='contained'
                    disabled={!updateFunc || isLoading}
                    onClick={() => updateFunc(index)}
                >
                    <Edit />
                </IconButton>
                <IconButton
                    size='small'
                    color='primary'
                    variant='contained'
                    disabled={isLoading}
                    onClick={() => {
                        removeFunc(index);
                    }}
                >
                    <Delete />
                </IconButton>
            </CardActions>
        </Card>
    );
};

ImageUploadCard.propTypes = {
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    index: PropTypes.number,
    title: PropTypes.string,
    isLoading: PropTypes.bool,
    updateFunc: PropTypes.func,
    removeFunc: PropTypes.func
};
