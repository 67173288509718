import React, { useEffect, useState } from 'react';
import { SketchPicker } from 'react-color';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button, ButtonBase, Popover } from '@material-ui/core';
import { Palette } from '@material-ui/icons';

import { backgroundToRGBA } from 'helpers/string';

export const BackgroundColorSelector = ({ defaultColor, updateBackgroundColor }) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [color, setColor] = useState(defaultColor || { r: 255, g: 255, b: 255, a: 1.0 });

    useEffect(() => {
        setColor(defaultColor);
    }, [defaultColor]);

    const onColorChange = (color) => {
        updateBackgroundColor(color.rgb); // return the background color
        setColor(color.rgb);
    };
    const onClick = (event) => setAnchorEl(event.currentTarget);
    const onClose = () => setAnchorEl(null);

    const open = Boolean(anchorEl);
    const id = open ? 'background-popover' : undefined;

    return (
        <>
            <ButtonBase
                aria-describedby={id}
                color='primary'
                onClick={onClick}
                startIcon={<Palette />}
                style={{
                    borderRadius: '5px',
                    border: '1px solid black',
                    backgroundColor: backgroundToRGBA(color),
                    width: '100%',
                    height: '100%'
                }}
            />
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={onClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'center', horizontal: 'right' }}
            >
                <SketchPicker color={color} onChangeComplete={onColorChange} />
                <Button fullWidth onClick={onClose} style={{ marginTop: 20 }}>
                    {t('Select color')}
                </Button>
            </Popover>
        </>
    );
};

BackgroundColorSelector.propTypes = {
    defaultColor: PropTypes.shape({
        r: PropTypes.number,
        g: PropTypes.number,
        b: PropTypes.number,
        a: PropTypes.number
    }),
    updateBackgroundColor: PropTypes.func
};
