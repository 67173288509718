import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Chip, styled } from '@material-ui/core';

import { RadiusSlider } from 'common/components';
import { locationModel } from 'common/models';

const LocationListContainer = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(4)
}));

const StyledChip = styled(Chip)(({ theme }) => ({
    '&:not(:first-of-type)': {
        marginTop: theme.spacing(2)
    },
    '& .MuiChip-label.MuiChip-labelSmall': {
        flexGrow: 1
    },
    '& .MuiChip-root': {
        marginBottom: theme.spacing(2)
    }
}));

export const LocationList = ({ locations, removeLocation, showLocation, setRadius, selectedLocationIndex }) => {
    const { t } = useTranslation();

    return (
        <LocationListContainer>
            {locations &&
                locations.map((location, i) => (
                    <React.Fragment key={i}>
                        <StyledChip
                            size='small'
                            label={`${location.label} + ${location.radius}${t('Km').toLowerCase()}`}
                            onClick={showLocation(i)}
                            onDelete={removeLocation(i)}
                            color={i === selectedLocationIndex ? 'primary' : 'default'}
                        />
                        {i === selectedLocationIndex && <RadiusSlider radius={location.radius} setRadius={setRadius} />}
                    </React.Fragment>
                ))}
        </LocationListContainer>
    );
};

LocationList.propTypes = {
    locations: PropTypes.arrayOf(PropTypes.shape(locationModel)),
    removeLocation: PropTypes.func,
    showLocation: PropTypes.func,
    setRadius: PropTypes.func,
    selectedLocationIndex: PropTypes.number
};
