import gql from 'graphql-tag';

import { FRAGMENT_PBI_REPORTS_INDUSTRY } from '../fragments/pbi.reports';
import { FRAGMENT_UNIT_ENTITY } from '../fragments/report';

/**
 *
 */

export const GET_UNIT_ENTITIES_LIST = gql`
    query getUnitEntitiesList($unitIds: [ID], $fromDate: Date, $toDate: Date) {
        getUnitEntitiesList(unitIds: $unitIds, fromDate: $fromDate, toDate: $toDate) {
            campaignGroups {
                ...unitEntity
            }
            campaigns {
                ...unitEntity
            }
            advertisers {
                ...unitEntity
            }
            ads {
                ...unitEntity
            }
        }
    }
    ${FRAGMENT_UNIT_ENTITY}
`;

/**
 *
 */

export const GET_REPORT_INDUSTRIES_LIST = gql`
    query getReportIndustriesList($unitId: ID) {
        getAllReportIndustries(unitId: $unitId) {
            ...pbiIndustry
        }
    }
    ${FRAGMENT_PBI_REPORTS_INDUSTRY}
`;

/**
 *
 */

export const GET_REPORT_SHARED_LINK = gql`
    query getReportSharedLink($input: ReportSharedLinkInput) {
        getReportSharedLink(input: $input)
    }
`;
