import React, { useCallback, useEffect, useState } from 'react';
import Cropper from 'react-easy-crop';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { isEqual, round } from 'lodash';

import { Box, Button, Grid, IconButton, MenuItem, Select, Slider, Typography } from '@material-ui/core';
import { Check, Close, CropRotate, Flip, SettingsBackupRestore, Wallpaper, ZoomOutMap } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

import { BackgroundColorSelector, ResolutionBox } from 'common/components';
import { templateMediaDimensionModel } from 'common/models/advertisement';
import { backgroundToRGBA, parseBackgroundCSS, parseCSSbackgroundToAlpha, renderResolution } from 'helpers/string';

const TOPBAR_HEIGHT = 50;
const SIDEBAR_WIDTH = 240;

const useStyles = makeStyles(() => ({
    cropContainer: {
        position: 'absolute',
        top: TOPBAR_HEIGHT,
        left: SIDEBAR_WIDTH,
        right: 0,
        bottom: 0
    },
    cropControls: {
        boxSizing: 'border-box',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        padding: '0 16px',
        height: TOPBAR_HEIGHT,
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden'
    }
}));

const MIN_ZOOM = 0.1;
const MAX_ZOOM = 3;
const DEFAULT_ZOOM = 1;
const DEFAULT_CROP = { x: 0, y: 0 };
const DEFAULT_COLOR = { r: 255, g: 255, b: 255, a: 1 };
const LOCAL_STORAGE_CROP = 'croppedAreaPixels';

export const ImageCropWithZoom = ({ image, resolutions, mediaDimension, onClickBack, onClickCrop, onClickCancel }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [currentImage, setCurrentImage] = useState(null);
    const [crop, setCrop] = useState(DEFAULT_CROP);
    const [zoom, setZoom] = useState(DEFAULT_ZOOM);
    const [rotation, setRotation] = useState(0);
    const [initialCroppedAreaPixels, setInitialCrop] = useState(undefined);
    const [completedCrop, setCompletedCrop] = useState(null);
    const [containerSize, setContainerSize] = useState(null);

    const [resolution, setResolution] = useState(resolutions[0]);

    const [flip, setFlip] = useState(1); // -1 is reversed
    const [flop, setFlop] = useState(1);

    const [backgroundColor, setBackgroundColor] = useState(DEFAULT_COLOR);

    useEffect(() => {
        const croppedAreaPixels = JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_CROP));
        setInitialCrop(croppedAreaPixels);

        const cropSettings = JSON.parse(window.localStorage.getItem('cropSettings'));
        if (cropSettings && Object.keys(cropSettings).length > 0) {
            setRotation(cropSettings.rotation);
            setBackgroundColor(parseBackgroundCSS(cropSettings.background));
            setFlip(cropSettings.flip);
            setFlop(cropSettings.flop);
        }
    }, []);

    const onCropComplete = useCallback(
        (_, c) => {
            setContainerSize({ width: c.width, height: c.height });
            if (
                c.x >= currentImage.naturalWidth ||
                c.y >= currentImage.naturalHeight ||
                c.width + c.x <= 0 ||
                c.height + c.y <= 0
            ) {
                // if outside on left, top, right or bottom
                setCrop(DEFAULT_CROP);
            }

            const W = currentImage.naturalWidth;
            const H = currentImage.naturalHeight;

            const top = c.y < 0 ? Math.abs(c.y) : 0;
            const right = c.width + c.x > W ? c.width - W + c.x : 0;
            const bottom = c.height + c.y > H ? c.height - H + c.y : 0;
            const left = c.x < 0 ? Math.abs(c.x) : 0;

            const whitespace = { top, right, bottom, left };

            const cropRegionWidth = c.width - right - left;
            const cropRegionHeight = c.height - top - bottom;

            window.localStorage.setItem(LOCAL_STORAGE_CROP, JSON.stringify(c));

            setCompletedCrop({
                x: c.x > 0 ? c.x : 0,
                y: c.y > 0 ? c.y : 0,
                width: cropRegionWidth,
                height: cropRegionHeight,
                whitespace
            });
        },
        [currentImage]
    );

    const onChangeAspect = () => {
        setCrop(DEFAULT_CROP);
        setZoom(DEFAULT_ZOOM);
        setBackgroundColor(DEFAULT_COLOR);
        setRotation(0);
        setFlip(1);
        setFlop(1);
    };

    // save and store to local storage
    const onUseImage = () => {
        const cropSettings = {
            ...completedCrop,
            rotation: rotation,
            background: parseCSSbackgroundToAlpha(backgroundColor),
            flip,
            flop
        };
        window.localStorage.setItem(
            'cropSettings',
            JSON.stringify({
                ...cropSettings,
                rotation,
                zoom
            })
        );
        onClickCrop(cropSettings);
    };

    const isChanged =
        !isEqual(crop, DEFAULT_CROP) ||
        flip === -1 ||
        flop === -1 ||
        rotation !== 0 ||
        zoom !== 1 ||
        backgroundColor !== DEFAULT_COLOR;

    return (
        <>
            <Box className={classes.cropContainer}>
                <Cropper
                    initialCroppedAreaPixels={initialCroppedAreaPixels}
                    onMediaLoaded={(img) => setCurrentImage(img)}
                    image={image}
                    // aspect={aspectRatio}
                    cropSize={{
                        width: resolution.width,
                        height: resolution.height
                    }}
                    crop={crop}
                    zoom={zoom}
                    rotation={rotation}
                    minZoom={MIN_ZOOM}
                    maxZoom={MAX_ZOOM}
                    zoomSpeed={0.25}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    restrictPosition={false}
                    transform={`translate(${crop.x}px, ${crop.y}px) rotate(${rotation}deg) scale(${zoom}) scaleX(${flip}) scaleY(${flop})`}
                    style={{ containerStyle: { backgroundColor: backgroundToRGBA(backgroundColor) } }}
                />
            </Box>
            <Box className={classes.cropControls}>
                <Grid container justifyContent='space-between' alignItems='center'>
                    <Grid container item xs>
                        {completedCrop && (
                            <Button
                                startIcon={<Check />}
                                style={{ marginRight: 8 }}
                                variant='contained'
                                color='primary'
                                onClick={onUseImage}
                            >
                                {t('Use image')}
                            </Button>
                        )}
                        <Button style={{ marginRight: 8 }} onClick={onClickBack} startIcon={<Wallpaper />}>
                            {t('Select another image')}
                        </Button>
                    </Grid>
                    <Grid container item xs={4} justifyContent='flex-end'>
                        <IconButton onClick={onClickCancel}>
                            <Close />
                        </IconButton>
                    </Grid>
                </Grid>
            </Box>
            <Box marginTop={3} marginLeft={-3} padding={3} maxWidth={240} minHeight='100%'>
                {completedCrop && (
                    <>
                        <Grid container direction='column' alignContent='flex-start' spacing={3}>
                            <Grid container item xs>
                                <Select fullWidth value={resolution} onChange={onChangeAspect}>
                                    {resolutions.map((res) => (
                                        <MenuItem key={res.description} value={res} onClick={() => setResolution(res)}>
                                            {renderResolution(res)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                            <Grid item xs>
                                <ResolutionBox
                                    leadingText={t('Ad image size')}
                                    w={mediaDimension.width}
                                    h={mediaDimension.height}
                                />
                                <ResolutionBox
                                    leadingText={t('Image resolution')}
                                    w={currentImage.naturalWidth}
                                    h={currentImage.naturalHeight}
                                />
                            </Grid>
                            <Grid container item xs justifyContent='space-between'>
                                <Grid item xs>
                                    <Slider
                                        value={zoom}
                                        min={MIN_ZOOM}
                                        max={MAX_ZOOM}
                                        step={0.05}
                                        aria-labelledby='zoom'
                                        onChange={(_, zoom) => setZoom(zoom)}
                                    />
                                </Grid>
                                <Grid item xs={4} style={{ textAlign: 'right' }}>
                                    <Typography variant='subtitle2'>{round(zoom * 100, 0)}%</Typography>
                                </Grid>
                            </Grid>
                            <Grid container justify='space-around'>
                                <Grid container item xs>
                                    <IconButton
                                        color={flip === 1 ? 'default' : 'primary'}
                                        onClick={() => setFlip(-1 * flip)}
                                    >
                                        <Flip />
                                    </IconButton>
                                    <Box transform='rotate(90deg)'>
                                        <IconButton
                                            color={flop === 1 ? 'default' : 'primary'}
                                            onClick={() => setFlop(-1 * flop)}
                                        >
                                            <Flip />
                                        </IconButton>
                                    </Box>
                                </Grid>
                                <Grid container item xs={5} justify='center' alignItems='center'>
                                    <IconButton onClick={() => setRotation(rotation - 180)}>
                                        <CropRotate />
                                    </IconButton>
                                    <Typography variant='subtitle2'>{rotation % 360}&deg;</Typography>
                                </Grid>
                            </Grid>
                            <Grid container item xs justify='space-between'>
                                <Grid item xs={7}>
                                    <Typography variant='body1'>{t('Background color')}</Typography>
                                </Grid>
                                <Grid container item xs alignContent='flex-end'>
                                    <BackgroundColorSelector
                                        defaultColor={backgroundColor}
                                        updateBackgroundColor={(color) => setBackgroundColor(color)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs direction='column' justify='space-between'>
                                {(containerSize.width !== currentImage.naturalWidth ||
                                    containerSize.height !== currentImage.naturalHeight) &&
                                    !isChanged && (
                                        <Button
                                            fullWidth
                                            variant='contained'
                                            color='primary'
                                            onClick={() => {
                                                let scale = 1;
                                                if (containerSize.width < currentImage.naturalWidth) {
                                                    scale = containerSize.width / currentImage.naturalWidth;
                                                } else if (containerSize.height < currentImage.naturalHeight) {
                                                    scale = containerSize.height / currentImage.naturalHeight;
                                                }
                                                onChangeAspect();
                                                setZoom(scale);
                                            }}
                                            startIcon={<ZoomOutMap />}
                                            style={{ marginBottom: 4 }}
                                        >
                                            {t('Fit image inside')}
                                        </Button>
                                    )}
                                {isChanged && (
                                    <Button
                                        fullWidth
                                        variant='contained'
                                        color='primary'
                                        onClick={onChangeAspect}
                                        startIcon={<SettingsBackupRestore />}
                                    >
                                        {t('Reset to original')}
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    </>
                )}
            </Box>
            <Grid container justify='space-between'>
                <Grid
                    container
                    item
                    xs
                    direction='column'
                    alignContent='flex-end'
                    style={{ textAlign: 'right' }}
                ></Grid>
            </Grid>
        </>
    );
};

ImageCropWithZoom.propTypes = {
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    resolutions: PropTypes.arrayOf(
        PropTypes.shape({
            description: PropTypes.string,
            width: PropTypes.number,
            height: PropTypes.number
        })
    ),
    mediaDimension: PropTypes.shape(templateMediaDimensionModel),
    onClickBack: PropTypes.func,
    onClickCrop: PropTypes.func,
    onClickCancel: PropTypes.func
};

export default ImageCropWithZoom;
