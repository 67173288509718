import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import isEmail from 'validator/es/lib/isEmail';
import isLength from 'validator/es/lib/isLength';

import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    MenuItem,
    TextField
} from '@material-ui/core';

import 'i18n';

import { DEFAULT_LANGUAGE, LANGUAGES, SUPPORTED_LANGUAGES } from 'i18n/utils';

import { PasswordVisibilityContext } from '../../../../../../context/settings';

const AccountDetailsView = ({ onSave, dirty: dirtyProp, user, ...rest }) => {
    const { t, i18n } = useTranslation();
    const [selection, setSelection] = useState(
        SUPPORTED_LANGUAGES.includes(i18n.language) ? i18n.language : DEFAULT_LANGUAGE
    );
    const [dirty, setDirty] = useState(dirtyProp);
    const [values, setValues] = useState(user);
    const { passwordVisibility, setPasswordVisibility } = useContext(PasswordVisibilityContext);

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        if (event.target.name === 'phone') {
            setDirty(isLength(event.target.value, { min: 8, max: 12 }));
        } else if (event.target.name === 'email') {
            setDirty(isEmail(event.target.value));
        } else {
            setDirty(isLength(event.target.value, { min: 2, max: 128 }));
        }
    };

    const handleSave = async (event) => {
        event.preventDefault();

        const success = await onSave(values);
        if (success) {
            setDirty(false);
        } else {
            setDirty(true);
        }
    };

    const handleDelete = async (event) => {
        event.preventDefault();
        window.confirm(`${t('Are you sure that you want to delete the user?')}`);
    };

    const handleSetPasswordVisibility = async (event) => {
        event.preventDefault();
        setPasswordVisibility(true);
    };

    const changeLanguage = (languageCode) => () => {
        document.documentElement.lang = languageCode;
        i18n.changeLanguage(languageCode);
        setSelection(languageCode);
    };

    const disabled = !dirty;

    return (
        <Card {...rest}>
            <Box align='right' m={1}>
                {!passwordVisibility && (
                    <Button color='primary' onClick={handleSetPasswordVisibility}>
                        {t('Change password')}
                    </Button>
                )}
            </Box>
            <CardHeader title={t('Profile')} />
            <Divider />
            <CardContent>
                <Grid container spacing={1}>
                    <Grid item md={12} xs={12}>
                        <TextField
                            fullWidth
                            label={t('First name')}
                            margin='dense'
                            name='firstName'
                            error={!isLength(values.firstName || '', { min: 2, max: 150 })}
                            helperText={
                                (values.firstName &&
                                    values.firstName.length < 2 &&
                                    t('First name must be between 2 to 150 characters')) ||
                                ''
                            }
                            onChange={handleChange}
                            value={values.firstName}
                            variant='filled'
                        />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <TextField
                            fullWidth
                            label={t('Last name')}
                            margin='dense'
                            name='lastName'
                            onChange={handleChange}
                            value={values.lastName}
                            error={!isLength(values.lastName || '', { min: 2, max: 150 })}
                            helperText={
                                (values.lastName &&
                                    values.lastName.length < 2 &&
                                    t('Last name must be between 2 to 150 characters')) ||
                                ''
                            }
                            variant='filled'
                        />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <TextField
                            fullWidth
                            label={t('E-mail address')}
                            error={!isEmail(values.email)}
                            helperText={!isEmail(values.email) && t('Is not a valid email').toLowerCase()}
                            margin='dense'
                            name='email'
                            onChange={handleChange}
                            value={values.email}
                            variant='filled'
                        />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <TextField
                            fullWidth
                            select
                            margin='dense'
                            variant='filled'
                            label={t('Language')}
                            value={selection}
                        >
                            <MenuItem onClick={changeLanguage('da-DK')} value='da-DK'>
                                {LANGUAGES['da-DK']}
                            </MenuItem>
                            <MenuItem onClick={changeLanguage('de-DE')} value='de-DE'>
                                {LANGUAGES['de-DE']}
                            </MenuItem>
                            <MenuItem onClick={changeLanguage('en-US')} value='en-US'>
                                {LANGUAGES['en-US']}
                            </MenuItem>
                            <MenuItem onClick={changeLanguage('es-ES')} value='es-ES'>
                                {LANGUAGES['es-ES']}
                            </MenuItem>
                            <MenuItem onClick={changeLanguage('fr-FR')} value='fr-FR'>
                                {LANGUAGES['fr-FR']}
                            </MenuItem>
                            <MenuItem onClick={changeLanguage('nb-NO')} value='nb-NO'>
                                {LANGUAGES['nb-NO']}
                            </MenuItem>
                            <MenuItem onClick={changeLanguage('sv-SE')} value='sv-SE'>
                                {LANGUAGES['sv-SE']}
                            </MenuItem>
                        </TextField>
                    </Grid>
                </Grid>
            </CardContent>
            <Divider />
            <CardActions>
                <Box width={1} display='flex' justifyContent='space-between'>
                    <Button color='primary' onClick={handleDelete} variant='contained'>
                        {t('Delete user')}
                    </Button>
                    <Button color='primary' disabled={disabled} onClick={handleSave} variant='contained'>
                        {t('Save')}
                    </Button>
                </Box>
            </CardActions>
        </Card>
    );
};

AccountDetailsView.defaultProps = {
    dirty: false
};

AccountDetailsView.propTypes = {
    className: PropTypes.string,
    dirty: PropTypes.bool,
    onSave: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired
};

export default AccountDetailsView;
