import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

import { TreeItemCheckbox } from 'common/components';

const useStyles = makeStyles(() => ({
    root: { width: '100%' },
    paper: {}
}));

const TreeFilter = ({ fieldId, items, setSelectedFilters }) => {
    const classes = useStyles();
    const [selected, setSelected] = useState([]);

    const handleCheck = (e) => {
        if (e.target.checked) {
            setSelected((selected) => [...selected, e.target.value]);
        } else {
            setSelected((selected) => selected.filter((x) => x !== e.target.value));
        }
    };
    useEffect(() => {
        setSelectedFilters({ field: fieldId, criterion: { in: selected }, selected: selected });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected]);

    const filterObjs = [];

    const showChildren = (children, level) => {
        const filterObjs = [];
        children.map((child) => {
            const childIdArray = child.split('@');
            if (childIdArray.length === level) {
                const thisChild = items.find((i) => i.id === child);
                const _item = {
                    id: thisChild.id,
                    name: thisChild.name,
                    count: thisChild.count
                };
                if (thisChild.children && thisChild.children.length) {
                    _item.children = showChildren(thisChild.children, level + 1);
                }
                filterObjs.push(_item);
            }

            return null;
        });

        return filterObjs;
    };
    items &&
        items.map((item) => {
            const idArray = item.id.split('@');
            if (idArray.length === 1) {
                const _item = {
                    id: item.id,
                    name: item.name,
                    count: item.count
                };
                if (item.children && item.children.length) {
                    _item.children = showChildren(item.children, 2);
                }
                filterObjs.push(_item);
            }

            return null;
        });

    const renderChildren = (items) => {
        const x = Object.keys(items).map((k) => {
            const isChecked = selected.find((f) => f.value === items[k].id);
            let content;
            if (items[k] && items[k].children && Object.keys(items[k].children).length > 0) {
                content = renderChildren(items[k].children);

                return (
                    <TreeItemCheckbox
                        value={items[k].id}
                        checked={isChecked}
                        key={items[k].id}
                        onChange={handleCheck}
                        label={`${items[k].name} (${items[k].count})`}
                    >
                        {content}
                    </TreeItemCheckbox>
                );
            } else {
                return (
                    <TreeItemCheckbox
                        value={items[k].id}
                        checked={isChecked}
                        key={items[k].id}
                        onChange={handleCheck}
                        label={`${items[k].name} (${items[k].count})`}
                    ></TreeItemCheckbox>
                );
            }
        });

        return x;
    };

    return (
        <div className={classes.root}>
            <Paper className={classes.paper} elevation={0}>
                {renderChildren(filterObjs)}
            </Paper>
        </div>
    );
};

TreeFilter.propTypes = {
    fieldId: PropTypes.any,
    items: PropTypes.any,
    setSelectedFilters: PropTypes.any
};

export default TreeFilter;
