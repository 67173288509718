/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import { ChevronRight, ExpandMore } from '@material-ui/icons';
import { TreeView } from '@material-ui/lab';

import { unitModel } from 'common/models';
import {
    buildUnitTree,
    isParentUnit,
    traverseChildUnitIds,
    traverseParentUnitIds,
    traverseSelectedSiblingUnitIds
} from 'helpers/unit';

import { RecursiveTreeItem } from './RecursiveTreeItem';

/**
 * To get the single select version add a `selectSingleUnit` function that takes in an instance of `unit`.
 * To get the multi select version add a ´selectedUnits` array containing string ids and a `setSelectedUnits` function.
 * If both are added you will only get the multi select version.
 */
export const UnitSelector = ({ selectedUnits, setSelectedUnits, selectSingleUnit, units }) => {
    const unitTree = buildUnitTree(units);

    const onSelectUnit = (unit) => {
        let ids = [unit.id];
        if (selectedUnits.find((id) => id === unit.id)) {
            if (isParentUnit(unit)) {
                ids = traverseChildUnitIds(unit, units);
            }

            if (unit.parentId !== null) {
                ids = ids.concat(traverseParentUnitIds(unit, units));
            }

            setSelectedUnits(selectedUnits.filter((id) => !ids.includes(id)));
        } else {
            if (isParentUnit(unit)) {
                ids = traverseChildUnitIds(unit, units);
            }

            if (unit.parentId !== null) {
                ids = ids.concat(traverseSelectedSiblingUnitIds(unit, units, selectedUnits));
            }

            setSelectedUnits([...selectedUnits, ...ids]);
        }
    };

    return (
        <TreeView defaultCollapseIcon={<ExpandMore />} defaultExpandIcon={<ChevronRight />}>
            <RecursiveTreeItem
                unitTree={unitTree}
                selectSingleUnit={selectSingleUnit}
                onSelectUnit={setSelectedUnits && onSelectUnit}
                selectedUnits={selectedUnits}
            />
        </TreeView>
    );
};

UnitSelector.propTypes = {
    units: PropTypes.arrayOf(PropTypes.shape(unitModel)).isRequired,
    selectedUnits: PropTypes.arrayOf(PropTypes.string),
    setSelectedUnits: PropTypes.func,
    selectSingleUnit: PropTypes.func
};
