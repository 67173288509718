import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { ApolloProvider } from '@apollo/client';

import { AuthContext } from 'context/AuthContext';
import { getApolloClient } from 'helpers/apollo';

/**
 * Apollo component creates a apolloClient dynamically based on the authenticated user in the UserContext.
 * The Apollo component has a client state that gets updated by useEffect if the userState in userContext changes
 */
export const Apollo = (props) => {
    const [client, setClient] = useState();
    const { authenticated } = useContext(AuthContext);

    useEffect(() => {
        const c = getApolloClient(new Date().getTime());
        setClient(c);
    }, [authenticated]);

    if (!client) return null;

    return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
};

Apollo.propTypes = {
    children: PropTypes.any
};
