import gql from 'graphql-tag';

export const SOFT_DELETE_UNIT = gql`
    mutation softDeleteUnit($id: ID!) {
        softDeleteUnit(unitId: $id)
    }
`;

export const RESTORE_SOFT_DELETED_UNIT = gql`
    mutation restoreSoftDeletedUnit($id: ID!) {
        restoreSoftDeletedUnit(unitId: $id)
    }
`;
