import gql from 'graphql-tag';

export const GET_UNIT_BUDGETS = gql`
    query ($unitId: ID!, $year: String) {
        getBudgetsByUnit(unitId: $unitId, year: $year) {
            id
            unitId
            name
            year
            created {
                user
                date
            }
            budgetEntries {
                unitId
                updates {
                    user
                    date
                    description
                }
                budgetLines {
                    name
                    period {
                        month
                        value
                        cut
                    }
                }
            }
        }
    }
`;
