import { startCase } from 'lodash';

const PENDING = 'Pending User';

export const formatName = (name) =>
    name
        .replace(/\s+/, ' ')
        .split(' ')
        .slice(0, 2)
        .map((v) => v && v[0].toUpperCase())
        .join('');

export function getInitials(user) {
    const name = getName(user);
    if (name === PENDING) {
        return 'PU';
    } else {
        return formatName(name);
    }
}

export function getName(user) {
    let name = PENDING;
    if (user.firstName || user.lastName) {
        name = [user.firstName || '', user.lastName || ''].join(' ').trim();
    }

    return startCase(name); // transform name into "Ola Normann" from "ola normann"
}

export function getShortName(user) {
    let name = PENDING;
    if (user.firstName || user.lastName) {
        name = [user.firstName || '', user.lastName ? user.lastName[0] : ''].join(' ').trim();
    }

    return startCase(name); // transform name into "Ola Normann" from "ola normann"
}
