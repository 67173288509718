import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';

import { interestModel, keywordModel } from '../../models/targeting';
import { TargetingChipContainer } from './TargetingChipContainer';
import { TargetingConditionHeader } from './TargetingConditionHeader';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%'
    }
}));

const KeywordSelectorWrapper = ({ children, onRemove, selectedItems, targetingConditionType }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <TargetingConditionHeader targetingConditionType={targetingConditionType} />
            {children}
            <TargetingChipContainer
                onRemove={onRemove}
                selectedItems={selectedItems}
                targetingConditionType={targetingConditionType}
            />
        </div>
    );
};

KeywordSelectorWrapper.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    onRemove: PropTypes.func.isRequired,
    selectedItems: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.shape(interestModel), PropTypes.arrayOf(PropTypes.shape(keywordModel)))
    ]),
    targetingConditionType: PropTypes.string.isRequired
};

export default KeywordSelectorWrapper;
