import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { addDays, startOfTomorrow } from 'date-fns';

import { useQuery } from '@apollo/client';
import { LinearProgress, Typography } from '@material-ui/core';

import { CampaignProvider } from 'context/campaign/CampaignContext';
import { Context as activeUnitContext } from 'context/units/activeUnitContext';
import { GET_CAMPAIGN_GROUP } from 'graphql/queries/object';

import { CampaignForm } from './CampaignForm';
import { CampaignSelector } from './CampaignSelector';

const getInitialState = (requestData, units, activeUnit) => {
    const initialState = { ...requestData, unitId: activeUnit.id, campaigns: requestData.campaigns || [] };

    initialState.campaigns = initialState.campaigns.map((campaign) => ({
        ...campaign,
        ads: new Map(
            campaign.adGroups[0].ads.map((ad) => [
                ad.advertiser.id,
                {
                    ...ad,
                    schedule: {
                        ...ad.schedule,
                        startDate: ad.schedule?.startDate ? new Date(ad.schedule?.startDate) : startOfTomorrow(),
                        endDate: ad.schedule?.endDate ? new Date(ad.schedule?.endDate) : addDays(startOfTomorrow(), 7)
                    },
                    creatives: ad.creatives?.map((creative) => {
                        const creativeContent = JSON.parse(creative.content || '{}');

                        return {
                            ...creative,
                            content: creativeContent,
                            networkAssets: {
                                facebookPage: { key: creativeContent.page_id }
                            },
                            items: creative.items?.map((item) => ({
                                ...item,
                                content: JSON.parse(item.content || '{}')
                            }))
                        };
                    })
                }
            ])
        ),
        units: campaign.adGroups[0].ads.map((ad) => ({ ...ad.advertiser, selected: true }))
    }));

    initialState.selectedCampaign = initialState.campaigns[0] ? 0 : undefined;
    initialState.availableUnits = units ? Array.from(units).filter((unit) => Boolean(unit.id)) : [];

    return initialState;
};

export const CampaignView = ({
    match: {
        params: { id }
    }
}) => {
    const { t } = useTranslation();
    const { loading, error, data } = useQuery(GET_CAMPAIGN_GROUP, { variables: { campaignGroupId: id } });
    const {
        state: { activeUnit, units }
    } = useContext(activeUnitContext);

    const isLoading = loading;
    const isError = error;

    return (
        <>
            {isError ? (
                <Typography variant='h6'>{t('Server error')}</Typography>
            ) : isLoading ? (
                <LinearProgress />
            ) : (
                data &&
                data.getCampaignGroup && (
                    <CampaignProvider initialState={getInitialState(data.getCampaignGroup, units, activeUnit.id)}>
                        <CampaignForm />
                        <CampaignSelector />
                    </CampaignProvider>
                )
            )}
        </>
    );
};

CampaignView.propTypes = {
    match: PropTypes.object
};
