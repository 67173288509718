import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: '100%'
    },
    label: {
        color: theme.palette.text.hint
    }
}));

export const FilterBy = ({ values, onChange }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const handleFilter = (e) => {
        onChange(e.target.value);
    };

    return (
        <FormControl variant='filled' className={classes.formControl}>
            <InputLabel id='multiple-select-filter' className={classes.label}>
                {t('Filter by')}
            </InputLabel>

            <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                multiple
                value={values}
                renderValue={(selected) => selected.join(',')}
                onChange={handleFilter}
            >
                <MenuItem value={'verified'} key={values}>
                    <Checkbox color='primary' checked={values && values.indexOf('verified') > -1} />
                    <ListItemText primary='verified' />
                </MenuItem>
                <MenuItem value={'notVerified'}>
                    <Checkbox color='primary' checked={values && values.indexOf('notVerified') > -1} />
                    <ListItemText primary='not verified' />
                </MenuItem>
                {/* <MenuItem value={'newest'}>Newest</MenuItem>
                <MenuItem value={'oldest'}>Oldest</MenuItem> */}
            </Select>
        </FormControl>
    );
};
FilterBy.propTypes = {
    values: PropTypes.array,
    onChange: PropTypes.func
};
