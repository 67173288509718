import React from 'react';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '34rem'
    },
    markdown: {
        color: '#263238',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        '& p': {
            marginBottom: theme.spacing(2),
            lineHeight: '21px',
            fontWeight: '400',
            fontSize: '14px'
        }
    }
}));

export const MarkdownTypography = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.markdown}>
                <ReactMarkdown source={props.source} />
            </div>
        </div>
    );
};

MarkdownTypography.propTypes = {
    source: PropTypes.any
};
