export const TargetingConditionType = Object.freeze({
    Include: 'include',
    Exclude: 'exclude'
});

export const TargetingType = Object.freeze({
    Demographics: 'DEMOGRAPHICS',
    Keywords: 'KEYWORDS',
    Places: 'PLACES',
    Interests: 'INTERESTS',
    Websites: 'WEBSITES',
    Devices: 'DEVICES'
});

export const KeywordMatchType = Object.freeze({
    Exact: 'EXACT',
    Phrase: 'PHRASE',
    Broad: 'BROAD'
});

export const Genders = Object.freeze({
    All: 'ALL',
    Female: 'FEMALE',
    Male: 'MALE'
});

export const selectedTypes = Object.freeze({
    None: 'NONE',
    Include: 'INCLUDE',
    Exclude: 'EXCLUDE'
});
