export const buildUnitTree = (units) => {
    const rootLevel = units.filter((unit) => unit.parentId === null);
    const childLevel = units.filter((unit) => unit.parentId !== null);
    const idMapping = units.reduce((treeMapping, unit, i) => {
        treeMapping[unit.id] = i;

        return treeMapping;
    }, {});

    const treeArray = [];

    rootLevel.forEach((parent) => {
        const root = parent;
        childLevel.forEach((unit) => {
            if (unit.parentId === null) {
                return;
            }
            const parentUnit = units[idMapping[unit.parentId]];

            if (parentUnit && !parentUnit.treeChildren) {
                parentUnit.treeChildren = [];
            }

            if (parentUnit && !parentUnit.treeChildren.find((el) => el.id === unit.id)) {
                parentUnit.treeChildren = sortUnitsAscending([...(parentUnit.treeChildren || []), unit]);
            } else {
                return;
            }
        });
        treeArray.push(root);
    });

    return sortUnitsAscending(treeArray);
};

export const buildFilteredUnitTree = (text, unitTree) => {
    if (text) {
        if (unitTree.treeChildren) {
            const treeChildren = [...unitTree.treeChildren];
            const filteredUnitTree = { ...unitTree };
            delete filteredUnitTree.treeChildren;
            const filteredTreeChildren = [];
            if (unitTree.name?.toLocaleLowerCase().includes(text.toLocaleLowerCase())) {
                treeChildren.forEach((child) => {
                    const childTree = buildFilteredUnitTree(text, child);
                    if (childTree) {
                        filteredTreeChildren.push(childTree);
                    }
                });
                if (filteredTreeChildren.length) {
                    filteredUnitTree.treeChildren = filteredTreeChildren;
                }
            } else {
                treeChildren.forEach((child) => {
                    const childTree = buildFilteredUnitTree(text, child);
                    if (childTree) {
                        filteredTreeChildren.push(childTree);
                    }
                });
                if (filteredTreeChildren.length) {
                    filteredUnitTree.treeChildren = filteredTreeChildren;
                } else {
                    return undefined;
                }
            }

            return filteredUnitTree;
        } else {
            if (unitTree.name?.toLocaleLowerCase().includes(text.toLocaleLowerCase())) {
                return unitTree;
            } else {
                return undefined;
            }
        }
    } else {
        return unitTree;
    }
};

export const findActiveUnitInTree = (activeUnit, unitTree) => {
    const root = { ...unitTree[0] };
    if (root.id === activeUnit.id) return root;
    if (root.treeChildren?.some((el) => el.id === activeUnit.id))
        return root.treeChildren.find((el) => el.id === activeUnit.id);

    const unitsQueue = [...root.treeChildren];
    while (unitsQueue.length > 0) {
        const unit = unitsQueue.shift();
        const children = unit.treeChildren || [];
        if (children.some((el) => el.id === activeUnit.id)) return children.find((el) => el.id === activeUnit.id);
        unitsQueue.push(...children);
    }
};

export const findAllNodesIdsWithChild = (unitTree) => {
    const ids = [];
    if (unitTree?.treeChildren) {
        const unitsQueue = [...unitTree.treeChildren];
        ids.push(unitTree.id);
        while (unitsQueue.length > 0) {
            const unit = unitsQueue.shift();
            if (unit.treeChildren) {
                const children = unit.treeChildren;
                unitsQueue.push(...children);
                ids.push(unit.id);
            }
        }
    }

    return ids;
};

export const sortUnitsAscending = (unitList) => {
    return unitList.sort((a, b) => {
        if (a.name < b.name) {
            return -1;
        }

        if (a.name > b.name) {
            return 1;
        }

        return 0;
    });
};

export const sortUnitsDescending = (unitList) => {
    return unitList.sort((a, b) => {
        if (a.name < b.name) {
            return 1;
        }

        if (a.name > b.name) {
            return -1;
        }

        return 0;
    });
};

export const isParentUnit = (unit) => {
    return (unit.treeChildren && unit.treeChildren.length !== 0) || false;
};

export const getChildUnitIds = (unit) => {
    return (unit.treeChildren && unit.treeChildren.map((unit) => unit.id)) || [];
};

export const traverseChildUnitIds = (unit, units) => {
    const childUnits = units.filter((el) => getChildUnitIds(unit).includes(el.id));

    return childUnits.reduce(
        (ids, childUnit) => {
            if (getChildUnitIds(childUnit).length !== 0) {
                return ids.concat(traverseChildUnitIds(childUnit, units));
            }
            ids.push(childUnit.id);

            return ids;
        },
        [unit.id]
    );
};

export const traverseParentUnitIds = (unit, units) => {
    const ids = [unit.ids];
    const parent = units.find((el) => el.id === unit.parentId);

    if (parent) {
        ids.push(parent.id);
        if (parent.parentId !== null) {
            return ids.concat(traverseParentUnitIds(parent, units));
        }
    }

    return ids;
};

export const traverseSelectedSiblingUnitIds = (unit, units, selectedUnits) => {
    const ids = [];
    const parent = units.find((el) => el.id === unit.parentId);
    const childrenIds = getChildUnitIds(parent);
    const selectedChildren = selectedUnits.filter((id) => childrenIds.includes(id));

    if (childrenIds.length - 1 === selectedChildren.length) {
        ids.push(parent.id);

        if (parent.parentId !== null) {
            return ids.concat(traverseSelectedSiblingUnitIds(parent, units, selectedUnits));
        }
    }

    return ids;
};
