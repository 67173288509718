import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';

import { IconButton, makeStyles } from '@material-ui/core';
import PhotoCamera from '@material-ui/icons/PhotoCamera';

const useStyles = (unit) =>
    makeStyles((theme) => ({
        container: {
            position: 'relative',
            justifyContent: 'center',
            '&:hover $overlay': {
                opacity: 0.8
            }
        },
        avatar: {
            width: theme.spacing(25),
            height: theme.spacing(25),
            backgroundImage: `url('${unit?.logo}')`,
            backgroundPosition: 'center',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            border: 0
        },
        overlay: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            width: theme.spacing(25),
            height: '100%',
            opacity: 0.3,
            transition: '.3s ease',
            backgroundColor: 'black',
            display: 'flex',
            justifyContent: 'center',
            zIndex: 9
        }
    }));

export const LogoUpload = ({ editingUnit, setEditingUnit, setIsEdited }) => {
    const classes = useStyles(editingUnit)();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const readFile = async (file) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.addEventListener('load', () => resolve(reader.result), false);
            reader.readAsDataURL(file);
        });
    };

    const onLoad = async (e) => {
        e.preventDefault();
        const file = e.target.files[0];

        if (file) {
            if (file.size < 1000000) {
                const logo = await readFile(e.target.files[0]);
                setEditingUnit({ ...editingUnit, logo });
                setIsEdited(true);
            } else {
                enqueueSnackbar(t('File cannot be larger than 1MB'), { variant: 'error' });
            }
        }
    };

    return (
        <div className={classes.container}>
            <input id='logo-upload' hidden accept='image/*' type='file' onChange={onLoad} />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor='logo-upload'>
                <div className={classes.avatar} />
                <div className={classes.overlay}>
                    <IconButton component='span' disableRipple disableFocusRipple size='medium'>
                        <PhotoCamera fontSize='large' color='inherit' />
                    </IconButton>
                </div>
            </label>
        </div>
    );
};

LogoUpload.propTypes = {
    editingUnit: PropTypes.object.isRequired,
    setEditingUnit: PropTypes.func.isRequired,
    setIsEdited: PropTypes.func.isRequired
};
