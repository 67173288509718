import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Grid, Input, InputAdornment, Slider, styled } from '@material-ui/core';
import { LocationSearching } from '@material-ui/icons';

const SliderContainer = styled('div')(() => ({
    width: '100%'
}));

export const RadiusSlider = ({ radius, setRadius }) => {
    const { t } = useTranslation();

    const [radiusState, setRadiusState] = useState(radius);

    const handleSliderChange = (_event, newValue) => {
        setRadiusState(newValue);
    };
    const handleSliderChangeCommited = (_event, newValue) => {
        setRadius(newValue);
    };

    const handleInputChange = (event) => {
        const radius = parseInt(event.target.value);
        const isDigit = new RegExp(/^\d+?$/);
        if (isDigit.test(radius)) {
            setRadius(radius);
            setRadiusState(radius);
        } else if (!radius) {
            setRadiusState(null);
            setRadius(0);
        }
    };

    const handleBlur = () => {
        if (radiusState < 1) {
            setRadiusState(1);
        } else if (radiusState > 70) {
            setRadiusState(70);
        }
    };

    return (
        <SliderContainer>
            <Grid container spacing={2} alignItems='center'>
                <Grid item>
                    <LocationSearching color='primary' />
                </Grid>
                <Grid item xs>
                    <Slider
                        value={typeof radiusState === 'number' ? radiusState : 1}
                        onChange={handleSliderChange}
                        onChangeCommitted={handleSliderChangeCommited}
                        getAriaValueText={(radius) => `${radius} ${t('Km').toLowerCase()}`}
                        aria-labelledby='radius-slider'
                        step={1}
                        min={1}
                        max={70}
                        valueLabelDisplay='auto'
                    />
                </Grid>
                <Grid item xs={4}>
                    <Input
                        value={radiusState}
                        margin='dense'
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        endAdornment={<InputAdornment position='end'>{t('Km').toLowerCase()}</InputAdornment>}
                        inputProps={{
                            step: 1,
                            min: 1,
                            max: 70,
                            'aria-labelledby': 'radius-slider'
                        }}
                    />
                </Grid>
            </Grid>
        </SliderContainer>
    );
};

RadiusSlider.propTypes = {
    radius: PropTypes.number,
    setRadius: PropTypes.func
};
