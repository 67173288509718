export const SUPPORTED_LANGUAGES = ['da-DK', 'de-DE', 'en-US', 'es-ES', 'fr-FR', 'nb-NO', 'sv-SE'];
export const DEFAULT_LANGUAGE = 'en-US';

export const LANGUAGES = {
    'de-DE': 'Deutsch',
    'da-DK': 'Dansk',
    'en-US': 'English',
    'es-ES': 'Español',
    'fr-FR': 'Français',
    'nb-NO': 'Norsk',
    'sv-SE': 'Svenska'
};
