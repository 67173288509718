import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';

import { useMutation, useQuery } from '@apollo/client';
import { Box, Button, Grid, LinearProgress, TextField, useMediaQuery, useTheme } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import { AgeRangeSelector } from 'common/components/AgeRangeSelector/AgeRangeSelector';
import { BlueExpansionPanel } from 'common/components/BlueExpansionPanel/BlueExpansionPanel';
import { BlueHeaderContainer } from 'common/components/BlueHeaderContainer/BlueHeaderContainer';
import { GenderSelector } from 'common/components/GenderSelector/GenderSelector';
import { GreyExpansionPanel } from 'common/components/GreyExpansionPanel/GreyExpansionPanel';
import { LocationSelector } from 'common/modules';
import { Context as UnitContext } from 'context/units/activeUnitContext';
import {
    CREATE_TARGETING_TEMPLATE,
    DELETE_TARGETING_TEMPLATE,
    SET_TARGET_DEFAULT,
    UPDATE_TARGETING_TEMPLATE
} from 'graphql/mutations/targeting';
import { GET_TARGETING_TEMPLATE } from 'graphql/queries/targeting';

// a filter used for the autocomplete module
const filter = createFilterOptions();

// TODO: build upon this when adding new fields
const defaultState = {
    id: null,
    name: 'Default template',
    gender: 'ALL',
    age: {
        from: 18,
        to: 65
    },
    locations: []
};

const TargetingTemplates = ({ enqueueSnackbar }) => {
    const { t } = useTranslation();

    const {
        state: { activeUnit }
    } = useContext(UnitContext);

    const theme = useTheme();
    const bigScreen = useMediaQuery(theme.breakpoints.up('md'));

    const [templateOptions, setOptions] = useState([]);
    const [autoCompleteValue, setAutocomplete] = useState(null);
    const [gqlData, setGqlData] = useState([]);

    const [templateState, setTemplateState] = useState(defaultState);
    const [backupState, setBackup] = useState(defaultState);
    const [defaultTemplate, setDefault] = useState(null);

    const [createTargeting] = useMutation(CREATE_TARGETING_TEMPLATE);
    const [updateTargeting] = useMutation(UPDATE_TARGETING_TEMPLATE);
    const [deleteTargeting] = useMutation(DELETE_TARGETING_TEMPLATE);
    const [setDefaultTargeting] = useMutation(SET_TARGET_DEFAULT);

    const setDefaultState = () => {
        setTemplateState(defaultState);
        setBackup(defaultState);
    };

    const setStateData = (data) => {
        if (data) {
            const dataWithFallback = {
                ...data,
                id: data.id || defaultState.id,
                name: data.name || defaultState.name,
                gender: data.gender || defaultState.gender,
                age: {
                    from: data.age.from || defaultState.age.from,
                    to: data.age.to || defaultState.age.to
                },
                locations: data.locations || defaultState.locations
            };
            setTemplateState(dataWithFallback);
            setBackup(dataWithFallback); // set the backup simultaneously upon data change.
        } else {
            setDefaultState();
        }
    };

    const { loading } = useQuery(GET_TARGETING_TEMPLATE, {
        variables: { unitId: activeUnit.id },
        onCompleted: (data) => {
            const targeting = data?.getTargetings;

            if (targeting?.length > 0) {
                setGqlData(targeting);

                const newOptions = targeting.map(({ name, id }) => ({ title: name, id }));
                if (activeUnit.targeting && activeUnit.targeting.id) {
                    const defaultTarget = newOptions.filter((opt) => opt.id === activeUnit.targeting.id)[0];
                    setDefault(defaultTarget);
                    setAutocomplete(defaultTarget);
                }
                setOptions(newOptions);
                setStateData(targeting.find((obj) => obj.id === activeUnit?.targeting?.id));

                enqueueSnackbar(t('Loaded targeting data'), { variant: 'success' });
            } else {
                enqueueSnackbar(t('No data found for the selected unit'), { variant: 'info' });
                setDefaultState();
            }
        },
        onError: () => {
            enqueueSnackbar(t('Error while fetching data'), { variant: 'error' });
        }
    });

    const addNewOption = (cache, { data }) => {
        // using cache to handle readQuery/writeQuery might become an option later on.
        const { name, id } = data.createTargeting;
        const newOption = {
            title: name,
            id: id
        };
        setAutocomplete(newOption);
        const currentOptions = templateOptions;
        currentOptions.push(newOption);
        setOptions(currentOptions);
    };

    /*
    desired functionality:
    - save if the ID does not exist in the backend
    - update the template if the ID exists
    */
    const handleSave = () => {
        if (!autoCompleteValue) {
            enqueueSnackbar(t('Missing template information'), { variant: 'error' });

            return;
        }
        const isNew =
            templateOptions.length === 0 ||
            templateOptions.filter((opt) => opt.id === autoCompleteValue.id).length === 0;

        const input = {
            ...templateState,
            unitId: activeUnit.id,
            name: autoCompleteValue.title
        };

        if (isNew) {
            createTargeting({
                variables: { input },
                update: addNewOption
            });
            enqueueSnackbar(t('Template {{ name }} saved', { name: autoCompleteValue.title }), {
                variant: 'success'
            });
            setBackup(templateState);
        } else {
            updateTargeting({
                variables: {
                    targetingID: templateState.id,
                    targetingInput: input
                }
            });
            setBackup(templateState);
            enqueueSnackbar(t('Template {{ name }} updated', { name: autoCompleteValue.title }), {
                variant: 'success'
            });
        }
    };

    const handleDelete = () => {
        // remove the to-be-deleted template from options
        templateOptions.splice(
            templateOptions.findIndex((opt) => opt.id === templateState.id),
            1
        );

        deleteTargeting({
            variables: {
                targetingID: templateState.id
            }
        });
        setDefaultState();
        setAutocomplete(null);
        enqueueSnackbar(t('Template {{ name }} deleted', { name: autoCompleteValue.title }), {
            variant: 'info'
        });
    };

    const handleAutocomplete = (value) => {
        // is the value input new and valid?
        if (value?.inputValue && typeof value.inputValue === 'string' && value.inputValue.length > 0) {
            setAutocomplete({ title: value.inputValue });
            setDefaultState();
            enqueueSnackbar(t('Added the template {{ name }}', { name: value.inputValue }), {
                variant: 'success'
            });
            // if adding a new template should enable the default state, uncomment below.
            // setDefaultState();
        } else if (value) {
            setAutocomplete(value);
            setStateData(gqlData.find((obj) => obj.id === value.id));
        }
    };

    const handleDefault = () => {
        if (!autoCompleteValue?.id) {
            return;
        }
        const validId = templateOptions.filter((opt) => opt.id === autoCompleteValue?.id).length > 0;
        if (validId) {
            setDefaultTargeting({
                variables: {
                    unitId: activeUnit.id,
                    targetingId: autoCompleteValue.id
                }
            });
            setDefault(autoCompleteValue);
            enqueueSnackbar(t('Set {{ name }} as default', { name: autoCompleteValue.title }), {
                variant: 'success'
            });
        } else {
            enqueueSnackbar(t('Cannot set unsaved template as default', { name: autoCompleteValue.title }), {
                variant: 'error'
            });
        }
    };

    const handleCancel = () => {
        enqueueSnackbar(t('Restoring to previous settings'), { variant: 'info' });
        setTemplateState(backupState);
        // if there's no saved template, reset the autocomplete field as well
        if (!autoCompleteValue?.id) {
            setAutocomplete(null);
        }
    };

    return (
        <BlueHeaderContainer title={t('Targeting templates')} style={{ padding: '5px' }}>
            <Grid container direction='column'>
                <Box mx={1} mt={3}>
                    <Grid container justify='space-between'>
                        <Grid container item xs={8}>
                            <Autocomplete
                                style={{ width: '100%' }}
                                value={autoCompleteValue}
                                onChange={(_, newValue) => {
                                    setTimeout(() => handleAutocomplete(newValue));
                                }}
                                filterOptions={(options, params) => {
                                    const filtered = filter(options, params);

                                    if (params.inputValue !== '') {
                                        filtered.push({
                                            inputValue: params.inputValue,
                                            title: t('Add {{ name }} to templates', { name: params.inputValue })
                                        });
                                    }

                                    return filtered;
                                }}
                                options={templateOptions || []}
                                getOptionLabel={(option) => {
                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    if (option.inputValue) {
                                        return option.inputValue;
                                    }

                                    return option.title;
                                }}
                                id='autocomplete-template-name'
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                                renderOption={(option) => option.title}
                                freeSolo
                                renderInput={(params) => (
                                    <TextField {...params} label={t('Select a template')} variant='filled' />
                                )}
                            />
                        </Grid>
                        <Grid container item xs={4} alignContent='center' justify='flex-end'>
                            <Box mr={bigScreen ? 3 : 0}>
                                {backupState === templateState && autoCompleteValue?.id ? (
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        disabled={defaultTemplate === autoCompleteValue}
                                        onClick={handleDefault}
                                    >
                                        {t('Set as default')}
                                    </Button>
                                ) : (
                                    <>
                                        <Button variant='contained' color='primary' onClick={handleSave}>
                                            {t('Save template')}
                                        </Button>
                                        <Button
                                            style={{ marginLeft: '1em' }}
                                            color='primary'
                                            startIcon={<Clear />}
                                            onClick={handleCancel}
                                        >
                                            {t('Cancel')}
                                        </Button>
                                    </>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box mt={3}>
                    <GreyExpansionPanel borderless title={t('Global settings for demographics')} defaultExpanded={true}>
                        <Grid container spacing={6} style={{ flexWrap: bigScreen ? 'nowrap' : 'wrap' }}>
                            <Grid item>
                                <GenderSelector
                                    gender={templateState.gender}
                                    setGender={(gender) => {
                                        setTemplateState({ ...templateState, gender });
                                    }}
                                />
                            </Grid>
                            <Grid item style={{ marginLeft: bigScreen ? '64px' : 0 }}>
                                <AgeRangeSelector
                                    ageRange={templateState.age}
                                    setAgeRange={(ageRange) => {
                                        const [from, to] = ageRange;
                                        setTemplateState({ ...templateState, age: { from, to } });
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </GreyExpansionPanel>
                </Box>

                <Box mt={3}>
                    <GreyExpansionPanel borderless title={t('Global settings for locations')} defaultExpanded={true}>
                        {loading ? (
                            <LinearProgress color='primary' />
                        ) : (
                            <LocationSelector
                                locations={templateState.locations}
                                addLocation={(loc) => {
                                    const locations = templateState.locations;
                                    locations.push(loc);
                                    setTemplateState({ ...templateState, locations });
                                }}
                                updateLocation={(index, loc) => {
                                    if (templateState.locations.length === 0) {
                                        return;
                                    }
                                    if (!index && templateState.locations.length === 1) {
                                        index = templateState.locations.length - 1;
                                    }
                                    const locations = templateState.locations;
                                    if (locations && locations[index]) {
                                        // loc will not contain radius - keep the old one
                                        const updatedLocation = { radius: locations[index].radius, ...loc };
                                        locations.splice(index, 1, updatedLocation);
                                        setTemplateState({ ...templateState, locations });
                                    }
                                }}
                                removeLocation={(locationIndex) => {
                                    const currentLocations = templateState.locations;
                                    currentLocations.splice(locationIndex, 1);
                                    setTemplateState({ ...templateState, locations: currentLocations });
                                }}
                                setRadius={(index, radius) => {
                                    const locations = templateState.locations;
                                    locations[index].radius = radius;
                                    setTemplateState({ ...templateState, locations });
                                }}
                            />
                        )}
                    </GreyExpansionPanel>
                </Box>

                <Box mt={1}>
                    <BlueExpansionPanel title={t('Targeting options for networks')} defaultExpanded>
                        expanded content
                    </BlueExpansionPanel>
                    <Grid container alignContent='center'>
                        <Grid item xs={12}>
                            <Button variant='contained' color='secondary' onClick={handleDelete}>
                                {t('Delete template')}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </BlueHeaderContainer>
    );
};

TargetingTemplates.propTypes = {
    enqueueSnackbar: PropTypes.any
};

export default withSnackbar(TargetingTemplates);
