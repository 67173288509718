import gql from 'graphql-tag';

import { FRAGMENT_CAMPAIGN } from '../fragments/campaign';
import { FRAGMENT_PRODUCT } from '../fragments/products';

export const CREATE_FEED = gql`
    mutation createFeed($name: String, $url: string, $unitId: ID) {
        createFeed(name: $name, url: $url, unitId: $unitId) {
            id
            unitId
            name
            rl
            fields {
                name
                field
                type
            }
        }
    }
`;
export const UPDATE_FEED_FILTERS = gql`
    mutation updateFeedFilters($feedId: ID, $input: FeedFilterInput) {
        updateFeedFilters(feedId: $feedId, input: $input) {
            id
            unitId
            name
            url
            fields {
                name
                field
                type
            }
        }
    }
`;

export const UPDATE_FEED_FIELDS = gql`
    mutation UpdateFeedFields($input: FeedFieldsInput) {
        updateFeedFields(input: $input) {
            id
            unitId
            name
            url
            fields {
                name
                label
                visibility
                type
            }
        }
    }
`;

export const FETCH_PRODUCTS_FROM_SOURCE = gql`
    mutation FetchProductsFromSource($feedId: ID, $unitId: ID) {
        FetchProductsFromSource(feedId: $feedId, unitId: $unitId)
    }
`;

export const UPDATE_FILTERS = gql`
    mutation UpdateFilters($feedId: ID, $unitId: ID) {
        updateFilters(feedId: $feedId, unitId: $unitId)
    }
`;

export const CREATE_INVITED_USER = gql`
    mutation createInvitedUser($unitId: ID!, $firstName: String, $lastName: String, $email: String!) {
        createInvitedUser(unitId: $unitId, firstName: $firstName, lastName: $lastName, email: $email) {
            id
            users {
                id
                email
                firstName
                lastName
                phone
                verified
            }
        }
    }
`;

export const VERIFY_INVITED_USER = gql`
    mutation verifyInvitedUser($input: VerifyInvitedUserInput) {
        verifyInvitedUser(input: $input)
    }
`;

export const CREATE_CAMPAIGN_IN_CAMPAIGN_GROUP = gql`
    mutation createCampaignInCampaignGroup($campaignGroupId: ID!, $campaignInput: CampaignInput!) {
        createCampaignInCampaignGroup(campaignGroupId: $campaignGroupId, campaignInput: $campaignInput) {
            ...campaign
        }
    }
    ${FRAGMENT_CAMPAIGN}
`;
export const UPDATE_CAMPAIGN = gql`
    mutation updateCampaign($campaign: CampaignInput!) {
        updateCampaign(campaign: $campaign)
    }
`;
export const CREATE_CAMPAIGN_GROUP = gql`
    mutation createCampaignGroup($campaignGroupInput: CampaignGroupInput!) {
        createCampaignGroup(campaignGroupInput: $campaignGroupInput) {
            id
            unitId
            name
            campaigns {
                ...campaign
            }
        }
    }
    ${FRAGMENT_CAMPAIGN}
`;
export const UPDATE_CAMPAIGN_GROUP = gql`
    mutation updateCampaignGroup($campaignGroupInput: CampaignGroupInput!) {
        updateCampaignGroup(campaignGroupInput: $campaignGroupInput) {
            id
            unitId
            name
            campaigns {
                ...campaign
            }
        }
    }
    ${FRAGMENT_CAMPAIGN}
`;

export const PUBLISH_CAMPAIGN_TO_GOOGLE = gql`
    mutation publishCampaignToGoogle($campaignId: ID!) {
        publishCampaignToGoogle(campaignId: $campaignId)
    }
`;

export const PUBLISH_CAMPAIGNGROUP = gql`
    mutation publishCampaignGroup($campaignGroupInput: CampaignGroupInput!) {
        publishCampaignGroup(campaignGroupInput: $campaignGroupInput) {
            id
            unitId
            name
            campaigns {
                ...campaign
            }
        }
    }
    ${FRAGMENT_CAMPAIGN}
`;

export const RECOVER_ACCOUNT = gql`
    mutation recoverAccount($email: String!) {
        recoverAccountWithEmail(email: $email) {
            found
            sent
        }
    }
`;

export const CREATE_PRODUCT = gql`
    mutation createProduct($productTypeId: ID!, $unitId: ID, $values: [ProductFieldInput]) {
        createProduct(productTypeId: $productTypeId, unitId: $unitId, values: $values) {
            ...product
        }
    }
    ${FRAGMENT_PRODUCT}
`;

export const DELETE_PRODUCT = gql`
    mutation deleteProduct($productId: ID!) {
        deleteProduct(productId: $productId) {
            ...product
        }
    }
    ${FRAGMENT_PRODUCT}
`;

export const UPDATE_PRODUCT = gql`
    mutation updateProduct($productId: ID!, $values: [ProductFieldInput]) {
        updateProduct(productId: $productId, values: $values) {
            ...product
        }
    }
    ${FRAGMENT_PRODUCT}
`;

export const SINGLE_PRODUCT_IMAGE_UPLOAD = gql`
    mutation singleProductImageUpload($file: Upload!) {
        singleProductImageUpload(file: $file) {
            filename
            mimetype
            encoding
            url
        }
    }
`;
