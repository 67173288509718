import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import { usePrevious } from 'hooks/usePrevious';

const { NODE_ENV } = process.env;

const GoogleAnalytics = ({ location, options }) => {
    const prevPath = usePrevious(location.pathname);
    const prevSearch = usePrevious(location.search);

    useEffect(() => {
        const logPageChange = (pathname, search = '') => {
            const page = pathname + search;
            const { location } = window;
            ReactGA.set({
                page,
                //eslint-disable-next-line
                location: `${location.origin}${page}`,
                ...options
            });
            ReactGA.pageview(page);
        };

        if (!prevPath) {
            logPageChange(location.pathname, location.search);
        }

        if (location.pathname !== prevPath || location.search !== prevSearch) {
            logPageChange(location.pathname, location.search);
        }
    });

    return null;
};

GoogleAnalytics.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string,
        search: PropTypes.string
    }).isRequired,
    options: PropTypes.object
};

const RouteTracker = () => <Route component={GoogleAnalytics} />;

const init = () => {
    const isGAEnabled = NODE_ENV === 'production';

    if (isGAEnabled) {
        ReactGA.initialize('UA-82223544-5');
    }

    return isGAEnabled;
};

export const GA = {
    GoogleAnalytics,
    RouteTracker,
    init
};
