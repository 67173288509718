import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import { compareAsc, parseISO } from 'date-fns';
import { clone, cloneDeep, debounce, flattenDeep, sum } from 'lodash';
import { useSnackbar } from 'notistack';

import { useMutation, useQuery } from '@apollo/client';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Grid,
    IconButton,
    makeStyles,
    Table,
    TableBody,
    TableContainer,
    TextField,
    Typography
} from '@material-ui/core';
import { Clear, Done, ExpandMoreOutlined } from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';
import { withStyles } from '@material-ui/styles';

import { createBudgetEntry, UnitBudgetModel } from 'common/models/budget';
import { Context as UnitContext } from 'context/units/activeUnitContext';
import { CREATE_BUDGET, DELETE_BUDGET, UPDATE_BUDGET_ENTRIES, UPDATE_BUDGET_NAME } from 'graphql/mutations/budget';
import { GET_UNIT_BUDGETS } from 'graphql/queries/budget';
import { USER_ID } from 'graphql/queries/user';

import BudgetEntry from './components/BudgetEntry';
import Footer from './components/Footer';
import Header from './components/Header';
import MonthHeader from './components/MonthHeader';
import { SelectionRow } from './components/RowTypes';
import BudgetEntryDialog from './dialogs/BudgetEntryDialog';
import NewBudgetDialog from './dialogs/NewBudgetDialog';
import { fillPeriodZeroValues, formatDate, getMixedValues } from './helpers';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            borderBottom: 'unset'
        },
        '& .MuiTextField-root': {
            margin: theme.spacing(1)
        }
    },
    paper: {
        // margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(4)
    },
    headerContent: {
        marginLeft: theme.spacing(2)
    },
    noPadding: {
        padding: 0
    },
    alignCell: {
        alignItem: 'center',
        minWidth: '250px'
    },
    selectionCell: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        marginLeft: theme.spacing(10),
        backgroundColor: null
    },
    blueHeader: {
        padding: theme.spacing(3),
        backgroundColor: theme.palette.primary[300]
    },
    blueCell: {
        backgroundColor: theme.palette.primary[300],
        color: 'white',
        padding: 0,
        borderLeft: `1px solid ${theme.palette.primary[400]}`,
        minWidth: '100px'
    }
}));

const AccordionSummaryLeftExpanded = withStyles({
    expandIcon: {
        order: -1
    }
})(AccordionSummary);

const Budgets = () => {
    const {
        state: { activeUnit }
    } = useContext(UnitContext);
    const { t } = useTranslation();

    const classes = useStyles();
    const [user, setUser] = useState(null);
    const [budgets, setBudgets] = useState([]);
    const [year] = useState(new Date().getFullYear());

    const [newBudgetDialogOpen, setNewBudgetDialogOpen] = useState(false);
    const [createBudgetMutation] = useMutation(CREATE_BUDGET);

    const { loading: userLoading } = useQuery(USER_ID, {
        onCompleted: (data) => {
            setUser(data.user.id);
        }
    });

    const { loading: budgetsLoading } = useQuery(GET_UNIT_BUDGETS, {
        variables: {
            unitId: activeUnit.id,
            year: year.toString()
        },
        onCompleted: (data) => {
            data = data.getBudgetsByUnit;
            if (data) {
                setBudgets(data);
            } else {
                return;
            }
        }
    });

    const handleAdd = (newBudgetName) => {
        let newBudget = null;
        const date = new Date();
        createBudgetMutation({
            variables: {
                budgetInput: {
                    unitId: activeUnit.id,
                    name: newBudgetName,
                    year: date.getFullYear().toString(),
                    created: {
                        user: user,
                        date: date.toISOString()
                    }
                }
            }
        }).then((res) => {
            newBudget = res.data.createBudget;
            const newBudgets = [...budgets];
            newBudgets.push(newBudget);
            setBudgets(newBudgets);
        });
    };

    const handleDelete = (index) => {
        const newBudgets = [...budgets];
        newBudgets.splice(index, 1);
        setBudgets(newBudgets);
    };

    return (
        <>
            <Header
                title={t('Budget')}
                subtitle={null}
                handleAdd={() => setNewBudgetDialogOpen(true)}
                className={classes.paper}
            />
            <NewBudgetDialog
                open={newBudgetDialogOpen}
                close={() => setNewBudgetDialogOpen(false)}
                handleSave={(budgetName) => {
                    handleAdd(budgetName);
                    setNewBudgetDialogOpen(false);
                }}
            />
            {!userLoading &&
                !budgetsLoading &&
                budgets.length > 0 &&
                budgets.map((budget, index) => (
                    <UnitBudget user={user} key={budget.id} budget={budget} onDelete={() => handleDelete(index)} />
                ))}
        </>
    );
};

const UnitBudget = ({ budget, user, onDelete }) => {
    const classes = useStyles();
    const {
        state: { units }
    } = useContext(UnitContext);
    const { t } = useTranslation();

    const { enqueueSnackbar } = useSnackbar();

    const [deleteBudgetMutation] = useMutation(DELETE_BUDGET);
    const [saveEntriesMutation] = useMutation(UPDATE_BUDGET_ENTRIES);
    const [updateBudgetName] = useMutation(UPDATE_BUDGET_NAME);

    // to keep a backup when unitBudget changes, and the initial state needs to be modified (cancel after save)
    const [initialBudget, setInitialBudget] = useState(budget);
    const [unitBudget, setUnitBudget] = useState(budget);
    const [budgetName, setBudgetName] = useState(`${t('Loading')}...`);
    const [totalBudget, setTotalBudget] = useState(0);

    const [selectedUnits, setSelectedUnits] = useState(Array(100).fill(false)); // 100 is set as a maximum number of units budgets. this is an optimalization feature, as keeping track of indexes and length is unnecessary.
    const [selectedBudget, setSelectedBudget] = useState([]);

    const [lastChanged, setLastChanged] = useState(null);

    const [entryToDuplicate, setEntryToDuplicate] = useState(null);
    const [duplicateDialogOpen, setDuplicateDialogOpen] = useState(false);

    const [addUnitDialogOpen, setAddUnitDialogOpen] = useState(false);

    const [budgetRename, setBudgetRename] = useState(false);

    useEffect(() => {
        if (unitBudget && unitBudget.budgetEntries.length > 0) {
            const allValues = unitBudget.budgetEntries.map((entry) =>
                entry.budgetLines.map((line) => fillPeriodZeroValues(line.period))
            );
            setTotalBudget(sum(flattenDeep(allValues)));

            setBudgetName(unitBudget.name);

            const lastUpdate = unitBudget.budgetEntries
                .map((entry) => entry.updates.map((update) => parseISO(update.date)))
                .flat()
                .sort(compareAsc);

            const lastUpdated = lastUpdate[lastUpdate.length - 1];
            setLastChanged(formatDate(lastUpdated));
        }
    }, [unitBudget]);

    useEffect(() => {
        const budget = {};
        selectedUnits.forEach((unit, unitIndex) => {
            if (unit) {
                const lines = unitBudget.budgetEntries[unitIndex].budgetLines;
                lines.forEach((line) => {
                    if (!budget[line.name]) {
                        budget[line.name] = [];
                    }
                    const periodValues = fillPeriodZeroValues(line.period);
                    budget[line.name].push(periodValues);
                });
            }
        });
        if (budget) {
            const mixedBudgets = Object.keys(budget).map((budgetType) => {
                return {
                    budgetType: budgetType,
                    values: getMixedValues(budget[budgetType], t('Mixed').toLowerCase())
                };
            });
            setSelectedBudget(mixedBudgets);
        }
    }, [selectedUnits, t, unitBudget.budgetEntries]);

    const handleDelete = () => {
        deleteBudgetMutation({
            variables: {
                budgetId: budget.id
            }
        }).then(() => {
            onDelete();
        });
    };

    const updateEntries = (entry) => {
        const entries = clone(unitBudget.budgetEntries);
        if (typeof entry === 'number') {
            // delete if the entry supplied is a number (i.e. index)
            entries.splice(entry, 1);
        } else {
            entries.push(entry);
        }

        setUnitBudget({
            ...unitBudget,
            budgetEntries: entries
        });
    };

    const handleSelection = (value, index) => {
        const selected = [...selectedUnits];
        selected[index] = value;
        setSelectedUnits(selected);
    };

    const handleDuplicateSave = (unitId) => {
        setDuplicateDialogOpen(false);
        const duplicatedWithUnit = {
            ...entryToDuplicate,
            unitId: unitId
        };
        updateEntries(duplicatedWithUnit);
    };

    const handleAddUnit = (unitId) => {
        setAddUnitDialogOpen(false);
        const entry = createBudgetEntry(unitId, user);
        updateEntries(entry);
    };

    const handleDeleteEntry = (entryIndex) => {
        updateEntries(entryIndex);
    };

    const clearSelected = () => {
        setSelectedUnits(Array(100).fill(false));
    };

    const handleBudgetSave = () => {
        clearSelected();
        saveEntriesMutation({
            variables: {
                budgetId: unitBudget.id,
                entries: unitBudget.budgetEntries
            }
        }).then(() => {
            enqueueSnackbar(t('Saved budgets'), { variant: 'success' });
        });
        setInitialBudget(unitBudget);
    };

    const handleBudgetCancel = () => {
        // restore to original or previously saved budget
        clearSelected();
        setUnitBudget(initialBudget);
        updateBudgetName({
            variables: {
                budgetId: budget.id,
                name: initialBudget.name
            }
        });
        enqueueSnackbar(t('Changes reset'), { variant: 'info' });
    };

    const updateEntry = debounce((budgetLines, entryIndex) => {
        const entries = [...unitBudget.budgetEntries];

        entries[entryIndex] = {
            ...entries[entryIndex],
            budgetLines: budgetLines
        };

        setUnitBudget({
            ...unitBudget,
            budgetEntries: entries
        });
    }, 200);

    const handleUpdateSelection = debounce((budgetIndex, valueIndex, value) => {
        const updatedBudget = cloneDeep(unitBudget);
        selectedUnits.forEach((unit, unitIndex) => {
            if (unit) {
                updatedBudget.budgetEntries[unitIndex].budgetLines[budgetIndex].period[valueIndex].value = value;
            }
        });
        setUnitBudget(updatedBudget);
    }, 200);

    const handleBudgetRename = debounce((name) => setBudgetName(name), 200);

    return (
        <Box my={2}>
            <BudgetEntryDialog
                text={`${t('Duplicate entry to unit')}:`}
                open={duplicateDialogOpen}
                close={() => setDuplicateDialogOpen(false)}
                handleSave={handleDuplicateSave}
                units={units}
            />
            <BudgetEntryDialog
                text={t('Add a new entry for unit:')}
                open={addUnitDialogOpen}
                close={() => setAddUnitDialogOpen(false)}
                handleSave={handleAddUnit}
                units={units}
            />
            <Accordion defaultExpanded className={classes.root}>
                <AccordionSummaryLeftExpanded
                    expandIcon={<ExpandMoreOutlined />}
                    aria-controls='panel-content'
                    id='panel-header'
                >
                    <Grid container justify='space-between' alignItems='center' className={classes.headerContent}>
                        <Grid item xs={6} container justify='flex-start' alignItems='center'>
                            {budgetRename ? (
                                <>
                                    <TextField
                                        defaultValue={budgetName}
                                        placeholder={`${t('Budget')}...`}
                                        onClick={(event) => event.stopPropagation()}
                                        onChange={(event) => handleBudgetRename(event.target.value)}
                                    ></TextField>
                                    <IconButton
                                        onClick={(event) => {
                                            event.stopPropagation(); // avoid collapsing the accordion

                                            setUnitBudget({
                                                ...unitBudget,
                                                name: budgetName
                                            });

                                            updateBudgetName({
                                                variables: {
                                                    budgetId: unitBudget.id,
                                                    name: budgetName
                                                }
                                            });

                                            setBudgetRename(false);
                                        }}
                                    >
                                        <Done />
                                    </IconButton>
                                    <IconButton
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            setBudgetName(unitBudget.name);
                                            setBudgetRename(false);
                                        }}
                                    >
                                        <Clear />
                                    </IconButton>
                                </>
                            ) : (
                                <>
                                    <Typography variant='h6' noWrap>
                                        {budgetName}
                                    </Typography>
                                    <IconButton
                                        onClick={(event) => {
                                            event.stopPropagation(); // avoid collapsing the accordion
                                            setBudgetRename(true);
                                        }}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </>
                            )}
                        </Grid>
                        <Grid item xs={4} container justifyContent='center'>
                            <Grid direction='column' container>
                                <Grid container justifyContent='space-between'>
                                    <Grid item xs>
                                        {t('Created')}:
                                    </Grid>
                                    <Grid item xs>
                                        {unitBudget.created ? formatDate(new Date(unitBudget.created.date)) : 'Unknown'}
                                    </Grid>
                                </Grid>
                                <Grid container justifyContent='space-between'>
                                    <Grid item xs>
                                        {t('Last updated')}:
                                    </Grid>
                                    <Grid item xs>
                                        {lastChanged}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs container direction='column' justifyContent='flex-end'>
                            <Typography variant='h6'>{t('view.campaign.budgetSumLabel')}:</Typography>
                            <Typography variant='h6'>{totalBudget}</Typography>
                        </Grid>
                    </Grid>
                </AccordionSummaryLeftExpanded>
                <AccordionDetails className={classes.noPadding}>
                    <Grid item xs={12}>
                        <Grid item>
                            <TableContainer>
                                <Table stickyHeader aria-label='collapsable-table' size='small'>
                                    <MonthHeader />
                                    <TableBody>
                                        {selectedBudget.length > 0 && (
                                            <SelectionRow
                                                numSelected={selectedUnits.filter((unit) => !!unit).length}
                                                budgets={selectedBudget}
                                                handleUpdate={handleUpdateSelection}
                                            />
                                        )}
                                        {unitBudget.budgetEntries.map((entry, entryIndex) => (
                                            <BudgetEntry
                                                key={`entry-${entryIndex}`}
                                                entry={entry}
                                                selected={selectedUnits[entryIndex]}
                                                handleDelete={() => {
                                                    handleDeleteEntry(entryIndex);
                                                }}
                                                handleDuplicate={() => {
                                                    setEntryToDuplicate(entry);
                                                    setDuplicateDialogOpen(true);
                                                }}
                                                handleEntryUpdate={(budgetLine) => {
                                                    updateEntry(budgetLine, entryIndex);
                                                }}
                                                handleSelection={(value) => {
                                                    handleSelection(value, entryIndex);
                                                }}
                                            />
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Footer
                            showButtons={unitBudget !== initialBudget}
                            handleAdd={() => setAddUnitDialogOpen(true)}
                            handleSave={handleBudgetSave}
                            handleCancel={handleBudgetCancel}
                            handleDelete={handleDelete}
                        />
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

UnitBudget.propTypes = {
    budget: UnitBudgetModel,
    user: PropTypes.string,
    onDelete: PropTypes.func
};

export default Budgets;
