import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { FormControl, TableCell, TextField } from '@material-ui/core';

import { productFieldModel } from 'common/models/product';

const MTableHeaderEditField = ({ field, onFieldUpdated }) => {
    const [label, setLabel] = useState(field.label);
    const [isValid, setIsValid] = useState(true);
    const [isChanged, setIsChanged] = useState(false);

    const { t } = useTranslation();

    const handleChange = (e) => {
        setLabel(e.target.value);
        setIsChanged(true);
        e.target.value.length === 0 ? setIsValid(false) : setIsValid(true);
    };

    const handleBlur = () => {
        if (isChanged) {
            onFieldUpdated({
                name: field.name,
                isValid: isValid,
                label: label
            });
        }
    };

    const keypressHandler = (event) => {
        if (event.key === 'Enter') {
            document.activeElement.blur();
        }
    };

    return (
        <TableCell key={'the_' + field.label}>
            <FormControl>
                <TextField
                    id={'the_tf_' + field.name}
                    key={'the_tf_' + field.name}
                    required
                    error={!isValid}
                    helperText={!isValid ? t('Field is empty') : null}
                    onKeyPress={(event) => keypressHandler(event)}
                    label={field.name}
                    defaultValue={label}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </FormControl>
        </TableCell>
    );
};

export default MTableHeaderEditField;

MTableHeaderEditField.propTypes = {
    field: PropTypes.shape(productFieldModel),
    onFieldUpdated: PropTypes.func
};
