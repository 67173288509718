import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Menu, MenuItem } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';

import 'i18n';

import { DEFAULT_LANGUAGE, LANGUAGES, SUPPORTED_LANGUAGES } from 'i18n/utils';

export const LanguageSelector = () => {
    const { i18n } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selection, setSelection] = useState(
        SUPPORTED_LANGUAGES.includes(i18n.language) ? i18n.language : DEFAULT_LANGUAGE
    );

    const changeLanguage = (languageCode) => () => {
        document.documentElement.lang = languageCode;
        i18n.changeLanguage(languageCode);
        setSelection(languageCode);
        handleClose();
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Button
                color='primary'
                onClick={handleClick}
                aria-controls='language-selector'
                aria-haspopup='true'
                startIcon={<ArrowDropDown style={{ height: '24px', width: '24px' }} />}
                variant='contained'
            >
                {LANGUAGES[selection]}
            </Button>
            <Menu id='language-selector' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem onClick={changeLanguage('de-DE')}>{LANGUAGES['de-DE']}</MenuItem>
                <MenuItem onClick={changeLanguage('da-DK')}>{LANGUAGES['da-DK']}</MenuItem>
                <MenuItem onClick={changeLanguage('en-US')}>{LANGUAGES['en-US']}</MenuItem>
                <MenuItem onClick={changeLanguage('es-ES')}>{LANGUAGES['es-ES']}</MenuItem>
                <MenuItem onClick={changeLanguage('fr-FR')}>{LANGUAGES['fr-FR']}</MenuItem>
                <MenuItem onClick={changeLanguage('nb-NO')}>{LANGUAGES['nb-NO']}</MenuItem>
                <MenuItem onClick={changeLanguage('sv-SE')}>{LANGUAGES['sv-SE']}</MenuItem>
            </Menu>
        </>
    );
};
