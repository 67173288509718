import React from 'react';
import PropTypes from 'prop-types';

import { Avatar, Box, CircularProgress, Grid, IconButton, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/styles';

import theme from 'layouts/theme';

const defaultStyles = {
    root: {
        background: theme.palette.background.filled,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 0,
        textAlign: 'left',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 56,
        '&.MuiButton-root': {
            backgroundColor: theme.palette.background.filled
        }
    }
};

const StyledBox = withStyles(() => ({
    root: (props) => props.styles.root
}))(Box);

const useStyles = makeStyles((theme) => ({
    avatar: {
        height: 40,
        width: 40,
        margin: theme.spacing(1)
    },
    titleContainer: {
        textAlign: 'left',
        width: 'calc(100% - 140px)'
    },
    iconContainer: { textAlign: 'right' }
}));

export const StyledSelectorBox = ({
    icon = null,
    label,
    styles = defaultStyles,
    onClick,
    handleDeleteItem,
    itemId,
    isLoading,
    thumbnailUrl,
    placeholderText = 'No placeholder text' //TODO transl
}) => {
    const { avatar, iconContainer, titleContainer } = useStyles();

    return (
        <StyledBox id='styled-selector-box' styles={styles}>
            {isLoading ? (
                <Grid container item justify='center'>
                    <CircularProgress />
                </Grid>
            ) : (
                <Grid container justify='space-between' alignItems='center' spacing={1}>
                    <Grid item>
                        {thumbnailUrl && <Avatar className={avatar} alt='label' src={thumbnailUrl}></Avatar>}
                    </Grid>

                    <Grid className={titleContainer} item xs>
                        <Typography noWrap>{label || placeholderText}</Typography>
                    </Grid>
                    <Grid className={iconContainer} item>
                        <IconButton onClick={onClick} color='primary'>
                            {icon}
                        </IconButton>
                        {thumbnailUrl && (
                            <IconButton onClick={() => handleDeleteItem(itemId)} color='primary'>
                                <DeleteIcon />
                            </IconButton>
                        )}
                    </Grid>
                </Grid>
            )}
        </StyledBox>
    );
};

StyledSelectorBox.propTypes = {
    icon: PropTypes.node,
    label: PropTypes.string,
    itemId: PropTypes.string,
    styles: PropTypes.object,
    isLoading: PropTypes.bool,
    onClick: PropTypes.func,
    handleDeleteItem: PropTypes.func,
    thumbnailUrl: PropTypes.string,
    placeholderText: PropTypes.string
};
