import { updateAds } from './merge';

export const CREATIVE_ACTION_TYPE = {
    CREATIVE_ADD: 'creativeAdd',
    CREATIVE_SET_NAME: 'creativeSetName',
    CREATIVE_ITEM_ADD: 'creativeItemAdd',
    CREATIVE_ITEM_SET: 'creativeItemSet',
    CREATIVE_ITEM_DELETE: 'creativeItemDelete',
    CREATIVE_ITEM_SET_PRODUCT_ID: 'creativeItemSetProductId',
    CREATIVE_UPDATE_FIELD: 'creativeUpdateField',
    CREATIVE_ITEM_UPDATE_FIELD: 'creativeItemUpdateField'
};

const unmarshalItem = (item) => ({ ...item, content: JSON.parse(item.content) });

export const creativesReducer = (state, action) => {
    switch (action.type) {
        case CREATIVE_ACTION_TYPE.CREATIVE_ADD: {
            const creativeMap = new Map(
                action.creativesMap?.map(({ unitId, creatives, networkAssets }) => [
                    unitId,
                    creatives?.map((creative) => {
                        return {
                            ...creative,
                            content: {
                                ...JSON.parse(creative.content),
                                page_id: networkAssets.facebookPage?.key
                            },
                            networkAssets,
                            items: creative.items.map(unmarshalItem)
                        };
                    })
                ])
            );

            return updateAds(state, 'creatives', (creatives, unitId) => creativeMap.get(unitId) || creatives);
        }
        case CREATIVE_ACTION_TYPE.CREATIVE_SET_NAME: {
            return updateAds(state, 'creatives', (creatives) => {
                return creatives.map((creative) =>
                    action.formatIds.includes(creative.formatId) ? { ...creative, name: action.name } : creative
                );
            });
        }
        case CREATIVE_ACTION_TYPE.CREATIVE_ITEM_SET_PRODUCT_ID: {
            return updateAds(state, 'creatives', (creatives) => {
                return creatives.map((creative) =>
                    action.formatIds.includes(creative.formatId)
                        ? {
                              ...creative,
                              items: creative.items.map((item) =>
                                  item.creativeGroupingId === action.creativeGroupingId
                                      ? {
                                            ...item,
                                            product: {
                                                id: action.productId
                                            },
                                            productId: action.productId //TODO: Remove one of these when backend structure is updated
                                        }
                                      : item
                              )
                          }
                        : creative
                );
            });
        }
        case CREATIVE_ACTION_TYPE.CREATIVE_UPDATE_FIELD: {
            return updateAds(state, 'creatives', (creatives) => {
                return creatives.map((creative) => {
                    if (action.formatIds.includes(creative.formatId)) {
                        const updatedCreative = {
                            ...creative,
                            content: creative.content || {}
                        };

                        switch (action.field) {
                            case 'page_id':
                                updatedCreative.networkAssets = {
                                    ...updatedCreative.networkAssets,
                                    facebookPage: { key: action.value }
                                };
                                updatedCreative.content[action.field] = action.value;
                                break;
                            default:
                                updatedCreative.content[action.field] = action.value;
                        }

                        return updatedCreative;
                    } else return creative;
                });
            });
        }
        case CREATIVE_ACTION_TYPE.CREATIVE_ITEM_ADD: {
            return updateAds(state, 'creatives', (creatives) => {
                return creatives.map((creative) =>
                    action.formatIds.includes(creative.formatId)
                        ? {
                              ...creative,
                              items: [...creative.items, action.newItem]
                          }
                        : creative
                );
            });
        }
        case CREATIVE_ACTION_TYPE.CREATIVE_ITEM_SET: {
            return updateAds(state, 'creatives', (creatives, uid) => {
                return creatives.map((creative) =>
                    action.formatIds.includes(creative.formatId)
                        ? {
                              ...creative,
                              items: creative.items
                                  .map((currentItem) => {
                                      const replacementItem = action.items.find(
                                          ({ unitId, item }) =>
                                              unitId === uid &&
                                              item.creativeGroupingId === currentItem.creativeGroupingId
                                      )?.item;

                                      return replacementItem
                                          ? { ...replacementItem, content: JSON.parse(replacementItem.content) }
                                          : currentItem;
                                  })
                                  .concat(
                                      action.items.reduce(
                                          (memo, { unitId, item }) =>
                                              unitId === uid &&
                                              !creative.items.find(
                                                  (currentItem) =>
                                                      item.creativeGroupingId === currentItem.creativeGroupingId
                                              )
                                                  ? [...memo, { ...item, content: JSON.parse(item.content) }]
                                                  : memo,
                                          []
                                      )
                                  )
                          }
                        : creative
                );
            });
        }
        case CREATIVE_ACTION_TYPE.CREATIVE_ITEM_DELETE: {
            return updateAds(state, 'creatives', (creatives) => {
                return creatives.map((creative) =>
                    action.formatIds.includes(creative.formatId)
                        ? {
                              ...creative,
                              items: creative.items.map((item) => {
                                  if (item.id === action.itemId) {
                                      delete item.product;
                                      delete item.content;
                                  }

                                  return item;
                              })
                          }
                        : creative
                );
            });
        }
        case CREATIVE_ACTION_TYPE.CREATIVE_ITEM_UPDATE_FIELD: {
            return updateAds(state, 'creatives', (creatives) => {
                return creatives.map((creative) =>
                    action.formatIds.includes(creative.formatId)
                        ? {
                              ...creative,
                              items: creative.items.map((item) => {
                                  if (item.creativeGroupingId === action.creativeGroupingId) {
                                      const updatedItem = {
                                          ...item,
                                          content: item.content || {}
                                      };
                                      updatedItem.content[action.field] = action.value;

                                      return updatedItem;
                                  } else return item;
                              })
                          }
                        : creative
                );
            });
        }
        default:
            return state;
    }
};
