import { captureException } from '@sentry/browser';

export const logError = (error) => {
    captureException(error);
};

export class NotImplementedError extends Error {
    constructor(...params) {
        super(...params);

        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, NotImplementedError);
        }

        this.name = 'NotImplementedError';
    }
}
