import { WebSocketLink } from 'apollo-link-ws';
import { createUploadLink } from 'apollo-upload-client';
import { extractFiles } from 'extract-files';

import { ApolloLink, createHttpLink, split } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { ApolloClient } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';

import { clearAuthKeys, getUserToken } from './auth';
import { logError } from './error';
import { omitDeep } from './object';

const { REACT_APP_GRAPHQL_API_ENDPOINT, REACT_APP_GRAPHQL_API_SUBSCRIPTION_ENDPOINT, NODE_ENV } = process.env;

// Strip __typename from variables
const middleWareLink = new ApolloLink((operation, forward) => {
    if (operation.variables) {
        operation.variables = omitDeep(operation.variables, '__typename');
    }

    return forward(operation);
});

export const getApolloClient = () => {
    const errorLink = onError(({ response, graphQLErrors, networkError }) => {
        if (graphQLErrors && graphQLErrors.length) {
            for (let index = 0; index < graphQLErrors.length; index++) {
                const error = graphQLErrors[index];
                if (error) {
                    if (error?.extensions && error?.extensions.code && error?.extensions.code === 'UNAUTHENTICATED') {
                        if (!window.location.href.includes('sign-in')) {
                            clearAuthKeys();
                            window.location.href = '/sign-in';
                        }
                        break;
                    }
                    console.error(`GraphQL: ${error?.message}`);
                    logError(new Error(`GraphQL: ${error?.message}`));
                }
            }
        }
        if (networkError) {
            console.error(`[Network error]: ${networkError}`);
            logError(new Error(`[Network error]: ${networkError}`));
        }
        if (response && response.errors) response.errors = null;
    });

    const authLink = setContext((_, { headers }) => {
        // get the authentication token from local storage if it exists
        const token = getUserToken();
        // return the headers to the context so httpLink can read them

        return {
            headers: {
                ...headers,
                authentication: token ? `Bearer ${token}` : ''
            }
        };
    });

    const wsLink = new WebSocketLink({
        uri: `${REACT_APP_GRAPHQL_API_SUBSCRIPTION_ENDPOINT}`,
        options: {
            reconnect: true,
            connectionParams: {
                authToken: getUserToken() ? `Bearer ${getUserToken()}` : ''
            }
        }
    });

    const splitLink = (httpLink) => {
        return split(
            ({ query }) => {
                const definition = getMainDefinition(query);

                return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
            },
            wsLink,
            httpLink
        );
    };

    const constructLink = (opts) =>
        ApolloLink.split(
            (op) => extractFiles(op).files.size > 0,
            authLink.concat(errorLink.concat(createUploadLink(opts))),
            middleWareLink.concat(authLink.concat(errorLink.concat(splitLink(createHttpLink(opts)))))
        );

    const link = constructLink({
        uri: REACT_APP_GRAPHQL_API_ENDPOINT
    });

    const cache = new InMemoryCache();

    const client = new ApolloClient({
        link,
        cache,
        connectToDevTools: NODE_ENV === 'production' ? false : true
    });

    return client;
};
