import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { AdScheduling } from './AdScheduling';

export const AdSchedulingWrapper = ({ schedule, setSchedule }) => {
    const [continuous, setContinuous] = useState(false);

    return (
        <AdScheduling
            continuous={continuous}
            setDateRange={(range) =>
                setSchedule({
                    ...schedule,
                    startDate: range[0],
                    endDate: range[1]
                })
            }
            dateRange={[schedule.startDate, schedule.endDate]}
            startTime={schedule.startTime}
            setStartTime={(time) => {
                setSchedule({
                    ...schedule,
                    startTime: time
                });
            }}
            endTime={schedule.endTime}
            setEndTime={(time) =>
                setSchedule({
                    ...schedule,
                    endTime: time
                })
            }
            days={schedule.days.map((day) => {
                return {
                    enabled: true,
                    ...day
                };
            })}
            setDays={(days) =>
                setSchedule({
                    ...schedule,
                    days
                })
            }
            scheduleType={continuous ? 'continuous' : 'startEnd'}
            setScheduleType={() => setContinuous(!continuous)}
        />
    );
};

AdSchedulingWrapper.propTypes = {
    schedule: PropTypes.object,
    setSchedule: PropTypes.func
};
