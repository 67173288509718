import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useQuery } from '@apollo/client';

import { BudgetEntryModel } from 'common/models/budget';
import { GET_UNIT_NAME } from 'graphql/queries/user';

import { arrayToPeriod, fillPeriodZeroValues, sumByColumns } from '../helpers';
import { BudgetRow, HeaderRow } from './RowTypes';

const BudgetEntry = ({ entry, selected, handleSelection, handleDuplicate, handleDelete, handleEntryUpdate }) => {
    const [total, setTotal] = useState(null);
    const [entryUnit, setEntryUnit] = useState(null);

    useQuery(GET_UNIT_NAME, {
        variables: {
            unitId: entry.unitId
        },
        onCompleted: (data) => {
            const unit = data?.getUnit;
            if (unit) {
                setEntryUnit(unit.name);
            }
        }
    });

    useEffect(() => {
        const budgetLineValues = entry.budgetLines.map((line) => fillPeriodZeroValues(line.period));
        setTotal(sumByColumns(budgetLineValues));
    }, [entry.budgetLines]);

    const updateEntry = (period, index) => {
        const newBudgetLines = [...entry.budgetLines];
        newBudgetLines[index] = {
            ...newBudgetLines[index],
            period: arrayToPeriod(period)
        };
        handleEntryUpdate(newBudgetLines);
    };

    return (
        <>
            {total && (
                <HeaderRow
                    name={entryUnit}
                    total={total}
                    checked={selected}
                    setChecked={handleSelection}
                    handleDuplicate={handleDuplicate}
                    handleDelete={handleDelete}
                />
            )}
            {entry &&
                entry.budgetLines.map((line, lineIdx) => (
                    <BudgetRow
                        key={lineIdx}
                        name={line.name}
                        period={fillPeriodZeroValues(line.period)}
                        updateBudgetRow={(row) => updateEntry(row, lineIdx)}
                        selected={selected}
                    />
                ))}
        </>
    );
};

BudgetEntry.propTypes = {
    entry: BudgetEntryModel,
    selected: PropTypes.bool,
    handleDelete: PropTypes.func,
    handleDuplicate: PropTypes.func,
    handleEntryUpdate: PropTypes.func,
    handleSelection: PropTypes.func
};

export default BudgetEntry;
