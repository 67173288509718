import { isNumeric } from '../utils';
import { Enum } from './enum';

export class AdGroupServingStatus extends Enum {
    static items = [];

    static UNDEFINED = new AdGroupServingStatus('', -1);
    static UNSPECIFIED = new AdGroupServingStatus('UNSPECIFIED', 0);
    static UNKNOWN = new AdGroupServingStatus('UNKNOWN', 1);
    static SERVING = new AdGroupServingStatus('SERVING', 2);
    static NONE = new AdGroupServingStatus('NONE', 3);
    static ENDED = new AdGroupServingStatus('ENDED', 4);
    static PENDING = new AdGroupServingStatus('PENDING', 5);
    static SUSPENDED = new AdGroupServingStatus('SUSPENDED', 6);
    static FAILED = new AdGroupServingStatus('FAILED', 7);
    static IN_PROGRESS = new AdGroupServingStatus('IN PROGRESS', 8);
    static PUBLISHED = new AdGroupServingStatus('PUBLISHED', 10);

    /**
     *
     */

    constructor(name, value) {
        super(name, value);

        AdGroupServingStatus.items.push(this);
    }

    /**
     *
     */

    equals(other) {
        return this.value === other?.value && this.name === other?.name;
    }

    /**
     *
     */

    static find(criteria) {
        if (isNumeric(criteria)) {
            return AdGroupServingStatus.items.find((v) => v.value === Number(criteria));
        }

        return AdGroupServingStatus.items.find((v) => v.name === criteria);
    }

    /**
     *
     */

    static contains(criteria) {
        const status = AdGroupServingStatus.find(criteria);

        return !!status;
    }

    /**
     *
     */

    static get(criteria) {
        return AdGroupServingStatus.find(criteria) || AdGroupServingStatus.UNDEFINED;
    }
}
