export class Enum {
    #name;
    #value;

    /**
     *
     *
     */
    constructor(name, value) {
        this.#name = name;
        this.#value = value;
    }

    /**
     *
     */

    toString() {
        return `${this.#name}`;
    }

    /**
     *
     */

    get value() {
        return this.#value;
    }

    /**
     *
     */

    get name() {
        return this.toString();
    }
}
