import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        alignItems: 'center'
    },
    flexRow: {
        display: 'flex',
        justifyContent: 'space-between'
    }
}));

export function Paginator({ onPrevious, onNext, onFirst, onLast, page, count, rowsPerPage }) {
    const classes = useStyles();
    const pages = Math.ceil(count / rowsPerPage);

    return (
        <div>
            <div className={classes.root}>
                <IconButton onClick={onFirst} disabled={page === 0} aria-label='First Page'>
                    <FirstPageIcon />
                </IconButton>
                <IconButton onClick={onPrevious} disabled={page === 0} aria-label='Previous Page'>
                    <KeyboardArrowLeftIcon />
                </IconButton>
                <Typography>
                    {page + 1} of {pages > 0 ? pages : '...'}
                </Typography>
                <IconButton
                    onClick={onNext}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label='Next Page'
                >
                    <KeyboardArrowRightIcon />
                </IconButton>
                <IconButton
                    onClick={onLast}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label='Last Page'
                >
                    <LastPageIcon />
                </IconButton>
            </div>
        </div>
    );
}

Paginator.propTypes = {
    onPrevious: PropTypes.any,
    onNext: PropTypes.any,
    onFirst: PropTypes.any,
    onLast: PropTypes.any,
    page: PropTypes.any,
    count: PropTypes.any,
    rowsPerPage: PropTypes.any
};
