import React from 'react';

import { SvgIcon } from '@material-ui/core';

export default function Edit(props) {
    return (
        <SvgIcon {...props}>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M17.659 3C17.409 3 17.149 3.1 16.959 3.29L15.129 5.12L18.879 8.87L20.709 7.04C21.099 6.65 21.099 6.02 20.709 5.63L18.369 3.29C18.169 3.09 17.919 3 17.659 3ZM14.059 9.02L14.979 9.94L5.91902 19H4.99902V18.08L14.059 9.02ZM2.99902 17.25L14.059 6.19L17.809 9.94L6.74902 21H2.99902V17.25Z'
            />
        </SvgIcon>
    );
}
