import React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles, Paper, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        align: 'center',
        width: '100%'
    },
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    paper: {
        marginTop: theme.spacing(10),
        alignContent: 'center'
    },
    flexRow: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    button: {
        margin: theme.spacing(2, 0),
        marginBottom: -theme.spacing(10),
        marginTop: theme.spacing(5)
    },
    textField: {
        margin: theme.spacing(5),
        marginBottom: theme.spacing(2),
        background: 'rgba(33, 33, 33, 0.08)'
    },
    form: {
        paddingLeft: 100,
        paddingRight: 100,
        paddingBottom: 125,
        flexBasis: 700,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2)
        }
    },
    title: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(3),
        color: '#0578D3',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '24px'
    },
    contentBody: {
        paddingLeft: 100,
        paddingRight: 100,
        paddingBottom: 20,
        paddingTop: 8,
        flexBasis: 700,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2)
        }
    },
    typo: {
        fonFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px'
        // marginBottom: theme.spacing(5)
    }
}));

const AccountRecoveryConfirmation = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.root}>
            <Grid>
                <Grid className={classes.content} item lg={4} xs={8} xl={4} md={6} sm={8}>
                    <div>
                        <Paper className={classes.paper} elevation={8}>
                            <div className={classes.contentBody}>
                                <Typography className={classes.title} variant='h2' align='center'>
                                    {t('Account recovery')}
                                </Typography>

                                <Typography gutterBottom h3 className={classes.typo}>
                                    {t(
                                        'A message has been sent to your email with instructions on how to reset your password.'
                                    )}
                                </Typography>
                            </div>
                        </Paper>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default AccountRecoveryConfirmation;
