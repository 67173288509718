import React, { useContext, useEffect, useState } from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useLazyQuery, useQuery } from '@apollo/client';
import { Avatar, Button, Grid, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/styles';

import { BlueHeaderContainer } from 'common/components/BlueHeaderContainer/BlueHeaderContainer';
import { Context as activeUnitContext } from 'context/units/activeUnitContext';
import { GET_FACEBOOK_BUSINESS_ID, GET_FACEBOOK_PAGES } from 'graphql/queries/user';

import HeaderConnections from '../components/AccountConnectedPages/HeaderConnections';
import AccountFBConnectedPages from './AccountConnectedPages/AccountFBConnectedPages';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%'
    },
    avatar: {
        width: theme.spacing(8),
        height: theme.spacing(8),
        marginRight: theme.spacing(2)
    },

    divContent: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(4),
        display: 'flex',
        justifyContent: 'space-between'
    },
    backButton: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(2)
    },

    subContent: {
        display: 'flex',
        justifyContent: 'flex-start'
    },

    connectButton: {
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            marginLeft: -theme.spacing(28)
        }
    },
    subContent2: {
        marginTop: theme.spacing(7)
    }
}));

const AccountConnectPage = () => {
    const classes = useStyles();
    const history = useHistory();
    const [connectedPages, setConnectedPages] = useState([]);
    const [fbPages, setFbPages] = useState([]);
    const {
        state: { activeUnit }
    } = useContext(activeUnitContext);
    const { t } = useTranslation();

    const { data } = useQuery(GET_FACEBOOK_BUSINESS_ID);
    const facebookBusinessId = data && data.getFacebookBusinessId;

    const [getFacebookPages, { loading }] = useLazyQuery(GET_FACEBOOK_PAGES, {
        variables: { input: { unitId: activeUnit.id } },
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            setConnectedPages(data.getFacebookPages);
            setFbPages(
                fbPages.filter(
                    (page) => !data.getFacebookPages.find((connectedPage) => connectedPage.id === page.id)
                ) || []
            );
        }
    });

    const responseFacebook = async (response) => {
        try {
            let fbPages = [];
            const loadFBPages = async (url) => {
                await fetch(url)
                    .then((response) => response.json())
                    .then(async (data) => {
                        const _pages = data.data;

                        fbPages = fbPages.concat(_pages);

                        if (data.paging && data.paging.next) {
                            loadFBPages(data.paging.next);
                        } else {
                            setFbPages(
                                fbPages.filter(
                                    (page) => !connectedPages.find((connectedPage) => connectedPage.id === page.id)
                                ) || []
                            );

                            for (const page of _pages) {
                                await postToFbgraphApi(page.id, response.accessToken);
                            }
                        }
                    });
            };
            loadFBPages(
                `https://graph.facebook.com/v8.0/${response.userID}/accounts?access_token=${response.accessToken}`
            );
        } catch (e) {
            console.error(e);
        }
    };

    const postToFbgraphApi = async (pageId, access_token) => {
        const res = await fetch(
            `https://graph.facebook.com/v8.0/${facebookBusinessId}/client_pages?access_token=${access_token}`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    page_id: pageId,
                    permitted_tasks: ['MANAGE']
                })
            }
        ).catch(() => {
            return new Response(
                JSON.stringify({
                    code: 400,
                    message: 'Error'
                })
            );
        });

        return res.json();
    };

    useEffect(() => {
        getFacebookPages();
    }, [getFacebookPages]);

    const handleGoBack = () => {
        history.push('/unit/plugins/browse');
    };
    if (!loading && connectedPages.length === 0 && fbPages.length === 0) {
        return (
            <div className={classes.root}>
                <Grid container spacing={1}>
                    <Grid item className={classes.content} lg={12} xs={12} xl={12} md={12} sm={12}>
                        <div>
                            <HeaderConnections />

                            <div className={classes.subContent2}>
                                <BlueHeaderContainer title={t('Browse connections')}>
                                    <div>
                                        <Button
                                            startIcon={<ArrowBackIcon />}
                                            onClick={handleGoBack}
                                            className={classes.backButton}
                                        >
                                            {t('Back')}
                                        </Button>

                                        <div className={classes.divContent}>
                                            <div className={classes.subContent}>
                                                <Avatar
                                                    className={classes.avatar}
                                                    alt='fb-icon'
                                                    src='/images/fbIcon.png'
                                                />

                                                <div>
                                                    <Typography variant='h5'>{t('Facebook pages')}</Typography>
                                                    <Typography variant='caption'>Facebook </Typography>
                                                </div>
                                            </div>
                                            <div>
                                                <FacebookLogin
                                                    appId='1702572803339249'
                                                    fields='name,email,picture'
                                                    autoLoad={false}
                                                    auth_type='rerequest'
                                                    version='8.0'
                                                    scope='pages_manage_ads '
                                                    render={(renderProps) => (
                                                        <Button
                                                            className={classes.connectButton}
                                                            variant='contained'
                                                            size='small'
                                                            color='primary'
                                                            onClick={renderProps.onClick}
                                                        >
                                                            {!loading && connectedPages.length > 0
                                                                ? t('Connected')
                                                                : t('Connect')}
                                                        </Button>
                                                    )}
                                                    callback={responseFacebook}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </BlueHeaderContainer>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        );
    } else {
        return (
            <AccountFBConnectedPages
                connectedPages={connectedPages}
                fbPages={fbPages}
                responseFacebook={responseFacebook}
                refetch={getFacebookPages}
            />
        );
    }
};

export default AccountConnectPage;
