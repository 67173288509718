import { TargetingConditionType } from 'helpers/targeting';

import { updateAds } from './merge';

export const TARGETING_ACTION_TYPE = {
    ADD_KEYWORDS: 'addKeywords',
    REMOVE_KEYWORD: 'removeKeword',
    ADD_EXCLUDE_KEYWORDS: 'addExcludeKeyword',
    REMOVE_EXCLUDE_KEYWORD: 'removeExcludeKeyword',
    ADD_INTEREST: 'addInterest',
    REMOVE_INTEREST: 'removeInterest',
    ADD_EXCLUDE_INTEREST: 'addExcludeInterest',
    REMOVE_EXCLUDE_INTEREST: 'removeExcludeInterest',
    REMOVE_ALL_INTERESTS_OF_TYPE: 'removeAllInterestsOfType',
    SET_GENDER: 'setGender',
    SET_AGE_RANGE: 'setAgeRange',
    ADD_LOCATION: 'addLocation',
    UPDATE_LOCATION: 'updateLocation',
    SET_RADIUS: 'setRadius',
    REMOVE_LOCATION: 'removeLocation'
};

export const targetingReducer = (state, action) => {
    switch (action.type) {
        case TARGETING_ACTION_TYPE.ADD_KEYWORDS:
            return updateAds(state, 'targeting', (targeting) => ({
                ...targeting,
                keywords: [...targeting.keywords, ...action.keywords]
            }));
        case TARGETING_ACTION_TYPE.REMOVE_KEYWORD:
            return updateAds(state, 'targeting', (targeting) => {
                const index = targeting.keywords.findIndex((keyword) => keyword.text === action.text);

                return {
                    ...targeting,
                    keywords:
                        index !== -1
                            ? [...targeting?.keywords.slice(0, index), ...targeting?.keywords.slice(index + 1)]
                            : targeting?.keywords
                };
            });
        case TARGETING_ACTION_TYPE.ADD_EXCLUDE_KEYWORDS:
            return updateAds(state, 'targeting', (targeting) => ({
                ...targeting,
                excludeKeywords: [...targeting?.excludeKeywords, ...action?.excludeKeywords]
            }));
        case TARGETING_ACTION_TYPE.REMOVE_EXCLUDE_KEYWORD:
            return updateAds(state, 'targeting', (targeting) => {
                const index = targeting.excludeKeywords.findIndex((keyword) => keyword.text === action.text);

                return {
                    ...targeting,
                    excludeKeywords:
                        index !== -1
                            ? [
                                  ...targeting?.excludeKeywords.slice(0, index),
                                  ...targeting?.excludeKeywords.slice(index + 1)
                              ]
                            : targeting?.excludeKeywords
                };
            });
        case TARGETING_ACTION_TYPE.ADD_INTEREST:
            return updateAds(state, 'targeting', (targeting) => ({
                ...targeting,
                interests: [...targeting?.interests, action.interest]
            }));
        case TARGETING_ACTION_TYPE.REMOVE_INTEREST:
            return updateAds(state, 'targeting', (targeting) => {
                const index = action.key
                    ? targeting?.interests.findIndex((item) => item.key === action.key)
                    : action.index;

                return {
                    ...targeting,
                    interests: [...targeting?.interests.slice(0, index), ...targeting?.interests.slice(index + 1)]
                };
            });
        case TARGETING_ACTION_TYPE.ADD_EXCLUDE_INTEREST:
            return updateAds(state, 'targeting', (targeting) => {
                return {
                    ...targeting,
                    excludeInterests: [...targeting?.excludeInterests, action.excludeInterest]
                };
            });
        case TARGETING_ACTION_TYPE.REMOVE_EXCLUDE_INTEREST:
            return updateAds(state, 'targeting', (targeting) => {
                const index = action.key
                    ? targeting?.excludeInterests.findIndex((item) => item.key === action.key)
                    : action.index;

                return {
                    ...targeting,
                    excludeInterests: [
                        ...targeting?.excludeInterests.slice(0, index),
                        ...targeting?.excludeInterests.slice(index + 1)
                    ]
                };
            });
        case TARGETING_ACTION_TYPE.REMOVE_ALL_INTERESTS_OF_TYPE:
            return updateAds(state, 'targeting', (targeting) => {
                if (action.targetingConditionType === TargetingConditionType.Include) {
                    return {
                        ...targeting,
                        interests: []
                    };
                } else if (action.targetingConditionType === TargetingConditionType.Exclude) {
                    return {
                        ...targeting,
                        excludeInterests: []
                    };
                }
            });
        case TARGETING_ACTION_TYPE.SET_GENDER:
            return updateAds(state, 'targeting', (targeting) => ({
                ...targeting,
                gender: action.gender
            }));
        case TARGETING_ACTION_TYPE.SET_AGE_RANGE:
            return updateAds(state, 'targeting', (targeting) => ({
                ...targeting,
                age: {
                    from: action.ageRange[0],
                    to: action.ageRange[1]
                }
            }));
        case TARGETING_ACTION_TYPE.ADD_LOCATION:
            return updateAds(state, 'targeting', (targeting) => ({
                ...targeting,
                locations: [action.location, ...(targeting.locations || [])]
            }));
        case TARGETING_ACTION_TYPE.UPDATE_LOCATION:
            return updateAds(state, 'targeting', (targeting) => ({
                ...targeting,
                locations: targeting.locations.map((location, i) =>
                    i === action.index ? { ...location, ...action.location } : location
                )
            }));
        case TARGETING_ACTION_TYPE.SET_RADIUS:
            return updateAds(state, 'targeting', (targeting) => ({
                ...targeting,
                locations: targeting.locations.map((location, i) =>
                    i === action.index ? { ...location, radius: action.radius } : location
                )
            }));
        case TARGETING_ACTION_TYPE.REMOVE_LOCATION:
            return updateAds(state, 'targeting', (targeting) => {
                const index = action.key
                    ? targeting?.locations.findIndex((item) => item.key === action.key)
                    : action.index;

                return {
                    ...targeting,
                    locations: [...targeting?.locations.slice(0, index), ...targeting?.locations.slice(index + 1)]
                };
            });
        default:
            return state;
    }
};
