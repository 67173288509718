import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useMutation } from '@apollo/client';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import { UnitSelector } from 'common/components';
import { DialogTitleClose } from 'common/components/DialogCloser/DialogTitleClose';
import { Context as activeUnitContext } from 'context/units/activeUnitContext';
import { SAVE_ACTIVE_UNIT } from 'graphql/mutations/units';

export const UnitSelectorDialog = ({ dialogOpen, setDialogOpen }) => {
    const { t } = useTranslation();
    const [saveActiveUnit] = useMutation(SAVE_ACTIVE_UNIT);

    const {
        state: { units },
        setActiveUnit
    } = useContext(activeUnitContext);

    const history = useHistory();

    const handleSetActiveUnit = (unit) => {
        if (unit) {
            setActiveUnit(Object.entries(unit).length > 0 ? unit : null);
            saveActiveUnit({ variables: { unitId: unit.id } });
            history.push('/');
        }
        setDialogOpen(false);
    };

    return (
        <Dialog
            onClose={() => setDialogOpen(false)}
            aria-describedby='scroll-dialog-description'
            aria-labelledby='scroll-dialog-title'
            open={dialogOpen}
            fullWidth
            scroll='paper'
        >
            <DialogTitleClose onClose={() => setDialogOpen(false)} id='scroll-dialog-title'>
                {t('Choose unit')}
            </DialogTitleClose>
            <DialogContent id='scroll-dialog-description' dividers={true}>
                <UnitSelector units={units} selectSingleUnit={handleSetActiveUnit} />
            </DialogContent>
        </Dialog>
    );
};

UnitSelectorDialog.propTypes = {
    setDialogOpen: PropTypes.func.isRequired,
    dialogOpen: PropTypes.bool.isRequired
};
