import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useMutation } from '@apollo/client';
import { Button, DialogActions, DialogContent, TextField } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';

import { CREATE_USER } from 'graphql/mutations/user';

function AddUser({ setModalState, refetch }) {
    const { t } = useTranslation();

    const [createUser] = useMutation(CREATE_USER, {
        onCompleted: () => {
            refetch();
        }
    });

    const [firstName, setFirstName] = useState('');
    const [lastName, setlastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password] = useState('');

    const onSubmit = (e) => {
        e.preventDefault();
        createUser({
            variables: {
                firstName: firstName,
                lastName: lastName,
                email: email,
                password: password,
                phone: phone
            }
        });
        setModalState(false);
    };

    return (
        <form>
            <>
                <DialogContent>
                    <FormControl>
                        <h4 style={{ textAlign: 'center' }}>{t('Create new user')}</h4>
                        <TextField
                            id='outlined-basic'
                            label={t('First name')}
                            name='firstName'
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                        ></TextField>
                        <br />
                        <TextField
                            id='outlined-basic'
                            label={t('Last name')}
                            name='lastName'
                            value={lastName}
                            onChange={(e) => setlastName(e.target.value)}
                        ></TextField>
                        <br />
                        <TextField
                            id='outlined-basic'
                            label={t('E-mail address')}
                            name='email'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        ></TextField>
                        <br />
                        <TextField
                            id='outlined-basic'
                            label={t('Phone number')}
                            name='phone'
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        ></TextField>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onSubmit}> {t('Create new user')}</Button>
                    <Button onClick={() => setModalState(false)}>{t('Cancel')}</Button>
                </DialogActions>
            </>
        </form>
    );
}

AddUser.propTypes = {
    setModalState: PropTypes.func.isRequired,
    refetch: PropTypes.func.isRequired
};

export default AddUser;
