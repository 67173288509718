import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Dialog } from '@material-ui/core';
import { Add, EditOutlined } from '@material-ui/icons';

import { DialogTitleClose } from '../DialogCloser/DialogTitleClose';
import { ProductTable } from '../ProductTable/ProductTable';
import { StyledSelectorBox } from '../StyledSelectorBox/StyledSelectorBox';

export const SingleProductSelector = ({ product, setProductId, isLoading, handleDeleteItem, itemId }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const labelField = product?.fields?.filter((field) => {
        if (field['fieldId'] === 'title') {
            return Object.values(field['dataJSON'])[0];
        }

        return null;
    });
    const imageField = product?.fields?.filter((field) => {
        if (field['fieldId'] === 'images') {
            return Object.values(field['dataJSON'])[0];
        }

        return null;
    });

    const label = labelField?.length && JSON.parse(labelField[0]['dataJSON']);
    const imageUrl = imageField?.length && JSON.parse(imageField[0]['dataJSON'])[0];

    const handleReturnSelection = (productIds) => {
        setOpen(false);
        setProductId(productIds[0]);
    };

    return (
        <>
            <StyledSelectorBox
                label={label}
                itemId={itemId}
                thumbnailUrl={imageUrl}
                onClick={() => setOpen(true)}
                handleDeleteItem={handleDeleteItem}
                isLoading={isLoading}
                icon={product ? <EditOutlined /> : <Add />}
                placeholderText={product ? t('Choose a product') : null}
            />
            <Dialog
                onClose={() => setOpen(false)}
                aria-labelledby='product-table-selection-dialog'
                open={open}
                fullWidth
                maxWidth='xl'
            >
                <DialogTitleClose onClose={() => setOpen(false)} id='scroll-dialog-title' />
                <ProductTable
                    defaultPageSize={10}
                    enableCustomFilter={true}
                    enableDeselectAll={false}
                    enableEditColumnLabels={false}
                    enableEditColumnVisibility={false}
                    enableEditColumnOrder={true}
                    enableSelection={false}
                    enableSearch={true}
                    enableSorting={true}
                    enableTitle={true}
                    skipProductIds={[product?.id]}
                    handleReturnSelection={handleReturnSelection}
                />
            </Dialog>
        </>
    );
};

SingleProductSelector.propTypes = {
    product: PropTypes.object,
    itemId: PropTypes.string,
    setProductId: PropTypes.func,
    handleDeleteItem: PropTypes.func,
    isLoading: PropTypes.bool
};
