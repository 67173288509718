import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';

import { AgeRangeSelector } from 'common/components/AgeRangeSelector/AgeRangeSelector';
import { GenderSelector } from 'common/components/GenderSelector/GenderSelector';
import { ageRangeModel } from 'common/models';
import { TARGETING_ACTION_TYPE } from 'context/campaign/targetingReducer';
import { Genders } from 'helpers/targeting';

export const DemographicsSelectorTabPanel = ({ dispatch, gender, ageRange }) => {
    return (
        <Grid container spacing={1}>
            <Grid item md xs={12}>
                <GenderSelector
                    gender={gender}
                    setGender={(gender) => dispatch({ type: TARGETING_ACTION_TYPE.SET_GENDER, gender })}
                />
            </Grid>

            <Grid item md xs={12}>
                <AgeRangeSelector
                    ageRange={ageRange}
                    setAgeRange={(ageRange) => dispatch({ type: TARGETING_ACTION_TYPE.SET_AGE_RANGE, ageRange })}
                />
            </Grid>
        </Grid>
    );
};

DemographicsSelectorTabPanel.propTypes = {
    dispatch: PropTypes.func,
    gender: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.oneOf([...[''], ...Object.values(Genders)])),
        PropTypes.string
    ]),
    ageRange: PropTypes.shape(ageRangeModel)
};
