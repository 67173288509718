import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as Sentry from '@sentry/browser';

export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        Sentry.withScope((scope) => {
            scope.setExtras(errorInfo);
            Sentry.captureException(error);
        });
    }

    render() {
        const { hasError } = this.state;
        const { children } = this.props;

        return hasError ? (
            <div style={{ textAlign: 'center' }}>
                <h1>
                    Oops seems like we broke something{' '}
                    <span role='img' aria-label='speak-no-evil'>
                        🙊
                    </span>
                </h1>
                <p>
                    We are on our way to fix the problem, in the mean time{' '}
                    <Link onClick={() => this.setState({ hasError: false })} to='/'>
                        click here to go back to safe ground
                    </Link>
                </p>
            </div>
        ) : (
            children
        );
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.node
};
