import { grey } from '@material-ui/core/colors';

export default {
    root: {
        backgroundColor: grey[50],
        '&$focused': {
            backgroundColor: grey[100]
        },
        '&:hover': {
            backgroundColor: grey[200]
        }
    },
    underline: {
        '&:before': {
            borderBottom: '1px solid',
            borderBottomColor: grey[300]
        },
        '&:hover': {
            '&:before': {
                borderBottom: '1px solid',
                borderBottomColor: grey[400]
            }
        }
    }
};
