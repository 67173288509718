import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Checkbox, Fab, Grid, IconButton } from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

import { BlueExpansionPanel } from 'common/components';
import { AddUnitModal } from 'common/components/AddUnitModal/AddUnitModal';
import { SearchInput } from 'common/components/SearchInput/SearchInput';
import { CAMPAIGN_ACTION_TYPE, useCampaignDispatch } from 'context/campaign/CampaignContext';

const selected = (unit) => unit.selected;

const contains = (filterText) => (unit) =>
    unit?.name?.toLowerCase().includes(filterText) || unit?.location?.county?.toLowerCase().includes(filterText);

const useStyles = makeStyles((theme) => ({
    addButton: {
        marginRight: theme.spacing(2)
    },
    listContainer: {
        border: `1px solid ${theme.palette.divider}`,
        overflowY: 'scroll',
        maxHeight: '128px',
        scrollbarColor: `${theme.palette.text.disabled} ${theme.palette.background.default}`,
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
            width: '8px'
        },
        '&::-webkit-scrollbar-track': {
            background: theme.palette.background.default
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.text.disabled,
            borderRadius: '4px',
            border: `2px solid ${theme.palette.background.default}`,
            minHeight: theme.spacing(5)
        }
    },
    listItem: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        minHeight: 50,
        fontWeight: 'normal',
        lineHeight: '20px',
        letterSpacing: '0.25px',
        color: theme.palette.text.mediumEmphasis,
        '&:last-of-type': {
            borderBottom: 'none'
        },
        '&:hover': {
            '& .unit-multi-selector-delete-button': {
                display: 'block'
            }
        },
        '& .unit-multi-selector-delete-button': {
            display: 'none'
        }
    },
    headerContainer: {
        marginTop: theme.spacing(4),
        marginLeft: 1,
        marginRight: 1,
        fontWeight: 'normal',
        lineHeight: '20px',
        letterSpacing: '0.25px',
        color: theme.palette.text.disabled
    },
    statusCell: {
        color: theme.palette.primary[400]
    }
}));

export const UnitMultiSelector = ({ units, availableUnits }) => {
    const { t } = useTranslation();
    const dispatch = useCampaignDispatch();
    const [filterText, setFilterText] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const { addButton, headerContainer, listContainer, listItem, statusCell } = useStyles();

    const selectedUnits = units.filter(selected);

    return (
        <BlueExpansionPanel
            title={t('Advertiser ({{selected}} of {{total}} selected)', {
                selected: selectedUnits.length,
                total: units.length
            })}
            defaultExpanded={true}
        >
            <Grid container direction='column'>
                <Grid item container direction='row'>
                    <Grid item container xs={10}>
                        <SearchInput
                            onChange={(event) => setFilterText(event.target.value.toLowerCase())}
                            placeholder={t('Advertiser')}
                        />
                    </Grid>
                    <Grid item container justifyContent='flex-end' xs={2}>
                        <Fab className={addButton} color='primary' onClick={() => setModalOpen(true)}>
                            <Add />
                        </Fab>
                        <AddUnitModal
                            availableUnits={availableUnits.filter(
                                ({ id }) => !units.find(({ id: secondId }) => id === secondId)
                            )}
                            open={modalOpen}
                            onClose={() => {
                                setModalOpen(false);
                            }}
                            onSave={(units) => {
                                dispatch({ type: CAMPAIGN_ACTION_TYPE.ADD_UNIT, units });
                                setModalOpen(false);
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid className={headerContainer} item container>
                    <Grid item container justifyContent='center' alignItems='center' xs={1}>
                        <Checkbox
                            indeterminate={selectedUnits.length > 0 && selectedUnits.length < units.length}
                            checked={selectedUnits.length > 0 && selectedUnits.length === units.length}
                            color='primary'
                            onChange={(event) =>
                                dispatch({
                                    type: CAMPAIGN_ACTION_TYPE.SET_UNIT_SELECTION,
                                    selected: event.target.checked
                                })
                            }
                            inputProps={{ 'aria-label': 'select all advertisers' }}
                        />
                    </Grid>
                    <Grid item container alignItems='center' xs={4}>
                        {t('Advertiser')}
                    </Grid>
                    <Grid item container alignItems='center' xs={1}>
                        {t('Status')}
                    </Grid>
                    <Grid item container justifyContent='flex-end' alignItems='center' xs={2}>
                        {t('Spending')}
                    </Grid>
                    <Grid item xs={4} />
                </Grid>
                <Grid className={listContainer} item>
                    {units.filter(contains(filterText)).map((unit, index) => (
                        <Grid key={`${unit.id}-${index}`} direction='row' container className={listItem}>
                            <Grid item container justifyContent='center' alignItems='center' xs={1}>
                                <Checkbox
                                    checked={Boolean(unit.selected)}
                                    color='primary'
                                    onChange={(event) =>
                                        dispatch({
                                            type: CAMPAIGN_ACTION_TYPE.SET_UNIT_SELECTION,
                                            selected: event.target.checked,
                                            id: unit.id
                                        })
                                    }
                                    inputProps={{ 'aria-label': `select advertiser ${unit.name}` }}
                                />
                            </Grid>
                            <Grid item container alignItems='center' xs={4}>
                                {unit.name}
                            </Grid>
                            <Grid item container alignItems='center' xs={1} className={statusCell}>
                                {unit.status}
                            </Grid>
                            <Grid item container justify='flex-end' alignItems='center' xs={2}>
                                {unit.budget}
                            </Grid>
                            <Grid item container justify='flex-end' alignItems='center' xs={4}>
                                <IconButton
                                    className='unit-multi-selector-delete-button'
                                    onClick={() =>
                                        dispatch({
                                            type: CAMPAIGN_ACTION_TYPE.REMOVE_UNIT,
                                            id: unit.id
                                        })
                                    }
                                >
                                    <Delete />
                                </IconButton>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </BlueExpansionPanel>
    );
};

UnitMultiSelector.propTypes = {
    units: PropTypes.array,
    availableUnits: PropTypes.array
};
