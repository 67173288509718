import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Input, makeStyles, Paper } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: '4px 4px 0 0',
        alignItems: 'center',
        padding: theme.spacing(1),
        display: 'flex',
        height: theme.spacing(6.75),
        width: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },
    icon: {
        marginRight: theme.spacing(1),
        color: theme.palette.text.secondary
    },
    input: {
        flexGrow: 1,
        fontSize: 16,
        marginLeft: theme.spacing(1)
    }
}));

export const SearchInput = ({ value, onChange }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const handleChange = (e) => {
        onChange(e.target.value);
    };

    return (
        <Paper className={classes.root}>
            <Input
                className={classes.input}
                disableUnderline
                placeholder={t('Search')}
                value={value}
                onChange={handleChange}
            />
            <SearchIcon className={classes.icon} />
        </Paper>
    );
};

SearchInput.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func
};
