import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: '100%'
    },
    label: {
        color: theme.palette.text.hint
    }
}));

export const SortBy = ({ value, onChange }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const handleSort = (e) => {
        onChange(e.target.value);
    };

    const sortItems = [
        {
            title: 'A-Z',
            value: 'asc'
        },
        {
            title: 'Z-A',
            value: 'desc'
        }
    ];

    return (
        <FormControl variant='filled' className={classes.formControl}>
            <InputLabel id='multiple-select-filter' className={classes.label}>
                {t('Sort by')}
            </InputLabel>

            <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                className={classes.selector}
                value={value || sortItems[0].value} // Ascending by default
                onChange={handleSort}
            >
                {sortItems.map((item) => (
                    <MenuItem key={item.title} value={item.value}>
                        {item.title}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

SortBy.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func
};
