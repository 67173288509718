import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import equals from 'validator/es/lib/equals';
import isLength from 'validator/es/lib/isLength';

import { Button, FormControl, Grid, IconButton, InputAdornment, Paper, TextField, Typography } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    contentBody: {
        flexGrow: 1,
        display: 'flex',
        align: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('lg')]: {
            justifyContent: 'center'
        }
    },
    form: {
        paddingLeft: 75,
        paddingRight: 75,
        paddingBottom: 125,
        flexBasis: 700,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            marginBottom: theme.spacing(2)
        }
    },
    title: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
        color: '#0578D3'
    },

    textField: {
        marginBottom: theme.spacing(3),
        fullWidth: true,
        background: 'rgba(33, 33, 33, 0.08)'
    },
    paper: {
        marginTop: theme.spacing(15),
        alignContent: 'center'
    },
    capsLockText: {
        color: ' #B00020',
        textAlign: 'left',
        marginTop: -theme.spacing(1),
        fontSize: '11px',
        marginLeft: theme.spacing(1.5)
    },

    resetButton: {
        marginTop: theme.spacing(4),
        marginBottom: -theme.spacing(10)
    }
}));

const InviteUserAccountForm = (props) => {
    const { t } = useTranslation();

    const classes = useStyles();

    const [formState, setFormState] = useState({
        isValid: false,
        values: { verify: '', firstName: '', lastName: '' },
        touched: {},
        errors: {},
        match: {}
    });

    const [capsLockOn, setCapsLockOn] = useState(false);

    const handleCapsLock = (e) => {
        const capsLockOn = e.getModifierState('CapsLock');
        if (capsLockOn === true) {
            setCapsLockOn(true);
        } else {
            setCapsLockOn(false);
        }
    };
    const handleChange = (event) => {
        event.persist();
        event.preventDefault();

        setFormState((formState) => ({
            ...formState,
            values: {
                ...formState.values,
                [event.target.name]: event.target.value
            },
            touched: {
                ...formState.touched,
                [event.target.name]: true
            },
            errors: {
                ...formState.errors,
                [event.target.name]: !isLength(event.target.value, { min: 6, max: 128 })
            },
            match: {
                ...formState.errors,
                isEqual: event.target.name === 'verify' && equals(formState.values.password, event.target.value)
            }
        }));
    };

    const hasError = (field) => (formState.touched[field] && formState.errors[field] ? true : false);

    const [showPassword, setShowPassword] = useState(true);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    const handleResetPassword = async (event) => {
        event.preventDefault();

        props.onResetPassword({
            password: formState.values.password,
            verify: formState.values.verify,
            firstName: formState.values.firstName,
            lastName: formState.values.lastName
        });
    };

    return (
        <Grid container justifyContent='center'>
            <Grid item lg={4} xs={4} xl={3} md={3}>
                <Paper className={classes.paper} elevation={8}>
                    <div className={classes.contentBody}>
                        <Grid>
                            <FormControl
                                className={classes.form}
                                autoComplete='off'
                                onSubmit={(formState.lastName = '')}
                            >
                                <Typography className={classes.title} variant='h2' align='center'>
                                    <div className={classes.title}>{t('Create your account')}</div>
                                </Typography>
                                <TextField
                                    variant='filled'
                                    label={t('First name')}
                                    type='text'
                                    fullWidth
                                    name='firstName'
                                    className={classes.textField}
                                    onChange={handleChange}
                                    value={formState.values.firstName}
                                />
                                <TextField
                                    variant='filled'
                                    type='text'
                                    label={t('Last name')}
                                    name='lastName'
                                    fullWidth
                                    className={classes.textField}
                                    onChange={handleChange}
                                    value={formState.values.lastName}
                                />

                                <TextField
                                    variant='filled'
                                    type='username'
                                    label={t('E-mail address')}
                                    fullWidth
                                    className={classes.textField}
                                    value={props.email}
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />

                                <TextField
                                    className={classes.textField}
                                    error={hasError('password')}
                                    fullWidth={true}
                                    helperText={
                                        formState.errors.password ? (
                                            <span>{t('Password must contain between 6 and 128 characters')}</span>
                                        ) : (
                                            ''
                                        )
                                    }
                                    label={t('Set password')}
                                    name='password'
                                    onChange={handleChange}
                                    variant='filled'
                                    onKeyDown={handleCapsLock}
                                    type={showPassword ? 'password' : 'text'}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <IconButton
                                                    aria-label='toggle password visibility'
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />

                                <TextField
                                    className={classes.textField}
                                    error={hasError('verify')}
                                    fullWidth
                                    helperText={
                                        !formState.match.isEqual && formState.values.verify.length > 0 ? (
                                            <span style={{ color: '#B00020' }}>
                                                {t('Confirmed password are not matching')}
                                            </span>
                                        ) : (
                                            ''
                                        )
                                    }
                                    label={t('Confirm new password')}
                                    name='verify'
                                    onChange={handleChange}
                                    variant='filled'
                                    value={formState.values.verify}
                                    onKeyDown={handleCapsLock}
                                    type={showPassword ? 'password' : 'text'}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <IconButton
                                                    aria-label='toggle password visibility'
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />

                                {capsLockOn && <div className={classes.capsLockText}>{t('Caps Lock is on')}</div>}

                                <Button
                                    className={classes.resetButton}
                                    onClick={handleResetPassword}
                                    color='primary'
                                    size='large'
                                    type='submit'
                                    variant='contained'
                                >
                                    {t('Create')}
                                </Button>
                            </FormControl>
                        </Grid>
                    </div>
                </Paper>
            </Grid>
        </Grid>
    );
};

InviteUserAccountForm.propTypes = {
    onResetPassword: PropTypes.func,
    email: PropTypes.string
};

export default InviteUserAccountForm;
