function makeResolutionObject(description, resArray) {
    return {
        description,
        width: resArray[0],
        height: resArray[1]
    };
}

export function makeRes(resolutionObj) {
    return [resolutionObj.width, resolutionObj.height];
}

export function getInitialResolutions(recommended, minimum, mediaDimension) {
    const resolutions = [];

    if (mediaDimension) {
        resolutions.push(makeResolutionObject('Media dimension', makeRes(mediaDimension)));
    }
    if (recommended) {
        resolutions.push(makeResolutionObject('Recommended', recommended));
    }
    if (minimum) {
        resolutions.push(makeResolutionObject('Minimum', minimum));
    }

    return resolutions;
}
