import gql from 'graphql-tag';

import { FRAGMENT_PBI_REPORTS_CATEGORY } from './pbi.category';

/**
 *
 */

export const FRAGMENT_PBI_CONFIG_FILTER_TARGET = gql`
    fragment pbiConfigFilterTarget on UnitPowerBIConfigFilterTarget {
        table
        column
    }
`;

/**
 *
 */

export const FRAGMENT_PBI_CONFIG_LOCALE_SETTINGS = gql`
    fragment pbiConfigLocaleSettings on UnitPowerBIConfigLocaleSettings {
        language
    }
`;

export const FRAGMENT_PBI_CONFIG_SETTINGS = gql`
    fragment pbiConfigSettings on UnitPowerBIConfigSettings {
        localeSettings {
            ...pbiConfigLocaleSettings
        }
    }
    ${FRAGMENT_PBI_CONFIG_LOCALE_SETTINGS}
`;

/**
 *
 */

export const FRAGMENT_PBI_CONFIG_FILTER = gql`
    fragment pbiConfigFilter on UnitPowerBIConfigFilter {
        schema
        target {
            ...pbiConfigFilterTarget
        }
        operator
        values
        filterType
        requireSingleSelection
    }
    ${FRAGMENT_PBI_CONFIG_FILTER_TARGET}
`;

/**
 *
 */

export const FRAGMENT_PBI_REPORTS_INDUSTRY = gql`
    fragment pbiIndustry on ReportIndustry {
        id
        name
        isActive
        categories {
            ...pbiCategory
        }
    }
    ${FRAGMENT_PBI_REPORTS_CATEGORY}
`;

/**
 *
 */

export const FRAGMENT_PBI_REPORTS = gql`
    fragment pbiReport on PbiReport {
        id
        name
        description
        pbiReportId
        pbiWorkspaceId
        sequence
        isReusable
        category {
            ...pbiCategory
        }
    }
    ${FRAGMENT_PBI_REPORTS_CATEGORY}
`;
