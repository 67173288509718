import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useMutation } from '@apollo/client';
import { Button, DialogActions, DialogContent, TextField, Typography } from '@material-ui/core';

import { UPDATE_USER } from 'graphql/mutations/user';

function EditUser({ user, setModalState, refetch }) {
    const [values, setValues] = useState({});

    const [updateUser] = useMutation(UPDATE_USER, {
        onCompleted: () => {
            refetch();
        }
    });
    const { t } = useTranslation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (user.id && !values.id) {
            setValues(user);
        } else if (user.id !== values.id) {
            setValues(user);
        }
    });

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    const onSubmit = () => {
        const userId = values.id;
        delete values.__typename;
        delete values.id;
        delete values.verified;
        delete values.units;
        updateUser({ variables: { userId: userId, input: values } });
    };

    return (
        <form>
            <>
                <DialogContent>
                    {values && (
                        <Typography>
                            <h4 style={{ textAlign: 'center' }}>{t('Update user')}</h4>
                            <TextField
                                id='outlined-basic'
                                label={t('First name')}
                                name='firstName'
                                value={values.firstName}
                                onChange={handleChange}
                            ></TextField>
                            <br />
                            <TextField
                                id='outlined-basic'
                                label={t('Last name')}
                                name='lastName'
                                value={values.lastName}
                                onChange={handleChange}
                            ></TextField>
                            <br />
                            <TextField
                                id='outlined-basic'
                                label={t('E-mail address')}
                                name='email'
                                value={values.email}
                                onChange={handleChange}
                            ></TextField>
                            <br />
                            <TextField
                                id='outlined-basic'
                                label={t('Phone number')}
                                name='phone'
                                value={values.phone}
                                onChange={handleChange}
                            ></TextField>
                        </Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            onSubmit();
                            setModalState(false);
                        }}
                    >
                        {t('Edit')}
                    </Button>
                    <Button onClick={() => setModalState(false)}>{t('Cancel')}</Button>
                </DialogActions>
            </>
        </form>
    );
}

EditUser.propTypes = {
    user: PropTypes.object,
    setModalState: PropTypes.func,
    refetch: PropTypes.func
};

export default EditUser;
