/* eslint-disable react/prop-types */
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import { MTableHeader } from 'material-table';
import { useSnackbar } from 'notistack';

import { makeReference, useLazyQuery, useMutation } from '@apollo/client';
import { Drawer, Popover } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { Table } from 'common/components';
import { ProductCreateEditFormDialog } from 'common/modules/ProductCreateEditFormDialog/ProductCreateEditFormDialog.jsx';
import { Context as ProductFilterContext } from 'context/ProductFilter/ProductFilterContext';
import { Context as activeUnitContext } from 'context/units/activeUnitContext';
import { CREATE_PRODUCT, DELETE_PRODUCT, UPDATE_FEED_FIELDS, UPDATE_PRODUCT } from 'graphql/mutations/object';
import { GET_PRODUCTS } from 'graphql/queries/object';
import { moveElement } from 'helpers/array';
import { logError } from 'helpers/error';
import { marshalProduct } from 'helpers/marshal';

import {
    mapColumnsForMaterialTable,
    mapMaterialTableColumnsForUpdate,
    mapProductsForMaterialTableWithSelectedRows,
    mapSortOrderForMaterialTable,
    shouldAddEmptyFirstColumnFieldToHeaderEdit
} from '../../../helpers/table';
import { ProductTypeSelector } from '../ProductTypeSelector/ProductTypeSelector';
import FilterList from './components/Filters/FilterList';
import MTableHeaderEdit from './components/MTableHeaderEdit';
import { getDefaultActionButtons } from './components/ProductTableActionButtons';

const useStyles = makeStyles((theme) => ({
    root: {
        fontSize: theme.typography.fontSize
    }
}));

export const ProductTable = ({
    customActionButtons = [],
    defaultPageSize = 10,
    enableCustomFilter = true,
    enableDeselectAll = true,
    enableAddNewProduct = true,
    enableEditProduct = true,
    enableDeleteProduct = true,
    enableEditColumnLabels = true,
    enableEditColumnOrder = true,
    enableEditColumnVisibility = true,
    enableSearch = true,
    enableSelection = true,
    enableSingleProductSelectionMode = false,
    enableProductTypeSelection = true,
    enableSorting = true,
    enableTitle = true,
    handleAddProducts,
    handleRemoveSelectedProducts,
    handleReturnSelection,
    passedProductIds,
    removeContainer = false,
    skipProductIds
}) => {
    const classes = useStyles();

    const {
        updateActiveUnit,
        state: { activeUnit }
    } = useContext(activeUnitContext);

    const { state: filterState, setSelectedFilters, resetFilters } = useContext(ProductFilterContext);
    const selectedFilter = filterState.selected.map(({ criterion, field }) => ({ criterion, field }));

    const { t } = useTranslation();

    const { enqueueSnackbar } = useSnackbar();

    const actionButtons = [...customActionButtons];

    const [currentPage, setCurrentPage] = useState(0);
    const [currentPageSize, setCurrentPageSize] = useState(defaultPageSize);
    const [currentSortField, setCurrentSortField] = useState('');
    const [currentSortOrder, setCurrentSortOrder] = useState(1);
    const [currentPagination, setCurrentPagination] = useState({
        limit: currentPageSize,
        skip: currentPage * currentPageSize,
        count: 0
    });
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedProductType, setSelectedProductType] = useState(activeUnit?.productType);
    const [customFilters, setCustomFilters] = useState({});
    const [products, setProducts] = useState([]);
    const [columns, setColumns] = useState([]);
    const [count, setCount] = useState(0);
    const [editColumnHeadersState, setEditColumnHeadersState] = useState(false);
    const [productForEdit, setProductForEdit] = useState(null);
    const [ProductCreateEditFormDialogIsOpen, setProductCreateEditFormDialogIsOpen] = useState(false);
    const [productTypeSelectionPopoverState, setProductTypeSelectionPopoverState] = useState(false);
    const [productTypeSelectionAnchorEl, setProductTypeSelectionAnchorEl] = React.useState(null);
    const [drawerState, setDrawerState] = useState({
        right: false
    });

    const queryVariables = {
        unitId: activeUnit ? activeUnit.id : null,
        productTypeId: selectedProductType?.id,
        limit: currentPagination.limit,
        productIds: passedProductIds,
        skipProductIds: skipProductIds,
        search: searchQuery,
        skip: currentPagination.skip,
        sort: [{ field: currentSortField, order: currentSortOrder }],
        filters: selectedFilter
    };

    const [getProducts, { data: productsData, loading }] = useLazyQuery(GET_PRODUCTS, {
        variables: queryVariables,
        onCompleted: (data) => {
            setData(data);
        },
        onError: (error) => {
            return logError(error);
        },
        skip: queryVariables.productTypeId === undefined
    });

    const cleanup = useCallback(() => {
        return () => {
            resetFilters();
            setSearchQuery('');
            setEditColumnHeadersState(false);
            setCustomFilters({});
            setProducts([]);
            setColumns([]);
            setCount(0);
        };
    }, [resetFilters]);

    useEffect(() => {
        cleanup();
        setData(productsData);
    }, [activeUnit.id, cleanup, productsData]);

    useEffect(() => {
        if (activeUnit?.id) {
            setSelectedProductType(activeUnit?.productType);
            getProducts();
        }
    }, [activeUnit, getProducts]);

    useEffect(() => {
        if (currentPage !== undefined && currentPageSize !== undefined) {
            setCurrentPagination({
                limit: currentPageSize,
                skip: currentPage * currentPageSize,
                count: 0
            });
        } else {
            setCurrentPagination(undefined);
        }
    }, [currentPage, currentPageSize]);

    const setData = (data) => {
        if (data && data.getProducts) {
            setCustomFilters(data.getProducts.filters);
            setProducts(data.getProducts.products);
            setColumns(data.getProducts.fields);
            setCount(data.getProducts.count);
        }
    };

    const [updatedFeedFields] = useMutation(UPDATE_FEED_FIELDS);

    const [createProduct, { loading: createProductLoading }] = useMutation(CREATE_PRODUCT, {
        onError: (error) => {
            logError(error);
            enqueueSnackbar(t('Failed to create the product'), { variant: 'error' });
        },
        onCompleted: () => {
            setProductCreateEditFormDialogIsOpen(false);
            enqueueSnackbar(t('Successfully created the product'), { variant: 'success' });
            setProductForEdit({ fields: {} });
        },
        update(cache, { data: { createProduct } }) {
            cache.modify({
                id: cache.identify(makeReference('ROOT_QUERY')),
                fields: {
                    getProducts(existingProductRefs, { toReference }) {
                        const updatedProductRefs = cloneDeep(existingProductRefs);
                        const newProductRef = toReference(createProduct, true);
                        setProducts((prev) => [createProduct, ...prev]);
                        updatedProductRefs.products = [newProductRef, ...updatedProductRefs.products];

                        return updatedProductRefs;
                    }
                }
            });
        }
    });
    const [updateProduct, { loading: updateProductLoading }] = useMutation(UPDATE_PRODUCT, {
        onError: (error) => {
            logError(error);
            enqueueSnackbar(t('Failed to update the product'), { variant: 'error' });
        },
        onCompleted: (data) => {
            setProductCreateEditFormDialogIsOpen(false);
            setProducts((prev) => {
                const index = products.findIndex((product) => product.id === data.updateProduct.id);
                const updatedProducts = cloneDeep(prev);
                updatedProducts[index] = data.updateProduct;

                return updatedProducts;
            });
            enqueueSnackbar(t('Successfully updated the product'), { variant: 'success' });
            setProductForEdit({ fields: {} });
        }
    });

    const [deleteProduct, { loading: deleteProductLoading }] = useMutation(DELETE_PRODUCT, {
        onError: (error) => {
            logError(error);
            enqueueSnackbar(t('Failed to delete the product'), { variant: 'error' });
        },
        onCompleted: () => {
            enqueueSnackbar(t('Successfully deleted the product'), { variant: 'success' });
            setProductForEdit({ fields: {} });
        },

        update(cache, { data: { deleteProduct } }) {
            cache.modify({
                id: cache.identify(makeReference('ROOT_QUERY')),
                fields: {
                    getProducts(existingProductRefs, { readField }) {
                        const updatedProductRefs = cloneDeep(existingProductRefs);
                        updatedProductRefs.products = updatedProductRefs.products.filter(
                            (productRef) => deleteProduct.id !== readField('id', productRef)
                        );

                        return updatedProductRefs;
                    }
                }
            });
        }
    });

    const productsFromQuery = productsData?.getProducts?.products;

    const mapProducts = useMemo(() => {
        if (productsFromQuery?.length > 0) {
            return mapProductsForMaterialTableWithSelectedRows(productsFromQuery, selectedRows, selectedProductType);
        }
    }, [productsFromQuery, selectedRows, selectedProductType]);

    const mapColumns = useMemo(() => {
        if (columns && columns.length && columns.length > 0) return mapColumnsForMaterialTable(columns);
        else return [];
    }, [columns]);

    const mapFields = mapColumns.map((column) => column.field);

    const mapSortOrder = (sortOrder) => {
        mapSortOrderForMaterialTable(sortOrder, setCurrentSortOrder);
    };

    const handleColumnDragged = (old_index, new_index) => {
        const orderedColumns = moveElement(old_index, new_index, columns);
        setColumns(orderedColumns);
        updatedFeedFields({
            variables: {
                input: {
                    unitId: activeUnit.id,
                    fields: mapMaterialTableColumnsForUpdate(orderedColumns)
                }
            },
            onError: (error) => logError(error)
        });
    };

    const handleSelectChange = (rows) => {
        const displayedIds = mapProducts.map((product) => product.id);
        const selectedRowsNotDisplayed = selectedRows.filter((selectedRow) => {
            return !displayedIds.includes(selectedRow.id);
        });
        const updatedSelectedRows = [...selectedRowsNotDisplayed, ...rows];
        setSelectedRows(updatedSelectedRows);
        enqueueSnackbar(t('{{ count }} selected product', { count: updatedSelectedRows.length }));
    };

    const handleDeselectAll = () => {
        mapProducts.map((product) => {
            product.tableData.checked = false;

            return product;
        });
        setSelectedRows([]);
        enqueueSnackbar(t('Deselected all products'));
    };

    const handleColumnHidden = (column, hidden) => {
        const updatedColumns = columns.map((col) => {
            if (col.id === column.field) {
                return {
                    ...col,
                    visibility: !hidden
                };
            }

            return col;
        });

        updatedFeedFields({
            variables: {
                input: {
                    unitId: activeUnit.id,
                    fields: mapMaterialTableColumnsForUpdate(updatedColumns)
                }
            },
            onError: (error) => logError(error)
        });
    };

    const handleEditHeaders = (updatedColumns, formIsValid) => {
        if (formIsValid) {
            setColumns(updatedColumns);
            updatedFeedFields({
                variables: {
                    input: {
                        unitId: activeUnit.id,
                        fields: mapMaterialTableColumnsForUpdate(updatedColumns)
                    }
                },
                onError: (error) => logError(error)
            });
        }
    };

    const handleReturnSelectionClicked = (productId) => {
        const productIds = productId ? [productId] : selectedRows.map((product) => product.id);
        enqueueSnackbar(t('Added {{count}} product', { count: productIds.length }), { variant: 'success' });
        handleReturnSelection(productIds);
    };

    const handleRemoveSelectedProductsClicked = (productId) => {
        const productIds = productId ? [productId] : selectedRows.map((product) => product.id);
        handleRemoveSelectedProducts(productIds);
        enqueueSnackbar(t('Removed {{count}} product', { count: productIds.length }), { variant: 'success' });
        setSelectedRows([]);
    };

    const handleAddNewProductClicked = () => {
        setProductForEdit(null);
        setProductCreateEditFormDialogIsOpen(true);
    };

    const handleEditProductClicked = (product) => {
        const index = products.findIndex((prod) => prod.id === product.id);
        setProductForEdit(marshalProduct(products[index]));
        setProductCreateEditFormDialogIsOpen(true);
    };

    const handleDeleteProductClicked = (deletedProduct) => {
        deleteProduct({
            variables: {
                productId: deletedProduct.id
            }
        });
    };
    const handleDeleteProductsClicked = (deletedProducts) => {
        console.error(`Not yet implemented ${deletedProducts}`); //TODO implement when backend supports deletion of multiple products
    };

    const handleSelectProductType = (newProductTypeId) => {
        setProductTypeSelectionPopoverState(false);
        setProductTypeSelectionAnchorEl(null);
        setSelectedProductType((prev) => {
            if (prev === newProductTypeId) {
                return prev;
            } else {
                updateActiveUnit({ ...activeUnit, ...{ productType: newProductTypeId } });
                getProducts();

                return newProductTypeId;
            }
        });
    };

    const toggleEditColumnLabels = () => {
        setEditColumnHeadersState(!editColumnHeadersState);
    };

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerState({ ...drawerState, [anchor]: open });
    };

    const toggleProductTypeSelectionPopover = () => {
        setProductTypeSelectionPopoverState((prev) => !prev);
    };

    const actionButtonProps = {
        states: {
            actionButtons: actionButtons,
            productsInTable: products.length,
            selectedRows: selectedRows
        },
        options: {
            enableCustomFilter: enableCustomFilter,
            enableDeselectAll: enableDeselectAll,
            enableAddNewProduct: enableAddNewProduct,
            enableEditProduct: enableEditProduct,
            enableDeleteProduct: enableDeleteProduct,
            enableSelection: enableSelection,
            enableEditColumnLabels: enableEditColumnLabels,
            enableSingleProductSelectionMode: enableSingleProductSelectionMode,
            enableProductTypeSelection: enableProductTypeSelection,
            setProductTypeSelectionAnchorEl: setProductTypeSelectionAnchorEl
        },
        callbacks: {
            handleAddProducts: handleAddProducts,
            handleAddNewProductClicked: handleAddNewProductClicked,
            handleEditProductClicked: handleEditProductClicked,
            handleDeleteProductClicked: handleDeleteProductClicked,
            handleDeleteProductsClicked: handleDeleteProductsClicked,
            handleRemoveSelectedProducts: handleRemoveSelectedProducts,
            handleRemoveSelectedProductsClicked: handleRemoveSelectedProductsClicked,
            handleReturnSelection: handleReturnSelection,
            handleReturnSelectionClicked: handleReturnSelectionClicked,
            handleDeselectAll: handleDeselectAll,
            toggleDrawer: toggleDrawer,
            toggleProductTypeSelectionPopover: toggleProductTypeSelectionPopover,
            toggleEditColumnLabels: toggleEditColumnLabels
        },
        t: t
    };

    const addEmptyFirstColumnProps = {
        enableSelection: enableSelection,
        handleRemoveSelectedProducts: handleRemoveSelectedProducts,
        handleReturnSelection: handleReturnSelection
    };

    const getComponentOverrides = () => {
        let obj = {};
        if (editColumnHeadersState) obj = { ...obj, Header: MTableHeaderEditWrapper };
        else obj = { ...obj, Header: MTableHeaderWrapper };
        if (removeContainer) obj = { ...obj, Container: React.Fragment };

        return obj;
    };

    // Wrappers
    const MTableHeaderWrapper = (props) => <MTableHeader {...props} />;
    const MTableHeaderEditWrapper = () => (
        <MTableHeaderEdit
            fields={columns}
            addEmptyFirstColumn={shouldAddEmptyFirstColumnFieldToHeaderEdit(addEmptyFirstColumnProps)}
            onEditHeaders={handleEditHeaders}
        />
    );

    return (
        <div style={{ maxWidth: '100%' }}>
            {selectedProductType && (
                <ProductCreateEditFormDialog
                    open={ProductCreateEditFormDialogIsOpen}
                    setOpen={setProductCreateEditFormDialogIsOpen}
                    product={productForEdit}
                    productType={selectedProductType}
                    createProduct={createProduct}
                    updateProduct={updateProduct}
                    createProductLoading={createProductLoading}
                    updateProductLoading={updateProductLoading}
                />
            )}

            <Drawer
                BackdropProps={{ invisible: true }}
                anchor={'right'}
                variant='temporary'
                open={drawerState['right']}
                onClose={toggleDrawer('right', false)}
            >
                <FilterList
                    title={t('Filters')}
                    fields={columns}
                    filters={customFilters}
                    setSelectedFilters={setSelectedFilters}
                    loading={loading}
                    closeDrawer={toggleDrawer('right', false)}
                />
            </Drawer>
            <Popover
                id={'product-type-selection-popover'}
                open={productTypeSelectionPopoverState}
                anchorEl={productTypeSelectionAnchorEl}
                onClose={() => {
                    setProductTypeSelectionPopoverState(false);
                    setProductTypeSelectionAnchorEl(null);
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                <div style={{ minWidth: '250px', minHeight: '56px' }}>
                    {selectedProductType && (
                        <ProductTypeSelector
                            productTypeId={selectedProductType?.id}
                            activeUnitId={activeUnit.id}
                            onSelect={handleSelectProductType}
                            isLoading={loading}
                        />
                    )}
                </div>
            </Popover>
            <Table
                title={t('Products')}
                className={classes.root}
                options={{
                    columnsButton: enableEditColumnVisibility,
                    debounceInterval: 500,
                    draggable: enableEditColumnOrder,
                    pageSize: currentPageSize,
                    search: enableSearch,
                    selection: enableSelection,
                    showTextRowsSelected: false,
                    sorting: enableSorting,
                    loadingType: 'overlay',
                    showTitle: enableTitle
                }}
                columns={mapColumns}
                isLoading={deleteProductLoading || loading}
                totalCount={count}
                page={currentPage}
                components={getComponentOverrides()}
                onSearchChange={(searchQuery) => {
                    setSearchQuery(searchQuery);
                }}
                onChangePage={(nextPage) => {
                    setCurrentPage(nextPage);
                }}
                onChangeRowsPerPage={(nextPerPage) => {
                    setCurrentPageSize(nextPerPage);
                    setCurrentPage(0);
                }}
                onOrderChange={(newSortFieldIndex, newSortOrder) => {
                    setCurrentSortField(mapFields[newSortFieldIndex] || undefined);
                    mapSortOrder(newSortOrder);
                }}
                onColumnDragged={(start, end) => {
                    handleColumnDragged(start, end);
                }}
                onChangeColumnHidden={(column, hidden) => {
                    handleColumnHidden(column, hidden);
                }}
                onSelectionChange={(rows) => handleSelectChange(rows)}
                actions={getDefaultActionButtons(actionButtonProps)}
                data={mapProducts}
            />
        </div>
    );
};
