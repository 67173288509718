import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Collapse, List, ListItem, ListItemText, makeStyles } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

import CheckboxFilter from './CheckboxFilter';
import RangeFilter from './RangeFilter';
import TreeFilter from './TreeFilter';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper
    },
    nested: {
        paddingLeft: theme.spacing(4)
    }
}));

const FilterListItem = ({ label, filter, setSelectedFilters }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <>
            <ListItem button onClick={handleClick}>
                <ListItemText primary={label} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout='auto'>
                <List component='div' disablePadding>
                    <ListItem className={classes.nested}>
                        {
                            {
                                TREE: (
                                    <TreeFilter
                                        key={'filter_' + filter.id}
                                        fieldId={filter.fieldId}
                                        items={filter.items}
                                        setSelectedFilters={setSelectedFilters}
                                    />
                                ),
                                RANGE: (
                                    <RangeFilter
                                        key={'filter_' + filter.id}
                                        fieldId={filter.fieldId}
                                        items={filter.items}
                                        setSelectedFilters={setSelectedFilters}
                                    />
                                ),
                                CHECKBOX: (
                                    <CheckboxFilter
                                        key={'filter_' + filter.id}
                                        fieldId={filter.fieldId}
                                        items={filter.items}
                                        setSelectedFilters={setSelectedFilters}
                                    />
                                )
                            }[filter.type]
                        }
                    </ListItem>
                </List>
            </Collapse>
        </>
    );
};

FilterListItem.propTypes = {
    label: PropTypes.string,
    filter: PropTypes.shape({
        fieldId: PropTypes.string,
        id: PropTypes.string,
        items: PropTypes.array,
        type: PropTypes.string
    }),
    setSelectedFilters: PropTypes.func
};

export default FilterListItem;
