import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useQuery } from '@apollo/client';
import { CircularProgress, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

import { Context as activeUnitContext } from 'context/units/activeUnitContext';
import { GET_PRODUCT_TYPES } from 'graphql/queries/object';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};

export const ProductTypeSelector = ({ productTypeId = null, onSelect, isLoading = false }) => {
    const {
        state: { activeUnit }
    } = useContext(activeUnitContext);

    const { data, loading, error } = useQuery(GET_PRODUCT_TYPES, {
        variables: { unitId: activeUnit.id }
    });
    const { t } = useTranslation();

    const handleChange = (e) => {
        const selectedProductType = data.getProductTypes.find((pType) => pType.id === e.target.value);
        onSelect(selectedProductType);
    };
    if (loading) return <CircularProgress size={24} />;
    if (error) return <div>{t('Error while fetching data')}!</div>;

    return (
        <React.Fragment>
            {data && data.getProductTypes && (
                <FormControl variant='filled' fullWidth>
                    <InputLabel id='product-type-selector-label'>{`${t('Product')} ${t('Type')} `}</InputLabel>
                    <Select
                        id='product-type-selector'
                        labelId='product-type-selector-label'
                        value={productTypeId || ''}
                        disabled={isLoading}
                        onChange={handleChange}
                        MenuProps={MenuProps}
                    >
                        {data.getProductTypes.map((productType) => (
                            <MenuItem key={productType?.id} value={productType?.id}>
                                {productType?.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
        </React.Fragment>
    );
};

ProductTypeSelector.propTypes = {
    productTypeId: PropTypes.string,
    onSelect: PropTypes.func,
    isLoading: PropTypes.bool
};
