import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { TreeView } from '@material-ui/lab';

export const InterestTreeViewComponent = ({ children, updateScroll, ...props }) => {
    useEffect(() => {
        updateScroll();
    }, [updateScroll]);

    return <TreeView {...props}>{children}</TreeView>;
};

InterestTreeViewComponent.propTypes = {
    children: PropTypes.node,
    updateScroll: PropTypes.func
};
