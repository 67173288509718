import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
export const AuthContext = React.createContext();

//ref https://medium.com/@ni3t/reacts-usestate-and-context-for-auth-routing-78347da1d6f
const AuthProvider = (props) => {
    const prevAuth = window.localStorage.getItem('authenticated') || false;
    const prevtoken = window.localStorage.getItem('token') || null;
    const [authenticated, setAuthenticated] = useState(
        props ? (props.authenticated === undefined ? prevAuth : props.authenticated) : prevAuth
    );
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(prevtoken);

    useEffect(() => {
        if (authenticated && token !== null && token !== 'null') {
            window.localStorage.setItem('authenticated', authenticated);
            window.localStorage.setItem('token', token);
        } else {
            window.localStorage.removeItem('authenticated');
            window.localStorage.removeItem('token');
        }
    }, [authenticated, token]);

    const defaultContext = {
        authenticated,
        setAuthenticated,
        token,
        setToken,
        user,
        setUser
    };

    return <AuthContext.Provider value={defaultContext}>{props.children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
    authenticated: PropTypes.bool,
    children: PropTypes.element
};

export default AuthProvider;
