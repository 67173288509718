import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { useLazyQuery, useQuery } from '@apollo/client';
import {
    Box,
    Button,
    Dialog,
    Grid,
    LinearProgress,
    Slide,
    Typography,
    useMediaQuery,
    useTheme
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

import {
    BlueHeaderContainer,
    CampaignTypeCardList,
    CampaignTypeFilter,
    CampaignTypeTabSelector,
    TopBar
} from 'common/components';
import { CAMPAIGN_ACTION_TYPE, useCampaignDispatch, useCampaignState } from 'context/campaign/CampaignContext';
import { Context as activeUnitContext } from 'context/units/activeUnitContext';
import { GET_CAMPAIGN_ADTYPES, GET_CAMPAIGN_TYPES } from 'graphql/queries/object';
import { logError } from 'helpers/error';

export const CampaignSelector = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const { name, selectedCampaign } = useCampaignState();
    const history = useHistory();
    const [selectedCampaignType, setSelectedCampaignType] = useState(null);
    const {
        state: { activeUnit }
    } = useContext(activeUnitContext);

    const dispatch = useCampaignDispatch();

    const [getAdTypes, { data: adTypesData, loading: adTypesLoading, error: errorAdTypes }] = useLazyQuery(
        GET_CAMPAIGN_ADTYPES,
        {
            onError: (error) => {
                enqueueSnackbar(t('systemMessage.errorGettingAdTypes'), { variant: 'error' });
                logError(new Error('Error with getAdTypes query got: ' + error.toString()));
            }
        }
    );

    const handleSelectCampaignType = (campaignType) => {
        setSelectedCampaignType(campaignType);
        getAdTypes({
            variables: {
                campaignTypeId: campaignType.id,
                unitId: activeUnit.id
            }
        });
    };

    const {
        data: campaignTypesData,
        loading: isLoadingCampaignTypes,
        error: errorCampaignTypes
    } = useQuery(GET_CAMPAIGN_TYPES, {
        onCompleted: (data) => {
            if (data && data.getCampaignTypes && data.getCampaignTypes.length > 0) {
                handleSelectCampaignType(data.getCampaignTypes[0]);
            }
        },
        onError: (error) => {
            enqueueSnackbar(t('systemMessage.errorGettingCampaignTypes'), { variant: 'error' });
            logError(new Error('Error with getCampaignTypes query got: ' + error.toString()));
        }
    });

    const responsivePadding = useMediaQuery(theme.breakpoints.up('sm')) ? 4 : 0;

    return (
        <Dialog
            open={typeof selectedCampaign === 'undefined'}
            PaperProps={{
                style: {
                    backgroundColor: theme.palette.background.empty
                }
            }}
            fullScreen
            aria-labelledby='campaign-create-dialog'
            TransitionComponent={Slide}
            TransitionProps={{ direction: 'up' }}
        >
            <TopBar>
                <Typography variant='h5'>
                    {name ? `${t('Add an ad to')} ${name}` : t('Start by selecting a campaign type')}
                </Typography>
                <Button
                    onClick={() => dispatch({ type: CAMPAIGN_ACTION_TYPE.CLOSE_SELECTOR, history })}
                    startIcon={<Close />}
                    color='primary'
                    variant='text'
                >
                    {t('Close')}
                </Button>
            </TopBar>
            <Box width={1} padding={responsivePadding}>
                <Grid container>
                    {!isLoadingCampaignTypes && !errorCampaignTypes && (
                        <CampaignTypeTabSelector
                            campaignTypes={campaignTypesData.getCampaignTypes}
                            onSelectCampaignType={handleSelectCampaignType}
                        />
                    )}
                    <BlueHeaderContainer title={t('Available campaign types')}>
                        <Box width={1} padding={2} paddingBottom={18}>
                            <CampaignTypeFilter />
                            <Box marginTop={2}>
                                {errorAdTypes || adTypesLoading ? (
                                    <LinearProgress />
                                ) : (
                                    <CampaignTypeCardList
                                        adTypes={adTypesData ? adTypesData.getCampaignAdTypes : []}
                                        onSelectAdType={(adType) =>
                                            dispatch({
                                                type: CAMPAIGN_ACTION_TYPE.ADD_CAMPAIGN,
                                                selectedCampaignType,
                                                adType,
                                                activeUnit
                                            })
                                        }
                                        withBackground={false}
                                        svg={true}
                                    />
                                )}
                            </Box>
                        </Box>
                    </BlueHeaderContainer>
                </Grid>
            </Box>
        </Dialog>
    );
};
