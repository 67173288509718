const policy = `
# What is cookies?
Cookies are small text files that can be used by websites to make a user's experience more efficient.
The law states that we can store cookies on your device if they are strictly necessary for the operation of this site. For all other types of cookies we need your permission.
This site uses different types of cookies. Some cookies are placed by third party services that appear on our pages.
## your cookies
You can at any time change or withdraw your consent from the Cookie Declaration on our website.
Learn more about who we are, how you can contact us and how we process personal data in our Privacy Policy.
Your consent applies to the following domains: www.cookiebot.com, manage.cookiebot.com, support.cookiebot.com, www.cybot.com
`;

export default policy;
