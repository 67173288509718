import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import DownIcon from '@material-ui/icons/KeyboardArrowDown';
import RightIcon from '@material-ui/icons/KeyboardArrowRight';
//import IconButton from '@material-ui/core/IconButton'

export const TreeItemCheckbox = (props) => {
    const [open, setOpen] = useState(false);
    const symbol = open ? <DownIcon /> : <RightIcon />;
    const showSymbol = props.children ? symbol : '';

    return (
        <div>
            <Box display='flex' justifyContent='space-between'>
                <Box alignItems='center' display='flex' p={1}>
                    <Typography onClick={() => setOpen(!open)}>
                        {showSymbol} {props.label}
                    </Typography>
                </Box>
                <Checkbox onChange={props.onChange} {...props} color='primary' />
            </Box>
            {open && <Box pl={4}>{props.children}</Box>}
        </div>
    );
};

TreeItemCheckbox.propTypes = {
    children: PropTypes.any,
    label: PropTypes.any,
    onChange: PropTypes.any
};
