/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react/display-name */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

import { Box, LinearProgress, Switch, useTheme } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import { Check } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { XGrid } from '@material-ui/x-grid';

import { ErrorOutlineIcon } from 'assets/icons/alert';
import { FacebookIcon, GoogleIcon, LinkedinIcon } from 'assets/icons/networks';
import { dateFnsLocales } from 'helpers/locales';
import { materialUnexposed } from 'layouts/theme/customColor';

import EditIcon from '../../../../assets/icons/edit';
import { CampaignStatus } from '../../../../common/enums';

const defaultTheme = createTheme();
const useStyles = makeStyles(
    (theme) => ({
        root: {
            '& .MuiDataGrid-cell, .MuiDataGrid-columnHeader, MuiDataGrid-cellCheckbox': {
                '&:focus': {
                    outline: 'none'
                }
            },
            '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
                borderRight: `1px solid ${materialUnexposed.backgroundHover}`
            },
            '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
                borderBottom: `1px solid ${materialUnexposed.backgroundHover}`
            },
            '& .MuiDataGrid-dataContainer, .MuiDataGrid-footerContainer': {
                backgroundColor: theme.palette.white
            },
            '& .MuiDataGrid-window': {
                overflowX: 'hidden'
            }
        },
        summary: {
            border: 'none',
            '& .MuiDataGrid-cell:focus, .MuiDataGrid-columnHeader:focus, MuiDataGrid-cellCheckbox:focus': {
                outline: 'none'
            },
            '& .MuiDataGrid-window': {
                overflowX: 'hidden'
            },
            '& .total': {
                backgroundColor: 'rgba(0, 0, 0, 0.018)'
            },
            '& .selectionSummary': {
                backgroundColor: 'rgba(180, 215, 242, 0.15)'
            }
        },
        statusPublished: {
            color: theme.palette.primary.main
        },
        container: {
            width: '100%',
            overflowX: 'auto',
            '&::-webkit-scrollbar-track': {
                WebkitBoxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.018)',
                backgroundColor: theme.palette.primary.light
            },
            '&::-webkit-scrollbar': {
                height: '5px',
                backgroundColor: theme.palette.primary.light
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main
            },
            '& div::-webkit-scrollbar-track': {
                width: '0'
            },
            '& div::-webkit-scrollbar': {
                width: '0'
            },
            '& div::-webkit-scrollbar-thumb': {
                width: '0'
            }
        }
    }),
    { defaultTheme }
);

const colWidth = {};

export const CampaignXGrid = ({
    data,
    selectedSummary,
    totalSummary,
    selectionModel,
    handleSelection,
    loading,
    edit,
    // eslint-disable-next-line no-unused-vars
    publish,
    selectedTab
}) => {
    const theme = useTheme();
    const classes = useStyles();
    const { t } = useTranslation();

    const { i18n } = useTranslation();

    const columns = [
        {
            headerName: ' ',
            field: 'progress',
            width: colWidth['progress'] || 64,
            // flex: 0.64,
            renderCell: (params) => (
                <Box display='flex' justifyContent='center' alignItems='center' height='100%' width='100%'>
                    {params.row?.status?.icon}
                </Box>
            )
        },
        {
            headerName: t('Campaign name'),
            field: 'name',
            width: colWidth['name'] || 400,
            // flex: 6,
            renderCell: (params) =>
                params.id === 'total' ? (
                    t('Total')
                ) : params.id === 'selectionSummary' ? (
                    `${t('Selected')} (${params.row.count})`
                ) : (
                    <Box width='100%' display='flex' flexDirection='row' justifyContent='space-between'>
                        <Box display='flex' flexShrink='1' overflow='hidden'>
                            <span
                                style={{
                                    width: '100%',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis'
                                }}
                            >
                                {`${params.row.name} [${params.id}]`}
                            </span>
                        </Box>
                        <Box
                            display='flex'
                            flexDirection='row'
                            justifyContent='flex-end'
                            alignItems='center'
                            flexShrink='0'
                            paddingLeft='15px'
                        >
                            {!!(
                                params.row.networks?.filter((network) => network.name.toLowerCase() === 'linkedin')
                                    .length || params.row.network?.name.toLowerCase() === 'linkedin'
                            ) && (
                                <LinkedinIcon
                                    color='inherit'
                                    fontSize='small'
                                    style={{
                                        color:
                                            params.row.networks?.find(
                                                (network) => network.name.toLowerCase() === 'linkedin'
                                            ).fail ||
                                            (params.row.network?.name.toLowerCase() === 'linkedin' &&
                                                params.row.network.fail)
                                                ? theme.palette.error.main
                                                : 'rgba(0, 0, 0, 0.6)'
                                    }}
                                />
                            )}
                            {!!(
                                params.row.networks?.filter((network) => network.name.toLowerCase() === 'google')
                                    .length || params.row.network?.name.toLowerCase() === 'google'
                            ) && (
                                <GoogleIcon
                                    color='inherit'
                                    fontSize='small'
                                    style={{
                                        color:
                                            params.row.networks?.find(
                                                (network) => network.name.toLowerCase() === 'google'
                                            ).fail ||
                                            (params.row.network?.name.toLowerCase() === 'google' &&
                                                params.row.network.fail)
                                                ? theme.palette.error.main
                                                : 'rgba(0, 0, 0, 0.6)'
                                    }}
                                />
                            )}
                            {!!(
                                params.row.networks?.filter((network) => network.name.toLowerCase() === 'facebook')
                                    .length || params.row.network?.name.toLowerCase() === 'facebook'
                            ) && (
                                <FacebookIcon
                                    color='inherit'
                                    fontSize='small'
                                    style={{
                                        color:
                                            params.row.networks?.find(
                                                (network) => network.name.toLowerCase() === 'facebook'
                                            ).fail ||
                                            (params.row.network?.name.toLowerCase() === 'facebook' &&
                                                params.row.network.fail)
                                                ? theme.palette.error.main
                                                : 'rgba(0, 0, 0, 0.6)'
                                    }}
                                />
                            )}
                        </Box>
                    </Box>
                )
        },
        {
            headerName: t('Click'),
            field: 'stats.clicks',
            width: colWidth['stats.clicks'] || 110,
            // flex: 1.1,
            type: 'number',
            resizable: true,
            valueGetter: (params) => params.row.clicks || 0
        },
        {
            headerName: t('Imp.'),
            field: 'stats.impressions',
            width: colWidth['stats.impressions'] || 110,
            // flex: 1.1,
            type: 'number',
            valueGetter: (params) => params.row.impressions || 0
        },
        {
            headerName: t('Revenue'),
            field: 'stats.renevue',
            width: colWidth['stats.renevue'] || 110,
            // flex: 1.1,
            type: 'number',
            valueGetter: (params) => params.row.renevue || 0
        },
        {
            headerName: t('Spent'),
            field: 'stats.spending',
            width: colWidth['stats.spending'] || 110,
            // flex: 1.1,
            type: 'number',
            valueGetter: (params) => params.row.spending || 0
        },
        {
            headerName: t('Budget'),
            field: 'budget',
            width: colWidth['stats.budget'] || 110,
            // flex: 1.1,
            type: 'number',
            valueGetter: (params) => params.row.budget || 0
        },
        {
            headerName: t('Start'),
            field: 'dateInterval.startDate',
            width: colWidth['dateInterval.startDate'] || 110,
            // flex: 1.1,
            type: 'date',
            valueFormatter: (params) =>
                params.row.dateInterval?.startDate
                    ? format(new Date(params.row.dateInterval.startDate), 'dd. MMM', {
                          locale: dateFnsLocales[i18n.language]
                      })
                    : '',
            sortComparator: (v1, v2, param1, param2) =>
                (+param1.api.getCellValue(param1.id, 'dateInterval')?.startDate || -1) -
                (+param2.api.getCellValue(param2.id, 'dateInterval')?.startDate || -1)
        },
        {
            headerName: t('End'),
            field: 'dateInterval.endDate',
            width: colWidth['dateInterval.endDate'] || 110,
            // flex: 1.1,
            type: 'date',
            valueFormatter: (params) =>
                params.row.dateInterval?.endDate
                    ? format(new Date(params.row.dateInterval.endDate), 'dd. MMM', {
                          locale: dateFnsLocales[i18n.language]
                      })
                    : '',
            sortComparator: (v1, v2, param1, param2) =>
                (+param1.api.getCellValue(param1.id, 'dateInterval')?.endDate || -1) -
                (+param2.api.getCellValue(param2.id, 'dateInterval')?.endDate || -1)
        }
    ];

    const statusHeaderColumn = {
        headerName: t('Status'),
        field: 'status',
        width: colWidth['status'] || 150,
        // flex: 1.3,
        cellClassName: ({ value }) => (value?.name?.toLowerCase() === 'published' ? classes.statusPublished : '')
    };

    const activityHeaderColumn = {
        headerName: t('Latest activity'),
        field: 'activity',
        width: colWidth['status'] || 150,
        // flex: 1.3,
        cellClassName: ({ value }) => (value?.name?.toLowerCase() === 'published' ? classes.statusPublished : '')
    };

    const servingStatusHeaderColumn = {
        headerName: t('Status'),
        field: 'servingStatus',
        width: colWidth['status'] || 150,
        // flex: 1.3,
        cellClassName: ({ value }) => (value?.name?.toLowerCase() === 'published' ? classes.statusPublished : '')
    };

    const detailsHeaderColumn = {
        headerName: t('Details'),
        field: 'message',
        width: colWidth['message'] || 350,
        // flex: 1.3,
        cellClassName: ({ value }) => (value?.name?.toLowerCase() === 'published' ? classes.statusPublished : '')
    };

    switch (selectedTab) {
        case 'adSet':
            columns.push(servingStatusHeaderColumn);
            columns.push(detailsHeaderColumn);
            break;
        case 'ads':
            columns.push(statusHeaderColumn);
            columns.push(detailsHeaderColumn);
            break;
        default:
            columns.push(statusHeaderColumn);
            break;
    }

    // useEffect(() => {
    //     console.log('data', data);
    // });

    return (
        <div className={classes.container}>
            {/* 52px - row's height, 56px - header's hight */}
            <div
                style={{
                    ...(data.length + (selectionModel?.length || 0) > 11 && { height: '682px' }),
                    width: '100%',
                    minWidth: '1662px',
                    overflowX: 'hidden'
                }}
            >
                <XGrid
                    columns={columns}
                    rows={data}
                    autoHeight={data.length + (selectionModel?.length || 0) < 12}
                    disableColumnMenu
                    checkboxSelection
                    disableSelectionOnClick
                    disableColumnReorder={true}
                    selectionModel={selectionModel}
                    onSelectionModelChange={(newSelection) => {
                        handleSelection(newSelection);
                    }}
                    onColumnWidthChange={({ width, colDef: { field } }) => {
                        colWidth[field] = width;
                    }}
                    onRowDoubleClick={({ id }) => edit(id)}
                    className={classes.root}
                    loading={loading}
                    components={{
                        Footer: () => (
                            <XGrid
                                columns={[
                                    {
                                        headerName: '',
                                        field: 'checkboxSpace',
                                        width: 48
                                    },
                                    ...columns
                                ]}
                                rows={selectionModel?.length ? [selectedSummary, totalSummary] : [totalSummary]}
                                autoHeight
                                disableSelectionOnClick
                                disableColumnReorder={true}
                                className={classes.summary}
                                headerHeight={0}
                                hideFooter={true}
                                getRowClassName={(params) => params.id}
                            />
                        )
                    }}
                />
            </div>
        </div>
    );
};

CampaignXGrid.propTypes = {
    data: PropTypes.array,
    selectedSummary: PropTypes.object,
    totalSummary: PropTypes.object,
    selectionModel: PropTypes.array,
    loading: PropTypes.bool,
    handleSelection: PropTypes.func,
    edit: PropTypes.func,
    publish: PropTypes.func,
    selectedTab: PropTypes.string
};
