import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSnackbar, withSnackbar } from 'notistack';

import { useMutation } from '@apollo/client';

import { VALIDATE_RECOVER_TOKEN } from 'graphql/mutations/user';
import { logError } from 'helpers/error';

// import gql from 'graphql-tag'
import { RESET_PASSWORD } from '../../graphql/mutations/user';
import ResetPasswordForm from './ResetPasswordForm';

const ResetPassword = ({ match }) => {
    const history = useHistory();
    const { t } = useTranslation();
    const [tokenEvaluated, setTokenEvaluated] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const token = match.params.token;
    const [validToken] = useMutation(VALIDATE_RECOVER_TOKEN, {
        variables: { recover: token },
        onCompleted: (res) => {
            if (res.validateRecoverToken === false) {
                history.push('/invalid-recovery');
            }
            setTokenEvaluated(true);
        }
    });

    const routertoDashBoard = () => {
        const path = '/sign-in';
        history.push(path);
    };

    const [resetPasswordMutation] = useMutation(RESET_PASSWORD, {
        onCompleted: () => {
            enqueueSnackbar(<span>{t('Your new password has been set and you can now log in')}</span>, {
                variant: 'success'
            });
            routertoDashBoard();
        },
        onError: (e) => {
            logError(e);
            enqueueSnackbar(<span>{t('Password was not changed')}</span>, {
                variant: 'error'
            });
        }
    });
    useEffect(() => {
        validToken();
    }, [validToken]);

    const handleReset = ({ password, confirmPassword }) => {
        resetPasswordMutation({ variables: { token, password, confirmPassword } });
    };

    return tokenEvaluated && <ResetPasswordForm onResetPassword={handleReset} />;
};

ResetPassword.propTypes = {
    match: PropTypes.any
};

export default withSnackbar(withRouter(ResetPassword));
