import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Backdrop, Box, CircularProgress, Typography } from '@material-ui/core';

import { Context as activeUnitContext } from 'context/units/activeUnitContext';

export const ExternalReport = ({ match }) => {
    const reportIndex = match.params.reportIndex;

    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [loadingText, setLoadingText] = useState('Retrieving and analyzing data...');

    const {
        state: { activeUnit }
    } = useContext(activeUnitContext);

    const report = activeUnit?.externalReports?.[reportIndex];

    useEffect(() => {
        setIsLoading(true);
        setLoadingText('Retrieving and analyzing data...');
    }, [reportIndex]);

    if (!report) {
        return <Box>{t('Error while fetching data')}</Box>;
    }

    return (
        <Box sx={{ height: '10000px', width: '100%', textAlign: 'center', padding: '20px 24px 32px' }}>
            {isLoading && (
                <Box component={Backdrop} sx={{ color: 'white' }} open>
                    <Box>
                        <CircularProgress color='primary' size='64px' />

                        <Typography variant='h5' color='inherit' style={{ marginTop: 16 }}>
                            {t(loadingText)}
                        </Typography>
                    </Box>
                </Box>
            )}

            {report.url && (
                <iframe
                    key={reportIndex}
                    src={report.url}
                    width='100%'
                    height='100%'
                    style={{ border: 'none', visibility: isLoading ? 'hidden' : 'visible' }}
                    title={report.name}
                    onLoad={() => {
                        setLoadingText('Showing report...');

                        setTimeout(() => {
                            setIsLoading(false);
                        }, 3000);
                    }}
                />
            )}
        </Box>
    );
};

ExternalReport.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            reportIndex: PropTypes.string
        })
    })
};
