import React from 'react';

import { useQuery } from '@apollo/client';

import { OBJECTS } from '../../graphql/queries/object';
import AdvertiserFilterView from './AdvertisersFilterView';

const AdvertiserFilter = () => {
    const { data, loading } = useQuery(OBJECTS, {
        //variables:{customerId}
    });

    const advertisers = loading ? [] : data.objects.objects;

    return <AdvertiserFilterView advertisers={advertisers} />;
};

export default AdvertiserFilter;
