import React from 'react';
import ReactDOM from 'react-dom';

import * as Sentry from '@sentry/browser';

import './i18n';

import App from './App';
import * as serviceWorker from './serviceWorker';

const { REACT_APP_SENTRY_DSN, NODE_ENV } = process.env;

if (NODE_ENV !== 'development') {
    Sentry.init({
        dsn: REACT_APP_SENTRY_DSN,
        environment: NODE_ENV
    });
}

if (module.hot) {
    module.hot.accept();
}

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
