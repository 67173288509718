import gql from 'graphql-tag';

import { FRAGMENT_AD_GROUP } from './ads';
import { FRAGMENT_NETWORK_WITHOUT_REFS } from './network';

export const FRAGMENT_CAMPAIGN = gql`
    fragment campaign on Campaign {
        id
        name
        advertiserName
        unitId
        type
        duration
        adGroups {
            ...adGroup
        }
        network {
            ...network
        }
        linkExtensions
    }
    ${FRAGMENT_AD_GROUP}
    ${FRAGMENT_NETWORK_WITHOUT_REFS}
`;
