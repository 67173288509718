import React, { useEffect, useState } from 'react';
import { Calendar, DateRange } from 'react-date-range';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { isEqual } from 'date-fns';
import { endOfYesterday, format, isBefore, startOfTomorrow } from 'date-fns/esm';

import { Box, Fab, Grid, Grow, IconButton, InputAdornment, Popover, TextField, Tooltip } from '@material-ui/core';
import { CalendarTodayOutlined, SaveOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

import { DATE_FORMAT, DATE_FORMAT_SHORT, rangeValid, startValid, validateInput } from 'helpers/datetime';
import { dateFnsLocales } from 'helpers/locales';

import 'react-date-range/dist/theme/default.css';
import 'react-date-range/dist/styles.css';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .rdrDateDisplayWrapper': {
            backgroundColor: theme.palette.primary.main
        },
        '& .rdrDateDisplayItem': {
            borderColor: theme.palette.primary.main
        },
        '& .rdrDateDisplayItem input': {
            color: theme.palette.text.light
        },
        '& .rdrDateDisplayItemActive input': {
            color: theme.palette.text.primary
        },
        '& .rdrDateDisplayItemActive': {
            border: '2px solid',
            borderColor: theme.palette.secondary.main
        },
        '& .rdrNextPrevButton': {
            background: 'transparent',
            borderRadius: '50%'
        },
        '& .rdrDayNumber': {
            top: 0,
            bottom: 0
        },
        '& .rdrDayToday .rdrDayNumber span:after': {
            height: 2,
            bottom: -2,
            border: 0
        },
        '& .rdrDayHovered .rdrDayNumber:after': {
            border: 0
        },
        '& .rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after': {
            border: 0
        }
    }
}));

// the active start/end date to be selected in the calendar
const START_SELECTED = [0, 0];
const END_SELECTED = [0, 1];

const DatePickerWithRange = ({ continuous, dateRange, setDateRange, allowPast = true }) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();

    const [anchorEl, setAnchorEl] = useState(null);
    const [inputString, setInputString] = useState('');
    const [invalidInput, setInvalid] = useState(false);
    const [focusedRange, setFocusedRange] = useState(START_SELECTED);
    const [range, setRange] = useState({});
    const [needsUpdate, setNeedsUpdate] = useState(true);

    useEffect(() => {
        setNeedsUpdate(true);
    }, [continuous]);

    useEffect(
        () =>
            setRange({
                startDate: (dateRange && dateRange.length === 2 && dateRange[0]) || new Date(),
                endDate: continuous ? null : (dateRange && dateRange.length === 2 && dateRange[1]) || null,
                key: 'selection'
            }),
        [continuous, dateRange]
    );

    useEffect(() => {
        if (rangeValid(range.startDate, range.endDate, allowPast)) {
            const _start = format(range.startDate, DATE_FORMAT);
            const _end = format(range.endDate, DATE_FORMAT);
            setInputString(`${_start} - ${_end}`);
        } else if (startValid(range.startDate, allowPast)) {
            setInputString(format(range.startDate, DATE_FORMAT));
        } else {
            setInputString('');
        }
    }, [allowPast, range]);

    const handleTextInputChange = (event) => {
        event.preventDefault();
        setInputString(event.target.value);
        const [start, end] = validateInput(event.target.value, continuous, allowPast);
        const valid = continuous ? start : start && end;
        setInvalid(!valid);
    };

    const handleTextInputBlur = (event) => {
        event.preventDefault();
        const [start, end] = validateInput(inputString, continuous, allowPast);
        // does the text field contain the same data, after parsing? avoid updating state
        const valid = continuous ? start : start && end;
        setInvalid(!valid);
        if (valid) {
            setRange((current) => ({
                ...current,
                startDate: start,
                endDate: continuous ? null : end
            }));
            updateSchedule(start, end);
        }
    };

    const updateSchedule = (start, end) => {
        setNeedsUpdate(false);
        if (!(isEqual(dateRange[0], start) && isEqual(dateRange[1], end))) {
            setDateRange([start, end]);
        }
    };

    return (
        <>
            <Grid container justify='center'>
                <Grid item xs={9}>
                    <TextField
                        fullWidth
                        error={invalidInput || (!continuous && !rangeValid(range.startDate, range.endDate, allowPast))}
                        disabled={Boolean(anchorEl)}
                        value={inputString}
                        onChange={handleTextInputChange}
                        onBlur={handleTextInputBlur}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <IconButton
                                        onClick={(e) => setAnchorEl(e.currentTarget)}
                                        aria-label='open calendar'
                                    >
                                        <CalendarTodayOutlined />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        variant='filled'
                        label={continuous ? t('Start date') : `${t('Start date')} - ${t('End date')}`}
                        helperText={t('Insert a {{dateOrRange}}. Shortened months (e.g. J/Jun/June) are accepted', {
                            dateOrRange: continuous ? t('Date') : t('Date range')
                        })}
                    />
                </Grid>
                <Grid item xs>
                    <Grow appear={false} in={needsUpdate && continuous}>
                        <Box marginX={1}>
                            <Tooltip title={t('Save')}>
                                <Fab
                                    color='primary'
                                    onClick={handleTextInputBlur}
                                    aria-label='manually save input date'
                                >
                                    <SaveOutlined />
                                </Fab>
                            </Tooltip>
                        </Box>
                    </Grow>
                </Grid>
            </Grid>
            <Popover
                id={anchorEl ? 'date-popover' : undefined}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                onClose={() => setAnchorEl(null)}
            >
                {continuous ? (
                    <Calendar
                        className={classes.root}
                        locale={dateFnsLocales[i18n.language]}
                        dateDisplayFormat={DATE_FORMAT_SHORT}
                        disabledDay={(date) => !allowPast && isBefore(date, endOfYesterday())}
                        date={range.startDate || startOfTomorrow()}
                        onChange={(date) => {
                            setRange((current) => ({
                                ...current,
                                startDate: date,
                                endDate: null
                            }));
                            updateSchedule(date, null);
                        }}
                        showSelectionPreview={false}
                    />
                ) : (
                    <DateRange
                        className={classes.root}
                        locale={dateFnsLocales[i18n.language]}
                        startDatePlaceholder={t('Start date')}
                        endDatePlaceholder={t('End date')}
                        focusedRange={focusedRange}
                        onRangeFocusChange={() => {
                            setFocusedRange(focusedRange !== START_SELECTED ? START_SELECTED : END_SELECTED);
                        }}
                        dateDisplayFormat={DATE_FORMAT_SHORT}
                        disabledDay={(date) => !allowPast && isBefore(date, endOfYesterday())}
                        shownDate={range.startDate || startOfTomorrow()}
                        onChange={(date) => {
                            setRange(date.selection);
                            setInvalid(false);

                            if (focusedRange === END_SELECTED) {
                                updateSchedule(date.selection.startDate, date.selection.endDate);
                            }
                        }}
                        moveRangeOnFirstSelection={false}
                        ranges={[range]}
                    />
                )}
            </Popover>
        </>
    );
};

DatePickerWithRange.propTypes = {
    allowPast: PropTypes.bool,
    continuous: PropTypes.bool,
    dateRange: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])).isRequired,
    setDateRange: PropTypes.func
};

export default DatePickerWithRange;
