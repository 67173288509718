import gql from 'graphql-tag';

import { FRAGMENT_SCHEDULE } from './date';
import { FRAGMENT_NETWORK_WITHOUT_REFS } from './network';
import { FRAGMENT_NETWORK_ASSETS } from './pages';
import { FRAGMENT_PRODUCT } from './products';
import { FRAGMENT_TARGETING } from './targeting';
import { FRAGMENT_UNIT_SIMPLE_REFS } from './units';

export const FRAGMENT_AD_TYPE_FIELD = gql`
    fragment adTypeField on AdTypeField {
        name
        type
        required
        advanced
        maxLength
        options
    }
`;

export const FRAGMENT_CAMPAIGN_TYPE_WITHOUT_REFS = gql`
    fragment campaignType on CampaignType {
        id
        name
    }
`;

export const FRAGMENT_MEDIA_DIMENSION = gql`
    fragment mediaDimension on MediaDimension {
        id
        name
        width
        height
        aspectRatio
    }
`;

export const FRAGMENT_TEMPLATE = gql`
    fragment template on Template {
        id
        unitId
        name
        width
        height
        mediaDimensions {
            ...mediaDimension
        }
    }
    ${FRAGMENT_MEDIA_DIMENSION}
`;

export const FRAGMENT_AD_TYPE = gql`
    fragment adType on AdType {
        id
        name
        campaignTypes {
            ...campaignType
        }
        networks {
            ...network
        }
        description
        thumbnailUrl
        biddingTypes
        targetingTypes
        supportedCreativeFormats {
            ...template
        }
        creativeFields {
            ...adTypeField
        }
        itemFields {
            ...adTypeField
        }
    }
    ${FRAGMENT_TEMPLATE}
    ${FRAGMENT_CAMPAIGN_TYPE_WITHOUT_REFS}
    ${FRAGMENT_NETWORK_WITHOUT_REFS}
    ${FRAGMENT_AD_TYPE_FIELD}
`;

export const FRAGMENT_MEDIA = gql`
    fragment media on Media {
        id
        url
    }
`;

export const FRAGMENT_ITEM = gql`
    fragment item on Item {
        id
        name
        creativeGroupingId
        product {
            ...product
        }
        media {
            ...media
        }
        url
        content
    }
    ${FRAGMENT_PRODUCT}
    ${FRAGMENT_MEDIA}
`;

export const FRAGMENT_ITEM_MAP = gql`
    fragment itemMap on ItemMap {
        unitId
        item {
            ...item
        }
    }
    ${FRAGMENT_ITEM}
`;

export const FRAGMENT_CREATIVE = gql`
    fragment creative on Creative {
        id
        formatId
        templateId
        name
        items {
            ...item
        }
        status
        budget
        type
        content
    }
    ${FRAGMENT_ITEM}
`;

export const FRAGMENT_CREATIVE_MAP = gql`
    fragment creativeMap on CreativeMap {
        unitId
        creatives {
            ...creative
        }
        networkAssets {
            ...networkAssets
        }
    }
    ${FRAGMENT_CREATIVE}
    ${FRAGMENT_NETWORK_ASSETS}
`;

export const FRAGMENT_AD = gql`
    fragment ad on Ad {
        id
        advertiser {
            ...unit
        }
        name
        advertisingChannelType
        advertisingChannelSubType
        budget
        schedule {
            ...schedule
        }
        duration
        creatives {
            ...creative
        }
        targeting {
            ...targeting
        }
        status
        salesCountry
        campaignPriority
        enableLocal
        biddingType
    }
    ${FRAGMENT_UNIT_SIMPLE_REFS}
    ${FRAGMENT_SCHEDULE}
    ${FRAGMENT_CREATIVE}
    ${FRAGMENT_TARGETING}
`;

export const FRAGMENT_AD_GROUP = gql`
    fragment adGroup on AdGroup {
        id
        name
        adType {
            ...adType
        }
        ads {
            ...ad
        }
        network {
            ...network
        }
    }
    ${FRAGMENT_AD_TYPE}
    ${FRAGMENT_AD}
    ${FRAGMENT_NETWORK_WITHOUT_REFS}
`;
