import createDataContext from './createDataContext';

const contextReducer = (state, action) => {
    switch (action.type) {
        case 'set_feeds':
            return {
                ...state,
                available: action.payload,
                current: action.payload[0]
            };
        case 'set_current':
            return { ...state, current: action.payload };
        default:
            return state;
    }
};

const setFeedsForUnit = (dispatch) => (feeds) => {
    dispatch({ type: 'set_feeds', payload: feeds });
};

const setCurrentFeed = (dispatch) => (feedId) => {
    dispatch({ type: 'set_current', payload: feedId });
};

export const { Provider, Context } = createDataContext(
    contextReducer,
    {
        setFeedsForUnit,
        setCurrentFeed
        // selectProduct,
        // deSelectProduct,
    },
    {
        available: [],
        current: null
        //selected: ["5d41996b45b72f4b98eca691", "5d41996b45b72f4b98eca6c5"]
    }
);
