import gql from 'graphql-tag';

export const GET_AD_PREVIEW = gql`
    query getAdPreview($adId: ID) {
        getAdPreview(adId: $adId) {
            previews
        }
    }
`;

export const GET_AD_PREVIEW_FROM_CREATIVE = gql`
    query getAdPreviewFromCreative($input: GetAdPreviewFromCreative) {
        getAdPreviewFromCreative(input: $input) {
            previews
        }
    }
`;

export const GET_AD_PREVIEW_IMAGES = gql`
    query getAdPreviewImages($adIds: [ID]) {
        getAdPreviewImages(adIds: $adIds) {
            previews
        }
    }
`;
