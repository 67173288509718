import React, { useContext, useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Context as activeUnitContext } from 'context/units/activeUnitContext';
import { GET_UNIT_DETAILS } from 'graphql/queries/unit';

import UserHeader from './components/Settings/UserHeader';
import UserList from './components/UserList';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: -theme.spacing(1)
    }
}));

const Users = () => {
    const classes = useStyles();
    const {
        state: { activeUnit }
    } = useContext(activeUnitContext);

    const [filters, setFilters] = useState({ filterBy: [], sortBy: '', searchText: '' });
    const [users, setUsers] = useState([]);

    const [getUnit] = useLazyQuery(GET_UNIT_DETAILS, {
        variables: { id: activeUnit.id },
        onCompleted: (res) => {
            setUsers([...(res.getUnitDetails?.users || [])]);
        },
        fetchPolicy: 'network-only'
    });

    useEffect(() => {
        getUnit();
    }, [getUnit]);

    let searchResults = users;

    if (filters.searchText) {
        searchResults = users.filter((user) =>
            (user.firstName + ' ' + user.lastName).toLowerCase().includes(filters.searchText.toLowerCase())
        );
    }
    if (filters.sortBy) {
        if (filters.sortBy === 'asc') {
            searchResults = searchResults.sort((a, b) => {
                if (a.firstName) {
                    return a.firstName.localeCompare(b.firstName);
                } else {
                    return false;
                }
            });
        }

        if (filters.sortBy === 'desc') {
            searchResults = searchResults.sort((a, b) => {
                if (b.firstName) {
                    return b.firstName.localeCompare(a.firstName);
                } else {
                    return false;
                }
            });
        }
    }

    if (filters.filterBy && filters.filterBy.length !== 0) {
        searchResults = searchResults.filter(
            (user) =>
                (user.verified && filters.filterBy.includes('verified')) ||
                (!user.verified && filters.filterBy.includes('notVerified'))
        );
    }

    return (
        <div className={classes.root}>
            <Grid container spacing={3} className={classes.grid}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Paper variant='outlined' square>
                        <UserHeader filters={filters} setFilters={setFilters} />
                        <UserList
                            users={users}
                            searchResults={searchResults}
                            unitName={activeUnit.name}
                            unitId={activeUnit.id}
                            refetch={getUnit}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
};

export default Users;
