import gql from 'graphql-tag';

export const UPDATE_BUDGET_ENTRIES = gql`
    mutation ($budgetId: ID!, $entries: [BudgetEntryInput!]!) {
        updateBudgetEntries(budgetId: $budgetId, entries: $entries)
    }
`;

export const UPDATE_BUDGET_NAME = gql`
    mutation ($budgetId: ID!, $name: String!) {
        updateBudgetName(budgetId: $budgetId, name: $name) {
            id
            name
        }
    }
`;

export const CREATE_BUDGET = gql`
    mutation ($budgetInput: BudgetInput!) {
        createBudget(budgetInput: $budgetInput) {
            id
            unitId
            name
            year
            created {
                user
                date
            }
            budgetEntries {
                unitId
                updates {
                    user
                    date
                    description
                }
                budgetLines {
                    name
                    period {
                        month
                        value
                        cut
                    }
                }
            }
        }
    }
`;

export const DELETE_BUDGET = gql`
    mutation ($budgetId: ID!) {
        deleteBudget(budgetId: $budgetId)
    }
`;

export const CREATE_BUDGET_ENTRY = gql`
    mutation ($budgetId: ID!, $unitId: ID!, $userId: ID!) {
        createBudgetEntry(budgetId: $budgetId, unitId: $unitId, userId: $userId) {
            budgetEntries {
                unitId
                updates {
                    user
                    date
                    description
                }
                budgetLines {
                    name
                    period {
                        month
                        value
                        cut
                    }
                }
            }
        }
    }
`;
