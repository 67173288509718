import createDataContext from '../createDataContext';
import { DESELECT_FILTERS, RESET_FILTERS, SET_SELECTED_FILTERS } from './actionTypes';

const contextReducer = (state, action) => {
    let selected;
    let index;

    switch (action.type) {
        case SET_SELECTED_FILTERS:
            selected = state.selected || [];
            index = selected.findIndex((s) => s.field === action.payload.field);
            if (index >= 0) {
                selected[index] = action.payload;
            } else {
                selected.push(action.payload);
            }

            return { ...state, selected };
        case DESELECT_FILTERS:
            return {
                ...state,
                selected: state.selected.filter((pid) => pid !== action.payload)
            };
        case RESET_FILTERS:
            return {
                selected: []
            };

        default:
            return state;
    }
};
const setSelectedFilters = (dispatch) => (filters) => {
    dispatch({ type: SET_SELECTED_FILTERS, payload: filters });
};
const deSelectFilters = (dispatch) => (filters) => {
    dispatch({ type: DESELECT_FILTERS, payload: filters });
};
const resetFilters = (dispatch) => () => {
    dispatch({ type: RESET_FILTERS });
};

export const { Provider, Context } = createDataContext(
    contextReducer,
    {
        setSelectedFilters,
        deSelectFilters,
        resetFilters
    },
    {
        selected: []
    }
);
