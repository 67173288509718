import PropTypes from 'prop-types';

export const userModel = {
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    verified: PropTypes.bool
};
