import React from 'react';

import { makeStyles } from '@material-ui/styles';

import AccountDetails from './components/AccountDetails';

const useStyles = makeStyles(() => ({
    root: {
        //padding: theme.spacing(1)
    }
}));

const Account = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <AccountDetails setPasswordVisibility />
        </div>
    );
};
export default Account;
