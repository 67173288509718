import React from 'react';

import AdvertisersForm from './AdvertiserForm/AdvertisersForm';

const AdvertisersFilterView = () => {
    return (
        <div>
            <AdvertisersForm />
        </div>
    );
};
export default AdvertisersFilterView;
