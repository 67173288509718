import { useMutation, useQueryClient } from '@tanstack/react-query';

const updateReport = async (reportId, reportData) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/powerBIReport/${reportId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(reportData)
    });
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const data = await response.json();

    return data;
};

const usePowerBIReportUpdate = () => {
    const queryClient = useQueryClient();

    return useMutation(({ reportId, reportData }) => updateReport(reportId, reportData), {
        onSuccess: () => {
            queryClient.invalidateQueries((query) => query.queryKey[0] === 'powerBIReport/list');
        }
    });
};

export default usePowerBIReportUpdate;
