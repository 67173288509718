import { useMutation, useQueryClient } from '@tanstack/react-query';

const restoreReport = async (reportId) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/powerBIReport/${reportId}/restore`, {
        method: 'PUT',
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
    });
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    return response.json();
};

const usePowerBIReportRestore = () => {
    const queryClient = useQueryClient();

    return useMutation(restoreReport, {
        onSuccess: () => {
            queryClient.invalidateQueries((query) => query.queryKey[0] === 'powerBIReport/list');
        }
    });
};

export default usePowerBIReportRestore;
