import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ImageUploading from 'react-images-uploading';
import PropTypes from 'prop-types';

import { useMutation } from '@apollo/client';
import {
    Button,
    CircularProgress,
    DialogActions,
    DialogContent,
    Grid,
    IconButton,
    InputAdornment,
    Slide,
    TextField,
    Tooltip,
    Typography
} from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

import { StyledProductCreateEditFormDialog } from 'common/modules/ProductCreateEditFormDialog/StyledProductCreateEditFormDialog';
import { StyledProductCreateEditFormDialogTitle } from 'common/modules/ProductCreateEditFormDialog/StyledProductCreateEditFormDialogTitle';
import { SINGLE_PRODUCT_IMAGE_UPLOAD } from 'graphql/mutations/object';
import { logError } from 'helpers/error';
import { formatBytes } from 'helpers/string';
import { validImageURL } from 'helpers/url';

import { ImageUploadCard } from './ImageUploadCard';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} mountOnEnter unmountOnExit />;
});

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px dashed black',
        borderRadius: theme.spacing(1),
        minHeight: theme.spacing(12)
    },
    buttonWrapper: {
        margin: theme.spacing(1),
        position: 'relative'
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    }
}));

export const ImageUploadDialog = ({ open, setOpen, imageUrls, fieldName, onSaveImages, ...rest }) => {
    const [images, setImages] = useState([]);
    const [imageUrlsState, setImageUrlsState] = useState(imageUrls);
    const [newImageURL, setNewImageURL] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    const { t } = useTranslation();
    const acceptType = ['jpg', 'jpeg', 'gif', 'png', 'bmp', 'tif'];
    const maxNumber = 20;
    const maxFileSize = 10485760;
    const classes = useStyles();
    const imageUrlError = newImageURL.length > 0 && !validImageURL(newImageURL, acceptType);

    useEffect(() => {
        setImageUrlsState(imageUrls);
    }, [imageUrls]);

    const [uploadImage] = useMutation(SINGLE_PRODUCT_IMAGE_UPLOAD, {
        onError: (error) => logError(error)
    });

    const uploadImageAsync = async (image, cb) => {
        const uploadedImage = await uploadImage({
            variables: {
                file: image.file
            }
        });

        if (uploadedImage?.data?.singleProductImageUpload?.url) cb(uploadedImage?.data?.singleProductImageUpload?.url);
    };

    const onChange = (imageList) => {
        setImages(imageList);
    };

    const handleRemoveImageUrl = (index) => {
        setImageUrlsState((prev) => {
            let updatedImageUrls = [...prev];
            updatedImageUrls = [...updatedImageUrls.slice(0, index), ...updatedImageUrls.slice(index + 1)];

            return updatedImageUrls;
        });
    };

    const handleSaveImages = () => {
        setIsUploading(true);
        const requests = images.map((item) => {
            return new Promise((resolve) => {
                uploadImageAsync(item, resolve);
            });
        });

        Promise.all(requests).then((results) => {
            setImages([]);
            setImageUrlsState((prev) => [...results, ...prev]);
            setIsUploading(false);
            onSaveImages([...results, ...imageUrlsState], fieldName);
        });
    };

    const handleClose = () => {
        setImages([]);
        setImageUrlsState(imageUrls);
        setOpen(false);
    };

    const handleChangeNewURL = (e) => {
        const updatedURL = e.target.value;
        setNewImageURL(updatedURL);
    };
    const handleAddImageURL = () => {
        setImageUrlsState((prev) => [newImageURL, ...prev]);
        setNewImageURL('');
    };

    return (
        <StyledProductCreateEditFormDialog
            aria-labelledby='image-upload-dialog'
            open={open}
            keepMounted
            TransitionComponent={Transition}
            fullWidth
            maxWidth='xl'
            {...rest}
        >
            <StyledProductCreateEditFormDialogTitle id='image-upload-dialog-title' onClose={handleClose}>
                {t('Upload or delete images')}
            </StyledProductCreateEditFormDialogTitle>
            <DialogContent dividers>
                <ImageUploading
                    multiple
                    value={images}
                    onChange={onChange}
                    acceptType={acceptType}
                    maxNumber={maxNumber}
                    maxFileSize={maxFileSize}
                    dataURLKey='data_url'
                >
                    {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                        errors
                    }) => {
                        return (
                            <Grid container alignItems='center' spacing={1}>
                                <Grid item xs={12} onClick={onImageUpload}>
                                    <div className={classes.root} {...dragProps}>
                                        {isDragging ? t('Drop here, please') : t('Click here or drag to upload')}
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label={t('Image') + 'URL'}
                                        type='url'
                                        variant='filled'
                                        disabled={isUploading}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        fullWidth
                                        helperText={imageUrlError ? t('Invalid input') : ''}
                                        error={imageUrlError}
                                        onChange={handleChangeNewURL}
                                        value={newImageURL}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <Tooltip
                                                        title={t('Value generated from product')}
                                                        aria-label={t('Value generated from product')}
                                                    >
                                                        <IconButton
                                                            onClick={handleAddImageURL}
                                                            disabled={
                                                                (newImageURL.length > 0 &&
                                                                    !validImageURL(newImageURL, acceptType)) ||
                                                                isUploading
                                                            }
                                                        >
                                                            <AddCircle />
                                                        </IconButton>
                                                    </Tooltip>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                                {(imageList?.length > 0 || imageUrlsState.length > 0) && (
                                    <Grid item xs={12}>
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            disabled={isUploading}
                                            onClick={() => {
                                                setImageUrlsState([]);
                                                onImageRemoveAll();
                                            }}
                                        >
                                            {t('Remove all images')}
                                        </Button>
                                    </Grid>
                                )}
                                <Grid container item justify='flex-start' spacing={1}>
                                    {errors && (
                                        <Grid item xs={12}>
                                            {errors.maxNumber && (
                                                <Typography color='error'>
                                                    {t('Number of selected images exceed the max')}: {maxNumber}
                                                </Typography>
                                            )}
                                            {errors.acceptType && (
                                                <Typography color='error'>
                                                    {t('Your selected file type is not allowed')}
                                                </Typography>
                                            )}
                                            {errors.maxFileSize && (
                                                <Typography color='error'>
                                                    {t('Selected file size exceed the max file size')}:{' '}
                                                    {formatBytes(maxFileSize)}
                                                </Typography>
                                            )}
                                        </Grid>
                                    )}
                                    {imageList.map((image, index) => (
                                        <Grid item xs={3} key={index}>
                                            <ImageUploadCard
                                                image={image['data_url']}
                                                title={image?.file['name']}
                                                index={index}
                                                isLoading={isUploading}
                                                updateFunc={onImageUpdate}
                                                removeFunc={onImageRemove}
                                            />
                                        </Grid>
                                    ))}
                                    {imageUrlsState.map((imageUrl, index) => (
                                        <Grid item xs={3} key={index}>
                                            <ImageUploadCard
                                                image={imageUrl}
                                                title={imageUrl
                                                    .split('/')
                                                    .pop()
                                                    .replace(/[#?].*$/, '')}
                                                index={index}
                                                isLoading={isUploading}
                                                updateFunc={null}
                                                removeFunc={handleRemoveImageUrl}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        );
                    }}
                </ImageUploading>
            </DialogContent>
            <DialogActions>
                <Button disabled={isUploading} onClick={handleClose} color='primary'>
                    {t('Cancel')}
                </Button>
                <div className={classes.buttonWrapper}>
                    <Button disabled={isUploading} onClick={handleSaveImages} color='primary'>
                        {t('Save')}
                    </Button>
                    {isUploading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
            </DialogActions>
        </StyledProductCreateEditFormDialog>
    );
};

ImageUploadDialog.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    imageUrls: PropTypes.array,
    fieldName: PropTypes.string,
    onSaveImages: PropTypes.func
};
