import gql from 'graphql-tag';

import { FRAGMENT_UNIT_SIMPLE_REFS } from './units';

export const FRAGMENT_FACEBOOK_PAGE = gql`
    fragment facebookPage on FacebookPage {
        id
        key
        name
        units {
            ...unit
        }
    }
    ${FRAGMENT_UNIT_SIMPLE_REFS}
`;

// Non-generic version exists backend in both form, this should be rectified.
export const FRAGMENT_NETWORK_ASSETS = gql`
    fragment networkAssets on NetworkAssets {
        facebookPage {
            ...facebookPage
        }
    }
    ${FRAGMENT_FACEBOOK_PAGE}
`;
