import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSnackbar, withSnackbar } from 'notistack';

import { useMutation } from '@apollo/client';

import { VALIDATE_VERIFY_TOKEN } from 'graphql/mutations/user';
import { logError } from 'helpers/error';

import { VERIFY_INVITED_USER } from '../../graphql/mutations/object';
import InviteUserAccountForm from './InviteUserAccountForm';

const InviteUserAccount = ({ match }) => {
    const { t } = useTranslation();
    const [tokenEvaluated, setTokenEvaluated] = useState(false);
    const [userEmail, setUserEmail] = useState('');

    const inviteCode = match.params.id;
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const [validateVerifyToken] = useMutation(VALIDATE_VERIFY_TOKEN, {
        variables: { verify: inviteCode },
        onCompleted: (res) => {
            if (res.validateVerifyToken.valid === false) {
                history.push('/invalid-invite');
            }
            setTokenEvaluated(res.validateVerifyToken.valid);
            setUserEmail(res.validateVerifyToken.email);
        }
    });

    useEffect(() => {
        validateVerifyToken();
    }, [validateVerifyToken]);

    const routertoDashBoard = () => {
        const path = '/sign-in';
        history.push(path);
    };
    const [verifyInvitedUserMutation] = useMutation(VERIFY_INVITED_USER, {
        onCompleted: () => {
            enqueueSnackbar(<span>{t('Your new password has been set and you can now log in')}</span>, {
                variant: 'success'
            });
            routertoDashBoard();
        },
        onError: (e) => {
            logError(e);
            enqueueSnackbar(<span>{t('Password was not changed')}</span>, {
                variant: 'error'
            });
        }
    });

    const handleReset = async ({ password, verify, firstName, lastName }) => {
        verifyInvitedUserMutation({
            variables: { input: { inviteCode, password, verify, firstName, lastName } }
        });
    };

    return tokenEvaluated && <InviteUserAccountForm email={userEmail} onResetPassword={handleReset} />;
};

InviteUserAccount.propTypes = {
    match: PropTypes.any
};

export default withSnackbar(withRouter(InviteUserAccount));
