import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Avatar, Button, Card, CardActions, CardContent, CardMedia, Grid, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/styles';

import FallbackImage from 'assets/fallbackimage.png';
import { BlueHeaderContainer } from 'common/components/BlueHeaderContainer/BlueHeaderContainer';

import HeaderConnections from '../components/AccountConnectedPages/HeaderConnections';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%'
    },
    paper: {
        height: '100px',
        padding: theme.spacing(1.5)
    },

    subheader: {
        marginTop: theme.spacing(5),
        background: ' #E1EFFA',
        height: '56px'
    },
    paper2: {
        height: '500px',
        padding: theme.spacing(2)
    },
    contentHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        marginRight: theme.spacing(2)
    },
    button: {
        marginTop: theme.spacing(1.5)
    },
    typo: {
        textTransform: 'uppercase',
        marginLeft: theme.spacing(2),
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '16px',
        letterSpacing: '1.25px',
        color: '#0578D3',
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(0.5)
        }
    },
    card: {
        maxWidth: 300,
        minHeight: theme.spacing(40),
        maxHeight: theme.spacing(51),
        margin: theme.spacing(1)
    },
    cardheader: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '20px',
        lineHeight: '24px',
        letterSpacing: '0.15px',
        color: 'rgba(0, 0, 0, 0.87)'
    },
    footer: {
        marginTop: theme.spacing(1)
    },
    divheader: {
        display: 'flex',
        justifyContent: 'flex-start'
    },
    avatar: {
        marginRight: theme.spacing(1.5)
    },
    media: {
        marginTop: -theme.spacing(2),
        minHeight: theme.spacing(24),
        maxHeight: theme.spacing(24),
        backgroundImage: `url(${FallbackImage})`
    },
    makeRequestButton: {
        marginTop: theme.spacing(2)
    },
    subContent: {
        marginTop: theme.spacing(7)
    }
}));

const AccountNewConnectionsBrowsePage = () => {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const handleClick = () => {
        history.push('/unit/plugins/facebook');
    };

    const handleGoBack = () => {
        history.push('/unit/plugins');
    };

    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid className={classes.content} item lg={12} xs={12} xl={12} md={12} sm={12}>
                    <div>
                        <HeaderConnections />

                        <div className={classes.subContent}>
                            <BlueHeaderContainer
                                title={t('Browse connections')}
                                titleLineAction={
                                    <Button startIcon={<AddIcon />} color='primary' onClick={handleClick} size='small'>
                                        {t('Make request')}
                                    </Button>
                                }
                            >
                                <div className={classes.paper2}>
                                    <div>
                                        <Button startIcon={<ArrowBackIcon />} onClick={handleGoBack}>
                                            {t('Back')}
                                        </Button>
                                        <Card className={classes.card} variant='outlined'>
                                            <CardContent>
                                                <div className={classes.divheader}>
                                                    <div>
                                                        <Avatar
                                                            className={classes.avatar}
                                                            alt='fb-icon'
                                                            src='/images/fbIcon.png'
                                                        />
                                                    </div>
                                                    <div>
                                                        <Typography className={classes.cardheader}>
                                                            {' '}
                                                            {t('Facebook pages')}
                                                        </Typography>
                                                        <Typography gutterBottom>facebook</Typography>
                                                    </div>
                                                </div>
                                            </CardContent>
                                            <CardMedia className={classes.media} src={FallbackImage} component='img' />
                                            <CardActions className={classes.footer}>
                                                <div className={classes.footer}>
                                                    <Button size='small' color='primary' onClick={handleClick}>
                                                        {t('Connect')}
                                                    </Button>
                                                </div>
                                            </CardActions>
                                        </Card>
                                    </div>
                                </div>
                            </BlueHeaderContainer>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};
export default AccountNewConnectionsBrowsePage;
