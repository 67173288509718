import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { createTheme, IconButton, InputAdornment, TextField, useTheme } from '@material-ui/core';
import { AddCircleOutline, RemoveCircleOutline } from '@material-ui/icons';
import { ThemeProvider } from '@material-ui/styles';

import { KeywordMatchType, TargetingConditionType } from 'helpers/targeting';

export const KeywordSelectorTextField = ({ onAddKeywords, targetingConditionType }) => {
    const defaultTheme = useTheme();
    const theme = useMemo(() => {
        if (targetingConditionType === TargetingConditionType.Exclude) {
            return createTheme({
                ...defaultTheme,
                palette: {
                    primary: {
                        main: defaultTheme.palette.error.main
                    }
                }
            });
        }

        return defaultTheme;
    }, [targetingConditionType, defaultTheme]);

    const { t } = useTranslation();

    const [state, setState] = useState('');

    const handleAddKeywords = () => {
        const lines = state
            ?.split(/\r\n|\r|\n/g)
            .map((item) => item.replace(/\s+/g, ' ').replace(/^\s+|\s+$/g, ''))
            .filter((item) => item)
            .map((item) => ({
                text: item,
                matchType: KeywordMatchType.Broad
            }));
        onAddKeywords(lines);
        setState('');
    };

    return (
        <ThemeProvider theme={theme}>
            <TextField
                value={state}
                onChange={(e) => {
                    setState(e.target.value);
                }}
                id='keyword-selector-textarea'
                label={t('Keywords')}
                placeholder={t('Type or paste your keywords - one word or phrase per line')}
                fullWidth
                multiline
                rows={10}
                variant='filled'
                InputLabelProps={{ shrink: true }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            <IconButton color='primary' onClick={handleAddKeywords} aria-label='add keywords'>
                                {targetingConditionType === TargetingConditionType.Include ? (
                                    <AddCircleOutline />
                                ) : (
                                    <RemoveCircleOutline />
                                )}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
        </ThemeProvider>
    );
};

KeywordSelectorTextField.propTypes = {
    onAddKeywords: PropTypes.func,
    targetingConditionType: PropTypes.string
};
