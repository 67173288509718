import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';

import { useLazyQuery } from '@apollo/client';
import {
    Box,
    Button,
    CircularProgress,
    DialogActions,
    DialogContent,
    Divider,
    Grid,
    Slide,
    Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { ImageUploadDialog } from 'common/components/ImageUploadDialog/ImageUploadDialog';
import { ProductTypeSelector } from 'common/components/ProductTypeSelector/ProductTypeSelector';
import { productModel, productTypeModel } from 'common/models/product';
import { Context as activeUnitContext } from 'context/units/activeUnitContext';
import { GET_PRODUCT_TYPE } from 'graphql/queries/object';
import { logError } from 'helpers/error';
import { marshalProductInput } from 'helpers/marshal';
import { getFieldValue } from 'helpers/product';

import { ProductField } from './ProductField';
import { StyledProductCreateEditFormDialog } from './StyledProductCreateEditFormDialog';
import { StyledProductCreateEditFormDialogTitle } from './StyledProductCreateEditFormDialogTitle';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} mountOnEnter unmountOnExit />;
});

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2)
    },
    dialogBackground: {
        backgroundColor: theme.palette.background.empty
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    placeholderText: {
        margin: theme.spacing(1)
    },
    buttonWrapper: {
        margin: theme.spacing(1),
        position: 'relative'
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    }
}));

export const ProductCreateEditFormDialog = ({
    open,
    setOpen,
    product,
    productType,
    createProduct,
    updateProduct,
    createProductLoading,
    updateProductLoading
}) => {
    const { dialogBackground } = useStyles();

    const classes = useStyles();
    const { t } = useTranslation();
    const {
        state: { activeUnit }
    } = useContext(activeUnitContext);

    const [isEditState, setIsEditState] = useState(product ? true : false);
    const [imageUploadDialogFieldName, setImageUploadDialogFieldName] = useState('');
    const [imageUploadDialogOpen, setImageUploadDialogOpen] = useState(false);
    const defaultProductContent = product || null;
    const [selectedProduct, setSelectedProduct] = useState(product || { fields: {} });
    const [selectedProductType, setSelectedProductType] = useState(productType);

    const [getProductType, { loading: getProductTypeLoading }] = useLazyQuery(GET_PRODUCT_TYPE, {
        variables: {
            id: selectedProduct?.productTypeId
        },
        onError: (error) => logError(error),
        onCompleted: (data) => {
            setSelectedProductType(data.getProductType);
        }
    });

    useEffect(() => {
        if (product) {
            setSelectedProduct(product);
            setIsEditState(true);
            if (selectedProduct?.productTypeId) {
                getProductType();
            }
        } else {
            setSelectedProduct({ fields: {} });
            setIsEditState(false);
        }
    }, [product, selectedProduct.productTypeId, getProductType]);

    const handleChange = (e, field) => {
        e.persist();
        setSelectedProduct((prev) => {
            const updatedProduct = cloneDeep(prev);
            updatedProduct.fields[field] = e?.target?.value;

            return updatedProduct;
        });
    };

    const handleChangeChecbox = (e, field) => {
        setSelectedProduct((prev) => {
            const updatedProduct = cloneDeep(prev);
            updatedProduct.fields[field] = e?.target?.checked;

            return updatedProduct;
        });
    };

    const handleResetField = (e, field) => {
        e.stopPropagation();
        e.preventDefault();
        setSelectedProduct((prev) => {
            const resetField = cloneDeep(prev);
            resetField.fields[field.name] = defaultProductContent.fields[field.name];

            return resetField;
        });
    };

    const handleSaveProduct = () => {
        if (isEditState) {
            const variables = marshalProductInput(selectedProduct.fields, null, null, selectedProduct.id);
            updateProduct({
                variables: variables
            });
        } else {
            const variables = marshalProductInput(selectedProduct.fields, selectedProductType.id, activeUnit.id, null);
            createProduct({
                variables: variables
            });
        }
    };

    const handleSaveImages = (imageUrls, fieldName) => {
        setImageUploadDialogOpen(false);
        setSelectedProduct((prev) => {
            const updatedFields = cloneDeep(prev);
            updatedFields.fields[fieldName] = imageUrls;

            return updatedFields;
        });
    };

    const handleOpenImageUploadDialog = (fieldName) => {
        setImageUploadDialogFieldName(fieldName);
        setOpen(false);
        setImageUploadDialogOpen(true);
    };

    return (
        <React.Fragment>
            <ImageUploadDialog
                open={imageUploadDialogOpen}
                setOpen={setImageUploadDialogOpen}
                onExited={() => setOpen(true)}
                imageUrls={selectedProduct?.fields[imageUploadDialogFieldName] || []}
                onSaveImages={handleSaveImages}
                fieldName={imageUploadDialogFieldName}
            />
            <StyledProductCreateEditFormDialog
                PaperProps={{ className: dialogBackground }}
                aria-labelledby='product-create-edit-dialog'
                open={open}
                keepMounted
                TransitionComponent={Transition}
                fullWidth
                maxWidth='xl'
            >
                <StyledProductCreateEditFormDialogTitle
                    id='product-create-edit-dialog-title'
                    onClose={() => {
                        setOpen(false);
                    }}
                >
                    {isEditState ? t('Edit product') : t('Create product')}
                </StyledProductCreateEditFormDialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={12}>
                            <ProductTypeSelector
                                productTypeId={selectedProductType?.id}
                                onSelect={setSelectedProductType}
                                isLoading={createProductLoading || updateProductLoading}
                            />

                            <Divider className={classes.divider} />
                            <Grid container spacing={2}>
                                {!selectedProductType?.fields && !getProductTypeLoading && (
                                    <React.Fragment>
                                        <Grid item xs={12}>
                                            <Typography
                                                className={classes.placeholderText}
                                                align='center'
                                                variant='subtitle2'
                                            >
                                                {t('Select a product type')}
                                            </Typography>
                                        </Grid>
                                    </React.Fragment>
                                )}
                                {getProductTypeLoading ? (
                                    <Grid item xs={12}>
                                        <Box display='flex' width='auto' height='100%'>
                                            <Box m='auto'>
                                                <CircularProgress size={48} />
                                            </Box>
                                        </Box>
                                    </Grid>
                                ) : (
                                    selectedProductType?.fields &&
                                    selectedProductType.fields.map((productTypeField) => (
                                        <ProductField
                                            key={productTypeField.id}
                                            value={getFieldValue(selectedProduct, productTypeField)}
                                            fieldType={productTypeField?.fieldType}
                                            isLoading={createProductLoading || updateProductLoading}
                                            productTypeField={productTypeField}
                                            defaultProductContentField={
                                                defaultProductContent?.fields[productTypeField.name]
                                            }
                                            selectedProductContentField={selectedProduct?.fields[productTypeField.name]}
                                            handleOpenImageUploadDialog={handleOpenImageUploadDialog}
                                            handleChangeChecbox={handleChangeChecbox}
                                            handleChange={handleChange}
                                            handleResetField={handleResetField}
                                        />
                                    ))
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={createProductLoading || updateProductLoading}
                        onClick={() => setOpen(false)}
                        color='primary'
                    >
                        {t('Cancel')}
                    </Button>
                    <div className={classes.buttonWrapper}>
                        <Button
                            disabled={createProductLoading || updateProductLoading || !selectedProductType}
                            onClick={handleSaveProduct}
                            color='primary'
                        >
                            {t('Save')}
                        </Button>
                        {(createProductLoading || updateProductLoading) && (
                            <CircularProgress size={24} className={classes.buttonProgress} />
                        )}
                    </div>
                </DialogActions>
            </StyledProductCreateEditFormDialog>
        </React.Fragment>
    );
};

ProductCreateEditFormDialog.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    product: PropTypes.shape(productModel),
    productType: PropTypes.shape(productTypeModel),
    createProduct: PropTypes.func,
    updateProduct: PropTypes.func,
    createProductLoading: PropTypes.bool,
    updateProductLoading: PropTypes.bool
};
