import React from 'react';

import { LinearProgress, Switch } from '@material-ui/core';

import { ErrorOutlineIcon } from 'assets/icons/alert';
import EditIcon from 'assets/icons/edit';

import { isNumeric } from '../utils';
import { Enum } from './enum';

export class CampaignStatus extends Enum {
    #icon;

    static items = [];

    static UNDEFINED = new CampaignStatus('', -1);
    static UNSPECIFIED = new CampaignStatus('UNSPECIFIED', 0, () => <ErrorOutlineIcon />);
    static UNKNOWN = new CampaignStatus('UNKNOWN', 1, () => <ErrorOutlineIcon />);
    static ENABLED = new CampaignStatus('PUBLISHED', 2, () => <Switch color='primary' checked={true} dir='rtl' />);
    static PAUSED = new CampaignStatus('PAUSED', 3, () => <Switch checked={false} />);
    static REMOVED = new CampaignStatus('REMOVED', 4, () => <ErrorOutlineIcon />);
    static MISCELLANEOUS = new CampaignStatus('PARTIAL', 5, <Switch color='primary' checked={false} dir='rtl' />);
    static FAILED = new CampaignStatus('FAILED', 6, () => <ErrorOutlineIcon />);
    static INPROGRESS = new CampaignStatus(
        'IN PROGRESS',
        8,
        <LinearProgress color='primary' style={{ width: '35px' }} />
    );

    static ENQUEUED_TO_PUBLISH = new CampaignStatus(
        'QUEUED',
        20,
        <LinearProgress color='primary' style={{ width: '35px' }} />
    );
    static DRAFT = new CampaignStatus('DRAFT', 21, <EditIcon fontSize='small' />);

    constructor(name, value, icon = null) {
        super(name, value);

        this.#icon = icon;
        CampaignStatus.items.push(this);
    }

    /**
     *
     */

    equals(other) {
        return this.value === other.value && this.name === other.name;
    }

    /**
     *
     */

    get icon() {
        return this.#icon;
    }
    /**
     *
     */

    static find(criteria) {
        if (isNumeric(criteria)) {
            return CampaignStatus.items.find((v) => v.value === Number(criteria));
        }

        return CampaignStatus.items.find((v) => v.name === criteria);
    }

    /**
     *
     */

    static contains(criteria) {
        const status = CampaignStatus.find(criteria);

        return !!status;
    }

    /**
     *
     */

    static get(criteria) {
        return CampaignStatus.find(criteria) || CampaignStatus.UNDEFINED;
    }
}
