import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { IconButton, makeStyles, TableCell, TableRow, TextField } from '@material-ui/core';
import { Check, Close, DeleteOutline, EditOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    mediumWidth: {
        width: '32%'
    },
    slimWidth: {
        width: '10%'
    },
    tableCell: {
        padding: '4px',
        fontSize: '12px',
        lineHeight: '12px'
    },
    tableIcon: {
        padding: '4px'
    },
    tableRow: {
        borderBottom: `1px solid ${theme.palette.divider}`
    },
    slimTextField: {
        '& .MuiOutlinedInput-input': {
            padding: '4px'
        }
    }
}));

export default function ExternalReportRow({ report, onDelete, onEdit }) {
    const classes = useStyles();
    const [isEditing, setIsEditing] = useState(false);
    const [name, setName] = useState(report.name);
    const [url, setUrl] = useState(report.url);

    return (
        <TableRow className={classes.tableRow}>
            <TableCell className={clsx(classes.tableCell, classes.mediumWidth)}>
                {isEditing ? (
                    <TextField
                        className={clsx(classes.slimTextField)}
                        variant='outlined'
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                        fullWidth
                    />
                ) : (
                    report.name
                )}
            </TableCell>
            <TableCell className={clsx(classes.tableCell)}>
                {isEditing ? (
                    <TextField
                        className={clsx(classes.slimTextField)}
                        variant='outlined'
                        onChange={(e) => setUrl(e.target.value)}
                        value={url}
                        fullWidth
                    />
                ) : (
                    report.url
                )}
            </TableCell>

            <TableCell className={clsx(classes.tableCell, classes.slimWidth)} align='right'>
                {isEditing ? (
                    <>
                        <IconButton
                            className={clsx(classes.tableIcon)}
                            onClick={() => {
                                setIsEditing(false);
                                onEdit({ name, url });
                            }}
                        >
                            <Check />
                        </IconButton>
                        <IconButton
                            className={clsx(classes.tableIcon)}
                            onClick={() => {
                                setIsEditing(false);
                                setName(report.name);
                                setUrl(report.url);
                            }}
                        >
                            <Close />
                        </IconButton>
                    </>
                ) : (
                    <>
                        <IconButton className={clsx(classes.tableIcon)} onClick={() => setIsEditing(true)}>
                            <EditOutlined />
                        </IconButton>
                        <IconButton className={clsx(classes.tableIcon)} onClick={onDelete}>
                            <DeleteOutline />
                        </IconButton>
                    </>
                )}
            </TableCell>
        </TableRow>
    );
}

ExternalReportRow.propTypes = {
    report: PropTypes.shape({
        name: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired
    }).isRequired,
    onDelete: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired
};
