import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useMutation } from '@apollo/client';
import { Button, DialogActions, DialogContent, IconButton, Typography } from '@material-ui/core';

import { REMOVE_USER } from 'graphql/mutations/user';

function RemoveUser({ user, setModalState, refetch }) {
    const { t } = useTranslation();

    const [removeUser] = useMutation(REMOVE_USER, {
        variables: { userId: user.id },
        onCompleted: () => {
            refetch();
        }
    });

    return (
        <form>
            <IconButton onClick={() => setModalState(false)}></IconButton>
            <>
                <DialogContent>
                    <Typography>
                        <h4 style={{ textAlign: 'center' }}>{t('Are you sure that you want to delete the user?')}</h4>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            removeUser();
                            setModalState(false);
                        }}
                    >
                        {t('Delete')}
                    </Button>
                    <Button onClick={() => setModalState(false)}>{t('Cancel')}</Button>
                </DialogActions>
            </>
        </form>
    );
}

RemoveUser.propTypes = {
    user: PropTypes.object,
    setModalState: PropTypes.func,
    refetch: PropTypes.func
};

export default RemoveUser;
