import PropTypes from 'prop-types';

import { unitModel } from './unit';

export const advertisementModel = {
    advertiserName: PropTypes.string,
    creatives: PropTypes.array,
    targeting: PropTypes.object,
    name: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    unit: PropTypes.shape(unitModel),
    unitId: PropTypes.string,
    budget: PropTypes.number
};

export const networkModel = {
    id: PropTypes.string,
    name: PropTypes.string
};

export const campaignTypeModel = {
    id: PropTypes.string,
    name: PropTypes.string
};

export const adTypeModel = {
    id: PropTypes.string,
    name: PropTypes.string,
    campaignTypes: PropTypes.arrayOf(PropTypes.shape(campaignTypeModel)),
    description: PropTypes.string,
    thumbnailUrl: PropTypes.string,
    networks: PropTypes.arrayOf(PropTypes.shape(networkModel)),
    biddingTypes: PropTypes.arrayOf(PropTypes.string),
    fields: PropTypes.array
};

export const adGroupModel = {
    id: PropTypes.string,
    name: PropTypes.string,
    adType: PropTypes.shape(adTypeModel),
    ads: PropTypes.arrayOf(PropTypes.shape(advertisementModel)),
    network: PropTypes.shape(networkModel)
};

export const campaignModel = {
    id: PropTypes.string,
    network: PropTypes.shape(networkModel),
    name: PropTypes.string,
    adGroups: PropTypes.arrayOf(PropTypes.shape(adGroupModel))
};

export const templateMediaDimensionModel = {
    aspectRatio: PropTypes.number || PropTypes.bool,
    height: PropTypes.number || PropTypes.bool,
    width: PropTypes.number || PropTypes.bool,
    name: PropTypes.string
};

export const adtypeTemplateModel = {
    id: PropTypes.string,
    unitId: PropTypes.string,
    height: PropTypes.number | PropTypes.bool,
    width: PropTypes.number | PropTypes.bool,
    name: PropTypes.string,
    selected: PropTypes.bool,
    mediaDimensions: PropTypes.arrayOf(PropTypes.shape(templateMediaDimensionModel))
};

export const creativeFieldModel = {
    name: PropTypes.string,
    maxLength: PropTypes.number,
    required: PropTypes.bool
};
