import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { groupBy } from 'lodash';

import {
    Button,
    Checkbox,
    Collapse,
    Dialog,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    makeStyles,
    Typography,
    useMediaQuery
} from '@material-ui/core';
import { ChevronRight, Close, ExpandLess } from '@material-ui/icons';
import { useTheme } from '@material-ui/styles';

import { SearchInput } from '../SearchInput/SearchInput';

const county = (unit) => unit?.location?.county;

const contains = (filterText) => (unit) =>
    unit?.name?.toLowerCase().includes(filterText) || unit?.location?.county?.toLowerCase().includes(filterText);

const useStyles = makeStyles((theme) => ({
    buttonPanel: {
        padding: theme.spacing(2)
    },
    container: {
        border: `1px solid ${theme.palette.primary[400]}`,
        [theme.breakpoints.up('sm')]: {
            maxWidth: '504px'
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    closeButton: {
        marginLeft: 'auto',
        padding: 0
    },
    dialog: {
        borderRadius: 0
    },
    innerList: {
        paddingLeft: theme.spacing(6)
    },
    listItem: {
        paddingTop: 0,
        paddingBottom: 0
    },
    outerList: {
        maxHeight: '360px',
        overflow: 'auto',
        scrollbarColor: `${theme.palette.text.disabled} ${theme.palette.background.default}`,
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
            width: '8px'
        },
        '&::-webkit-scrollbar-track': {
            background: theme.palette.background.default
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.text.disabled,
            borderRadius: '4px',
            border: `2px solid ${theme.palette.background.default}`,
            minHeight: theme.spacing(5)
        }
    },
    searchContainer: {
        backgroundColor: theme.palette.background.empty,
        padding: theme.spacing(2)
    },
    selectAll: {
        padding: theme.spacing(1)
    },
    header: {
        padding: theme.spacing(2)
    },
    bottomBordered: {
        borderBottom: `1px solid ${theme.palette.primary[400]}`
    }
}));

export const UnitListItem = ({ checked, className, expandable, expanded, indeterminate, onClick, onSelect, text }) => (
    <ListItem className={className} button={expandable} onClick={onClick}>
        {expandable && (expanded ? <ExpandLess /> : <ChevronRight />)}
        <Checkbox
            indeterminate={Boolean(indeterminate)}
            checked={checked}
            color='primary'
            onClick={(event) => {
                event.stopPropagation();

                onSelect(event.target.checked);
            }}
            inputProps={{ 'aria-label': 'select all advertisers' }}
        />
        <ListItemText primary={text} />
    </ListItem>
);

UnitListItem.propTypes = {
    checked: PropTypes.bool,
    className: PropTypes.string,
    expandable: PropTypes.bool,
    expanded: PropTypes.bool,
    indeterminate: PropTypes.bool,
    onClick: PropTypes.func,
    onSelect: PropTypes.func,
    text: PropTypes.string
};

export const AddUnitModal = ({ open, onClose, availableUnits, onSave }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const { t } = useTranslation();
    const [filterText, setFilterText] = useState('');
    const {
        bottomBordered,
        buttonPanel,
        closeButton,
        container,
        dialog,
        header,
        innerList,
        listItem,
        outerList,
        searchContainer,
        selectAll
    } = useStyles();
    const [openGroups, setOpenGroups] = useState({});
    const [selectedUnits, setSelectedUnits] = useState(new Set());
    const shownUnits = availableUnits.filter(contains(filterText));
    const visibleChecked = shownUnits.reduce((memo, { id }) => memo && selectedUnits.has(id), true);

    return (
        <Dialog PaperProps={{ className: dialog }} fullScreen={fullScreen} open={open} onClose={onClose}>
            <Grid container className={container}>
                <Grid item container alignItems='center' className={`${header} ${bottomBordered}`} xs={12}>
                    <Typography variant='h6'>{t('Select advertisers')}</Typography>
                    <IconButton color='primary' onClick={onClose} className={closeButton}>
                        <Close />
                    </IconButton>
                </Grid>
                <Grid item className={`${searchContainer} ${bottomBordered}`} xs={12}>
                    <SearchInput
                        onChange={(event) => setFilterText(event.target.value.toLowerCase())}
                        placeholder={t('Advertiser')}
                    />
                </Grid>
                <Grid item className={`${selectAll} ${bottomBordered}`} xs={12}>
                    <Checkbox
                        indeterminate={!visibleChecked && Boolean(shownUnits.find(({ id }) => selectedUnits.has(id)))}
                        checked={visibleChecked}
                        color='primary'
                        onClick={(event) =>
                            setSelectedUnits(
                                event.target.checked
                                    ? new Set([...selectedUnits, ...shownUnits.map(({ id }) => id)])
                                    : new Set(
                                          [...selectedUnits].filter(
                                              (unitId) => !shownUnits.find(({ id }) => unitId === id)
                                          )
                                      )
                            )
                        }
                        inputProps={{ 'aria-label': 'select all advertisers' }}
                    />
                    <Typography variant='body2' component='span'>
                        {t('Select all')}
                    </Typography>
                </Grid>
                <Grid item className={bottomBordered} xs={12}>
                    <List className={outerList}>
                        {Object.entries(groupBy(shownUnits, county)).map(([group, entries]) => {
                            const entryIds = new Set(entries.map(({ id }) => id));
                            const groupChecked = entries.reduce((memo, { id }) => memo && selectedUnits.has(id), true);

                            return (
                                <React.Fragment key={group}>
                                    <UnitListItem
                                        className={listItem}
                                        indeterminate={
                                            !groupChecked && Boolean(entries.find(({ id }) => selectedUnits.has(id)))
                                        }
                                        checked={groupChecked}
                                        expandable
                                        expanded={Boolean(openGroups[group] || filterText)}
                                        onClick={() => setOpenGroups({ ...openGroups, [group]: !openGroups[group] })}
                                        onSelect={(checked) =>
                                            setSelectedUnits(
                                                checked
                                                    ? new Set([...selectedUnits, ...entryIds])
                                                    : new Set(
                                                          [...selectedUnits].filter((unitId) => !entryIds.has(unitId))
                                                      )
                                            )
                                        }
                                        text={group}
                                    />
                                    <Collapse
                                        in={Boolean(openGroups[group] || filterText)}
                                        timeout='auto'
                                        unmountOnExit
                                    >
                                        <List className={innerList} disablePadding>
                                            {entries.map(({ id, name }) => (
                                                <UnitListItem
                                                    className={listItem}
                                                    key={id}
                                                    checked={selectedUnits.has(id)}
                                                    onSelect={(checked) =>
                                                        setSelectedUnits(
                                                            checked
                                                                ? new Set([...selectedUnits, id])
                                                                : new Set(
                                                                      [...selectedUnits].filter(
                                                                          (unitId) => unitId !== id
                                                                      )
                                                                  )
                                                        )
                                                    }
                                                    text={name}
                                                />
                                            ))}
                                        </List>
                                    </Collapse>
                                </React.Fragment>
                            );
                        })}
                    </List>
                </Grid>
                <Grid item className={buttonPanel} xs={12}>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={() => onSave(availableUnits.filter(({ id }) => selectedUnits.has(id)))}
                    >
                        {t('Add ({{selected}}) units', { selected: [...selectedUnits].length })}
                    </Button>
                </Grid>
            </Grid>
        </Dialog>
    );
};

AddUnitModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onSave: PropTypes.func,
    availableUnits: PropTypes.arrayOf(PropTypes.object)
};
