import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { DialogTitleClose } from 'common/components';
import { BlueHeaderContainer } from 'common/components/BlueHeaderContainer/BlueHeaderContainer';
import { Context as activeUnitContext } from 'context/units/activeUnitContext';

import SettingsTabs from './SettingsTabs';
import { UnitContext, UnitProvider } from './unit.provider';

export const UnitSettings = () => {
    const { t } = useTranslation();
    const {
        state: { activeUnit }
    } = useContext(activeUnitContext);

    const [modalState, setModalState] = useState(false);

    return (
        <UnitProvider>
            <UnitContext.Consumer>
                {({ isLoading, editingUnit, createNewUnit }) => (
                    <>
                        <BlueHeaderContainer
                            title={t('Settings')}
                            titleLineAction={
                                editingUnit?.id && (
                                    <>
                                        <Button
                                            disabled={!editingUnit?.name?.length}
                                            size='small'
                                            startIcon={<Add />}
                                            onClick={() => {
                                                setModalState(!modalState);
                                            }}
                                        >
                                            {t('Create new customer')}
                                        </Button>
                                        <Dialog
                                            open={modalState}
                                            onClose={() => setModalState(false)}
                                            aria-describedby='scroll-dialog-description'
                                            aria-labelledby='scroll-dialog-title'
                                        >
                                            <DialogTitle>{t('Create new customer')}</DialogTitle>
                                            <DialogTitleClose
                                                onClose={() => setModalState(false)}
                                                id='scroll-dialog-title'
                                            />
                                            <DialogContent id='scroll-dialog-description' dividers={true}>
                                                <DialogContentText>
                                                    {t(
                                                        'A new customer unit will be created under {{name}}. You will be navigated away from the current form. Continue?',
                                                        activeUnit
                                                    )}
                                                </DialogContentText>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={() => setModalState(false)}>{t('Cancel')}</Button>
                                                <Button
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        createNewUnit();
                                                        setModalState(false);
                                                    }}
                                                >
                                                    {t('Continue')}
                                                </Button>
                                            </DialogActions>
                                        </Dialog>
                                    </>
                                )
                            }
                        >
                            {isLoading && <CircularProgress />}
                            {!isLoading && <SettingsTabs />}
                        </BlueHeaderContainer>
                    </>
                )}
            </UnitContext.Consumer>
        </UnitProvider>
    );
};

export default UnitSettings;
