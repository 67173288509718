/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';

import { useUpdatePowerBIReportVersionEmbedConfigForUnit } from 'hooks/unit';

const PowerBIReportAdditionalMenu = ({ reportRef, unitId, reportId, versionNumber, hasSavedSlicers }) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);

    const { mutateAsync: updatePowerBIReportVersionEmbedConfigForUnit } =
        useUpdatePowerBIReportVersionEmbedConfigForUnit();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                size='small'
                aria-label='more'
                aria-controls='simple-menu'
                aria-haspopup='true'
                onClick={handleClick}
            >
                <MoreVert />
            </IconButton>
            <Menu id='simple-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem
                    onClick={async () => {
                        const pages = await reportRef.getPages();

                        const mergedVisuals = await Promise.all(pages.map((p) => p.getVisuals()));
                        const flattenedVisuals = mergedVisuals.flat();

                        const slicerVisuals = flattenedVisuals.filter((v) => v.type === 'slicer');

                        const slicerData = await Promise.all(
                            slicerVisuals.map((v) => v.getSlicerState().catch(() => {}))
                        );

                        const nonEmptySlicerData = slicerData.filter((slicer) => {
                            if (!slicer) return false;

                            return slicer.filters.length > 0;
                        });

                        const embedSlicers = nonEmptySlicerData.map((slicer) => {
                            return {
                                selector: {
                                    $schema: 'http://powerbi.com/product/schema#slicerTargetSelector',
                                    target: slicer.filters[0].target
                                },
                                state: { filters: slicer.filters }
                            };
                        });

                        const reportVersionConfigPayload = {
                            reportVersionConfig: {
                                slicers: embedSlicers
                            }
                        };

                        await updatePowerBIReportVersionEmbedConfigForUnit({
                            unitId,
                            reportId,
                            versionNumber,
                            reportVersionConfig: reportVersionConfigPayload
                        });

                        handleClose();
                    }}
                >
                    {t('Save selection')}
                </MenuItem>
                {hasSavedSlicers && (
                    <MenuItem
                        onClick={async () => {
                            await updatePowerBIReportVersionEmbedConfigForUnit({
                                unitId,
                                reportId,
                                versionNumber,
                                reportVersionConfig: { reportVersionConfig: {} }
                            });

                            handleClose();
                            window.location.reload();
                        }}
                    >
                        {t('Reset selection')}
                    </MenuItem>
                )}
            </Menu>
        </>
    );
};

export default PowerBIReportAdditionalMenu;
