/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, makeStyles, Dialog, DialogContent, Button, styled, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { ArrowDropDown, ExpandMore, ChevronRight } from '@material-ui/icons';
import { TreeView, TreeItem } from '@material-ui/lab';

import {
    buildUnitTree,
    buildFilteredUnitTree,
    findActiveUnitInTree,
    findAllNodesIdsWithChild,
    traverseChildUnitIds
} from 'helpers/unit';

import { Context as activeUnitContext } from 'context/units/activeUnitContext';
import { SearchInput } from 'common/components/SearchInput/SearchInput';

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
}));

const TreeItemContainer = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',

    '& p': {
        flexGrow: 1
    },
    '&.Mui-selected': {
        backgroundColor: 'transparent'
    }
}));

const StyledTreeItem = styled(TreeItem)(({ theme }) => ({
    color: theme.palette.text.secondary,
    fontSize: theme.typography.h4
}));

const StyledButton = styled(Button)(({ theme }) => ({
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    width: '100%',
    height: '100%',
    backgroundColor: grey[50]
}));

const useStyles = makeStyles((theme) => ({
    selector: {
        backgroundColor: grey[50],
        width: '100%',
        height: 56,
        '&.MuiFilledInput-underline:before': {
            borderBottomColor: grey[50]
        }
    },
    label: {
        color: theme.palette.text.hint
    },
    listItem: {
        textTransform: 'capitalize'
    },
    openTagsOverride: {
        '& .MuiAutocomplete-tag': {
            display: 'none'
        }
    },
    tagsOvveride: {
        position: 'absolute',
        bottom: '4px'
    },
    groupHeader: {
        textTransform: 'uppercase',
        letterSpacing: '1.5px',
        fontWeight: 500,
        fontSize: 10,
        lineHeight: '16px',
        color: theme.palette.text.secondary,
        cursor: 'pointer',
        top: -10,
        backgroundColor: theme.palette.white,
        zIndex: 10
    },
    listCheckbox: {
        marginLeft: theme.spacing(1)
    },
    unitSelectorLabel: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    search: {
        flexShrink: 0
    }
}));

const TreeItemContent = ({ unit, onSelectUnit, /* onSelectGroup,*/ selectedUnits }) => {
    return (
        <TreeItemContainer>
            <Typography variant='body2'>{unit.name}</Typography>
            {/* {
                !!unit.treeChildren &&
                <StyledCheckbox
                    size='small'
                    color='primary'
                    onClick={(e) => e.stopPropagation()}
                    onChange={(_, check) => {
                        onSelectGroup(unit, check);
                    }}
                    checked={(selectedUnits.find((id) => unit.id === id) && true) || false}
                    inputProps={{ 'aria-label': `Checkbox ${unit.name}` }}
                />
            } */}
            <StyledCheckbox
                size='small'
                color='primary'
                onClick={(e) => e.stopPropagation()}
                onChange={(_, check) => {
                    onSelectUnit(unit, check);
                }}
                checked={(selectedUnits.find((id) => unit.id === id) && true) || false}
                inputProps={{ 'aria-label': `Checkbox ${unit.name}` }}
            />
        </TreeItemContainer>
    );
};

const RecursiveTreeItem = ({ unitTree, onSelectUnit, selectedUnits }) => {
    return (
        <React.Fragment>
            {unitTree.map((unit) => {
                return !!unit ? (
                    <StyledTreeItem
                        tabIndex='0'
                        key={unit.id}
                        nodeId={unit.id}
                        label={
                            <TreeItemContent
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                                unit={unit}
                                onSelectUnit={onSelectUnit}
                                selectedUnits={selectedUnits}
                            />
                        }
                    >
                        {unit.treeChildren && (
                            <RecursiveTreeItem
                                unitTree={unit.treeChildren}
                                onSelectUnit={onSelectUnit}
                                selectedUnits={selectedUnits}
                                key={`${unit.id}-children-tree`}
                            />
                        )}
                    </StyledTreeItem>
                ) : (
                    <Typography variant='body1'>No advertisers</Typography>
                );
            })}
        </React.Fragment>
    );
};

export const AdvertiserSelector = ({ updFunc }) => {
    const [selected, setSelected] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const [options, setOptions] = useState([]);
    const [activeUnitTree, setActiveUnitTree] = useState({});
    const [filteredActiveUnitTree, setFilteredAcctiveUnitTree] = useState();

    const [isOpen, setIsOpen] = useState(false);
    const [filterText, setFilterText] = useState('');

    const classes = useStyles();
    const { t } = useTranslation();

    const {
        state: { units, activeUnit }
    } = useContext(activeUnitContext);

    useEffect(() => {
        const unitTree = buildUnitTree(units);
        const activeUnitTree = findActiveUnitInTree(activeUnit, unitTree);
        const ids = traverseChildUnitIds(activeUnitTree, units);
        setSelected(ids);
        setOptions(ids);
        setActiveUnitTree(activeUnitTree);
    }, [activeUnit, units]);

    useEffect(() => {
        const filteredTree = buildFilteredUnitTree(filterText, activeUnitTree);
        setFilteredAcctiveUnitTree(filteredTree);
        if (filterText && filteredTree) {
            const expanded = findAllNodesIdsWithChild(filteredTree);
            setExpanded(expanded);
        }
        if (!filterText) {
            setExpanded([]);
        }
    }, [filterText, activeUnitTree]);

    // useEffect(() => {
    //     updFunc(selected);
    // }, [selected]);
    useEffect(() => {
        if (!isOpen) {
            updFunc(selected);
        }
    }, [isOpen]);

    const handleAdvertisersSelection = (unit, check) => {
        const ids = traverseChildUnitIds(unit, units);
        if (check) {
            const idDiff = ids.filter((id) => !selected.includes(id));
            setSelected([...selected, ...idDiff]);
        } else {
            setSelected(selected.filter((id) => !ids.includes(id)));
        }
    };

    const handleToggle = (_, nodeIds) => {
        setExpanded(nodeIds);
    };

    return (
        <React.Fragment>
            <StyledButton
                color='inherit'
                onClick={() => setIsOpen(!isOpen)}
                endIcon={<ArrowDropDown style={{ height: '24px', width: '24px' }} />}
            >
                <span className={classes.unitSelectorLabel}>
                    {`${t('Advertisers')}: ${selected.length} ${t('Of').toLowerCase()} ${options.length}`}
                </span>
            </StyledButton>
            <Dialog
                open={isOpen}
                onClose={() => setIsOpen(false)}
                fullWidth
                scroll='paper'
                aria-describedby='scroll-dialog-description'
                aria-labelledby='scroll-dialog-title'
            >
                <DialogContent dividers={true} className={classes.search}>
                    <SearchInput
                        onChange={(event) => setFilterText(event.target.value.toLowerCase())}
                        placeholder={t('Advertiser')}
                    />
                </DialogContent>
                <DialogContent id='scroll-dialog-description' dividers={true}>
                    <TreeView
                        defaultCollapseIcon={<ExpandMore />}
                        defaultExpandIcon={<ChevronRight />}
                        selected={[]}
                        expanded={expanded}
                        onNodeToggle={handleToggle}
                    >
                        <RecursiveTreeItem
                            unitTree={[filteredActiveUnitTree]}
                            onSelectUnit={handleAdvertisersSelection}
                            selectedUnits={selected}
                        />
                    </TreeView>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
};
