import React from 'react';
import PropTypes from 'prop-types';

import { Badge, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import { Error } from '@material-ui/icons';

import { a11yProps } from 'helpers/a11yProps';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    labelIcon: {
        heigth: 48,
        minHeight: 48,
        maxHeight: 48,
        padding: '16px 12px 12px 16px',
        color: theme.palette.grey[900],
        '&.Mui-selected': {
            transition: 'background-color 500ms linear',
            backgroundColor: (props) =>
                props.color === 'secondary' ? theme.palette.secondary[50] : theme.palette.primary[50],
            color: (props) => (props.color === 'secondary' ? theme.palette.secondary.main : theme.palette.primary.main)
        }
    },
    labelIconBottomBorder: {
        border: `1px solid ${theme.palette.divider}`,
        borderLeft: 0,
        borderRight: 0,
        borderTop: 0,
        '&:first-child': {
            borderTop: 0
        },
        '&:last-child': {
            borderBottom: 0
        }
    },
    tabLabel: {
        textTransform: 'none',
        fontWeight: theme.typography.fontWeightBold,
        paddingBottom: theme.typography.pxToRem(5),
        letterSpazing: '0.1px',
        color: theme.palette.grey[900],
        fontSize: '14px',
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3)
    },
    badgeIcon: {
        paddingBottom: theme.typography.pxToRem(5),
        marginRight: theme.spacing(1)
    },
    customBadge: {
        backgroundColor: (props) =>
            props.color === 'secondary' ? theme.palette.secondary.dark : theme.palette.primary.dark,
        color: theme.palette.white
    },
    defaultBadge: {
        backgroundColor: theme.palette.grey[400],
        color: theme.palette.grey[800]
    }
}));

export const StyledTab = ({
    index,
    showIcon = true,
    centerLabel = false,
    tabLabel,
    tabLabelStyle,
    icon,
    badgeNum,
    color = 'secondary',
    enableBottomBorder = false,
    ...props
}) => {
    const classes = useStyles({ color });

    const StyleBadge = () => {
        return (
            <Badge
                classes={{ badge: badgeNum > 0 ? classes.customBadge : classes.defaultBadge }}
                className={classes.badgeIcon}
                invisible={badgeNum === undefined || null}
                badgeContent={badgeNum}
                max={999}
                showZero={true}
            />
        );
    };

    return (
        <Tab
            classes={{
                wrapper: classes.wrapper,
                labelIcon: enableBottomBorder
                    ? `${classes.labelIcon} ${classes.labelIconBottomBorder}`
                    : classes.labelIcon
            }}
            icon={showIcon ? icon ? icon : <Error /> : <span />}
            label={
                <Grid justify='space-between' container>
                    {centerLabel && <Grid item></Grid>}
                    <Grid item>
                        <Typography
                            className={tabLabelStyle ? `${classes.tabLabel} ${tabLabelStyle}` : classes.tabLabel}
                        >
                            {tabLabel}
                        </Typography>
                    </Grid>

                    <Grid item>
                        <StyleBadge />
                    </Grid>
                </Grid>
            }
            {...a11yProps(index)}
            {...props}
        />
    );
};

StyledTab.propTypes = {
    index: PropTypes.number,
    showIcon: PropTypes.bool,
    centerLabel: PropTypes.bool,
    tabLabel: PropTypes.string,
    tabLabelStyle: PropTypes.string,
    icon: PropTypes.node,
    color: PropTypes.string,
    badgeNum: PropTypes.number,
    enableBottomBorder: PropTypes.bool
};
