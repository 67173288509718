import React from 'react';
import PropTypes from 'prop-types';

import { Accordion, AccordionDetails, AccordionSummary, makeStyles, Typography } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    panelDetailsRoot: {
        padding: (props) => (props.disableContainerPadding ? 0 : `${theme.spacing(2)}px ${theme.spacing(3)}px`)
    },
    panelRoot: {
        width: '100%',
        marginBottom: theme.spacing(2),
        border: (props) => (props.inverted ? null : `1px solid ${theme.palette.divider}`),
        '&.Mui-expanded': {
            margin: 0
        }
    },
    panelSummaryRoot: {
        backgroundColor: theme.palette.primary.light,
        '&.Mui-expanded': {
            minHeight: 48,
            maxHeight: 48
        }
    },
    panelSummaryContent: {
        margin: '0',
        '&.Mui-expanded': {
            margin: '0'
        }
    },
    panelSummaryExpandIcon: {
        padding: 0,
        margin: `${(0 - theme.spacing(1)) / 2}px`
    }
}));

export const BlueExpansionPanel = ({
    title,
    children,
    disableContainerPadding = false,
    inverted = false,
    allCapsText = true,
    ...props
}) => {
    const { panelDetailsRoot, panelRoot, panelSummaryRoot, panelSummaryContent, panelSummaryExpandIcon } = useStyles({
        disableContainerPadding,
        inverted
    });

    const color = inverted ? 'white' : 'primary';

    return (
        <Accordion square={true} elevation={0} className={panelRoot} {...props}>
            <AccordionSummary
                expandIcon={<ArrowDropDown style={{ color }} />}
                aria-controls='panel-content'
                id='panel-header'
                classes={{ root: panelSummaryRoot, content: panelSummaryContent, expandIcon: panelSummaryExpandIcon }}
            >
                <Typography color={color} variant={allCapsText ? 'button' : 'body1'}>
                    {title}
                </Typography>
            </AccordionSummary>
            <AccordionDetails classes={{ root: panelDetailsRoot }}>{children}</AccordionDetails>
        </Accordion>
    );
};

BlueExpansionPanel.propTypes = {
    title: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    disableContainerPadding: PropTypes.bool,
    inverted: PropTypes.bool,
    allCapsText: PropTypes.bool
};
