import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Collapse, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import NavMenuItem from './NavMenuItem';

const useStyles = makeStyles((theme) => ({
    notYetActivePage: {
        color: theme.palette.primary.main,
        textTransform: 'uppercase',
        paddingTop: 0,
        paddingBottom: 0,

        '& .MuiTypography-body2': {
            color: theme.palette.primary.main,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: 14
        }
    },
    icon: {
        color: theme.palette.primary.main,
        width: 24,
        height: 24,
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(1)
    },
    active: {
        color: theme.palette.sidebar.active
    },
    listItem: {
        marginTop: '20px'
    }
}));

function NavItem({ page, isOpenSidebar, handleSidebarOpen, currentPath }) {
    const classes = useStyles({ open: isOpenSidebar });
    const [open, setOpen] = useState(false);
    const [active, setActive] = useState(false);

    useEffect(() => {
        const currentlyActive =
            page.href === currentPath ||
            page.directories?.some(
                (dir) =>
                    dir?.href === currentPath || dir?.subDirectories?.some((subDir) => subDir?.href === currentPath)
            );
        setActive(currentlyActive);
    }, [currentPath, page]);

    useEffect(() => {
        setOpen(isOpenSidebar);
    }, [isOpenSidebar]);

    const { directories, title } = page;

    if (!directories) return null;

    return (
        <>
            <ListItem
                button
                className={classes.listItem}
                onClick={() => {
                    if (!isOpenSidebar) handleSidebarOpen();
                    setOpen((prev) => !prev);
                }}
            >
                <div className={clsx(classes.icon, active && classes.active)}>{page.icon}</div>

                <ListItemText
                    className={clsx(classes.notYetActivePage, active && classes.active)}
                    primary={isOpenSidebar ? title : ''}
                />
            </ListItem>
            {isOpenSidebar && (
                <Collapse in={open} timeout='auto' unmountOnExit>
                    <List component='div' dense disablePadding>
                        {directories.map(({ title, href, subDirectories, disabled, onClick }, index) => (
                            <NavMenuItem
                                key={title + href + index}
                                classes={classes}
                                title={title}
                                href={href ? href : onClick || undefined}
                                subDirectories={subDirectories}
                                currentPath={currentPath}
                                disabled={disabled}
                                isOpenSidebar={isOpenSidebar}
                            />
                        ))}
                    </List>
                </Collapse>
            )}
        </>
    );
}

NavItem.propTypes = {
    page: PropTypes.any,
    currentPath: PropTypes.string,
    handleSidebarOpen: PropTypes.func,
    isOpenSidebar: PropTypes.bool
};

export default NavItem;
