/* eslint-disable react/display-name */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { MTableToolbar } from 'material-table';

import { IconButton, LinearProgress, makeStyles, useTheme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import {
    Code,
    Facebook,
    FeaturedVideoOutlined,
    FilterNoneOutlined,
    FolderOutlined,
    StoreOutlined,
    VisibilityOutlined
} from '@material-ui/icons';

import Google from 'assets/icons/Google';
import { Table } from 'common/components';
import { dateFnsLocales } from 'helpers/locales';
import { formatIntWithSpaces } from 'helpers/number';
import { materialUnexposed } from 'layouts/theme/customColor';

import { AdPreviewDialog } from '../AdPreviewDialog/AdPreviewDialog';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
        },
        '& [class^="MTableToolbar-spacer-"]': {
            display: 'none'
        }
    },
    previewButton: {
        backgroundColor: theme.palette.primary[200],
        borderRadius: 4,
        width: 36,
        height: 36,
        padding: 8,

        '& .MuiSvgIcon-root': {
            fill: grey[100],
            fontSize: 20
        },

        '&:hover': {
            backgroundColor: materialUnexposed.backgroundHover,
            fill: theme.palette.primary[200],

            '& .MuiSvgIcon-root': {
                fill: theme.palette.primary[200]
            }
        }
    },
    titleIcon: {
        borderRadius: '50%',
        backgroundColor: grey[100],
        padding: theme.spacing(1) + 'px',
        marginRight: theme.spacing(2) + 'px',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',

        '& .MuiSvgIcon-root': {
            fill: grey[600]
        }
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center'
    }
}));

export const CampaignList = ({
    campaignsData,
    setSelected,
    selected,
    defaultCheckAll,
    setDefaultCheckAll,
    debug = false
}) => {
    const theme = useTheme();
    const { root, previewButton, titleIcon, titleContainer } = useStyles();
    const { t, i18n } = useTranslation();
    const toolbarRef = useRef(null);
    const tableRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [selectedAd, setSelectedAd] = useState();

    const [toolbarWidth, setToolbarWidth] = useState(0);

    useEffect(() => {
        if (toolbarRef.current) {
            setToolbarWidth(toolbarRef.current.offsetWidth - 48);
        }
    }, [toolbarRef]);

    useEffect(() => {
        if (tableRef.current && defaultCheckAll) {
            tableRef.current.onAllSelected(true);
            setDefaultCheckAll(false);
        }
    }, [tableRef, defaultCheckAll, setDefaultCheckAll]);

    // TODO: Add media types when those are supported
    const tableColumns = [
        {
            title: t('Title'),
            field: 'name',
            width: '20%',
            render: (rowData) => (
                <div className={titleContainer}>
                    <span className={titleIcon}>
                        {rowData.type === 'campaigngroup' ? (
                            <FolderOutlined />
                        ) : rowData.type === 'campaign' ? (
                            <FilterNoneOutlined />
                        ) : rowData.type === 'advertiser' ? (
                            <StoreOutlined />
                        ) : (
                            <FeaturedVideoOutlined />
                        )}
                    </span>
                    <span>{rowData.name}</span>
                </div>
            )
        },
        {
            title: t('Clicks'),
            field: 'stats.clicks',
            width: '8%',
            render: (rowData) => formatIntWithSpaces(rowData.stats.clicks || 0)
        },
        {
            title: t('Views'),
            field: 'stats.impressions',
            width: '8%',
            render: (rowData) => formatIntWithSpaces(rowData.stats.impressions || 0)
        },
        {
            title: t('Spent') + ' / ' + t('Budget'),
            field: 'stats.spending',
            width: '12%',
            cellStyle: { fontSize: 12 },
            render: (rowData) => (
                <React.Fragment>
                    {`${formatIntWithSpaces(rowData.stats.spending || 0)} / ${formatIntWithSpaces(
                        rowData.stats.budget || 0
                    )}`}
                    <LinearProgress
                        variant='determinate'
                        value={
                            (rowData.stats.budget &&
                                Math.round((rowData.stats.spending / rowData.stats.budget) * 100)) ||
                            0
                        }
                        style={{ marginTop: 8 }}
                    />
                </React.Fragment>
            )
        },
        {
            title: t('Media'),
            field: '',
            width: '8%'
        },
        {
            title: t('Start'),
            field: 'dateInterval.startDate',
            width: '7%',
            render: (rowData) => {
                return (
                    rowData.dateInterval.startDate &&
                    format(new Date(rowData.dateInterval.startDate), 'dd. MMM', {
                        locale: dateFnsLocales[i18n.language]
                    })
                );
            }
        },
        {
            title: t('End'),
            field: 'dateInterval.endDate',
            width: '7%',
            render: (rowData) => {
                return (
                    rowData.dateInterval.endDate &&
                    format(new Date(rowData.dateInterval.endDate), 'dd. MMM', {
                        locale: dateFnsLocales[i18n.language]
                    })
                );
            }
        },
        {
            title: t('Network'),
            field: 'network',
            width: '8%',
            render: (rowData) => (
                <React.Fragment>
                    {rowData.network.map(
                        (network, index) =>
                            (network.name === 'facebook' && <Facebook key={index} />) ||
                            (network.name === 'google' && <Google key={index} />) ||
                            (network.name === 'hosted' && <Code key={index} />)
                    )}
                </React.Fragment>
            )
        },
        {
            title: '',
            field: '',
            width: '6%',
            sorting: false,
            render: (rowData) => (
                <React.Fragment>
                    {rowData.type === 'ad' && (
                        <IconButton className={previewButton} onClick={openAdPreview(rowData)}>
                            <VisibilityOutlined />
                        </IconButton>
                    )}
                </React.Fragment>
            )
        }
    ];

    const Toolbar = (props) => (
        <div ref={toolbarRef} style={{ paddingTop: '14px', paddingBottom: '14px' }}>
            <MTableToolbar {...props} />
        </div>
    );

    const findParent = (row, rows) => rows.find((e) => e.realId === row.parentId);

    const checkItem = (rows) => {
        const ids = [];
        rows.map((row) => ids.indexOf(row.realId) < 0 && ids.push(row.realId));
        setSelected(ids);
    };

    const openAdPreview = (ad) => () => {
        setSelectedAd(ad);
        setOpen(true);
    };

    return (
        <React.Fragment>
            <Table
                tableRef={tableRef}
                className={root}
                data={campaignsData}
                columns={tableColumns}
                parentChildData={findParent}
                components={{ Toolbar }}
                onSelectionChange={checkItem}
                options={{
                    selection: true,
                    selectionProps: (row) => ({ color: 'primary', checked: selected.includes(row.realId) }),
                    showTitle: false,
                    showTextRowsSelected: false,
                    searchFieldAlignment: 'left',
                    searchFieldVariant: 'outlined',
                    pageSize: 20,
                    actionsColumnIndex: -1,
                    searchFieldStyle: { minWidth: toolbarWidth, backgroundColor: grey[50] },
                    headerStyle: {
                        color: theme.palette.text.secondary,
                        backgroundColor: grey[50],
                        letterSpacing: '0.25px'
                    },
                    cellStyle: {
                        letterSpacing: '0.25px'
                    }
                }}
            />
            {!debug && selectedAd && <AdPreviewDialog open={open} setOpen={setOpen} ad={selectedAd} />}
        </React.Fragment>
    );
};

CampaignList.propTypes = {
    campaignsData: PropTypes.array,
    selected: PropTypes.array,
    setSelected: PropTypes.func,
    defaultCheckAll: PropTypes.bool,
    setDefaultCheckAll: PropTypes.func,
    debug: PropTypes.bool
};
