import React from 'react';

import {
    Add,
    AddCircle,
    CheckBoxOutlineBlank,
    Delete,
    DeleteOutlined,
    EditOutlined,
    FilterList as FilterListIcon,
    PlaylistAdd,
    ShoppingCart,
    TextFormat
} from '@material-ui/icons';

const CustomFilterButton = (isFreeAction, toggleDrawer, t) => {
    const CustomFilterBtn = () => <FilterListIcon />;

    return {
        icon: CustomFilterBtn,
        tooltip: t('Filter'),
        onClick: toggleDrawer('right', true),
        isFreeAction: isFreeAction
    };
};

const DeselectAllButton = (isFreeAction, selectedRows, handleDeselectAll, t) => {
    const DeselectAllBtn = () => <CheckBoxOutlineBlank />;

    return {
        icon: DeselectAllBtn,
        disabled: selectedRows.length === 0,
        tooltip: t('Deselect all products'),
        onClick: () => handleDeselectAll(),
        isFreeAction: isFreeAction
    };
};

const AddProductsButton = (isFreeAction, enableSingleProductSelectionMode, productsInTable, handleAddProducts, t) => {
    const AddProductsBtn = () => <PlaylistAdd />;

    return {
        icon: AddProductsBtn,
        tooltip: t('Added {{count}} product', {
            count: enableSingleProductSelectionMode ? undefined : null
        }),
        disabled: enableSingleProductSelectionMode && productsInTable > 0,
        onClick: () => handleAddProducts(),
        isFreeAction: isFreeAction
    };
};

const AddNewProductButton = (
    isFreeAction,
    enableSingleProductSelectionMode,
    productsInTable,
    handleAddNewProductClicked,
    t
) => {
    const AddNewProductBtn = () => <AddCircle />;

    return {
        icon: AddNewProductBtn,
        tooltip: t('Added {{count}} product'),
        disabled: enableSingleProductSelectionMode && productsInTable > 1,
        onClick: () => handleAddNewProductClicked(),
        isFreeAction: isFreeAction
    };
};
const EditProductButton = (isFreeAction, enableSelection, selectedRows, handleEditProductClicked, t) => {
    const EditProductBtn = () => <EditOutlined />;

    return {
        icon: EditProductBtn,
        tooltip: t('Edit product'),
        disabled: enableSelection && selectedRows > 1,
        onClick: (event, rowdata) => handleEditProductClicked(rowdata),
        isFreeAction: isFreeAction
    };
};
const DeleteProductButton = (isFreeAction, handleDeleteProductClicked, t) => {
    const DeleteProductBtn = () => <DeleteOutlined />;

    return {
        icon: DeleteProductBtn,
        tooltip: t('Delete product'),
        onClick: (event, rowdata) => handleDeleteProductClicked(rowdata),
        isFreeAction: isFreeAction
    };
};
const DeleteProductsButton = (isFreeAction, enableSelection, selectedRows, handleDeleteProductsClicked, t) => {
    const DeleteProductsBtn = () => <DeleteOutlined />;

    return {
        icon: DeleteProductsBtn,
        tooltip: t('Delete {{count}} products', {
            count: enableSelection ? (selectedRows.length > 0 ? selectedRows.length : null) : undefined
        }),
        onClick: (event, rowdata) => handleDeleteProductsClicked(rowdata),
        isFreeAction: isFreeAction
    };
};

const RemoveProductsButton = (isFreeAction, enableSelection, selectedRows, handleRemoveSelectedProductsClicked, t) => {
    const RemoveProductsBtn = () => <Delete />;

    return {
        icon: RemoveProductsBtn,
        tooltip: t('Remove {{count}} product', {
            count: enableSelection ? (selectedRows.length > 0 ? selectedRows.length : null) : undefined
        }),
        disabled: enableSelection && selectedRows.length === 0,
        onClick: (event, rowdata) => {
            enableSelection ? handleRemoveSelectedProductsClicked() : handleRemoveSelectedProductsClicked(rowdata.id);
        },
        isFreeAction: isFreeAction
    };
};

const EditColumnLabelsButton = (isFreeAction, toggleEditColumnLabels, t) => {
    const EditColumnLabelsBtn = () => <TextFormat />;

    return {
        icon: EditColumnLabelsBtn,
        tooltip: t('Edit headers'),
        onClick: toggleEditColumnLabels,
        isFreeAction: isFreeAction
    };
};

const ReturnSelectedProductIdsButton = (
    isFreeAction,
    enableSelection,
    selectedRows,
    handleReturnSelectionClicked,
    t
) => {
    const ReturnSelectedProductIdsBtn = () => <Add />;

    return {
        icon: ReturnSelectedProductIdsBtn,
        disabled: enableSelection && selectedRows.length === 0,
        tooltip: t('Added {{count}} product', {
            count: enableSelection ? (selectedRows.length > 0 ? selectedRows.length : null) : undefined
        }),
        onClick: (event, rowdata) => {
            enableSelection ? handleReturnSelectionClicked() : handleReturnSelectionClicked(rowdata.id);
        },
        isFreeAction: isFreeAction
    };
};

const ToggleProductTypePopoverButton = (
    isFreeAction,
    toggleProductTypeSelectionPopover,
    setProductTypeSelectionAnchorEl,
    t
) => {
    const ToggleProductTypePopoverBtn = () => <ShoppingCart />;

    return {
        icon: ToggleProductTypePopoverBtn,
        tooltip: t('Change selected product type'),
        onClick: (event) => {
            setProductTypeSelectionAnchorEl(event.currentTarget);
            toggleProductTypeSelectionPopover();
        },
        isFreeAction: isFreeAction
    };
};

export const getDefaultActionButtons = ({
    states: { actionButtons, productsInTable, selectedRows },
    options: {
        enableCustomFilter,
        enableAddNewProduct,
        enableEditProduct,
        enableDeleteProduct,
        enableDeselectAll,
        enableEditColumnLabels,
        enableSelection,
        enableSingleProductSelectionMode,
        enableProductTypeSelection,
        setProductTypeSelectionAnchorEl
    },
    callbacks: {
        handleAddProducts,
        handleAddNewProductClicked,
        handleEditProductClicked,
        handleDeleteProductClicked,
        handleDeleteProductsClicked,
        handleRemoveSelectedProducts,
        handleRemoveSelectedProductsClicked,
        handleReturnSelection,
        handleReturnSelectionClicked,
        handleDeselectAll,
        toggleProductTypeSelectionPopover,
        toggleDrawer,
        toggleEditColumnLabels
    },
    t
}) => {
    if (handleAddProducts) {
        actionButtons.push(
            AddProductsButton(true, enableSingleProductSelectionMode, productsInTable, handleAddProducts, t)
        );
        if (enableSelection)
            actionButtons.push(
                AddProductsButton(false, enableSingleProductSelectionMode, productsInTable, handleAddProducts, t)
            );
    }
    if (enableProductTypeSelection) {
        actionButtons.push(
            ToggleProductTypePopoverButton(true, toggleProductTypeSelectionPopover, setProductTypeSelectionAnchorEl, t)
        );
    }
    if (enableAddNewProduct && handleAddNewProductClicked) {
        actionButtons.push(
            AddNewProductButton(true, enableSingleProductSelectionMode, productsInTable, handleAddNewProductClicked, t)
        );
        if (enableSelection)
            actionButtons.push(
                AddNewProductButton(
                    false,
                    enableSingleProductSelectionMode,
                    productsInTable,
                    handleAddNewProductClicked,
                    t
                )
            );
    }
    if (enableEditProduct && handleEditProductClicked) {
        actionButtons.push(EditProductButton(false, enableSelection, selectedRows, handleEditProductClicked, t));
    }
    if (enableDeleteProduct && handleDeleteProductClicked) {
        if (enableSelection) {
            actionButtons.push(
                DeleteProductsButton(false, enableSelection, selectedRows, handleDeleteProductsClicked, t)
            );
        } else {
            actionButtons.push(DeleteProductButton(false, handleDeleteProductClicked, t));
        }
    }
    if (handleRemoveSelectedProducts) {
        if (enableSelection) {
            actionButtons.push(
                RemoveProductsButton(true, enableSelection, selectedRows, handleRemoveSelectedProductsClicked, t)
            );
        }
        actionButtons.push(
            RemoveProductsButton(false, enableSelection, selectedRows, handleRemoveSelectedProductsClicked, t)
        );
    }
    if (handleReturnSelection) {
        if (enableSelection)
            actionButtons.push(
                ReturnSelectedProductIdsButton(true, enableSelection, selectedRows, handleReturnSelectionClicked, t)
            );
        actionButtons.push(
            ReturnSelectedProductIdsButton(false, enableSelection, selectedRows, handleReturnSelectionClicked, t)
        );
    }
    if (enableSelection && enableDeselectAll) {
        actionButtons.push(DeselectAllButton(true, selectedRows, handleDeselectAll, t));
        actionButtons.push(DeselectAllButton(false, selectedRows, handleDeselectAll, t));
    }
    if (enableEditColumnLabels) {
        actionButtons.push(EditColumnLabelsButton(true, toggleEditColumnLabels, t));
    }
    if (enableEditColumnLabels && enableSelection)
        actionButtons.push(EditColumnLabelsButton(false, toggleEditColumnLabels, t));
    if (enableCustomFilter) {
        actionButtons.push(CustomFilterButton(true, toggleDrawer, t));
    }
    if (enableCustomFilter && enableSelection) actionButtons.push(CustomFilterButton(false, toggleDrawer, t));

    return actionButtons;
};
