/* eslint-disable no-console */
import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { v4 } from 'uuid';

import { marshalAdDefault, marshalCampaignDefault, marshalCampaignGroupInput } from 'helpers/marshal';

import { creativesReducer } from './creativesReducer';
import { settingsReducer } from './settingsReducer';
import { targetingReducer } from './targetingReducer';

const reduceReducers =
    (...reducers) =>
    (state, action) =>
        reducers.reduce((acc, nextReducer) => nextReducer(acc, action), state);

const CampaignStateContext = React.createContext();
const CampaignDispatchContext = React.createContext();

export const CAMPAIGN_ACTION_TYPE = {
    CLOSE: 'close',
    CLOSE_SELECTOR: 'closeSel',
    PUBLISH: 'publish',
    PUBLISH_SUCCESS: 'published',
    PUBLISH_ERROR: 'notpublished',
    SAVE: 'save',
    SAVE_SUCCESS: 'saved',
    SAVE_ERROR: 'notsaved',
    UPDATE_NAME: 'nameUpdate',
    UPDATE_LINK_EXTENSION: 'updateLinkExtension',
    ADD_CAMPAIGN: 'campaignAdd',
    SELECT_CAMPAIGN: 'campaignSelect',
    DELETE_CAMPAIGN: 'campaignDelete',
    COPY_CAMPAIGN: 'campaignCopy',
    ADD_UNIT: 'unitAdd',
    REMOVE_UNIT: 'unitDelete',
    SET_UNIT_SELECTION: 'unitSelect'
};

export const CAMPAIGN_OVERVIEW_PATH = '/campaigns';

export const reduceSchedule = (memo, current) => {
    const schedule = memo;

    if (memo?.startDate?.getTime() !== current?.startDate?.getTime()) {
        schedule.startDate = null;
        schedule.mixed = true;
    }
    if (memo?.endDate?.getTime() !== current?.endDate?.getTime()) {
        schedule.endDate = null;
        schedule.mixed = true;
    }
    if (memo?.startTime !== current?.startTime) {
        schedule.startTime = '00:00';
        schedule.mixed = true;
    }
    if (memo?.endTime !== current?.endTime) {
        schedule.endTime = '23:59';
        schedule.mixed = true;
    }

    return schedule;
};

export const reduceCampaign = (campaign) => ({
    ...campaign,
    ad: campaign.units.reduce((memo, unit) => {
        if (!unit.selected) {
            return memo;
        }
        const current = campaign.ads.get(unit.id);

        if (memo === undefined) {
            return cloneDeep({
                ...current,
                budgetTotal: current.budget,
                advertisers: new Set([unit.id]),
                targeting: { [unit.id]: current.targeting },
                creatives: { [unit.id]: current.creatives }
            });
        }

        memo.creatives[unit.id] = current.creatives ? current.creatives : []; // Finish merging in creative component.
        memo.targeting[unit.id] = current.targeting ? current.targeting : {}; // Finish merging in targeting component.

        return {
            advertisers: memo.advertisers.add(unit.id),
            schedule: reduceSchedule(memo.schedule, current.schedule),
            budget: memo.budget === current.budget ? memo.budget : 'mixed',
            budgetTotal: memo.budgetTotal + current.budget,
            creatives: memo.creatives,
            targeting: memo.targeting
        };
    }, undefined),
    budgetTotal: [...campaign.ads.values()].reduce((memo, ad) => memo + ad.budget, 0)
});

const campaignReducer = (state, action) => {
    switch (action.type) {
        case CAMPAIGN_ACTION_TYPE.ADD_CAMPAIGN: {
            const newCampaign = marshalCampaignDefault(
                [{ ...action.activeUnit, selected: true }],
                action.selectedCampaignType,
                action.adType,
                state.name
            );

            return {
                ...state,
                campaigns: state.campaigns ? [...state.campaigns, newCampaign] : [newCampaign],
                selectedCampaign: state.campaigns.length
            };
        }
        case CAMPAIGN_ACTION_TYPE.SELECT_CAMPAIGN: {
            return {
                ...state,
                previousCampaign: state.selectedCampaign,
                selectedCampaign: action.campaignId
                    ? state.campaigns.findIndex((x) => x.id === action.campaignId)
                    : undefined
            };
        }
        case CAMPAIGN_ACTION_TYPE.DELETE_CAMPAIGN: {
            const index = state.campaigns.findIndex((x) => x.id === action.campaignId);

            return {
                ...state,
                selectedCampaign:
                    state.campaigns.length === 1
                        ? undefined
                        : index < state.selectedCampaign || state.selectedCampaign === state.campaigns.length - 1
                        ? state.selectedCampaign - 1
                        : state.selectedCampaign,
                campaigns: [...state.campaigns.slice(0, index), ...state.campaigns.slice(index + 1)]
            };
        }
        case CAMPAIGN_ACTION_TYPE.COPY_CAMPAIGN: {
            const index = state.campaigns.findIndex((x) => x.id === action.campaignId);

            return {
                ...state,
                selectedCampaign: state.campaigns.length,
                campaigns: [...state.campaigns, { ...cloneDeep(state.campaigns[index]), id: v4() }]
            };
        }
        case CAMPAIGN_ACTION_TYPE.UPDATE_NAME: {
            return {
                ...state,
                name: action.name,
                campaigns: state.campaigns.map((campaign) => ({ ...campaign, name: action.name }))
            };
        }
        case CAMPAIGN_ACTION_TYPE.UPDATE_LINK_EXTENSION: {
            const newState = {
                ...state,
                campaigns: state.campaigns.map((campaign) => {
                    return campaign.id === action?.campaignID
                        ? { ...campaign, linkExtensions: action.linkExtensions }
                        : campaign;
                })
            };
            // console.log('oldState', state);
            // console.log('newState', newState);
            // console.log('lxt old state');
            // state.campaigns.forEach((c) => {
            //     console.log('lxt', c.linkExtensions);
            // });
            // console.log('new state');
            // newState.campaigns.forEach((c) => {
            //     console.log('lxt', c.linkExtensions);
            // });

            return newState;
        }
        case CAMPAIGN_ACTION_TYPE.PUBLISH: {
            action.mutation({
                variables: {
                    campaignGroupInput: marshalCampaignGroupInput(state)
                }
            });
            // console.log('publish', state);

            return { ...state, saving: true };
        }
        case CAMPAIGN_ACTION_TYPE.PUBLISH_SUCCESS: {
            action.history.push(CAMPAIGN_OVERVIEW_PATH);

            return { ...state, saving: false };
        }
        case CAMPAIGN_ACTION_TYPE.PUBLISH_ERROR: {
            return { ...state, saving: false };
        }
        case CAMPAIGN_ACTION_TYPE.SAVE: {
            action.mutation({
                variables: {
                    campaignGroupInput: marshalCampaignGroupInput(state)
                }
            });

            return { ...state, saving: true };
        }
        case CAMPAIGN_ACTION_TYPE.SAVE_SUCCESS: {
            action.history.push(CAMPAIGN_OVERVIEW_PATH);

            return { ...state, saving: false, unsaved: [] };
        }
        case CAMPAIGN_ACTION_TYPE.SAVE_ERROR: {
            return { ...state, saving: false };
        }
        case CAMPAIGN_ACTION_TYPE.CLOSE: {
            action.history.push(CAMPAIGN_OVERVIEW_PATH);

            return state;
        }
        case CAMPAIGN_ACTION_TYPE.CLOSE_SELECTOR: {
            if (state.previousCampaign) {
                return { ...state, selectedCampaign: state.previousCampaign };
            } else {
                action.history.push(CAMPAIGN_OVERVIEW_PATH);
            }

            return state;
        }
        case CAMPAIGN_ACTION_TYPE.ADD_UNIT: {
            const selectedCampaign = state.campaigns[state.selectedCampaign];

            return {
                ...state,
                campaigns: [
                    ...state.campaigns.slice(0, state.selectedCampaign),
                    {
                        ...selectedCampaign,
                        units: [
                            ...selectedCampaign.units,
                            ...action.units.map((unit) => ({ ...unit, selected: true }))
                        ],
                        ads: new Map([
                            ...selectedCampaign.ads,
                            ...action.units.map((unit) => [
                                unit.id,
                                marshalAdDefault(selectedCampaign.adGroups[0].adType, unit)
                            ])
                        ])
                    },
                    ...state.campaigns.slice(state.selectedCampaign + 1)
                ]
            };
        }
        case CAMPAIGN_ACTION_TYPE.REMOVE_UNIT: {
            const selectedCampaign = state.campaigns[state.selectedCampaign];
            selectedCampaign.ads.delete(action.id);

            return {
                ...state,
                campaigns: [
                    ...state.campaigns.slice(0, state.selectedCampaign),
                    {
                        ...selectedCampaign,
                        units: selectedCampaign.units.filter((unit) => unit.id !== action.id)
                    },
                    ...state.campaigns.slice(state.selectedCampaign + 1)
                ]
            };
        }
        case CAMPAIGN_ACTION_TYPE.SET_UNIT_SELECTION: {
            const selectedCampaign = state.campaigns[state.selectedCampaign];

            return {
                ...state,
                campaigns: [
                    ...state.campaigns.slice(0, state.selectedCampaign),
                    {
                        ...selectedCampaign,
                        units: selectedCampaign.units.map((unit) =>
                            !action.id || action.id === unit.id ? { ...unit, selected: action.selected } : unit
                        )
                    },
                    ...state.campaigns.slice(state.selectedCampaign + 1)
                ]
            };
        }
        default: {
            return state;
        }
    }
};

const rootReducer = reduceReducers(campaignReducer, settingsReducer, creativesReducer, targetingReducer);

export const CampaignProvider = ({ children, initialState }) => {
    const [state, dispatch] = React.useReducer(rootReducer, initialState);

    return (
        <CampaignStateContext.Provider value={state}>
            <CampaignDispatchContext.Provider value={dispatch}>{children}</CampaignDispatchContext.Provider>
        </CampaignStateContext.Provider>
    );
};

CampaignProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
    initialState: PropTypes.object
};

export const useCampaignState = () => {
    const context = React.useContext(CampaignStateContext);

    if (context === undefined) {
        throw new Error('useCampaignState used without a CampaignProvider');
    }

    return context;
};

export const useCampaignDispatch = () => {
    const context = React.useContext(CampaignDispatchContext);

    if (context === undefined) {
        throw new Error('useCampaignState used without a CampaignProvider');
    }

    return context;
};

export const useCampaign = () => {
    return { state: useCampaignState(), dispatch: useCampaignDispatch() };
};
