import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Box, Typography } from '@material-ui/core';

export const TargetingConditionHeader = ({ targetingConditionType }) => {
    const { t } = useTranslation();

    return (
        <>
            <Box mb={1}>
                <Typography variant='h4'>{t('genericLabel.' + targetingConditionType)}</Typography>
            </Box>
            <Box mb={1}>
                <Typography variant='subtitle2'>{t('targeting.' + targetingConditionType + 'KeywordPara')}</Typography>
            </Box>
        </>
    );
};
TargetingConditionHeader.propTypes = {
    targetingConditionType: PropTypes.string
};
