import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';

import { MinimalTopbar } from './partials';

const useStyles = makeStyles(() => ({
    root: {
        height: '100vh'
    },
    content: {
        height: 'calc(100vh - 64px)'
    }
}));

const Minimal = (props) => {
    const { children } = props;
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <MinimalTopbar />
            <main className={classes.content}>{children}</main>
        </div>
    );
};

Minimal.propTypes = {
    children: PropTypes.node
};

export default Minimal;
