import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Box, Button, Dialog, DialogContent, Grid, TextField, Typography } from '@material-ui/core';

const NewBudgetDialog = ({ open, close, handleSave }) => {
    const { t } = useTranslation();
    const [budgetName, setBudgetName] = useState(null);

    return (
        <Dialog fullWidth={true} maxWidth='sm' open={open} onClose={close} aria-labelledby='budget-line-dialog'>
            <DialogContent>
                <Typography variant='h6'>{t('Add new budget')}</Typography>
                <TextField placeholder={'New budget name'} onChange={(event) => setBudgetName(event.target.value)} />
                <Box my={2}>
                    <Grid container justifyContent='space-between' alignItems='center' spacing={2}>
                        <Grid item xs={3}>
                            <Button variant='contained' onClick={close}>
                                {t('Cancel')}
                            </Button>
                        </Grid>
                        <Grid item xs={9}>
                            <Box textAlign='right'>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={() => {
                                        handleSave(budgetName);
                                    }}
                                >
                                    {t('Confirm')}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

NewBudgetDialog.propTypes = {
    open: PropTypes.bool,
    close: PropTypes.func,
    handleSave: PropTypes.func
};

export default NewBudgetDialog;
