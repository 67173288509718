export const moveElement = (old_index, new_index, arr) => {
    if (new_index >= arr.length) {
        let k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);

    return arr;
};

// Only works for arrays containing primitive values
export const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
};

export const uniqueArrayOfObject = (array, keyToBeUnique) => {
    return array.filter((x, xi) => !array.slice(xi + 1).some((y) => y[keyToBeUnique] === x[keyToBeUnique]));
};

export const deepCopyFunction = (inObject) => {
    let value, key;

    if (typeof inObject !== 'object' || inObject === null) {
        return inObject;
    }

    const outObject = Array.isArray(inObject) ? [] : {};

    for (key in inObject) {
        value = inObject[key];

        outObject[key] = deepCopyFunction(value);
    }

    return outObject;
};

export const stripTypenames = (value) => {
    if (Array.isArray(value)) {
        return value.map(stripTypenames);
    } else if (value !== null && typeof value === 'object') {
        const newObject = {};
        for (const property in value) {
            if (property !== '__typename') {
                newObject[property] = stripTypenames(value[property]);
            }
        }

        return newObject;
    } else {
        return value;
    }
};

export function* arrayDifference(array, excludes) {
    for (const x of array) if (!excludes.includes(x)) yield x;
}
