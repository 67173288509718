import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLazyQuery, useMutation } from '@apollo/client';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    makeStyles,
    Popover,
    TextField,
    Tooltip,
    Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import ContentCopyIcon from '@material-ui/icons/FileCopy';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { XGrid } from '@material-ui/x-grid';

import { BlueHeaderContainer } from 'common/components/BlueHeaderContainer/BlueHeaderContainer';
import { DialogTitleClose } from 'common/components/DialogCloser/DialogTitleClose';
import { AuthContext } from 'context/AuthContext';
import { Context as activeUnitContext } from 'context/units/activeUnitContext';
import {
    CREATE_CAMPAIGN_IDENTIFIER_MAPPING,
    REMOVE_CAMPAIGN_IDENTIFIER_MAPPING
} from 'graphql/mutations/campaign.identifier.mapping';
import { GET_UNIT_CAMPAIGN_MAPPING_LIST } from 'graphql/queries/campaign.identifier.mapping';

const useStyles = makeStyles((theme) => ({
    name: {
        marginTop: theme.spacing(2),
        textAlign: 'center'
    },

    form: {
        marginTop: theme.spacing(3)
    },

    submit: {
        margin: theme.spacing(3, 0, 2)
    },
    txtField: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },

    inputFields: { backgroundColor: 'none' },

    listContainer: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },

    listTitle: {
        borderBottom: '1px solid grey',
        marginBottom: theme.spacing(2)
    },
    list: {
        listStyles: 'none',
        listStyleType: 'none',
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(1)
    },
    listItem: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2)
    },
    innerTitle: {
        color: theme.palette.primary
    }
}));

const CampaignMapper = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { user } = useContext(AuthContext);
    const [modalState, setModalState] = useState(false);
    const [name, setName] = useState('');
    const [open, setOpen] = useState(false);
    const [generated, setGenerated] = useState('');
    const [identifiers, setIdentifiers] = useState([]);
    const anchorEl = useRef(null);
    const {
        state: { activeUnit }
    } = useContext(activeUnitContext);

    const [createCampaignIdentifierMapping] = useMutation(CREATE_CAMPAIGN_IDENTIFIER_MAPPING, {
        onCompleted: ({ createCampaignIdentifierMapping }) => {
            if (createCampaignIdentifierMapping) {
                setIdentifiers(createCampaignIdentifierMapping);
            }
        }
    });

    const [removeCampaignIdentifierMapping] = useMutation(REMOVE_CAMPAIGN_IDENTIFIER_MAPPING, {
        onCompleted: ({ removeCampaignIdentifierMapping }) => {
            if (removeCampaignIdentifierMapping) {
                setIdentifiers(removeCampaignIdentifierMapping);
            }
        }
    });

    const [getUnitCampaignIdentifierMappingList] = useLazyQuery(GET_UNIT_CAMPAIGN_MAPPING_LIST, {
        variables: { unitId: activeUnit.id },
        onCompleted: (data) => {
            if (data?.getUnitCampaignIdentifierMappingList) {
                setIdentifiers([...data?.getUnitCampaignIdentifierMappingList]);
                setOpen(!data?.getUnitCampaignIdentifierMappingList?.length);
            }
        }
    });

    const columns = [
        {
            resizable: true,
            align: 'center',
            flex: 1,
            field: 'name',

            renderHeader: function Cell() {
                return <strong>{t('Name')}</strong>;
            },
            renderCell: function Cell(params) {
                return (
                    <Box
                        display='flex'
                        justifyContent='space-between'
                        alignItems='center'
                        height='100%'
                        width='100%'
                        paddingLeft='5%'
                        paddingRight='3%'
                    >
                        <typography>{params.row?.name} </typography>
                        <IconButton onClick={() => clipItem(params.row?.name)}>
                            <Tooltip id='button-copy-tracking-id' title='copy name'>
                                <ContentCopyIcon fontSize='small' />
                            </Tooltip>
                        </IconButton>
                    </Box>
                );
            }
        },
        {
            resizable: true,
            align: 'center',
            flex: 1,
            field: 'identifier',

            renderHeader: function Cell() {
                return <strong>{t('Campaign name ID')}</strong>;
            },
            renderCell: function Cell(params) {
                return (
                    <Box
                        display='flex'
                        justifyContent='space-between'
                        alignItems='center'
                        height='100%'
                        width='100%'
                        paddingLeft='5%'
                        paddingRight='3%'
                    >
                        <typography>{params.row?.identifier} </typography>
                        <IconButton onClick={() => clipItem(params.row?.identifier)}>
                            <Tooltip id='button-copy-tracking-id' title='copy identifier'>
                                <ContentCopyIcon fontSize='small' />
                            </Tooltip>
                        </IconButton>
                    </Box>
                );
            }
        },
        {
            resizable: true,
            align: 'center',
            flex: 1,
            field: 'trackingId',

            renderHeader: function Cell() {
                return <strong>{t('UTM tracking ID')}</strong>;
            },
            renderCell: function Cell(params) {
                return (
                    <Box
                        display='flex'
                        justifyContent='space-between'
                        alignItems='center'
                        height='100%'
                        width='100%'
                        paddingLeft='5%'
                        paddingRight='3%'
                    >
                        <typography>{params.row?.trackingId} </typography>
                        <IconButton onClick={() => clipItem(params.row?.trackingId)}>
                            <Tooltip id='button-copy-tracking-id' title='copy tracking id'>
                                <ContentCopyIcon fontSize='small' />
                            </Tooltip>
                        </IconButton>
                    </Box>
                );
            }
        },
        {
            headerName: t('Created'),
            field: 'date',
            align: 'left',
            resizable: true,
            flex: 1,
            renderCell: function Cell(params) {
                return (
                    <Box
                        paddingLeft='5%'
                        display='flex'
                        justifyContent='left'
                        alignItems='center'
                        height='100%'
                        width='100%'
                    >
                        <typography>{params.row?.date} </typography>
                    </Box>
                );
            }
        },
        {
            headerName: t('User'),
            field: 'createdBy',
            align: 'left',
            resizable: true,
            flex: 1,
            renderCell: function Cell(params) {
                return (
                    <Box
                        paddingLeft='5%'
                        display='flex'
                        justifyContent='left'
                        alignItems='center'
                        height='100%'
                        width='100%'
                    >
                        <typography>{params.row?.createdBy} </typography>
                    </Box>
                );
            }
        },
        {
            headerName: '',
            field: '   ',
            width: 64,
            resizable: false,
            align: 'right',
            renderCell: function Cell(params) {
                return (
                    <Box display='flex' justifyContent='center' alignItems='center' height='100%' width='100%'>
                        <IconButton onClick={() => removeItem(params.row?.id)}>
                            <Tooltip id='button-delete' title='remove row'>
                                <DeleteIcon fontSize='small' />
                            </Tooltip>
                        </IconButton>
                    </Box>
                );
            }
        }
    ];

    const handleChange = (e) => {
        e.preventDefault();
        setName(e.target.value);
    };

    const identity = open ? 'more-popover' : undefined;

    const handleCloseToggle = (el, isOpen) => {
        const popover = document.querySelector('#popover-content');
        if (popover && el && popover.contains(el)) {
            return;
        }
        setOpen(isOpen);
    };

    const handleClick = (e) => {
        e.preventDefault();
        const randomId = Math.floor(Math.random() * 343434);
        const today = new Date();
        const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(today);
        const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(today);
        const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(today);
        setGenerated({ id: randomId, name, date: `${day}.${month}.${year}` });
        setName('');
        setModalState(!modalState);
    };

    const handleNameGenerate = () => {
        const { email } = user;
        createCampaignIdentifierMapping({
            variables: {
                input: {
                    unitId: activeUnit.id,
                    name: `${generated?.name}`,
                    date: generated?.date,
                    identifier: `pac_${generated?.id}`,
                    trackingId: `utm_id=pac_${generated?.id}`,
                    createdBy: email
                }
            }
        });
        setGenerated('');
        setModalState(!modalState);
    };

    const removeItem = (id) => {
        removeCampaignIdentifierMapping({
            variables: {
                id,
                unitId: activeUnit.id
            }
        });
    };

    const clipItem = (clipped) => {
        navigator.clipboard.writeText(clipped);
    };

    useEffect(() => {
        getUnitCampaignIdentifierMappingList();
        document.addEventListener('click', (e) => handleCloseToggle(e.target, false), true);

        return () => {
            document.removeEventListener('click', (e) => handleCloseToggle(e.target, false));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <BlueHeaderContainer title={t('Campaign mapper - Tracking Code Generator')} style={{ minHeight: '50vh' }}>
            <Grid container direction={'column'} justifyContent='center' alignItems='center'>
                <Grid item xs={12} md={12} container direction='row' alignItems='center' justifyContent='space-around'>
                    <Grid item xs={6} md={6} spacing={4} alignItems='center'>
                        <div className={classes.txtField}>
                            <TextField
                                id='name'
                                name='name'
                                label={t('Campaign Name')}
                                type='text'
                                variant='filled'
                                value={name}
                                onChange={handleChange}
                                fullWidth
                                className={classes.inputFields}
                                InputLabelProps={{ shrink: true }}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={5} md={5} alignItems='bottom'>
                        <>
                            <Button
                                disabled={!name?.length}
                                className={classes.removeButton}
                                variant='outlined'
                                size='small'
                                onClick={handleClick}
                            >
                                {t('Generate campaign identifier')}
                            </Button>
                            <Dialog
                                open={modalState}
                                onClose={() => setModalState(false)}
                                aria-describedby='scroll-dialog-description'
                                aria-labelledby='scroll-dialog-title'
                            >
                                <DialogTitle>{t('Generate campaign identifier')}</DialogTitle>
                                <DialogTitleClose onClose={() => setModalState(false)} id='scroll-dialog-title' />
                                <DialogContent id='scroll-dialog-description' dividers={true}>
                                    <DialogContentText>
                                        {t(
                                            'A new campaign identifier with {{name}}?pac={{id}} will generated - Continue?',
                                            {
                                                id: generated?.id,
                                                name: generated?.name
                                            }
                                        )}
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => setModalState(false)}>{t('Cancel')}</Button>
                                    <Button onClick={handleNameGenerate}>{t('Continue')}</Button>
                                </DialogActions>
                            </Dialog>
                        </>
                    </Grid>
                    <Grid item xs={1} md={1} direction='row' alignItems='top' justifyContent='flex-start'>
                        <IconButton
                            aria-describedby={identity}
                            color='primary'
                            variant='contained'
                            style={{ padding: 0, marginTop: '-25px' }}
                            onClick={() => handleCloseToggle(null, !open)}
                            ref={anchorEl}
                        >
                            <HelpOutlineIcon fontSize='small' />
                        </IconButton>
                        <Popover
                            className='popover'
                            id={identity}
                            open={open}
                            anchorEl={anchorEl.current}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left'
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                        >
                            <div style={{ padding: '20px', width: '500px' }} id='popover-content'>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography variant='body2' color='primary'>
                                        Get control over your campaigns and effect
                                    </Typography>
                                    <IconButton
                                        style={{ padding: 0 }}
                                        aria-describedby={identity}
                                        color='primary'
                                        variant='contained'
                                        onClick={() => handleCloseToggle(null, false)}
                                    >
                                        <CloseIcon fontSize='small' />
                                    </IconButton>
                                </div>
                                <div style={{ backgroundColor: '#FEF5E2', padding: '10px', marginTop: '5px' }}>
                                    <Typography variant='caption' lineHeight={1} color='primary'>
                                        Use the Campaign Mapper to group and measure performance for a business campaign
                                        that is using multiple media and channels, both online and offline
                                    </Typography>
                                    <ul className={classes.list} style={{ marginTop: '10px' }}>
                                        <li>
                                            <Typography variant='caption'>
                                                1.Give it a descriptive campaign name
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography variant='caption'>
                                                2.Hit the &quot;generate campaign code&quot; button
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography variant='caption'>
                                                3.Copy and use the Campaign name ID in the different adnetwork campaign
                                                names
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography variant='caption'>
                                                4.Copy and use the UTM tracking ID in the ads UTM tracking tag
                                            </Typography>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Popover>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container direction={'column'} justifyContent='center' alignItems='center'>
                <Grid
                    item
                    xs={12}
                    md={12}
                    direction='row'
                    container
                    alignItems='center'
                    justifyContent='center'
                    className={classes.listContainer}
                >
                    {
                        <XGrid
                            columns={columns}
                            autoHeight
                            headerHeight={35}
                            rows={identifiers}
                            disableColumnReorder={true}
                            hideFooter={true}
                        />
                    }
                </Grid>
            </Grid>
        </BlueHeaderContainer>
    );
};

export { CampaignMapper };
