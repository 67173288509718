import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Drawer } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles } from '@material-ui/styles';

import { AuthContext } from 'context/AuthContext';
import { Context as ActiveUnitContext } from 'context/units/activeUnitContext';
import { usePowerBIReportGetReportsForUnit } from 'hooks/powerBIReport';

import { drawerWidth } from '../Topbar/Topbar';
import { SidebarNav } from './components';
import pagesAuthenticated from './permissions/pagesAuthenticated';

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('md')]: {
            zIndex: `${theme.zIndex.drawer} !important`
        }
    },
    root: {
        backgroundColor: theme.palette.white,
        display: 'flex',
        flexDirection: 'column',
        height: 56,
        padding: theme.spacing(2, 0),
        overflowX: 'clip'
    },
    divider: {
        margin: theme.spacing(2, 0)
    },
    nav: {
        paddingTop: 0,
        marginBottom: theme.spacing(2),
        width: '100%'
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: 56
        }
    }
}));

const Sidebar = (props) => {
    const { open, variant, onClose, handleSidebarOpen, className, ...rest } = props;
    const { t, i18n } = useTranslation();

    const { authenticated } = useContext(AuthContext);
    const {
        state: { activeUnit, activePermissions }
    } = useContext(ActiveUnitContext);

    const { data: pbiReports } = usePowerBIReportGetReportsForUnit({ unitId: activeUnit?.id });

    const classes = useStyles();

    const pagesWithAuthAndPermissions = pagesAuthenticated(t, i18n, activePermissions, activeUnit, pbiReports?.reports);

    const pagesAnonymous = [
        {
            title: 'Login',
            href: '/sign-in',
            icon: <LockOpenIcon />
        },
        {
            title: 'About',
            href: '/About',
            icon: <InfoIcon />
        },
        {
            title: 'Settings',
            href: '/settings',
            icon: <SettingsIcon />
        }
    ];

    return (
        <Drawer
            anchor='left'
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open
            })}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open
                })
            }}
            onClose={onClose}
            open={open}
            variant={variant}
        >
            <div {...rest} className={clsx(classes.root, className)}>
                <SidebarNav
                    className={classes.nav}
                    pages={authenticated ? [...pagesWithAuthAndPermissions] : [...pagesAnonymous]}
                    open={open}
                    handleSidebarOpen={handleSidebarOpen}
                    onClose={onClose}
                />
            </div>
        </Drawer>
    );
};

Sidebar.propTypes = {
    className: PropTypes.string,
    onClose: PropTypes.func,
    handleSidebarOpen: PropTypes.func,
    open: PropTypes.bool.isRequired,
    variant: PropTypes.string.isRequired
};

export default Sidebar;
