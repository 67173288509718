import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import {
    Box,
    Button,
    Collapse,
    Dialog,
    Divider,
    IconButton,
    List,
    ListItem,
    TableCell,
    TableRow,
    Tooltip
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Delete, Edit, KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';

import { userModel } from 'common/models';

import AddUnitsToUser from './AddUnitsToUser';

const useStyles = makeStyles(() => ({
    root: {
        background: '#DCDCDC'
    }
}));

function UserList({
    user,
    setEditingUser,
    setRemoveUser,
    setRemoveModalState,
    setModalState,
    removeModalState,
    modalState,
    refetch
}) {
    const [open, setOpen] = useState(false);
    const [addUnitModalState, setAddUnitModalState] = useState(false);

    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Fragment>
            <TableRow key={user.id}>
                <TableCell align='right'>
                    <Tooltip title={t('Unit list')}>
                        <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                        </IconButton>
                    </Tooltip>
                </TableCell>
                <TableCell align='right'>{user.firstName}</TableCell>
                <TableCell align='right'>{user.lastName}</TableCell>
                <TableCell align='right'>{user.email}</TableCell>
                <TableCell align='right'>{user.phone}</TableCell>
                <TableCell>
                    {user.verified ? '' : <span style={{ color: 'red' }}> {t('Not verified')} </span>}
                </TableCell>
                <TableCell align='right'>
                    <IconButton
                        onClick={(e) => {
                            e.preventDefault();
                            setModalState(!modalState);
                            setEditingUser(user);
                        }}
                    >
                        <Tooltip title={t('Edit this user')}>
                            <Edit />
                        </Tooltip>
                    </IconButton>
                </TableCell>

                <TableCell align='right'>
                    <IconButton
                        onClick={(e) => {
                            e.preventDefault();
                            setRemoveModalState(!removeModalState);
                            setRemoveUser(user);
                        }}
                    >
                        <Tooltip title={t('Remove this user')}>
                            <Delete />
                        </Tooltip>
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                    <Collapse in={open} timeout='auto' unmountOnExit>
                        <Box margin={1} className={classes.root}>
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={(e) => {
                                    e.preventDefault();
                                    setAddUnitModalState(!addUnitModalState);
                                }}
                            >
                                {t('Add units')}
                            </Button>
                            <span> {t('Units')}</span>
                            <ul>
                                <Divider />
                                {user.units.map((unit) => (
                                    <List key={unit.id}>
                                        <ListItem> {unit.name} </ListItem> <Divider />
                                    </List>
                                ))}
                                {!user.verified && <span style={{ color: 'red' }}> {t('Not verified')}</span>}
                            </ul>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>

            <Dialog open={addUnitModalState} onClose={() => setAddUnitModalState(false)}>
                <AddUnitsToUser
                    setAddUnitModalState={setAddUnitModalState}
                    setEditingUser={setEditingUser}
                    refetch={refetch}
                    user={user}
                ></AddUnitsToUser>
            </Dialog>
        </Fragment>
    );
}

UserList.propTypes = {
    user: PropTypes.shape(userModel),
    setEditingUser: PropTypes.func,
    setRemoveUser: PropTypes.func,
    setRemoveModalState: PropTypes.func,
    setModalState: PropTypes.func,
    removeModalState: PropTypes.bool,
    modalState: PropTypes.bool,
    refetch: PropTypes.func
};

export default UserList;
