import gql from 'graphql-tag';
/**
 *
 */

export const FRAGMENT_PBI_CATEGORY_DATASOURCES = gql`
    fragment pbiCategoryDataSource on CategoryDataSource {
        id
        name
        description
        sequence
        authorized
    }
`;

/**
 *
 */

export const FRAGMENT_PBI_CATEGORY_REPORTS = gql`
    fragment pbiCategoryReport on CategoryReport {
        id
        name
        description
        isReusable
        sequence
        pbiReportId
        pbiWorkspaceId
    }
`;
/**
 *
 */

export const FRAGMENT_PBI_REPORTS_CATEGORY = gql`
    fragment pbiCategory on ReportCategory {
        id
        name
        sequence
        isActive
        parentId
        reports {
            ...pbiCategoryReport
        }
        datasources {
            ...pbiCategoryDataSource
        }
    }
    ${FRAGMENT_PBI_CATEGORY_REPORTS}
    ${FRAGMENT_PBI_CATEGORY_DATASOURCES}
`;
