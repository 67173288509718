import React from 'react';

import { ProductTable } from 'common/components/';

const ProductTableView = () => {
    return (
        <ProductTable
            defaultPageSize={10}
            enableCustomFilter={true}
            enableEditColumnLabels={true}
            enableEditColumnOrder={true}
            enableSelection={false}
            enableSearch={true}
            enableSorting={true}
            enableTitle={true}
        />
    );
};

export default ProductTableView;
