import React from 'react';
import PropTypes from 'prop-types';

import { Grid, IconButton, List, ListSubheader, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { productFieldModel } from 'common/models/product';

import FilterListItem from './FilterListItem';

const useStyles = makeStyles((theme) => ({
    root: {
        width: 360
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular
    }
}));

const FilterList = ({ title, fields, filters, setSelectedFilters, closeDrawer }) => {
    const classes = useStyles();

    return (
        <List
            component='nav'
            aria-labelledby='nested-list-subheader'
            subheader={
                <ListSubheader>
                    <Grid container justify='space-between'>
                        {title}
                        <IconButton onClick={closeDrawer} aria-label='close'>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </ListSubheader>
            }
            className={classes.root}
        >
            {filters.map((filter) => {
                return (
                    fields.find((field) => field.id === filter.fieldId) && (
                        <FilterListItem
                            key={'fl_' + filter.fieldId}
                            label={fields.find((field) => field.id === filter.fieldId).label}
                            filter={filter}
                            setSelectedFilters={setSelectedFilters}
                        />
                    )
                );
            })}
        </List>
    );
};

FilterList.propTypes = {
    title: PropTypes.string,
    fields: PropTypes.arrayOf(PropTypes.shape(productFieldModel)),
    filters: PropTypes.any,
    setSelectedFilters: PropTypes.func,
    closeDrawer: PropTypes.func
};

export default FilterList;
