import React from 'react';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';

import { useMutation, useQuery } from '@apollo/client';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import { UPDATE_USER } from '../../../../../../graphql/mutations/user';
import { ME } from '../../../../../../graphql/queries/user';
import AccountDetailsView from './AccountDetailsView';

const AccountDetails = ({ enqueueSnackbar }) => {
    const { data, loading, error } = useQuery(ME);
    const [updateUser] = useMutation(UPDATE_USER);

    if (loading) {
        return <CircularProgress />;
    }
    const user = data ? data.me : {};
    const handleSave = async (data) => {
        // eslint-disable-next-line no-unused-vars
        const { id, __typename, ...input } = data;
        let success = false;
        try {
            delete input.units;
            delete input.userPermissions;
            const res = await updateUser({
                variables: {
                    userId: id,
                    input: input
                }
            });
            if (res.data.updateUser && res.data.updateUser) {
                enqueueSnackbar('User updated', { variant: 'success' });
                success = true;
            }
        } catch (e) {
            enqueueSnackbar('Something went wrong', { variant: 'error' });
        }

        return success;
    };

    return (
        <>
            {error && <Typography>Network error. Showing dummy data</Typography>}
            {user && user.id && <AccountDetailsView onSave={handleSave} user={user} />}
        </>
    );
};

AccountDetails.propTypes = {
    enqueueSnackbar: PropTypes.any
};

export default withSnackbar(AccountDetails);
