import React from 'react';
import PropTypes from 'prop-types';

import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    panelSummaryRoot: {
        backgroundColor: theme.palette.primary.light,
        minHeight: 48,
        maxHeight: 48,
        display: 'flex',
        alignItems: 'center'
    }
}));

export const BlueHeaderContainer = ({ title, children, titleLineAction, withBorder = true, ...rest }) => {
    const theme = useTheme();
    const { panelSummaryRoot, panelSummaryContent, panelSummaryExpandIcon } = useStyles();

    return (
        <Box width={1} margin='auto' border={withBorder ? 1 : 0} borderColor={theme.palette.divider}>
            <Grid container item xs={12} justifyContent='space-between' alignItems='center'>
                <Box
                    width={1}
                    classes={{
                        root: panelSummaryRoot,
                        content: panelSummaryContent,
                        expandIcon: panelSummaryExpandIcon
                    }}
                    px={2}
                >
                    <Typography color='primary' variant='button' style={{ flexGrow: 1 }}>
                        {title}
                    </Typography>
                    {titleLineAction}
                </Box>
            </Grid>
            <Box bgcolor={theme.palette.background.paper} {...rest}>
                <Grid container>{children}</Grid>
            </Box>
        </Box>
    );
};

BlueHeaderContainer.propTypes = {
    title: PropTypes.string.isRequired,
    titleLineAction: PropTypes.element,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    withBorder: PropTypes.bool
};
