import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Paper, Typography } from '@material-ui/core';

export default function HeaderConnections() {
    const { t } = useTranslation();

    return (
        <Paper elevation={2} variant='outlined' square>
            <Box padding={1.5} height={100} display='flex' alignItems='center'>
                <Typography component='h4' variant='h4' color='primary'>
                    {t('Connections')}
                </Typography>
            </Box>
        </Paper>
    );
}
