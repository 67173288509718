import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    capitalize,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    TextField,
    Typography,
    useTheme
} from '@material-ui/core';
import { Clear, ExpandMore } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';

import { UnitSelector } from 'common/components';
import { usePowerBIReportUpdate } from 'hooks/powerBIReport';
import { LANGUAGES } from 'i18n/utils';

import { REPORT_CATEGORIES, REPORT_DATASOURCES } from './utils';

function EditReportDialog({ report, onClose, units }) {
    const { i18n } = useTranslation();
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();

    const [reportData, setReportData] = useState({
        name: report.name,
        tags: report.tags,
        dataSources: report.dataSources,
        versions: report.versions,
        permission: report.permission,
        defaultCategory: report.defaultCategory,
        internalNote: report.internalNote,
        description: report.description
    });

    const { mutateAsync: updateReport } = usePowerBIReportUpdate();

    const handleSave = async () => {
        if (!reportData.name['en-US']) {
            enqueueSnackbar('At least an English name is required', { variant: 'error' });

            return;
        }

        if (!reportData.versions[1]?.powerBIWorkspaceId) {
            enqueueSnackbar('Atleast 1 Power BI workspace ID is required', { variant: 'error' });

            return;
        }

        if (!reportData.versions[1]?.powerBIReportId) {
            enqueueSnackbar('Atleast 1 Power BI report ID is required', { variant: 'error' });

            return;
        }

        if (!reportData.defaultCategory) {
            enqueueSnackbar('Category is required', { variant: 'error' });

            return;
        }

        if (!reportData.permission.type) {
            enqueueSnackbar('Permission type is required', { variant: 'error' });

            return;
        }

        await updateReport(
            { reportId: report.id, reportData },
            {
                onSuccess: (data) => {
                    console.info('Report updated successfully:', data);
                    enqueueSnackbar('Report edited', { variant: 'success' });
                    onClose();
                },
                onError: (error) => {
                    console.error('Error updating report:', error);
                    enqueueSnackbar('Could not edit report', { variant: 'error' });
                }
            }
        );
    };

    return (
        <React.Fragment>
            <Dialog open onClose={onClose}>
                <DialogTitle>
                    <Grid container justifyContent='space-between' alignItems='center'>
                        <Typography variant='h6' color='primary'>
                            Edit Report
                        </Typography>
                        <IconButton onClick={onClose}>
                            <Clear />
                        </IconButton>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Accordion fullWidth>
                                <AccordionSummary expandIcon={<ExpandMore />}>Report name</AccordionSummary>

                                <AccordionDetails>
                                    <Box sx={{ width: '100%' }}>
                                        {Object.entries(LANGUAGES).map((lang) => (
                                            <Box key={lang[0]} sx={{ mb: 2 }}>
                                                <TextField
                                                    name={lang[0]}
                                                    fullWidth
                                                    value={reportData.name[lang[0]]}
                                                    onChange={(e) =>
                                                        setReportData((prev) => ({
                                                            ...prev,
                                                            name: { ...prev.name, [lang[0]]: e.target.value }
                                                        }))
                                                    }
                                                    InputLabelProps={{
                                                        shrink: true,
                                                        style: {
                                                            color:
                                                                lang[0] === i18n.language
                                                                    ? theme.palette.secondary.main
                                                                    : 'inherit',
                                                            fontWeight: lang[0] === i18n.language ? 'bold' : 'normal'
                                                        }
                                                    }}
                                                    label={lang[1]}
                                                />
                                            </Box>
                                        ))}
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>

                        <Grid item xs={12}>
                            <Accordion fullWidth defaultExpanded>
                                <AccordionSummary expandIcon={<ExpandMore />}>Report version</AccordionSummary>

                                <AccordionDetails>
                                    <Box sx={{ width: '100%' }}>
                                        {Object.entries(reportData.versions).map((version, i, arr) => (
                                            <Box key={version[0]} sx={{ mb: 2, px: 2, py: 2 }} component='fieldset'>
                                                <legend>Version {version[0]}</legend>
                                                {i !== 0 && i === arr.length - 1 && (
                                                    <Button
                                                        style={{ float: 'right' }}
                                                        color='error'
                                                        onClick={() => {
                                                            setReportData((prev) => {
                                                                const newVersions = { ...prev.versions };
                                                                delete newVersions[version[0]];

                                                                return {
                                                                    ...prev,
                                                                    versions: newVersions
                                                                };
                                                            });
                                                        }}
                                                    >
                                                        Remove
                                                    </Button>
                                                )}
                                                <TextField
                                                    name='powerBIWorkspaceId'
                                                    fullWidth
                                                    value={reportData.versions[version[0]].powerBIWorkspaceId}
                                                    onChange={(e) =>
                                                        setReportData((prev) => ({
                                                            ...prev,
                                                            versions: {
                                                                ...prev.versions,
                                                                [version[0]]: {
                                                                    ...prev.versions[version[0]],
                                                                    powerBIWorkspaceId: e.target.value
                                                                }
                                                            }
                                                        }))
                                                    }
                                                    label='Power BI workspace ID'
                                                />

                                                <TextField
                                                    style={{ marginTop: 16 }}
                                                    name='powerBIReportId'
                                                    fullWidth
                                                    value={reportData.versions[version[0]].powerBIReportId}
                                                    onChange={(e) =>
                                                        setReportData((prev) => ({
                                                            ...prev,
                                                            versions: {
                                                                ...prev.versions,
                                                                [version[0]]: {
                                                                    ...prev.versions[version[0]],
                                                                    powerBIReportId: e.target.value
                                                                }
                                                            }
                                                        }))
                                                    }
                                                    label='Power BI report ID'
                                                />
                                            </Box>
                                        ))}

                                        <Button
                                            color='primary'
                                            style={{ float: 'right' }}
                                            onClick={() => {
                                                setReportData((prev) => {
                                                    const newVersions = { ...prev.versions };

                                                    const newVersionNumber = Object.keys(prev.versions).length + 1;

                                                    newVersions[newVersionNumber] = {};
                                                    newVersions[newVersionNumber].powerBIWorkspaceId = '';
                                                    newVersions[newVersionNumber].powerBIReportId = '';

                                                    return {
                                                        ...prev,
                                                        versions: newVersions
                                                    };
                                                });
                                            }}
                                        >
                                            Add new version
                                        </Button>
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label='Internal note'
                                name='internalNote'
                                value={reportData.internalNote}
                                onChange={(e) => setReportData((prev) => ({ ...prev, internalNote: e.target.value }))}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Autocomplete
                                options={Object.values(REPORT_CATEGORIES)}
                                value={reportData.defaultCategory}
                                onChange={(_, newValue) => {
                                    setReportData((prev) => ({ ...prev, defaultCategory: newValue }));
                                }}
                                renderInput={(params) => <TextField {...params} label='Category' />}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Autocomplete
                                options={Object.values(REPORT_DATASOURCES)}
                                value={reportData.dataSources}
                                disableCloseOnSelect
                                multiple
                                getOptionLabel={(option) => capitalize(option)}
                                onChange={(_, newValue) => {
                                    setReportData((prev) => ({ ...prev, dataSources: newValue }));
                                }}
                                renderInput={(params) => <TextField {...params} label='Datasources' />}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormLabel component='legend'>Permission Type</FormLabel>
                            <RadioGroup
                                row
                                aria-label='permission-type'
                                name='permissionType'
                                value={reportData.permission.type}
                                onChange={(e) =>
                                    setReportData((prev) => ({
                                        ...prev,
                                        permission: { ...prev.permission, type: e.target.value }
                                    }))
                                }
                            >
                                <FormControlLabel value='global' control={<Radio color='primary' />} label='Global' />
                                <FormControlLabel
                                    value='selective'
                                    control={<Radio color='primary' />}
                                    label='Selective'
                                />
                            </RadioGroup>
                        </Grid>

                        <Grid item xs={12}>
                            <FormLabel>
                                {reportData.permission.type === 'global'
                                    ? 'Select units to exclude'
                                    : 'Select units that should have access'}
                            </FormLabel>
                            <UnitSelector
                                setSelectedUnits={(units) =>
                                    setReportData((prev) => ({
                                        ...prev,
                                        permission: {
                                            ...prev.permission,
                                            ...(prev.permission.type === 'global'
                                                ? { excludedUnits: units }
                                                : { units })
                                        }
                                    }))
                                }
                                selectedUnits={
                                    reportData.permission.type === 'global'
                                        ? reportData.permission.excludedUnits
                                        : reportData.permission.units
                                }
                                units={units}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color='primary'>
                        Cancel
                    </Button>
                    <Button onClick={handleSave} color='primary' variant='contained'>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

EditReportDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    report: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.shape({
            'da-DK': PropTypes.string,
            'de-DE': PropTypes.string,
            'en-US': PropTypes.string,
            'es-ES': PropTypes.string,
            'fr-FR': PropTypes.string,
            'nb-NO': PropTypes.string,
            'sv-SE': PropTypes.string
        }).isRequired,
        tags: PropTypes.arrayOf(PropTypes.string).isRequired,
        dataSources: PropTypes.arrayOf(PropTypes.string).isRequired,
        versions: PropTypes.objectOf(
            PropTypes.shape({
                powerBIWorkspaceId: PropTypes.string.isRequired,
                powerBIReportId: PropTypes.string.isRequired
            })
        ).isRequired,
        permission: PropTypes.shape({
            type: PropTypes.oneOf(['global', 'selective']).isRequired,
            excludedUnits: PropTypes.arrayOf(PropTypes.string),
            units: PropTypes.arrayOf(PropTypes.string)
        }).isRequired,
        defaultCategory: PropTypes.string.isRequired,
        internalNote: PropTypes.string,
        description: PropTypes.shape({
            'da-DK': PropTypes.string,
            'de-DE': PropTypes.string,
            'en-US': PropTypes.string,
            'es-ES': PropTypes.string,
            'fr-FR': PropTypes.string,
            'nb-NO': PropTypes.string,
            'sv-SE': PropTypes.string
        })
    }).isRequired,
    units: PropTypes.any
};

export default EditReportDialog;
