import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Grid, makeStyles, Tab, Tabs, useTheme } from '@material-ui/core';
import { BookOutlined, Business, Clear, InsertChartOutlined, Link } from '@material-ui/icons';

import TabPanel from 'common/components/TabPanel/TabPanel';
import { Context as activeUnitContext } from 'context/units/activeUnitContext';
import { a11yProps } from 'helpers/a11yProps';

import DataSourceSettings from './DataSourceSettings';
import ExternalReportSettings from './ExternalReportSettings';
import ReportSettings from './ReportSettings';
import { UnitContext } from './unit.provider';
import UnitAccountSettings from './UnitAccountSettings';

const useStyles = makeStyles((theme) => ({
    root: {
        borderRight: `1px solid ${theme.palette.divider}`,
        minHeight: 10,
        '& .MuiTab-root': {
            maxWidth: '100%'
        }
    },
    wrapper: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    labelIcon: {
        textTransform: 'none',
        minHeight: 48,
        maxHeight: 48,
        color: theme.palette.grey[900],
        '&.Mui-selected': {
            backgroundColor: theme.palette.secondary[50],
            transition: 'background-color 1000ms linear',
            color: theme.palette.secondary.main
        },
        '&:not(:first-child)': {
            borderTop: `1px solid ${theme.palette.divider}`
        },
        '&:last-child': {
            borderBottom: `1px solid ${theme.palette.divider}`
        },
        '& .MuiSvgIcon-root': {
            marginRight: theme.spacing(1.5),
            marginTop: theme.spacing(0.75)
        }
    }
}));

const SettingsTabs = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const classes = useStyles();
    const [tab, setTab] = React.useState(0);

    const {
        state: { activeUnit }
    } = useContext(activeUnitContext);

    const { editingUnit, isEdited, setIsEdited, resetEditingUnit, saveUnit } = useContext(UnitContext);

    const onSubmit = () => {
        if (editingUnit?.name?.length) {
            saveUnit();
            setIsEdited(false);
        }
    };

    const cancelSubmit = () => {
        resetEditingUnit();
        setIsEdited(false);
    };

    return (
        <>
            <Grid container item xs={12} style={{ borderTop: `1px solid ${theme.palette.divider}` }}>
                <Grid className={classes.root} item sm={3} xs={12}>
                    <Tabs
                        orientation='vertical'
                        variant='scrollable'
                        value={tab}
                        onChange={(_, newValue) => setTab(newValue)}
                        aria-label='Vertical tabs for unit settings'
                    >
                        <Tab
                            classes={{
                                labelIcon: classes.labelIcon,
                                wrapper: classes.wrapper
                            }}
                            icon={<Business />}
                            label={t('Account settings')}
                            {...a11yProps(0)}
                        />
                        <Tab
                            classes={{
                                labelIcon: classes.labelIcon,
                                wrapper: classes.wrapper
                            }}
                            icon={<InsertChartOutlined />}
                            label={t('Report settings')}
                            {...a11yProps(1)}
                        />
                        <Tab
                            classes={{
                                labelIcon: classes.labelIcon,
                                wrapper: classes.wrapper
                            }}
                            icon={<Link />}
                            label={t('Data sources')}
                            {...a11yProps(2)}
                        />
                        <Tab
                            classes={{
                                labelIcon: classes.labelIcon,
                                wrapper: classes.wrapper
                            }}
                            icon={<BookOutlined />}
                            label={t('External reports')}
                            {...a11yProps(3)}
                        />
                    </Tabs>
                </Grid>
                <Grid item sm={9} xs={12}>
                    <TabPanel value={tab} index={0}>
                        <UnitAccountSettings unitId={activeUnit.id} />
                    </TabPanel>

                    <TabPanel value={tab} index={1}>
                        <ReportSettings />
                    </TabPanel>

                    <TabPanel value={tab} index={2}>
                        <DataSourceSettings />
                    </TabPanel>

                    <TabPanel value={tab} index={3}>
                        <ExternalReportSettings />
                    </TabPanel>

                    <Grid container justifyContent='flex-end' style={{ minHeight: 48, paddingBottom: 8 }}>
                        {isEdited && (
                            <Grid item>
                                <Button
                                    disabled={!editingUnit?.name?.length}
                                    onClick={() => onSubmit()}
                                    variant='contained'
                                    color='primary'
                                    style={{ marginRight: 8 }}
                                >
                                    {t('Save')}
                                </Button>

                                <Button color='primary' onClick={() => cancelSubmit()}>
                                    <Clear />
                                    {t('Cancel')}
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default SettingsTabs;
