import React from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@material-ui/core';

import { getAdTypeImage } from 'helpers/campaign';

import { adTypeModel } from '../../models/advertisement';
import { CampaignTypeCard } from '../CampaignTypeCard/CampaignTypeCard';

export const CampaignTypeCardList = ({ adTypes, onSelectAdType, svg, withBackground }) => (
    <Grid container spacing={2}>
        {adTypes &&
            adTypes.map((adType) => (
                <Grid key={'campaigntype_card_' + adType.id} item lg={3} sm={4} xs={12}>
                    <CampaignTypeCard
                        adType={adType}
                        media={getAdTypeImage(adType.networks[0].name, adType.name, withBackground, svg)}
                        onClick={() => onSelectAdType(adType)}
                    />
                </Grid>
            ))}
    </Grid>
);

CampaignTypeCardList.propTypes = {
    adTypes: PropTypes.arrayOf(PropTypes.shape(adTypeModel)),
    onSelectAdType: PropTypes.func,
    svg: PropTypes.bool,
    withBackground: PropTypes.bool
};
