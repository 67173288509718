import React, { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';

import { Box, Divider, MenuItem, Select, Typography } from '@material-ui/core';

import { useGetPowerBIReportEmbedConfigForUnit } from 'hooks/unit';
import { usePrevious } from 'hooks/usePrevious';

import PowerBIReportAdditionalMenu from './PowerBIReportAdditionalMenu';

export const PowerBIReportContent = ({ versionKeys, report, unitId, setIsLoading, setLoadingText }) => {
    const { t, i18n } = useTranslation();
    const [currentVersionKey, setCurrentVersionKey] = useState(() => {
        const maxVersionKey = versionKeys.length ? Math.max(...versionKeys) : 0;

        return maxVersionKey;
    });

    const reportRef = useRef({});
    const { enqueueSnackbar } = useSnackbar();

    const { data, error } = useGetPowerBIReportEmbedConfigForUnit({
        versionNumber: currentVersionKey,
        reportId: report.id,
        unitId
    });
    const embedConfig = data?.embedConfig;

    const previousEmbedConfig = usePrevious(embedConfig);

    useEffect(() => {
        if (error) {
            enqueueSnackbar(t('Report could not be loaded'), { variant: 'error' });
            setIsLoading(false);
            setLoadingText('Retrieving and analyzing data...');
        }
    }, [enqueueSnackbar, error, setIsLoading, setLoadingText, t]);

    useEffect(() => {
        if (previousEmbedConfig && previousEmbedConfig.embedToken) {
            if (embedConfig && embedConfig.embedToken && previousEmbedConfig.embedToken !== embedConfig.embedToken) {
                if (reportRef.current && typeof reportRef.current.setAccessToken === 'function') {
                    reportRef.current.setAccessToken(embedConfig.embedToken);
                }
            }
        }
    }, [previousEmbedConfig, embedConfig]);

    const derivedEmbedConfig = embedConfig
        ? {
              type: 'report',
              tokenType: models.TokenType.Embed,
              accessToken: embedConfig.embedToken,
              embedUrl: embedConfig.embedUrl,
              settings: {
                  panes: {
                      filters: {
                          expanded: false,
                          visible: false
                      }
                  },
                  navContentPaneEnabled: false,
                  persistentFiltersEnabled: true,
                  background: models.BackgroundType.Transparent,
                  localeSettings: { language: i18n.language },
                  ...(isMobile ? { layoutType: models.LayoutType.MobilePortrait } : {})
              },
              filters: [
                  {
                      $schema: 'http://powerbi.com/product/schema#basic',
                      target: {
                          table: 'Languages',
                          column: 'LanguageId'
                      },
                      operator: 'In',
                      values: [i18n.language],
                      filterType: 1,
                      requireSingleSelection: true
                  }
              ],
              ...(embedConfig.slicers ? { slicers: embedConfig.slicers } : {})
          }
        : null;

    return (
        <>
            <Box sx={{ width: '100%', pt: 1, pb: 1 }}>
                <Box sx={{ mb: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    {versionKeys.length > 1 && (
                        <Box sx={{ display: 'flex' }}>
                            <Typography style={{ marginRight: '8px', fontSize: 12 }}>{t('Version')}</Typography>
                            <Select
                                style={{ fontSize: 12 }}
                                value={currentVersionKey}
                                onChange={(val) => {
                                    setCurrentVersionKey(val.target.value);

                                    setIsLoading(true);
                                    setLoadingText('Retrieving and analyzing data...');
                                }}
                            >
                                {versionKeys.map((versionKey) => (
                                    <MenuItem key={versionKey} value={versionKey}>
                                        {versionKey}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                    )}

                    {reportRef.current && (
                        <PowerBIReportAdditionalMenu
                            reportRef={reportRef.current}
                            unitId={unitId}
                            reportId={report.id}
                            versionNumber={currentVersionKey}
                            hasSavedSlicers={!!embedConfig?.slicers}
                        />
                    )}
                </Box>
                <Divider />
            </Box>

            {derivedEmbedConfig && (
                <PowerBIEmbed
                    key={
                        report.versions[currentVersionKey].powerBIReportId +
                        report.versions[currentVersionKey].powerBIWorkspaceId +
                        unitId
                    }
                    embedConfig={derivedEmbedConfig}
                    eventHandlers={
                        new Map([
                            [
                                'loaded',
                                function () {
                                    console.info('Report has loaded');
                                    setLoadingText('Showing report...');

                                    setTimeout(() => {
                                        setIsLoading(false);
                                    }, 3000);
                                }
                            ],
                            [
                                'rendered',
                                async function () {
                                    console.info('Report has rendered');
                                    setLoadingText('Crunching datasets...');
                                }
                            ],
                            [
                                'error',
                                function (event) {
                                    if (event) console.error(event);
                                    if (event?.message) console.error(event.message);
                                    if (event?.detailedMessage) console.error(event.detailedMessage);

                                    setIsLoading(false);
                                }
                            ]
                        ])
                    }
                    cssClassName={'report-style-class'}
                    getEmbeddedComponent={(rep) => {
                        if (rep) {
                            reportRef.current = rep;
                        }
                    }}
                />
            )}
        </>
    );
};

PowerBIReportContent.displayName = 'PowerBIReportContent';

PowerBIReportContent.propTypes = {
    unitId: PropTypes.string,
    versionKeys: PropTypes.array,
    report: PropTypes.object,
    setIsLoading: PropTypes.func,
    setLoadingText: PropTypes.func
};
