import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSnackbar, withSnackbar } from 'notistack';

import { useMutation } from '@apollo/client';

import { MFAStatus } from 'common/enums';
import { AuthContext } from 'context/AuthContext';
import { Context as activeUnitContext } from 'context/units/activeUnitContext';
import { clearAuthKeys } from 'helpers/auth';

import { FB_SIGN_IN, SIGN_IN } from '../../graphql/mutations/user';
import SignInForm from './SignInForm';

const SignIn = (props) => {
    const [mfa, setMFA] = useState(null);
    const [signInMutation] = useMutation(SIGN_IN);
    const [fbSignInMutation] = useMutation(FB_SIGN_IN);
    const { setAuthenticated, setToken, setUser } = useContext(AuthContext);
    const { enqueueSnackbar } = useSnackbar();
    const {
        state: { activeUnit }
    } = useContext(activeUnitContext);

    const { t } = useTranslation();

    const handleSignIn = async ({ password, email, mfaToken }) => {
        clearAuthKeys();
        try {
            const res = await signInMutation({ variables: { input: { email, password, mfaToken } } });

            if (res.data.login) {
                const { user, token: jwt } = res.data.login;

                if (jwt) {
                    setUser(user);
                    setToken(jwt);
                    setAuthenticated(user.id);
                }

                setMFA(user?.mfa);
            } else {
                props.enqueueSnackbar(t('Sign in failed'), { variant: 'error' });
            }
        } catch (e) {
            props.enqueueSnackbar(t('Server error'), { variant: 'error' });
        }
    };

    const handleGoogle = async () => {
        props.enqueueSnackbar('SignIn with google not implemented', { variant: 'error' });
    };

    const handleFacebook = async (facebookResponse) => {
        try {
            const res = await fbSignInMutation({
                variables: { input: { id: facebookResponse.id, accessToken: facebookResponse.accessToken } }
            });
            if (res.data.fbLogin && res.data.fbLogin.token) {
                props.enqueueSnackbar('SignIn success', { variant: 'success' });
                // props.history.push('/')
                const { user, token: jwt } = res.data.fbLogin;
                setAuthenticated(user.id);
                setToken(jwt);

                if (props.history?.location?.pathname !== activeUnit?.landingPage) {
                    props.history.push(activeUnit?.landingPage);
                }
            } else {
                props.enqueueSnackbar(t('Sign in failed'), { variant: 'error' });
            }
        } catch (e) {
            props.enqueueSnackbar(t('Sign in failed'), { variant: 'error' });
            // setAuthenticated(true)
            // setAuthBody('123')
            // props.history.push('/account');
        }
    };

    useEffect(() => {
        switch (mfa?.status) {
            case MFAStatus.invalid.value:
                enqueueSnackbar(t('Token entered is invalid please try again'), { variant: 'error' });

                break;
            case MFAStatus.unspecified.value:
            case MFAStatus.validated.value:
            case MFAStatus.inactive.value:
            case MFAStatus.processing.value:
                props.enqueueSnackbar(t('Sign in success'), { variant: 'success' });
                props.history.push('/');
                setTimeout(() => {
                    if (props.history?.location?.pathname !== activeUnit?.landingPage) {
                        props.history.push(activeUnit?.landingPage);
                    }
                }, 10);

                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mfa]);

    return (
        <SignInForm
            onSignIn={handleSignIn}
            history={props.history}
            onGoogle={handleGoogle}
            onFacebook={handleFacebook}
            setMFA={setMFA}
            mfa={mfa}
        />
    );
};

SignIn.propTypes = {
    history: PropTypes.any,
    enqueueSnackbar: PropTypes.any
};

export default withSnackbar(withRouter(SignIn));
