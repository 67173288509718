import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { TableCell, TableHead, TableRow } from '@material-ui/core';

import { productFieldModel } from 'common/models/product';

import MTableHeaderEditField from './MTableHeaderEditField';

const MTableHeaderEdit = ({ fields, addEmptyFirstColumn = false, onEditHeaders: onEditColumnLabels }) => {
    const [validationForm, setValidationForm] = useState(
        fields.map((field) => ({
            name: field.id,
            isValid: true
        }))
    );

    const handleFieldUpdated = (updatedField) => {
        const updatedValidationForm = updateFieldInValidationForm(updatedField);
        setValidationForm(updatedValidationForm);
        updateFields(updatedValidationForm);
    };

    const updateFieldInValidationForm = (updatedField) => {
        const updatedValidationForm = [...validationForm];
        const foundIndex = updatedValidationForm.findIndex((field) => field.name === updatedField.name);
        updatedValidationForm[foundIndex] = updatedField;

        return updatedValidationForm;
    };

    const updateFields = (formToBeValidated) => {
        if (validateForm(formToBeValidated)) {
            const updatedFields = fields.map((field, index) => {
                return {
                    ...field,
                    label: formToBeValidated[index].label ? formToBeValidated[index].label : field.label
                };
            });
            onEditColumnLabels(updatedFields, true);
        } else {
            onEditColumnLabels(null, false);
        }
    };

    const validateForm = (formToBeValidated) => {
        let hasOnlyValidFields = true;
        formToBeValidated.forEach((field) => (!field.isValid ? (hasOnlyValidFields = false) : null));

        return hasOnlyValidFields;
    };

    return (
        <TableHead>
            <TableRow>
                {addEmptyFirstColumn && <TableCell />}
                {fields
                    .filter((field) => field.visibility)
                    .map((field) => {
                        return (
                            <MTableHeaderEditField
                                key={'mthef_' + field.name}
                                field={field}
                                onFieldUpdated={handleFieldUpdated}
                            />
                        );
                    })}
            </TableRow>
        </TableHead>
    );
};

export default MTableHeaderEdit;

MTableHeaderEdit.propTypes = {
    fields: PropTypes.arrayOf(PropTypes.shape(productFieldModel)),
    addEmptyFirstColumn: PropTypes.bool,
    onEditHeaders: PropTypes.func
};
