import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useMediaQuery } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import { DevicesOutlined, Error, Favorite, HomeOutlined, Mood, Public, Search } from '@material-ui/icons';
import { useTheme } from '@material-ui/styles';

import { BlueHeaderContainer } from 'common/components/BlueHeaderContainer/BlueHeaderContainer';
import { StyledTab } from 'common/components/StyledTab/StyledTab';
import TabPanel from 'common/components/TabPanel/TabPanel';
import { adTypeModel } from 'common/models/advertisement';
import { a11yProps } from 'helpers/a11yProps';
import { capitalizeFirstLetter } from 'helpers/string';
import { TargetingType } from 'helpers/targeting';

import { DemographicsSelectorTabPanel } from './components/DemographicsSelectorTabPanel';
import { InterestsSelectorTabPanel } from './components/InterestSelectorTabPanel';
import { KeywordsSelectorTabPanel } from './components/KeywordsSelectorTabPanel';
import { LocationsSelectorTabPanel } from './components/LocationsSelectorTabPanel';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: 224
    },
    tabsContainer: {
        maxWidth: 300,
        borderRight: `1px solid ${theme.palette.grey[300]}`,
        height: '100%'
    },
    tabPanel: {
        padding: theme.spacing(2)
    }
}));

export const AdTargeting = ({ dispatch, targeting, adType }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    const [selectedTabIndex, setSelectedTabIndex] = useState(0);

    const handleChangeTab = (event, newTabIndex) => setSelectedTabIndex(newTabIndex);

    const getTabIndex = (targetingTypeName) => adType?.targetingTypes?.indexOf(targetingTypeName);

    const AdTargetingTab = ({ targetingType, ...props }) => {
        let icon = <Error />;
        let badgeNum;
        if (targetingType.toLowerCase() === TargetingType.Demographics.toLowerCase()) {
            icon = <Mood />;
        }
        if (targetingType.toLowerCase() === TargetingType.Keywords.toLowerCase()) {
            icon = <Search />;
            badgeNum = (targeting.keywords?.length || 0) + (targeting.excludeKeywords?.length || 0);
        }
        if (targetingType.toLowerCase() === TargetingType.Places.toLowerCase()) {
            icon = <Public />;
            badgeNum = targeting.locations?.length || 0;
        }
        if (targetingType.toLowerCase() === TargetingType.Interests.toLowerCase()) {
            icon = <Favorite />;
            badgeNum = (targeting.interests?.length || 0) + (targeting.excludeInterests?.length || 0);
        }
        if (targetingType.toLowerCase() === TargetingType.Websites.toLowerCase()) {
            icon = <HomeOutlined />;
        }
        if (targetingType.toLowerCase() === TargetingType.Devices.toLowerCase()) {
            icon = <DevicesOutlined />;
        }

        return (
            <StyledTab
                icon={icon}
                badgeNum={badgeNum}
                tabLabelStyle={classes.tabLabel}
                tabLabel={t(capitalizeFirstLetter(targetingType.toString().toLowerCase()))}
                {...props}
            />
        );
    };

    AdTargetingTab.propTypes = {
        targetingType: PropTypes.string
    };

    return (
        <div>
            <BlueHeaderContainer title={t('Targeting')}>
                <Grid container justifyContent='flex-start'>
                    <Grid item md={3} sm={4} xs={12}>
                        <div className={matches ? classes.tabsContainer : null}>
                            <Tabs
                                orientation={matches ? 'vertical' : 'horizontal'}
                                variant={matches ? 'fullWidth' : 'scrollable'}
                                scrollButtons={matches ? 'off' : 'on'}
                                value={selectedTabIndex}
                                onChange={handleChangeTab}
                                aria-label='Vertical-tabs'
                                className={classes.tabs}
                                indicatorColor='secondary'
                            >
                                {adType?.targetingTypes &&
                                    adType.targetingTypes.map((targetingType, index) => (
                                        <AdTargetingTab
                                            key={'tab_at_' + targetingType}
                                            targetingType={targetingType}
                                            {...a11yProps(index)}
                                        />
                                    ))}
                            </Tabs>
                        </div>
                    </Grid>
                    <Grid item md={9} sm={8} xs={12}>
                        {getTabIndex(TargetingType.Demographics) !== -1 && (
                            <TabPanel
                                key={'tabpanel_' + TargetingType.Demographics}
                                className={classes.tabPanel}
                                value={selectedTabIndex}
                                index={getTabIndex(TargetingType.Demographics)}
                            >
                                <DemographicsSelectorTabPanel
                                    dispatch={dispatch}
                                    gender={targeting.gender}
                                    ageRange={targeting.age}
                                />
                            </TabPanel>
                        )}
                        {getTabIndex(TargetingType.Keywords) !== -1 && (
                            <TabPanel
                                key={'tabpanel_' + TargetingType.Keywords}
                                className={classes.tabPanel}
                                value={selectedTabIndex}
                                index={getTabIndex(TargetingType.Keywords)}
                            >
                                <KeywordsSelectorTabPanel
                                    dispatch={dispatch}
                                    keywords={targeting.keywords}
                                    excludeKeywords={targeting.excludeKeywords}
                                />
                            </TabPanel>
                        )}
                        {getTabIndex(TargetingType.Places) !== -1 && (
                            <TabPanel
                                key={'tabpanel_' + TargetingType.Places}
                                className={classes.tabPanel}
                                value={selectedTabIndex}
                                index={getTabIndex(TargetingType.Places)}
                            >
                                <LocationsSelectorTabPanel dispatch={dispatch} locations={targeting.locations} />
                            </TabPanel>
                        )}
                        {getTabIndex(TargetingType.Interests) !== -1 && (
                            <TabPanel
                                key={'tabpanel_' + TargetingType.Interests}
                                className={classes.tabPanel}
                                value={selectedTabIndex}
                                index={getTabIndex(TargetingType.Interests)}
                            >
                                <InterestsSelectorTabPanel
                                    dispatch={dispatch}
                                    interests={targeting.interests}
                                    excludeInterests={targeting.excludeInterests}
                                />
                            </TabPanel>
                        )}
                        {getTabIndex(TargetingType.Websites) !== -1 && (
                            <TabPanel
                                key={'tabpanel_' + TargetingType.Websites}
                                className={classes.tabPanel}
                                value={selectedTabIndex}
                                index={getTabIndex(TargetingType.Websites)}
                            >
                                <div>Websites</div>
                            </TabPanel>
                        )}
                        {getTabIndex(TargetingType.Devices) !== -1 && (
                            <TabPanel
                                key={'tabpanel_' + TargetingType.Devices}
                                className={classes.tabPanel}
                                value={selectedTabIndex}
                                index={getTabIndex(TargetingType.Devices)}
                            >
                                <div>Devices</div>
                            </TabPanel>
                        )}
                    </Grid>
                </Grid>
            </BlueHeaderContainer>
        </div>
    );
};

AdTargeting.propTypes = {
    dispatch: PropTypes.any,
    targeting: PropTypes.any,
    adType: PropTypes.shape(adTypeModel)
};
