export const DATA_SOURCES = [
    { key: 'bidtheatre', name: 'BidTheatre' },
    { key: 'deltaProjects', name: 'Delta Projects' },
    { key: 'hubspot', name: 'HubSpot' },
    { key: 'facebookAds', name: 'Facebook Ads' },
    { key: 'facebookPages', name: 'Facebook Pages' },
    { key: 'googleAds', name: 'Google Ads' },
    { key: 'googleAnalyticsGA4', name: 'Google Analytics 4' },
    { key: 'googleAnalyticsGAU', name: 'Google Analytics Universal' },
    { key: 'googleDisplayAndVideo360', name: 'Google Display & Video 360' },
    { key: 'instagramPages', name: 'Instagram Pages' },
    { key: 'keywordPlanner', name: 'Keyword Planner' },
    { key: 'linkedinAds', name: 'LinkedIn Ads' },
    { key: 'linkedinPages', name: 'LinkedIn Pages' },
    { key: 'microsoftAds', name: 'Microsoft Ads' },
    { key: 'pinterestAds', name: 'Pinterest Ads' },
    { key: 'pinterestPages', name: 'Pinterest Pages' },
    { key: 'piwikPro', name: 'Piwik Pro' },
    { key: 'searchConsole', name: 'Search Console' },
    { key: 'seRankings', name: 'SE Rankings' },
    { key: 'snapchatAds', name: 'Snapchat Ads' },
    { key: 'snapchatPages', name: 'Snapchat Pages' },
    { key: 'stripe', name: 'Stripe' },
    { key: 'tiktokAds', name: 'TikTok Ads' },
    { key: 'tiktokPages', name: 'TikTok Pages' },
    { key: 'twitterX', name: 'Twitter (X)' },
    { key: 'xandr', name: 'Xandr' }
];
