import { useQuery } from '@tanstack/react-query';

const fetchReports = async (params = {}) => {
    const url = new URL(`${process.env.REACT_APP_API_URL}/powerBIReport/list`);

    // Construct query string from params
    Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));

    const response = await fetch(url.toString(), {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
    });
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const data = await response.json();

    return data;
};

const usePowerBIReportList = (params) => {
    return useQuery(['powerBIReport/list', params], () => fetchReports(params));
};

export default usePowerBIReportList;
