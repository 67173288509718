import { isNumeric } from '../utils';
import { Enum } from './enum';

export class DataSourceStatus extends Enum {
    static items = [];

    static unkown = new DataSourceStatus('unknown', -1);
    static unspecified = new DataSourceStatus('unspecified', 0);
    static authorize = new DataSourceStatus('authorize', 1);
    static pending = new DataSourceStatus('pending', 2);
    static remove = new DataSourceStatus('remove', 3);

    /**
     *
     */

    constructor(name, value) {
        super(name, value);

        DataSourceStatus.items.push(this);
    }

    /**
     *
     */

    equals(other) {
        return this.value === other.value && this.name === other.name;
    }

    /**
     *
     */

    static find(criteria) {
        if (isNumeric(criteria)) {
            return DataSourceStatus.items.find((v) => v.value === criteria);
        }

        return DataSourceStatus.items.find((v) => v.name === criteria);
    }

    /**
     *
     */

    static contains(criteria) {
        const status = DataSourceStatus.find(criteria);

        return !!status;
    }

    /**
     *
     */

    static get(criteria) {
        return DataSourceStatus.find(criteria) || DataSourceStatus.authorize;
    }
}
