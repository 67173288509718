import React from 'react';
import PropTypes from 'prop-types';

import { Box, Card, CardMedia, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
    cardRoot: {
        maxWidth: 80
    },
    media: {
        height: 80
    }
}));

export const ProductImageCardContainer = ({ imageUrls, enableThumbnails = false, activeIndex = 0, onImageClick }) => {
    const classes = useStyles();

    const withThumb = (imageUrl) => {
        const index = imageUrl.lastIndexOf('.');
        const urlWithThumb = imageUrl.slice(0, index) + '-thumb' + imageUrl.slice(index);

        return urlWithThumb;
    };

    const handleImageError = (e, imageUrl) => {
        e.target.onerror = null;
        e.target.src = imageUrl;
    };

    return (
        <React.Fragment>
            <Grid container>
                {imageUrls &&
                    imageUrls.map((imageUrl, index) => (
                        <Box
                            key={imageUrl}
                            onClick={() => !!onImageClick && onImageClick(index)}
                            borderColor={'black'}
                            borderRadius={5}
                            border={onImageClick && activeIndex === index ? 3 : 0}
                            padding={onImageClick && activeIndex === index ? 1 : 0}
                        >
                            <Card className={classes.cardRoot} variant='outlined'>
                                <CardMedia
                                    className={classes.media}
                                    component='img'
                                    image={(enableThumbnails && withThumb(imageUrl)) || imageUrl}
                                    onError={(e) => handleImageError(e, imageUrl)}
                                    title={imageUrl
                                        .split('/')
                                        .pop()
                                        .replace(/[#?].*$/, '')}
                                />
                            </Card>
                        </Box>
                    ))}
            </Grid>
        </React.Fragment>
    );
};

ProductImageCardContainer.propTypes = {
    imageUrls: PropTypes.array,
    activeIndex: PropTypes.number,
    enableThumbnails: PropTypes.bool,
    onImageClick: PropTypes.func
};
