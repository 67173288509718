import React, { useState } from 'react';
import BlockUi from 'react-block-ui';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';

import { useMutation } from '@apollo/client';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';

import { CREATE_USER } from 'graphql/mutations/user';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(1),
        zIndex: 0
    },
    textField: {
        margin: theme.spacing(1)
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column'
    }
}));

const validate = (values) => {
    const errors = {};
    if (!values.firstName) {
        errors.firstName = 'Required';
    }

    if (!values.lastName) {
        errors.lastName = 'Required';
    }

    if (!values.email) {
        errors.email = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address';
    }

    if (!values.phone) {
        errors.phone = 'Required';
    } else if (!/^[2-9]\d{7}$/i.test(values.phone)) {
        errors.phone = 'Write a correct norwegian number';
    }

    return errors;
};

const CardHeader = ({ children }) => {
    const { t } = useTranslation();

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}
        >
            <Typography color='primary' variant='h3'>
                {t('Users')}
            </Typography>
            <div style={{ display: 'flex' }}>{children}</div>
        </div>
    );
};

CardHeader.propTypes = {
    children: PropTypes.element.isRequired
};

const UserView = ({ users }) => {
    const classes = useStyles();

    const [open, setOpen] = useState(false);

    const [createUser, { loading }] = useMutation(CREATE_USER, {
        onCompleted: () => {
            setOpen(false);
        }
    });

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            phone: ''
        },
        validate,
        onSubmit: (values) => {
            createUser({ variables: { ...values } });
        }
    });

    return (
        <Card>
            <CardContent>
                <CardHeader>
                    <Button onClick={() => setOpen(!open)}>Add user</Button>
                    <Button onClick={() => setOpen(!open)}>Create user</Button>
                </CardHeader>
                <List>
                    {users.map(({ firstName, lastName }, i) => (
                        <ListItem button key={lastName + i}>
                            <Typography>
                                {firstName} {lastName}
                            </Typography>
                        </ListItem>
                    ))}
                </List>
            </CardContent>
            <Dialog onClose={() => setOpen(false)} open={open}>
                <BlockUi blocking={loading}>
                    <form onSubmit={formik.handleSubmit}>
                        <DialogTitle id='simple-dialog-title'>Create user</DialogTitle>
                        <DialogContent className={classes.dialogContent}>
                            <TextField
                                className={classes.textField}
                                error={formik.errors.firstName}
                                label='First name'
                                name='firstName'
                                onChange={formik.handleChange}
                                type='text'
                                value={formik.values.firstName}
                                variant='outlined'
                            />
                            <TextField
                                className={classes.textField}
                                error={formik.errors.lastname}
                                label='Last name'
                                name='lastName'
                                onChange={formik.handleChange}
                                value={formik.values.lastName}
                                variant='outlined'
                            />
                            <TextField
                                className={classes.textField}
                                error={formik.errors.email}
                                id='standard-required'
                                label='E-mail'
                                name='email'
                                onChange={formik.handleChange}
                                value={formik.values.email}
                                variant='outlined'
                            />
                            <TextField
                                className={classes.textField}
                                error={formik.errors.phone}
                                id='standard-required'
                                label='Phone'
                                name='phone'
                                onChange={formik.handleChange}
                                required
                                value={formik.values.phone}
                                variant='outlined'
                            />

                            <Typography>{formik.errors.firstName}</Typography>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={() => setOpen(false)}>Close</Button>
                            <Button type='submit'>Create</Button>
                        </DialogActions>
                    </form>
                </BlockUi>
            </Dialog>
        </Card>
    );
};

UserView.defaultProps = {
    users: []
};

UserView.propTypes = {
    users: PropTypes.arrayOf(PropTypes.object)
};

export default UserView;
