import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';

import { Grid } from '@material-ui/core';

import { adTypeModel } from 'common/models/advertisement';
import { AdTargeting } from 'common/modules/AdTargeting/AdTargeting';
import { useCampaignDispatch } from 'context/campaign/CampaignContext';
import { onlyUnique } from 'helpers/array';

const mergeGender = (memo, current) => {
    return current
        ? Array.prototype
              .concat(memo, current)
              .filter((value) => Object.keys(value).length !== 0)
              .filter(onlyUnique)
        : Array.prototype.concat(memo);
};

const mergeAgeGroup = (memo, current) => {
    const ageGroupArray = current
        ? Array.prototype.concat(memo, current).filter((value) => Object.keys(value).length !== 0)
        : Array.prototype.concat(memo);

    let smallestFrom;
    let highestTo;

    ageGroupArray.forEach((ageGroup) => {
        if (!smallestFrom) smallestFrom = ageGroup.from;
        if (!highestTo) highestTo = ageGroup.from;

        if (smallestFrom > ageGroup.from) smallestFrom = ageGroup.from;
        if (highestTo < ageGroup.to) highestTo = ageGroup.to;
    });

    return { from: smallestFrom, to: highestTo };
};

const uniqueLocation = (current, index, self) =>
    self.findIndex(
        (memo) =>
            memo.latitude === current.latitude && memo.longitude === current.longitude && memo.radius === current.radius
    ) === index;

const mergeLocations = (memo, current) => {
    return (current ? Array.prototype.concat(memo, current) : memo).filter(uniqueLocation);
};

const uniqueInterest = (current, index, self) =>
    self.findIndex((memo) => memo.key === current.key && memo.parentKey === current.parentKey) === index;

const mergeInterests = (memo, current) => {
    return (current ? Array.prototype.concat(memo, current) : memo).filter(uniqueInterest);
};

const uniqueKeywords = (current, index, self) => self.findIndex((memo) => memo.text === current.text) === index;

const mergeKeywords = (memo, current) => {
    return (current ? Array.prototype.concat(memo, current) : memo).filter(uniqueKeywords);
};

const reduceTargeting = (memo, [currentUid, current]) => {
    if (memo === undefined) {
        return {
            unitIds: currentUid ? [currentUid] : [],
            name: current?.name || '',
            age: current?.age ? cloneDeep(current?.age) : {},
            gender: current?.gender || '',
            locations: current?.locations ? cloneDeep(current?.locations) : [],
            interests: current?.interests ? cloneDeep(current?.interests) : [],
            excludeInterests: current?.excludeInterests ? cloneDeep(current?.excludeInterests) : [],
            status: current?.status || '',
            keywords: current?.keywords ? cloneDeep(current?.keywords) : [],
            excludeKeywords: current?.excludeKeywords ? cloneDeep(current?.excludeKeywords) : []
        };
    }

    return {
        unitIds: currentUid ? [...memo.unitIds, currentUid] : memo.unitIds,
        name: memo.name === current?.name ? memo.name : 'mixed',
        age: mergeAgeGroup(memo.age, current?.age),
        gender: mergeGender(memo.gender, current?.gender),
        locations: mergeLocations(memo.locations, current?.locations),
        interests: mergeInterests(memo.interests, current?.interests),
        excludeInterests: mergeInterests(memo.excludeInterests, current?.excludeInterests),
        status: memo.status === current?.status ? memo.status : 'mixed',
        keywords: mergeKeywords(memo.keywords, current?.keywords),
        excludeKeywords: mergeKeywords(memo.excludeKeywords, current?.excludeKeywords)
    };
};

export const CampaignAdTargeting = ({ ad, adType }) => {
    const dispatch = useCampaignDispatch();
    const targeting = Object.entries(ad.targeting).reduce(reduceTargeting, undefined);

    return (
        <Grid container>
            <Grid item xs>
                <AdTargeting dispatch={dispatch} targeting={targeting} adType={adType} />
            </Grid>
        </Grid>
    );
};

CampaignAdTargeting.propTypes = {
    ad: PropTypes.object,
    adType: PropTypes.shape(adTypeModel)
};
