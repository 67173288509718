import { updateAds } from './merge';

export const SETTINGS_ACTION_TYPE = {
    UPDATE_SCHEDULE: 'updateSchedule',
    UPDATE_BUDGET: 'updateBudget'
};

export const settingsReducer = (state, action) => {
    switch (action.type) {
        case SETTINGS_ACTION_TYPE.UPDATE_SCHEDULE: {
            return updateAds(state, 'schedule', () => action.schedule);
        }
        case SETTINGS_ACTION_TYPE.UPDATE_BUDGET: {
            return updateAds(state, 'budget', () => action.budget);
        }
        default:
            return state;
    }
};
