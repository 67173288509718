import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import {
    Grid,
    IconButton,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';

import { BlueExpansionPanel } from 'common/components';
import { usePowerBIReportGetWithPermissions } from 'hooks/powerBIReport';

import { UnitContext } from '../unit.provider';
import AddReport from './AddReport';

const useStyles = makeStyles((theme) => ({
    smallWidth: {
        width: '10%'
    },
    tableCell: {
        padding: '4px',
        fontSize: '12px',
        lineHeight: '12px'
    },
    tableIcon: {
        padding: '4px'
    },
    tableRow: {
        borderBottom: `1px solid ${theme.palette.divider}`
    }
}));

const ReportSettings = () => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const { editingUnit, setEditingUnit, setIsEdited } = useContext(UnitContext);

    const { data: pbReports } = usePowerBIReportGetWithPermissions({ unitId: editingUnit.id });

    const categorizedReports = pbReports?.reports?.reduce((acc, report) => {
        const category = report.defaultCategory;
        if (!acc[category]) {
            acc[category] = [];
        }

        acc[category].push(report);

        return acc;
    }, {});

    if (!editingUnit?.powerBIReportConfigs || !pbReports?.reports) return null;

    const editingUnitAddedReports = Object.keys(editingUnit.powerBIReportConfigs);

    return (
        <Grid container direction={'column'} spacing={4} style={{ maxWidth: 1152, padding: 40 }}>
            <Typography variant='h5' color='primary' style={{ marginBottom: 16 }}>
                {t('PowerAnalytics report settings')}
            </Typography>

            <Grid container justifyContent='space-between' style={{ marginBottom: 16 }}>
                <Grid item>
                    <Typography variant='h6' color='primary'>
                        {t('Reports')}
                    </Typography>
                </Grid>
                <Grid item>
                    <AddReport
                        existingReports={editingUnitAddedReports}
                        reportCategories={categorizedReports}
                        addReports={(newReports) => {
                            setEditingUnit((prev) => ({
                                ...prev,
                                powerBIReportConfigs: {
                                    ...prev.powerBIReportConfigs,
                                    ...newReports.reduce((acc, report) => {
                                        acc[report.id] = {};

                                        return acc;
                                    }, {})
                                }
                            }));

                            setIsEdited(true);
                        }}
                    />
                </Grid>
            </Grid>

            {Object.entries(categorizedReports).map((category) => {
                const hasActiveReportInCategory = category[1].some((report) =>
                    editingUnitAddedReports.some((rep) => rep === report.id)
                );

                if (!hasActiveReportInCategory) return null;

                return (
                    <BlueExpansionPanel
                        key={category[0]}
                        title={t(category[0])}
                        defaultExpanded
                        style={{ marginBottom: 16, overflow: 'auto' }}
                    >
                        <Table>
                            <TableHead>
                                <TableRow className={classes.tableRow}>
                                    <TableCell className={clsx(classes.tableCell)}>{t('Report name')}</TableCell>
                                    <TableCell className={clsx(classes.tableCell)} />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {category[1].map((report) => {
                                    const addedReport = editingUnitAddedReports.find((rep) => rep === report.id);

                                    if (!addedReport) return null;

                                    return (
                                        <TableRow key={`report-${report.id}`} className={classes.tableRow}>
                                            <TableCell className={clsx(classes.tableCell)}>
                                                {report.name[i18n.language] ?? report.name['en-US']}
                                            </TableCell>
                                            <TableCell
                                                align='right'
                                                className={clsx(classes.tableCell, classes.smallWidth)}
                                            >
                                                <IconButton
                                                    className={classes.tableIcon}
                                                    onClick={() => {
                                                        setEditingUnit((prev) => {
                                                            const { [report.id]: _, ...rest } =
                                                                prev.powerBIReportConfigs;

                                                            return {
                                                                ...prev,
                                                                powerBIReportConfigs: rest
                                                            };
                                                        });
                                                        setIsEdited(true);
                                                    }}
                                                >
                                                    <Delete />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </BlueExpansionPanel>
                );
            })}
        </Grid>
    );
};

export default ReportSettings;
