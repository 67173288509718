import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';

import { makeStyles } from '@material-ui/core';

import { tableIcons } from './TableIcons';

const useStyles = makeStyles((theme) => ({
    base: {
        fontSize: theme.typography.fontSize,
        letterSpacing: 0.25
    }
}));

export const Table = ({ options, columns, className, data, ...rest }) => {
    const { t } = useTranslation();
    const { base } = useStyles();

    return (
        <div className={`${base} ${className || ''}`}>
            <MaterialTable
                {...rest}
                columns={columns}
                data={data}
                icons={tableIcons}
                options={{
                    emptyRowsWhenPaging: false,
                    ...options
                }}
                localization={{
                    header: {
                        actions: t('Actions')
                    },
                    body: {
                        emptyDataSourceMessage: t('No records to display')
                    },
                    toolbar: {
                        searchTooltip: t('Search'),
                        searchPlaceholder: t('Search'),
                        addRemoveColumns: t('Add or remove columns'),
                        showColumnsTitle: t('Show columns'),
                        showColumnsAriaLabel: t('Show columns')
                    },
                    pagination: {
                        firstTooltip: t('First'),
                        labelDisplayedRows: '{from}-{to} ' + t('Of').toLowerCase() + ' {count}',
                        labelRowsSelect: t('Row(s)'),
                        lastTooltip: t('Last'),
                        nextTooltip: t('Next'),
                        previousTooltip: t('Previous')
                    }
                }}
            />
        </div>
    );
};

Table.propTypes = {
    options: PropTypes.object,
    className: PropTypes.string,
    numberOfSelectedRows: PropTypes.number,
    columns: PropTypes.any,
    data: PropTypes.any,
    localization: PropTypes.object
};
