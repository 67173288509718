import React from 'react';
import PropTypes from 'prop-types';

import { IconButton, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import StoreIcon from '@material-ui/icons/Store';
import { makeStyles } from '@material-ui/styles';
const useStyles = makeStyles((theme) => ({
    deleteIcon: {
        color: 'rgba(0, 0, 0, 0.38)'
    },
    unitsTypo: {
        marginLeft: theme.spacing(1),
        fontStyle: (props) => (!props.onRemove && 'italic') || 'normal',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '24px',
        color: theme.palette.text.secondary
    },
    storeIcon: {
        color: '#DADADA'
    },
    listItemSecondaryAction: {
        visibility: 'hidden'
    },
    listItem: {
        '&:hover $listItemSecondaryAction': {
            visibility: 'visible'
        }
    },
    listItemContainer: {
        backgroundColor: (props) => !props.onRemove && theme.palette.grey[50],
        display: 'flex',
        aligItem: 'center'
    }
}));

const SelectedListItems = ({ unit, onRemove }) => {
    const classes = useStyles({ onRemove });

    return (
        <ListItem key={unit.id} className={classes.listItemContainer} ContainerProps={{ className: classes.listItem }}>
            <ListItemIcon>
                <StoreIcon className={classes.storeIcon} />
            </ListItemIcon>

            <ListItemText primary={unit?.name} className={classes.unitsTypo} disableTypography />

            {onRemove && (
                <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
                    <IconButton size='small' edge='end' aria-label='delete' onClick={() => onRemove(unit.id)}>
                        <DeleteIcon fontSize='small' className={classes.deleteIcon} />
                    </IconButton>
                </ListItemSecondaryAction>
            )}
        </ListItem>
    );
};
SelectedListItems.propTypes = {
    unit: PropTypes.object,
    onRemove: PropTypes.func
};
export default SelectedListItems;
