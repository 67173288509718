import React from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@material-ui/core';

import { ResolutionSwitch } from 'common/components';
import { templateMediaDimensionModel } from 'common/models/advertisement';

const FormatFilter = ({ formats, selectedMedia, onChange }) => {
    return (
        <Grid container justify='flex-start' alignItems='center'>
            {/* the inner container for all checkboxes */}
            <Grid container item xs={8} s={6} wrap='wrap'>
                {/* the checkbox container itself */}
                {Object.entries(formats).map(([key, mediaDataObject]) => (
                    <ResolutionSwitch
                        key={`resolution-switch-${key}`}
                        name={mediaDataObject.name}
                        checked={selectedMedia.indexOf(key) > -1}
                        resolution={[
                            mediaDataObject.mediaDimensions[0].width,
                            mediaDataObject.mediaDimensions[0].height
                        ]}
                        onChecked={() => onChange(key)}
                    />
                ))}
            </Grid>
        </Grid>
    );
};

FormatFilter.propTypes = {
    formats: PropTypes.objectOf(PropTypes.shape(templateMediaDimensionModel)),
    selectedMedia: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func
};

export default FormatFilter;
