import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Grid, Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { StyledTab } from 'common/components/StyledTab/StyledTab';
import TabPanel from 'common/components/TabPanel/TabPanel';

const useStyles = makeStyles((theme) => ({
    tab: {
        border: `1px solid ${theme.palette.divider}`,
        borderBottom: 0,
        backgroundColor: theme.palette.background.paper,
        minHeight: theme.typography.pxToRem(48),
        paddingTop: theme.typography.pxToRem(6),
        paddingBottom: theme.typography.pxToRem(6),
        '&:not(:first-child)': {
            borderLeft: 0
        }
    },
    tabLabel: {
        fontWeight: theme.typography.fontWeightRegular,
        letterSpacing: '0.25px'
    }
}));

export const CampaignSectionTabs = ({ settings, creatives, targeting }) => {
    const classes = useStyles();

    const { t } = useTranslation();

    const [selectedTab, setSelectedTab] = useState(0);
    const handleChangeSelectedTab = (e, newIndex) => {
        e.preventDefault();
        setSelectedTab(newIndex);
    };

    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Tabs
                    value={selectedTab}
                    scrollButtons='auto'
                    onChange={handleChangeSelectedTab}
                    variant='standard'
                    orientation='horizontal'
                    indicatorColor='primary'
                >
                    <StyledTab
                        classes={{ root: classes.tab }}
                        tabLabelStyle={classes.tabLabel}
                        index={0}
                        showIcon={false}
                        centerLabel
                        enableBottomBorder={false}
                        color='primary'
                        tabLabel={t('Settings')}
                    />
                    <StyledTab
                        classes={{ root: classes.tab }}
                        tabLabelStyle={classes.tabLabel}
                        index={1}
                        showIcon={false}
                        centerLabel
                        enableBottomBorder={false}
                        color='primary'
                        tabLabel={t('Ads')}
                    />
                    <StyledTab
                        classes={{ root: classes.tab }}
                        tabLabelStyle={classes.tabLabel}
                        index={2}
                        showIcon={false}
                        centerLabel
                        enableBottomBorder={false}
                        color='primary'
                        tabLabel={t('Targeting')}
                    />
                </Tabs>
            </Grid>
            <Grid item xs={12}>
                <TabPanel value={selectedTab} index={0}>
                    {settings}
                </TabPanel>
                <TabPanel value={selectedTab} index={1}>
                    {creatives}
                </TabPanel>
                <TabPanel value={selectedTab} index={2}>
                    {targeting}
                </TabPanel>
            </Grid>
        </React.Fragment>
    );
};

CampaignSectionTabs.propTypes = {
    settings: PropTypes.node,
    creatives: PropTypes.node,
    targeting: PropTypes.node
};
