import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import {
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Tooltip,
    Typography
} from '@material-ui/core';
import { Info, RotateLeft } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

import { ProductImageCardContainer } from 'common/components';
import { fieldTypeModel } from 'common/models/product';

const useStyles = makeStyles((theme) => ({
    infoTooltip: {
        padding: theme.spacing(1.5),
        maxHeight: theme.spacing(6)
    },
    right: {
        marginLeft: 'auto'
    },
    fieldContainer: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1.5)
    }
}));

export const ProductField = ({
    value,
    fieldType,
    isLoading,
    productTypeField,
    defaultProductContentField,
    selectedProductContentField,
    handleOpenImageUploadDialog,
    handleChangeChecbox,
    handleChange,
    handleResetField
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    if (fieldType === fieldTypeModel.IMAGE) {
        return (
            <Grid item xs={12} className={classes.fieldContainer}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant='h5'>{productTypeField.name}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <ProductImageCardContainer imageUrls={value} enableThumbnails={true} />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            color='primary'
                            variant='contained'
                            disabled={isLoading}
                            onClick={() => {
                                handleOpenImageUploadDialog(productTypeField.name);
                            }}
                        >
                            {t('Upload or delete images')}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        );
    } else if (fieldType === fieldTypeModel.CHECKBOX) {
        return (
            <Grid container item xs={12} className={classes.fieldContainer}>
                <Grid item xs={6}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={value}
                                color='primary'
                                disabled={isLoading}
                                onChange={(e) => handleChangeChecbox(e, productTypeField.name)}
                                inputProps={{
                                    'aria-label': `${productTypeField.name} checkbox`
                                }}
                            />
                        }
                        label={productTypeField.name}
                    />
                </Grid>

                <Grid item className={classes.right}>
                    {typeof defaultProductContentField !== 'undefined' ? (
                        defaultProductContentField === selectedProductContentField ? (
                            <Tooltip title={t('Default value')} aria-label={t('Default value')}>
                                <div className={classes.infoTooltip}>
                                    <Info />
                                </div>
                            </Tooltip>
                        ) : (
                            <IconButton
                                onMouseDown={(e) => {
                                    handleResetField(e, productTypeField);
                                }}
                                disabled={isLoading}
                            >
                                <RotateLeft />
                            </IconButton>
                        )
                    ) : (
                        <div></div>
                    )}
                </Grid>
            </Grid>
        );
    } else
        return (
            <Grid item xs={12}>
                <TextField
                    label={productTypeField.name}
                    type={fieldType}
                    variant='filled'
                    disabled={isLoading}
                    InputLabelProps={{
                        shrink: true
                    }}
                    fullWidth
                    onChange={(e) => handleChange(e, productTypeField.name)}
                    value={value}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='end'>
                                {defaultProductContentField ? (
                                    defaultProductContentField === selectedProductContentField ? (
                                        <Tooltip
                                            title={t('Value generated from product')}
                                            aria-label={t('Value generated from product')}
                                        >
                                            <div className={classes.infoTooltip}>
                                                <Info />
                                            </div>
                                        </Tooltip>
                                    ) : (
                                        <IconButton
                                            onMouseDown={(e) => {
                                                handleResetField(e, productTypeField);
                                            }}
                                        >
                                            <RotateLeft />
                                        </IconButton>
                                    )
                                ) : (
                                    <div></div>
                                )}
                            </InputAdornment>
                        )
                    }}
                />
            </Grid>
        );
};

ProductField.propTypes = {
    value: PropTypes.any,
    fieldType: PropTypes.string,
    isLoading: PropTypes.bool,
    productTypeField: PropTypes.shape(fieldTypeModel),
    defaultProductContentField: PropTypes.any,
    selectedProductContentField: PropTypes.any,
    handleOpenImageUploadDialog: PropTypes.func,
    handleChangeChecbox: PropTypes.func,
    handleChange: PropTypes.func,
    handleResetField: PropTypes.func
};
