import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@material-ui/core';

export const SelectorButton = ({ Icon, onClick, text, color, ...props }) => (
    <Button size='small' variant='text' startIcon={<Icon style={{ color }} />} onClick={onClick} {...props}>
        {text}
    </Button>
);

SelectorButton.propTypes = {
    Icon: PropTypes.object,
    onClick: PropTypes.func,
    text: PropTypes.string,
    color: PropTypes.string
};
