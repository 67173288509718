import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Box, Grid, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';

import { UnitContext } from '../unit.provider';
import AddExternalReport from './AddExternalReport';
import ExternalReportRow from './ExternalReportRow';

const useStyles = makeStyles((theme) => ({
    mediumWidth: {
        width: '32%'
    },
    slimWidth: {
        width: '10%'
    },
    tableCell: {
        padding: '4px',
        fontSize: '12px',
        lineHeight: '12px'
    },
    tableRow: {
        borderBottom: `1px solid ${theme.palette.divider}`
    }
}));

function ExternalReportSettings() {
    const { t } = useTranslation();
    const classes = useStyles();
    const { editingUnit, setEditingUnit, setIsEdited } = useContext(UnitContext);

    return (
        <Box px={2} py={2}>
            <Grid container justifyContent='space-between' style={{ marginBottom: 16 }}>
                <Grid item>
                    <Typography variant='h6' color='primary'>
                        {t('External reports')}
                    </Typography>
                </Grid>
                <Grid item>
                    <AddExternalReport
                        onSave={(externalReport) => {
                            setIsEdited(true);
                            setEditingUnit((prev) => ({
                                ...prev,
                                externalReports: [
                                    ...(prev?.externalReports ? prev?.externalReports : []),
                                    externalReport
                                ]
                            }));
                        }}
                    />
                </Grid>
            </Grid>

            <Table>
                <TableHead>
                    <TableRow className={classes.tableRow}>
                        <TableCell className={clsx(classes.tableCell, classes.mediumWidth)}>
                            {t('Report name')}
                        </TableCell>
                        <TableCell className={clsx(classes.tableCell)}>{t('Report link')}</TableCell>
                        <TableCell className={clsx(classes.tableCell, classes.slimWidth)} />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {editingUnit?.externalReports?.map((report, i) => (
                        <ExternalReportRow
                            key={report.name + report.url + i}
                            report={report}
                            onEdit={(newReport) => {
                                setIsEdited(true);

                                setEditingUnit((prev) => {
                                    const newReports = [...prev.externalReports];
                                    newReports[i] = newReport;

                                    return { ...prev, externalReports: newReports };
                                });
                            }}
                            onDelete={() => {
                                setIsEdited(true);

                                setEditingUnit((prev) => {
                                    const newReports = [...prev.externalReports];
                                    newReports.splice(i, 1);

                                    return { ...prev, externalReports: newReports };
                                });
                            }}
                        />
                    ))}
                </TableBody>
            </Table>
        </Box>
    );
}

export default ExternalReportSettings;
