import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Checkbox, ListSubheader, makeStyles, TextField } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
    selector: {
        backgroundColor: grey[50],
        width: '100%',
        height: 56,
        '&.MuiFilledInput-underline:before': {
            borderBottomColor: grey[50]
        }
    },
    label: {
        color: theme.palette.text.hint
    },
    listItem: {
        textTransform: 'capitalize'
    },
    openTagsOverride: {
        '& .MuiAutocomplete-tag': {
            display: 'none'
        }
    },
    tagsOvveride: {
        position: 'absolute',
        bottom: '4px'
    },
    groupHeader: {
        textTransform: 'uppercase',
        letterSpacing: '1.5px',
        fontWeight: 500,
        fontSize: 10,
        lineHeight: '16px',
        color: theme.palette.text.secondary,
        cursor: 'pointer',
        top: -10,
        backgroundColor: theme.palette.white,
        zIndex: 10
    },
    listCheckbox: {
        marginLeft: theme.spacing(1)
    }
}));

/**
 * This is a multiselect filter list. It takes in an array of string values that acts as a key for a mapping list.
 * The mapping list has the shape [{identifier: {name: string, groupByIdentifier: "string"}}]
 */
export const DropdownListFilter = ({ values, setValues, mappingList, label }) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const entries = Object.entries(mappingList).sort(
        (a, b) =>
            b[1].groupByIdentifier &&
            a[1].groupByIdentifier &&
            a[1].groupByIdentifier.localeCompare(b[1].groupByIdentifier, i18n.language === 'no' ? 'nb' : i18n.language)
    );

    const onClickSubLine = (grouping) => {
        const checkedChildren = grouping.children.filter((child) => child.props['aria-selected']);
        const groupEntries = entries.filter((el) => el[1].groupByIdentifier === grouping.group).map((el) => el[0]);

        if (checkedChildren.length === grouping.children.length) {
            setValues(values.filter((el) => !groupEntries.includes(el)));
        } else {
            setValues([...new Set([...values, ...groupEntries])]);
        }
    };

    return (
        <Autocomplete
            /* Required */
            options={entries.map((entry) => entry[0])}
            renderInput={(params) => (
                <TextField
                    {...params}
                    className={classes.selector}
                    variant='filled'
                    label={label}
                    InputLabelProps={{ className: classes.label }}
                />
            )}
            /* Booleans */
            multiple
            openOnFocus
            disableCloseOnSelect
            /* Tags */
            limitTags={0}
            getLimitTagsText={(numTags) => `${numTags} ${t('Of').toLowerCase()} ${entries.length}`}
            classes={{ focused: classes.openTagsOverride, tag: classes.tagsOvveride }}
            /* Controlled values */
            value={values}
            onChange={(_, val) => setValues(val)}
            getOptionSelected={(option, val) => val === option}
            getOptionLabel={(option) => mappingList[option]?.name}
            /* Texts */
            noOptionsText={t('No records to display')}
            /* Grouping */
            groupBy={(option) => mappingList[option].groupByIdentifier}
            renderGroup={(grouping) => {
                const checkedChildren = grouping.children.filter((child) => child.props['aria-selected']);

                return (
                    <li key={grouping.key}>
                        <ListSubheader
                            component='div'
                            className={classes.groupHeader}
                            onClick={() => onClickSubLine(grouping)}
                        >
                            <Checkbox
                                checked={checkedChildren.length === grouping.children.length}
                                indeterminate={
                                    checkedChildren.length > 0 && checkedChildren.length < grouping.children.length
                                }
                            />
                            {grouping.group}
                        </ListSubheader>
                        <ul>
                            {grouping.children.map((child) => (
                                <li
                                    {...child.props}
                                    key={child.key}
                                    className={`MuiAutocomplete-option ${classes.listItem}`}
                                >
                                    <Checkbox
                                        checked={child.props['aria-selected']}
                                        color='primary'
                                        className={classes.listCheckbox}
                                    />
                                    {child.props.children}
                                </li>
                            ))}
                        </ul>
                    </li>
                );
            }}
        />
    );
};

DropdownListFilter.propTypes = {
    values: PropTypes.array.isRequired,
    setValues: PropTypes.func.isRequired,
    mappingList: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired
};
