const Keys = {
    Token: 'token',
    Authenticated: 'authenticated'
};

export const getUserToken = () => {
    const token = localStorage.getItem(Keys.Token);

    return token;
};

export const clearAuthKeys = () => {
    Object.values(Keys).forEach((key) => {
        localStorage.removeItem(key);
    });
};
