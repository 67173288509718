import { flatMap, has } from 'lodash';

import { fieldTypeModel } from 'common/models/product';

export function getFieldValue(product, productTypeField) {
    switch (productTypeField) {
        case fieldTypeModel.IMAGE:
            return product?.fields && product.fields[productTypeField.name];
        case fieldTypeModel.CHECKBOX:
            return (
                product?.fields &&
                typeof product?.fields[productTypeField.name] !== 'undefined' &&
                product.fields[productTypeField.name]
            );
        default:
            return (product?.fields && product.fields[productTypeField.name]) || '';
    }
}

export function getProductImages(product) {
    if (!has(product, 'fields')) {
        return;
    }
    const imageIdentifier = fieldTypeModel.IMAGE.toLowerCase();
    const imageFields = product.fields.filter((field) => field.fieldId.includes(imageIdentifier));
    const validImages = imageFields
        // ensure the parsed json is an object
        .map((imageField) => {
            const json = JSON.parse(imageField.dataJSON);
            if (typeof json === 'object') {
                return json;
            }

            return null;
        })
        // ensure all image urls are defined
        .filter((url) => !!url);

    return flatMap(validImages);
}
