import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

export const RouteWithLayout = (props) => {
    const {
        layout: Layout,
        component: Component,
        guarded,
        authenticated,
        currentPermissions,
        permitted,
        from,
        to,
        ...rest
    } = props;

    const doRedirect = guarded && !authenticated;
    let isPermitted = true;
    if (!doRedirect && permitted.length > 0 && currentPermissions) {
        isPermitted = !!currentPermissions.find((p) => permitted.indexOf(p.name) >= 0);
    }
    const render = (matchProps) => {
        if (doRedirect) {
            return <Redirect to='/sign-in' />;
        } else if (!isPermitted) {
            return <Redirect to='/settings' />;
        } else if (from && to) {
            return <Redirect to={to} />;
        } else {
            return (
                <Layout>
                    <Component {...matchProps} {...rest} />
                </Layout>
            );
        }
    };

    return <Route {...rest} render={(matchProps) => render(matchProps)} />;
};

RouteWithLayout.defaultProps = {
    to: '',
    from: '',
    currentPermissions: [],
    permitted: []
};

RouteWithLayout.propTypes = {
    to: PropTypes.string,
    from: PropTypes.string,
    component: PropTypes.any,
    layout: PropTypes.any.isRequired,
    path: PropTypes.string,
    guarded: PropTypes.any,
    authenticated: PropTypes.any,
    currentPermissions: PropTypes.array,
    permitted: PropTypes.array
};
