import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { Context as activeUnitContext } from 'context/units/activeUnitContext';

const useStyles = makeStyles(() => ({
    root: {
        height: '100%'
    },
    content: {
        height: '100%'
    }
}));

const EmptyWithActiveUnit = (props) => {
    const { children } = props;
    const classes = useStyles();
    const {
        state: { activeUnit }
    } = useContext(activeUnitContext);

    return (
        <div className={classes.root}>
            {activeUnit && children ? <main className={classes.content}>{children}</main> : <LinearProgress />}
        </div>
    );
};

EmptyWithActiveUnit.propTypes = {
    children: PropTypes.node
};

export default EmptyWithActiveUnit;
