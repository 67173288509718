import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Typography } from '@material-ui/core';

export const ResolutionBox = ({ leadingText, w, h }) => (
    <Grid container item xs alignItems='center' justify='space-between'>
        <Typography variant='subtitle1'>{leadingText}:</Typography>
        <code>{`${w}x${h}`}</code>
    </Grid>
);

ResolutionBox.propTypes = {
    leadingText: PropTypes.string,
    w: PropTypes.number,
    h: PropTypes.number
};
