import gql from 'graphql-tag';

export const ENTITY_STATUS_SUBSCRIPTION = gql`
    subscription {
        entityStatus {
            ads {
                id
                status
                servingStatus
                message
            }
            adSets {
                id
                status
                servingStatus
                message
            }

            campaigns {
                id
                status
                servingStatus
                message
            }

            campaignGroups {
                id
                status
                servingStatus
                message
            }
        }
    }
`;
