import React from 'react';
import PropTypes from 'prop-types';

import { Avatar } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

import { logoComponents } from 'helpers/campaign';

export const NetworkLogo = ({ networkName }) => {
    networkName = networkName.toLowerCase();
    const theme = useTheme();

    if (networkName in logoComponents) {
        const NetworkLogo = logoComponents[networkName];

        return <NetworkLogo />;
    } else {
        return (
            <Avatar
                aria-label='recipe'
                style={{
                    backgroundColor: theme.palette.background.grey,
                    width: theme.spacing(3),
                    height: theme.spacing(3)
                }}
            >
                {networkName.substring(0, 1).toUpperCase()}
            </Avatar>
        );
    }
};

NetworkLogo.propTypes = {
    networkName: PropTypes.string
};
