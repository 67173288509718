import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button, Checkbox, styled, Typography } from '@material-ui/core';

import { unitModel } from 'common/models';

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
}));

const StyledButton = styled(Button)(({ theme }) => ({
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
}));

const TreeItemContainer = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    margin: '8px 0',

    '& p': {
        flexGrow: 1
    }
}));

export const TreeItemContent = ({ onClick, unit, onSelectUnit, selectedUnits }) => {
    const { t } = useTranslation();

    return (
        <TreeItemContainer>
            <Typography variant='body2'>{unit.name}</Typography>
            {onSelectUnit ? (
                <StyledCheckbox
                    size='small'
                    color='primary'
                    onClick={(e) => e.stopPropagation()}
                    onChange={() => onSelectUnit(unit)}
                    checked={(selectedUnits.find((id) => unit.id === id) && true) || false}
                    inputProps={{ 'aria-label': `Checkbox ${unit.name}` }}
                />
            ) : (
                <StyledButton onClick={onClick} size='small' variant='outlined' color='primary'>
                    {t('Select')}
                </StyledButton>
            )}
        </TreeItemContainer>
    );
};

TreeItemContent.propTypes = {
    onClick: PropTypes.func,
    unit: PropTypes.shape(unitModel),
    selectedUnits: PropTypes.arrayOf(PropTypes.string),
    onSelectUnit: PropTypes.func
};
