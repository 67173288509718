import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button, styled } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

import { unitModel } from 'common/models';

const StyledButton = styled(Button)(({ theme }) => ({
    marginLeft: theme.spacing(0),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(4)
}));

const useStyles = makeStyles(() => ({
    unitSelectorLabel: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    }
}));

export const ActiveUnitButton = ({ onClick, activeUnit }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <StyledButton
            color='inherit'
            onClick={onClick}
            endIcon={<ArrowDropDown style={{ height: '24px', width: '24px' }} />}
        >
            <span className={classes.unitSelectorLabel}>
                {(activeUnit && activeUnit.name) || t('No units available')}
            </span>
        </StyledButton>
    );
};

ActiveUnitButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    activeUnit: PropTypes.shape(unitModel)
};
