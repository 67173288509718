import PropTypes from 'prop-types';

export const unitModel = {
    id: PropTypes.string,
    parentId: PropTypes.string,
    customerId: PropTypes.string,
    name: PropTypes.string,
    users: PropTypes.array,
    children: PropTypes.array,
    treeChildren: PropTypes.array,
    campaigns: PropTypes.array,
    campaignGroups: PropTypes.array,
    productFeeds: PropTypes.any,
    productTypes: PropTypes.any,
    linkExtension: PropTypes.any,
    __typeName: PropTypes.string
};
