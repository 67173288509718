import palette from './palette';

export default {
    h1: {
        color: palette.text.primary,
        fontSize: '96px',
        lineHeight: '112px',
        letterSpacing: '-1.5px'
    },
    h2: {
        color: palette.text.primary,
        fontSize: '60px',
        lineHeight: '72px',
        letterSpacing: '-0.5px'
    },
    h3: {
        color: palette.text.primary,
        fontSize: '48px',
        lineHeight: '56px'
    },
    h4: {
        color: palette.text.primary,
        fontSize: '34px'
    },
    h5: {
        color: palette.text.primary,
        fontSize: '24px',
        letterSpacing: '0.18px'
    },
    h6: {
        color: palette.text.primary,
        fontSize: '20px',
        lineHeight: '24px',
        letterSpacing: '0.15px'
    },
    subtitle1: {
        color: palette.text.primary,
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.15px'
    },
    subtitle2: {
        color: palette.text.secondary,
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0.1px'
    },
    body1: {
        color: palette.text.primary,
        fontSize: '16px',
        lineHeight: '24px'
    },
    body2: {
        color: palette.text.primary,
        fontSize: '14px',
        lineHeight: '20px'
    },
    button: {
        color: palette.text.primary,
        fontSize: '14px'
    },
    caption: {
        color: palette.text.secondary,
        fontSize: '11px',
        lineHeight: '16px'
    },
    overline: {
        color: palette.text.secondary,
        fontSize: '10px',
        lineHeight: '16px'
    }
};
