import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';

import { useMutation } from '@apollo/client';
import { Box, Button, List, ListItem, ListItemIcon, ListItemText, ListSubheader, Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { Alert } from '@material-ui/lab';

import { CHANGE_USER_PERMISSIONS_FOR_UNIT } from 'graphql/mutations/user';
import { logError } from 'helpers/error';

const useStyles = makeStyles((theme) => ({
    cancelButton: {
        marginRight: theme.spacing(3)
    },
    listHeader: {
        fontSize: 24
    },
    alert: {
        width: '100%',

        '& .MuiAlert-message': {
            width: '100%'
        }
    }
}));

export default function Permissions({ selectedUsers, unitId, refetch }) {
    const [disable] = useState(false);
    const classes = useStyles();
    const { t } = useTranslation();
    const [checked, setChecked] = useState({});
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        const permissionCount = selectedUsers.reduce(
            (acc, user) => {
                if (!user.userPermissions) return acc;

                const unitPermissionsForUser = user.userPermissions.find((userPer) => {
                    return userPer.unitId === unitId;
                });

                if (unitPermissionsForUser && unitPermissionsForUser.permissions) {
                    unitPermissionsForUser.permissions.forEach((permission) => {
                        acc[permission.name] += 1;
                    });
                }

                return acc;
            },
            {
                UNIT: 0
            }
        );

        setChecked((prev) => {
            const tmpChecked = { ...prev };

            Object.entries(permissionCount).forEach((count) => {
                let tmp = 0; // 0 = unchecked, 1 = checked, -1 = intermediate
                if (count[1] === selectedUsers.length) {
                    tmp = 1; // checked
                } else if (count[1] > 0 && count[1] < selectedUsers.length) {
                    tmp = -1; // intermediate
                }
                tmpChecked[count[0]] = tmp;
            });

            return tmpChecked;
        });
    }, [selectedUsers, unitId]);

    const handleChange = (e) => {
        setChecked({ ...checked, [e.target.value]: e.target.checked ? 1 : 0 });
    };

    const [updateUserPermissions] = useMutation(CHANGE_USER_PERMISSIONS_FOR_UNIT, {
        onCompleted: () => {
            enqueueSnackbar(<span>{'User Permissions Updated'}</span>, {
                variant: 'success'
            });
            refetch();
        },
        onError: (error) => logError(error)
    });

    const handleSave = () => {
        updateUserPermissions({
            variables: {
                input: {
                    unitId,
                    users: selectedUsers.map((user) => {
                        return {
                            userId: user.id,
                            roles: applyRoles(user.id)
                        };
                    })
                }
            }
        });
    };

    const applyRoles = (userId) => {
        // Setting permission * for the checked roles
        const roles = [];
        for (const role in checked) {
            if (checked[role] === 1) {
                roles.push({
                    name: role,
                    permission: '*'
                });
            } else if (checked[role] === -1) {
                const user = selectedUsers.find((user) => user.id === userId);
                const unitPermissionsForUser = user.userPermissions.find((userPer) => {
                    return userPer.unitId === unitId;
                });

                if (unitPermissionsForUser && unitPermissionsForUser.permissions) {
                    const matchedRole = unitPermissionsForUser.permissions.find(
                        (permission) => permission.name === role
                    );
                    if (matchedRole) {
                        roles.push(matchedRole);
                    }
                }
            }
        }

        return roles;
    };

    const handleCancel = () => {
        const permissionCount = selectedUsers.reduce(
            (acc, user) => {
                if (!user.userPermissions) return acc;

                const unitPermissionsForUser = user.userPermissions.find((userPer) => {
                    return userPer.unitId === unitId;
                });

                if (unitPermissionsForUser && unitPermissionsForUser.permissions) {
                    unitPermissionsForUser.permissions.forEach((permission) => {
                        acc[permission.name] += 1;
                    });
                }

                return acc;
            },
            {
                UNIT: 0
            }
        );

        setChecked((prev) => {
            const tmpChecked = { ...prev };

            Object.entries(permissionCount).forEach((count) => {
                let tmp = 0; // 0 = unchecked, 1 = checked, -1 = intermediate
                if (count[1] === selectedUsers.length) {
                    tmp = 1; // checked
                } else if (count[1] > 0 && count[1] < selectedUsers.length) {
                    tmp = -1; // intermediate
                }
                tmpChecked[count[0]] = tmp;
            });

            return tmpChecked;
        });
    };

    return (
        <Alert severity='info' className={classes.alert}>
            <Typography color='inherit' variant='h6'>
                {t('Permissions')}
            </Typography>

            <List disablePadding>
                <ListItem disableGutters>
                    <ListItemIcon>
                        <Checkbox checked color='primary' disabled />
                    </ListItemIcon>
                    <ListItemText>{t('Report access')}</ListItemText>
                </ListItem>
                <ListItem disableGutters>
                    <ListItemIcon>
                        <Checkbox
                            checked={checked['UNIT'] === 1}
                            indeterminate={checked['UNIT'] === -1}
                            onChange={handleChange}
                            name='units'
                            color='primary'
                            value={'UNIT'}
                            disabled={disable}
                        />
                    </ListItemIcon>
                    <ListItemText>{t('Account management')}</ListItemText>
                </ListItem>
            </List>

            <Box display='flex' width='100%' justifyContent='flex-end' pt={2}>
                <Button
                    color='primary'
                    startIcon={<CloseIcon />}
                    className={classes.cancelButton}
                    onClick={handleCancel}
                >
                    {t('Cancel')}
                </Button>
                <Button onClick={handleSave} variant='contained' color='primary'>
                    {t('Save')}
                </Button>
            </Box>
        </Alert>
    );
}

Permissions.propTypes = {
    selectedUsers: PropTypes.array,
    unitId: PropTypes.string,
    refetch: PropTypes.func
};
