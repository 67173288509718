import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { makeStyles, TableCell, TableHead, TableRow } from '@material-ui/core';

import { formattedMonths } from 'helpers/localeFormatter';

const useStyles = makeStyles((theme) => ({
    alignCell: {
        alignItem: 'center',
        minWidth: '250px'
    },
    blueHeader: {
        padding: theme.spacing(3),
        backgroundColor: theme.palette.primary[300]
    },
    blueCell: {
        backgroundColor: theme.palette.primary[300],
        color: 'white',
        padding: 0,
        borderLeft: `1px solid ${theme.palette.primary[400]}`,
        minWidth: '100px'
    }
}));

const MonthHeader = ({ minified }) => {
    const classes = useStyles();
    const { i18n } = useTranslation();
    const months = formattedMonths(i18n.language);

    return (
        <TableHead>
            <TableRow>
                {!minified && <TableCell className={`${classes.alignCell} ${classes.blueHeader}`} />}
                {months.map((month) => (
                    <TableCell key={month} align='center' className={classes.blueCell}>
                        {month}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};
MonthHeader.propTypes = {
    minified: PropTypes.bool
};

export default MonthHeader;
