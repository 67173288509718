import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Box, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

export const SingleMetricInfoBox = ({ label, value, icon, subtext, weight, formatOptions = {} }) => {
    const theme = useTheme();
    const { i18n } = useTranslation();

    return (
        <Box p={2} bgcolor={theme.palette.primary[weight]} color={theme.palette.primary.contrastText}>
            <Typography color='inherit' variant='subtitle1'>
                {label}
            </Typography>
            <Box display='flex' flexDirection='row' alignItems='center' width={1}>
                {icon}
                <Box pl={1}>{Number(value).toLocaleString(i18n.language, formatOptions)}</Box>
            </Box>
            <Typography color='inherit' variant='caption'>
                {subtext}
            </Typography>
        </Box>
    );
};

SingleMetricInfoBox.propTypes = {
    label: PropTypes.string,
    value: PropTypes.number,
    icon: PropTypes.element,
    subtext: PropTypes.string,
    weight: PropTypes.number,
    formatOptions: PropTypes.object
};
