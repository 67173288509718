export const updateAds = (state, key, callback) => {
    const campaign = state.campaigns[state.selectedCampaign];
    const ads = new Map(campaign.ads);

    for (const unit of campaign.units) {
        if (unit.selected) {
            const ad = campaign.ads.get(unit.id);
            ads.set(unit.id, key ? { ...ad, [key]: callback(ad[key], unit.id) } : callback(ad, unit.id));
        }
    }

    return {
        ...state,
        campaigns: [
            ...state.campaigns.slice(0, state.selectedCampaign),
            { ...campaign, ads },
            ...state.campaigns.slice(state.selectedCampaign + 1)
        ]
    };
};
