import { has } from 'lodash';

import { Facebook, Instagram, LinkedIn } from '@material-ui/icons';

import Google from 'assets/icons/Google';

export const CampaignType = Object.freeze({
    Shopping: 'Shopping',
    Promote: 'Promote',
    Search: 'Search',
    LeadGeneration: 'Lead Generation',
    ProductCatalogue: 'Product Catalogue',
    StoreVisits: 'Store Visits'
});

const IMAGE_CONSTRAINTS = Object.freeze({
    facebook: {
        image: {
            width: {
                minimum: 600,
                recommended: 1200
            },
            height: {
                minimum: 600,
                recommended: 628
            }
        },
        carousel: {
            width: {
                minimum: 600,
                recommended: 1080
            },
            height: {
                minimum: 600,
                recommended: 1080
            }
        },
        // where's the story implementation in the frontend..?
        story: {
            width: {
                minimum: 600,
                recommended: 1080
            },
            height: {
                minimum: 1067,
                recommended: 1920
            }
        }
    },
    google: {
        display: {
            // https://support.google.com/google-ads/editor/answer/57755?hl=en
            size: 150 * 1000 // max 150 KB
        }
    }
});

function getImageConstraints(adTypeName, adTypeNetwork) {
    if (has(IMAGE_CONSTRAINTS, adTypeNetwork)) {
        if (has(IMAGE_CONSTRAINTS[adTypeNetwork], adTypeName)) {
            return IMAGE_CONSTRAINTS[adTypeNetwork][adTypeName];
        }
    }

    return null;
}

export function getImageResolutionRequirements(adTypeName, adTypeNetwork) {
    const constraint = getImageConstraints(adTypeName, adTypeNetwork);

    if (has(constraint, 'width') && has(constraint, 'height')) {
        const min = [constraint.width.minimum, constraint.height.minimum];
        const rec = [constraint.width.recommended, constraint.height.recommended];

        return [min, rec];
    }
}

export function getImageSizeRequirements(adTypeName, adTypeNetwork) {
    const constraint = getImageConstraints(adTypeName, adTypeNetwork);

    if (has(constraint, 'size')) {
        return constraint.size;
    }
}

export const logoComponents = {
    facebook: Facebook,
    instagram: Instagram,
    google: Google,
    linkedin: LinkedIn
};

export function getAdTypeImage(network, adTypeName, withBackground, svg = false) {
    network = network.toLowerCase();
    adTypeName = adTypeName.toLowerCase();
    const fileType = svg ? 'SVG' : 'PNG';
    const subFolder = withBackground ? fileType : `${fileType}_nobackground`;

    const path = `/images/campaigns/${subFolder}/${network}/${adTypeName}.${fileType.toLowerCase()}`;

    return path;
}

export function resetCropperStorage() {
    window.localStorage.removeItem('croppedAreaPixels');
    window.localStorage.removeItem('cropSettings');
}
