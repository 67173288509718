import React from 'react';
import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types';

import { styled } from '@material-ui/core';

const StyledHistogram = styled('div')(() => ({
    position: 'relative',
    width: '100%'
}));

const Histogram = ({ data, highlight }) => {
    const trimData = data;
    trimData.pop();

    const barData = {
        labels: trimData.map((val, i) => i),
        datasets: [
            {
                categoryPercentage: 1,
                barPercentage: 1,
                backgroundColor: trimData.map((val, i) => {
                    //console.log(i, highlight[0]);
                    return i >= highlight[0] && i < highlight[1] ? '#4f98bd' : '#C8CED5';
                }),
                hoverBackgroundColor: '#4f98bd',
                data: trimData
            }
        ]
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        legend: { display: false },
        tooltips: { enabled: false },
        scales: {
            xAxes: [
                {
                    display: false
                }
            ],
            yAxes: [{ display: false }]
        }
    };

    return (
        <StyledHistogram>
            <Bar data={barData} options={options} />
        </StyledHistogram>
    );
};

Histogram.propTypes = {
    data: PropTypes.array,
    highlight: PropTypes.array
};

Histogram.defaultProps = {
    data: [],
    highlight: []
};

export default Histogram;
