import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Card, CardActionArea, IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: (props) =>
            (props.active && props.activeColor) ||
            (props.inactive && theme.palette.action.disabled) ||
            theme.palette.white,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        paddingLeft: theme.spacing(2),
        width: '100%',
        justifyContent: 'space-between',
        height: '100%'
    },
    infoContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
    },
    topInfoText: {
        fontSize: 10,
        display: 'flex',
        alignItems: 'center',
        fontWeight: 500,
        letterSpacing: 1.5,
        color: (props) => (props.active && theme.palette.white) || theme.palette.text.disabled,

        '& .MuiSvgIcon-root': {
            marginRight: theme.spacing(0.5)
        }
    },
    topMenuIcon: {
        color: (props) => (props.active && theme.palette.white) || 'inherit'
    },
    titleStyle: {
        color: (props) => (props.active && theme.palette.white) || props.titleColor || theme.palette.text.primary,
        margin: `${theme.spacing(-1.5)}px 0 ${theme.spacing(0.5)}px 0`,
        width: `calc(100% - ${theme.spacing(6)}px)`,
        lineClamp: 2,
        boxOrient: 'vertical',
        display: '-webkit-box',
        overflow: 'hidden'
    },
    subtitleStyle: {
        color: (props) => (props.active && theme.palette.white) || theme.palette.text.secondary,
        width: `calc(100% - ${theme.spacing(6)}px)`,
        marginBottom: theme.spacing(1.5)
    }
}));

/**
 * menuItems onClick functions should include event.stopPropagation()
 */
export const SelectorCard = ({
    active,
    activeColor,
    onClick,
    onInactiveClick,
    topIcon,
    topText,
    title,
    subtitle,
    titleColor,
    inactive,
    className,
    menuItems = []
}) => {
    const { root, infoContainer, topInfoText, topMenuIcon, titleStyle, subtitleStyle } = useStyles({
        active,
        activeColor,
        titleColor,
        inactive
    });
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(null);
    };

    return (
        <Card className={className}>
            <CardActionArea
                className={root}
                component='div'
                onClick={inactive && !active ? onInactiveClick : onClick}
                disableRipple
            >
                <div className={infoContainer}>
                    <span className={topInfoText}>
                        {topIcon} {topText}
                    </span>
                    <IconButton onClick={handleClick} aria-controls='selector-card-menu' aria-haspopup='true'>
                        <MoreVert className={topMenuIcon} />
                    </IconButton>
                    <Menu
                        id='selector-card-menu'
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        {menuItems.map(({ label, onClick }) => (
                            <MenuItem key={label} onClick={onClick}>
                                {label}
                            </MenuItem>
                        ))}
                    </Menu>
                </div>
                <Typography variant='h6' className={titleStyle}>
                    {title}
                </Typography>
                <Typography variant='body2' className={subtitleStyle}>
                    {subtitle}
                </Typography>
            </CardActionArea>
        </Card>
    );
};

SelectorCard.propTypes = {
    active: PropTypes.bool,
    activeColor: PropTypes.string,
    onClick: PropTypes.func,
    topIcon: PropTypes.element,
    topText: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    titleColor: PropTypes.string,
    inactive: PropTypes.bool,
    className: PropTypes.string,
    onInactiveClick: PropTypes.func,
    menuItems: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, onClick: PropTypes.func }))
};
