import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    paper: {
        //padding: theme.spacing(3, 2)
    },
    group: {
        margin: theme.spacing(1, 0)
    }
}));

const CheckboxFilter = ({ fieldId, items, setSelectedFilters }) => {
    const classes = useStyles();
    const [selected, setSelected] = useState([]);

    const handleMainClick = (e) => {
        if (e.target.checked) {
            setSelected((current) => [...current, e.target.name]);
        } else {
            setSelected((current) => current.filter((x) => x !== e.target.name));
        }
    };

    useEffect(() => {
        setSelectedFilters({ field: fieldId, criterion: { in: selected }, selected: selected });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected]);

    return (
        <div className={classes.root}>
            <Paper className={classes.paper} elevation={0}>
                <FormGroup>
                    {items &&
                        items.map((item, idx) => {
                            //const isChecked = filters.find(({ value }) => value === item.name) != undefined;
                            const isChecked = null;

                            return (
                                <div key={`check_${item.id}`}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name={item.name}
                                                color='primary'
                                                onChange={(e) => handleMainClick(e, idx)}
                                                checked={isChecked}
                                            />
                                        }
                                        label={`${item.name} (${item.count})`}
                                        key={'label_' + item.id}
                                    />
                                </div>
                            );
                        })}
                </FormGroup>
            </Paper>
        </div>
    );
};

CheckboxFilter.propTypes = {
    fieldId: PropTypes.any,
    setSelectedFilters: PropTypes.any,
    items: PropTypes.any
};

export default CheckboxFilter;
