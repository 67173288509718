import React, { useState } from 'react';

import { Grid, IconButton, Typography } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { makeStyles } from '@material-ui/styles';

import { ProductCard, ProductsToolbar } from './components';
import mockData from './data';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3)
    },
    content: {
        marginTop: theme.spacing(2)
    },
    pagination: {
        marginTop: theme.spacing(3),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    }
}));

const ProductList = () => {
    const classes = useStyles();

    const [products] = useState(mockData);

    return (
        <div className={classes.root}>
            <ProductsToolbar />
            <div className={classes.content}>
                <Grid container spacing={3}>
                    {products.map((product) => (
                        <Grid item key={product.id} lg={4} md={6} xs={12}>
                            <ProductCard product={product} />
                        </Grid>
                    ))}
                </Grid>
            </div>
            <div className={classes.pagination}>
                <Typography variant='caption'>1-6 of 20</Typography>
                <IconButton>
                    <ChevronLeftIcon />
                </IconButton>
                <IconButton>
                    <ChevronRightIcon />
                </IconButton>
            </div>
        </div>
    );
};

export default ProductList;
