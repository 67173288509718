import React, { useState } from 'react';
import BlockUi from 'react-block-ui';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';

import { useMutation } from '@apollo/client';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';

import { CREATE_CHILD_UNIT } from '../../../../graphql/mutations/units';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(1),
        zIndex: 0
    },
    textField: {
        margin: theme.spacing(1)
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column'
    }
}));

const CardHeader = ({ children }) => {
    const { t } = useTranslation();

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}
        >
            <Typography color='primary' variant='h3'>
                {t('Sub unit creation')}
            </Typography>
            <div style={{ display: 'flex' }}>{children}</div>
        </div>
    );
};

CardHeader.propTypes = {
    children: PropTypes.element
};

const SubUnitsCard = ({ currentUnit }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const { loading } = useMutation(CREATE_CHILD_UNIT);

    const formik = useFormik({
        initialValues: {
            parentId: currentUnit.id,
            unitId: '',
            name: '',
            address: '',
            orgNr: ''
        },
        onSubmit: () => {
            console.warn('NOT IMPLEMENTED');
        }
    });

    return (
        <Card>
            <CardContent>
                <CardHeader>
                    <Button onClick={() => setOpen(!open)}>Add Sub unit</Button>
                    <Button onClick={() => setOpen(!open)}>Create Sub-unit</Button>
                </CardHeader>
                <List>
                    {['sub-unit1', 'sub-unit2'].map((subUnit, i) => (
                        <ListItem button key={subUnit + i}>
                            {subUnit}
                        </ListItem>
                    ))}
                </List>
            </CardContent>
            <Dialog onClose={() => setOpen(false)} open={open}>
                <BlockUi blocking={loading}>
                    <form onSubmit={formik.handleSubmit}>
                        <DialogTitle id='simple-dialog-title'>Create user</DialogTitle>
                        <DialogContent className={classes.dialogContent}>
                            <TextField
                                variant='outlined'
                                label='First name'
                                type='text'
                                name='name'
                                onChange={formik.handleChange}
                                value={formik.values.name}
                                error={formik.errors.name}
                                className={classes.textField}
                            />
                            <Typography>{formik.errors.name}</Typography>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={() => setOpen(false)}>Close</Button>
                            <Button type='submit'>Create</Button>
                        </DialogActions>
                    </form>
                </BlockUi>
            </Dialog>
        </Card>
    );
};

SubUnitsCard.propTypes = {
    currentUnit: PropTypes.object
};

export default SubUnitsCard;
