import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Box, Button, Dialog, DialogContent, Grid, Typography } from '@material-ui/core';

const DeleteBudgetDialog = ({ open, onClose, onConfirm }) => {
    const { t } = useTranslation();

    return (
        <Dialog fullWidth={true} maxWidth='sm' open={open} onClose={onClose} aria-labelledby='budget-line-dialog'>
            <DialogContent>
                <Typography variant='h6'>{t('Delete budget')}</Typography>
                <Box my={2}>
                    <Grid container justifyContent='space-between' alignItems='center' spacing={2}>
                        <Grid item xs={3}>
                            <Button variant='contained' onClick={onClose}>
                                {t('Cancel')}
                            </Button>
                        </Grid>
                        <Grid item xs={9}>
                            <Box textAlign='right'>
                                <Button variant='contained' color='primary' onClick={onConfirm}>
                                    {t('Confirm')}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

DeleteBudgetDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onConfirm: PropTypes.func
};

export default DeleteBudgetDialog;
