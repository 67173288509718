import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Label from '@material-ui/icons/Label';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';

const useTreeItemStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.text.secondary,
        '&:focus > $content': {
            backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
            color: 'var(--tree-view-color)'
        }
    },
    content: {
        color: theme.palette.text.secondary,
        borderTopRightRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
        paddingRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightMedium,
        '$expanded > &': {
            fontWeight: theme.typography.fontWeightRegular
        }
    },
    group: {
        marginLeft: 0,
        '& $content': {
            paddingLeft: theme.spacing(2)
        }
    },
    expanded: {},
    label: {
        fontWeight: 'inherit',
        color: 'inherit'
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0.5, 0)
    },
    labelIcon: {
        marginRight: theme.spacing(1)
    },
    labelText: {
        fontWeight: 'inherit',
        flexGrow: 1
    }
}));

function StyledTreeItem(props) {
    const classes = useTreeItemStyles();
    const { labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, ...other } = props;

    return (
        <TreeItem
            classes={{
                root: classes.root,
                content: classes.content,
                expanded: classes.expanded,
                group: classes.group,
                label: classes.label
            }}
            label={
                <div className={classes.labelRoot}>
                    <LabelIcon className={classes.labelIcon} color='inherit' />
                    <Typography className={classes.labelText} variant='body2'>
                        {labelText}
                    </Typography>
                    <Typography color='inherit' variant='caption'>
                        {labelInfo}
                    </Typography>
                </div>
            }
            style={{
                '--tree-view-color': color,
                '--tree-view-bg-color': bgColor
            }}
            {...other}
        />
    );
}

StyledTreeItem.propTypes = {
    bgColor: PropTypes.string,
    color: PropTypes.string,
    labelIcon: PropTypes.elementType.isRequired,
    labelInfo: PropTypes.string,
    labelText: PropTypes.string.isRequired
};

const useStyles = makeStyles({
    root: {
        height: 264,
        flexGrow: 1,
        maxWidth: 300
    }
});

const UnitTreeView = ({ units, setCurrentUnit, handleShowCampaigns, handleShowUsers }) => {
    const classes = useStyles();

    const renderUnits = () =>
        units.map((unit, i) => (
            <StyledTreeItem
                key={unit.id}
                labelIcon={Label}
                labelText={unit.name}
                onClick={() => setCurrentUnit(unit)}
                nodeId={`root${unit.id}`}
            >
                <StyledTreeItem
                    labelIcon={AssignmentIcon}
                    labelInfo={String(unit.campaigns.length)}
                    labelText={'Campaigns'}
                    nodeId={String(`Campaings${i}`)}
                    onClick={handleShowCampaigns}
                />
                <StyledTreeItem
                    labelIcon={SupervisorAccountIcon}
                    labelInfo={String(unit.users.length)}
                    labelText={'Users'}
                    nodeId={`users${i}`}
                    onClick={handleShowUsers}
                />
                {unit.children}
            </StyledTreeItem>
        ));

    return (
        <TreeView
            className={classes.root}
            defaultCollapseIcon={<ArrowDropDownIcon />}
            defaultEndIcon={<div style={{ width: 24 }} />}
            defaultExpanded={['3']}
            defaultExpandIcon={<ArrowRightIcon />}
        >
            {renderUnits()}
        </TreeView>
    );
};

UnitTreeView.propTypes = {
    units: PropTypes.array,
    setCurrentUnit: PropTypes.func,
    handleShowCampaigns: PropTypes.func,
    handleShowUsers: PropTypes.func
};

export default UnitTreeView;
