import React, { useState } from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(5),
        overflowX: 'auto'
    },

    table: {
        minWidth: 650,
        marginBottom: '30px'
    },
    row: {
        display: 'flex'
    },

    clickable: {
        //backgroundColor: '#ccc',
        padding: theme.spacing(0.5),
        // borderRadius: '3px',
        cursor: 'pointer'
    },
    selectedTag: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main
    },
    paper: {
        padding: '2px 4px',
        //display: 'flex',
        alignItems: 'center',
        width: 400,
        marginBottom: theme.spacing(4)
    }
}));

const AdvertisersForm = () => {
    const classes = useStyles();
    const [filteredAdvertisers] = useState([]);
    const [selected, setSelected] = useState([]);

    // useEffect(() => {
    //     setFilteredAdvertisers(advertisers)
    //     // const uniqueTags = get_uniqe_tags()

    //     //setTags([...uniqueTags])
    //     // console.log('useEffect')
    // }, [])

    const handleCheckbox = (event, id) => {
        if (event.target.checked) {
            setSelected([...selected, id]);
        } else {
            setSelected(selected.filter((s) => s !== id));
        }
    };

    return (
        <div className={classes.paper}>
            {filteredAdvertisers.map((a) => {
                return (
                    <div key={a.id} className={classes.row}>
                        <Checkbox onClick={(e) => handleCheckbox(e, a.id)} />
                        <div>
                            <Typography>{a.name} </Typography>
                            {/* <div>{a.tags.map(t => <Tag clickable={false} >{t}</Tag>)}</div> */}
                        </div>
                    </div>
                );
            })}
            <div>
                Selected ids{' '}
                {selected.map((s) => (
                    <div key={s}>{s}</div>
                ))}
            </div>
        </div>
    );
};

export default AdvertisersForm;
