import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Box, Button, Dialog, DialogContent, Grid, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { unitModel } from 'common/models';

const BudgetEntryDialog = ({ text, open, close, handleSave, units }) => {
    const { t } = useTranslation();
    const [unit, setUnit] = useState(null);

    return (
        <Dialog fullWidth={true} maxWidth='sm' open={open} onClose={close} aria-labelledby='budget-line-dialog'>
            <DialogContent>
                <Grid container alignItems='center'>
                    <Typography variant='subtitle1'>{text}</Typography>

                    <Autocomplete
                        freeSolo
                        id='duplicate-to-unit-select'
                        disableClearable
                        fullWidth
                        options={units}
                        getOptionLabel={(unit) => unit.name}
                        onChange={(_, newUnit) => {
                            setUnit(newUnit.id);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t('Search for units')}
                                margin='normal'
                                variant='outlined'
                                InputProps={{ ...params.InputProps, type: 'search' }}
                            />
                        )}
                    />
                </Grid>
                <Box my={2}>
                    <Grid container justifyContent='space-between' alignItems='center'>
                        <Grid item xs={2}>
                            <Button variant='contained' onClick={close}>
                                {t('Cancel')}
                            </Button>
                        </Grid>
                        <Grid item xs={2}>
                            <Box textAlign='right'>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={() => {
                                        handleSave(unit);
                                    }}
                                >
                                    {t('Confirm')}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

BudgetEntryDialog.propTypes = {
    text: PropTypes.string,
    open: PropTypes.bool,
    close: PropTypes.func,
    handleSave: PropTypes.func,
    onChange: PropTypes.func,
    units: PropTypes.arrayOf(PropTypes.shape(unitModel))
};

export default BudgetEntryDialog;
