import React, { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';

import { useLazyQuery } from '@apollo/client';
import { Grid, makeStyles } from '@material-ui/core';

import 'i18n';

import { GET_POWER_BI_REPORT_CONFIG_FOR_UNIT } from 'graphql/queries/unit';

import './report.css';

const useStyles = makeStyles(() => ({
    reportContainer: {
        display: 'flex'
    },

    menuContainer: {
        display: 'flex',
        direction: 'column',
        alignSelf: 'flex-end'
    },

    popoverContainer: {
        marginRight: 20
    },

    listItemIcon: {
        width: 30,
        display: 'flex',
        justifyContent: 'center'
    }
}));

export const PowerBiSharedReport = () => {
    const classes = useStyles();
    const [isSuccess, setIsSuccess] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const { i18n } = useTranslation();

    const { enqueueSnackbar } = useSnackbar();
    const bookmarksManager = useRef({});
    const bookmarks = useRef({});
    const [reportConfig, setReportConfig] = useState({
        type: 'report',
        embedUrl: undefined,
        accessToken: undefined,
        tokenType: models.TokenType.Embed,
        settings: {
            panes: {
                filters: {
                    expanded: false,
                    visible: false
                }
            },
            navContentPaneEnabled: false,
            persistentFiltersEnabled: true,
            background: models.BackgroundType.Transparent,
            customLayout: {
                displayOption: models.DisplayOption.ActualSize
            },
            layoutType: models.LayoutType.Custom
        }
    });

    const [getUnitPowerBIReportConfig] = useLazyQuery(GET_POWER_BI_REPORT_CONFIG_FOR_UNIT, {
        onCompleted: (res) => {
            if (res?.getPowerBIReportConfig) {
                const { embeddedToken, bookmarks: savedBookmarks } = res.getPowerBIReportConfig;
                const { token, embedUrl, filters, isEditable, settings } = embeddedToken;

                if (savedBookmarks) {
                    for (const [key, value] of Object.entries(savedBookmarks)) {
                        bookmarks.current = { name: key, state: value };
                    }
                }

                let config = {
                    accessToken: token,
                    embedUrl,
                    filters: filters.map(
                        ({ schema, target, operator, values, filterType, requireSingleSelection }) => ({
                            $schema: schema,
                            target,
                            operator,
                            values,
                            filterType,
                            requireSingleSelection
                        })
                    ),
                    settings
                };

                if (isMobile) {
                    config = {
                        ...config,
                        settings: { ...config?.settings, layoutType: models.LayoutType.MobilePortrait }
                    };
                }

                if (isEditable) {
                    config = {
                        ...config,
                        permissions: models.Permissions.ReadWrite,
                        viewMode: models.ViewMode.Edit
                    };
                }

                setReportConfig((prev) => ({
                    ...prev,
                    ...config
                }));
                setIsSuccess(true);
            } else {
                setIsSuccess(false);
            }
            setIsLoading(false);
        }
    });

    const eventHandlersMap = new Map([
        [
            'loaded',
            async function () {
                /* eslint-disable-next-line */
                console.log('Report has loaded');

                try {
                    if (bookmarksManager.current) {
                        if (bookmarks?.current?.name) {
                            await bookmarksManager.current?.applyState(bookmarks?.current?.state);

                            enqueueSnackbar(`${bookmarks?.current?.name} is applied`, { variant: 'success' });
                        }

                        /* eslint-disable-next-line */
                        console.log('bookmark is applied');
                    }
                } catch (error) {
                    /* eslint-disable-next-line */

                    enqueueSnackbar('bookmark applicaiton has failed', { variant: 'error' });
                }
            }
        ],
        [
            'rendered',
            async function () {
                /* eslint-disable-next-line */
                console.log('Report has rendered');
            }
        ],
        [
            'error',
            function (event) {
                if (event) {
                    /* eslint-disable-next-line */
                    console.error(event.detail);
                }
            }
        ]
    ]);

    useEffect(() => {
        const report = new URL(document.location).searchParams?.get('report');

        if (report) {
            getUnitPowerBIReportConfig({
                variables: {
                    report,
                    mangled: true,
                    locale: i18n?.language
                }
            });
        }
    }, [getUnitPowerBIReportConfig, i18n?.language]);

    return isLoading ? (
        <p style={{ padding: 20 }}>Loading...</p>
    ) : (
        <Grid container key={'inner.container'} direction='column' className={classes.reportContainer}>
            {(isSuccess && (
                <PowerBIEmbed
                    embedConfig={reportConfig}
                    eventHandlers={eventHandlersMap}
                    cssClassName={'report-style-class'}
                    getEmbeddedComponent={(report) => {
                        if (report) {
                            bookmarksManager.current = report?.bookmarksManager;
                        }
                    }}
                />
            )) || <Redirect to='/not-found' />}
        </Grid>
    );
};

PowerBiSharedReport.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
            groupId: PropTypes.string,
            reportId: PropTypes.string,
            unitId: PropTypes.string
        })
    })
};
