import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { IconButton, makeStyles } from '@material-ui/core';
import { Edit } from '@material-ui/icons';

import EditReportDialog from './EditReportDialog';

const useStyles = makeStyles((theme) => ({
    tableIcon: {
        padding: '4px'
    }
}));

function EditReport({ report, units }) {
    const [open, setOpen] = useState(false);
    const classes = useStyles();

    return (
        <React.Fragment>
            <IconButton className={classes.tableIcon} onClick={() => setOpen(true)}>
                <Edit />
            </IconButton>
            {open && report ? <EditReportDialog report={report} onClose={() => setOpen(false)} units={units} /> : null}
        </React.Fragment>
    );
}

EditReport.propTypes = {
    report: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.shape({
            'da-DK': PropTypes.string,
            'de-DE': PropTypes.string,
            'en-US': PropTypes.string,
            'es-ES': PropTypes.string,
            'fr-FR': PropTypes.string,
            'nb-NO': PropTypes.string,
            'sv-SE': PropTypes.string
        }).isRequired,
        tags: PropTypes.arrayOf(PropTypes.string).isRequired,
        dataSources: PropTypes.arrayOf(PropTypes.string).isRequired,
        versions: PropTypes.objectOf(
            PropTypes.shape({
                powerBIWorkspaceId: PropTypes.string.isRequired,
                powerBIReportId: PropTypes.string.isRequired
            })
        ).isRequired,
        permission: PropTypes.shape({
            type: PropTypes.oneOf(['global', 'selective']).isRequired,
            excludedUnits: PropTypes.arrayOf(PropTypes.string),
            units: PropTypes.arrayOf(PropTypes.string)
        }).isRequired,
        defaultCategory: PropTypes.string.isRequired,
        internalNote: PropTypes.string,
        description: PropTypes.shape({
            'da-DK': PropTypes.string,
            'de-DE': PropTypes.string,
            'en-US': PropTypes.string,
            'es-ES': PropTypes.string,
            'fr-FR': PropTypes.string,
            'nb-NO': PropTypes.string,
            'sv-SE': PropTypes.string
        })
    }).isRequired,
    units: PropTypes.any
};

export default EditReport;
