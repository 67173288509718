import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Fab, Grid, makeStyles } from '@material-ui/core';
import { Add, FolderOpenOutlined } from '@material-ui/icons';
import { useTheme } from '@material-ui/styles';

import { SelectorCard } from 'common/components';

const useStyles = makeStyles((theme) => ({
    card: {
        margin: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(1)}px 0`,
        maxWidth: 352,
        width: 352,
        height: 128,

        '&:last-of-type': {
            marginRight: theme.spacing(4)
        }
    }
}));

export const TiledCardSelector = ({ list, activeCardIdentifier, onClickCard, onAdd, onCopy, onDelete }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { card } = useStyles();

    return (
        <Grid container alignItems='center'>
            {list.map((listItem) => (
                <SelectorCard
                    className={card}
                    key={listItem.id}
                    active={!!(activeCardIdentifier === listItem.id)}
                    activeColor={theme.palette.primary[400]}
                    onClick={() => onClickCard(listItem.id)}
                    topIcon={<FolderOpenOutlined />}
                    topText={`${listItem.adGroups[0]?.adType?.networks[0]?.name?.toUpperCase() ?? ''} ${
                        listItem.adGroups[0]?.adType?.name?.toUpperCase() ?? ''
                    }`}
                    title={`${listItem.adGroups[0]?.adType?.networks[0]?.name?.toUpperCase()} ${listItem.name} (${
                        listItem.adGroups[0]?.ads?.length ?? 1
                    } ads)`}
                    titleColor={theme.palette.primary[400]}
                    menuItems={[
                        {
                            label: t('Copy'),
                            onClick: (e) => {
                                e.stopPropagation();
                                onCopy(listItem.id);
                            }
                        },
                        {
                            label: t('Delete'),
                            onClick: (e) => {
                                e.stopPropagation();
                                onDelete(listItem.id);
                            }
                        }
                    ]}
                    subtitle={`${t('Spending')} ${
                        listItem?.ads?.values() &&
                        Array.from(listItem.ads.values()).reduce((acc, curr) => (acc += curr.budget), 0)
                    }`}
                />
            ))}
            <Fab color='primary' onClick={onAdd}>
                <Add />
            </Fab>
        </Grid>
    );
};

TiledCardSelector.propTypes = {
    list: PropTypes.array,
    activeCardIdentifier: PropTypes.string,
    onClickCard: PropTypes.func,
    onAdd: PropTypes.func,
    onDelete: PropTypes.func,
    onCopy: PropTypes.func
};
