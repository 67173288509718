import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';

import { locationModel } from 'common/models';
import { LocationSelector } from 'common/modules/LocationSelector/LocationSelector';
import { TARGETING_ACTION_TYPE } from 'context/campaign/targetingReducer';

export const LocationsSelectorTabPanel = ({ dispatch, locations }) => {
    return (
        <Grid container spacing={1}>
            <Grid item md xs={12}>
                <LocationSelector
                    locations={locations}
                    addLocation={(location) => dispatch({ type: TARGETING_ACTION_TYPE.ADD_LOCATION, location })}
                    updateLocation={(index, location) =>
                        dispatch({ type: TARGETING_ACTION_TYPE.UPDATE_LOCATION, index, location })
                    }
                    setRadius={(index, radius) => dispatch({ type: TARGETING_ACTION_TYPE.SET_RADIUS, index, radius })}
                    removeLocation={(index) => dispatch({ type: TARGETING_ACTION_TYPE.REMOVE_LOCATION, index })}
                />
            </Grid>
        </Grid>
    );
};

LocationsSelectorTabPanel.propTypes = {
    dispatch: PropTypes.func,
    locations: PropTypes.arrayOf(PropTypes.shape(locationModel))
};
