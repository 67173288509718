import React from 'react';

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    quoteContainer: {
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    quote: {
        backgroundColor: theme.palette.neutral,
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: 'url(/images/auth.jpg)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
    },
    quoteInner: {
        textAlign: 'center',
        flexBasis: '600px'
    },
    quoteText: {
        color: theme.palette.white
    }
}));

export const AuthQuote = () => {
    const classes = useStyles();

    return (
        <div className={classes.quote}>
            <div className={classes.quoteInner}>
                <Typography className={classes.quoteText} variant='h1'>
                    Be the change you want to see.
                </Typography>
                <div className={classes.person}>
                    <Typography className={classes.name} variant='body1'>
                        Mahatma Ghandi
                    </Typography>
                    <Typography className={classes.bio} variant='body2'>
                        Manager
                    </Typography>
                </div>
            </div>
        </div>
    );
};
