import gql from 'graphql-tag';

export const GET_TARGETING = gql`
    query getTargetings($unitId: ID!) {
        getTargetings(unitId: $unitId) {
            id
            name
            gender
        }
    }
`;

export const GET_TARGETING_TEMPLATE = gql`
    query getTargetings($unitId: ID!) {
        getTargetings(unitId: $unitId) {
            id
            name
            gender
            age {
                from
                to
            }
            locations {
                latitude
                longitude
                label
                radius
                address
            }
        }
    }
`;
