import gql from 'graphql-tag';

import {
    FRAGMENT_PBI_CONFIG_FILTER,
    FRAGMENT_PBI_CONFIG_SETTINGS,
    FRAGMENT_PBI_REPORTS
} from 'graphql/fragments/pbi.reports';

export const GET_FACEBOOK_PAGES_FOR_UNIT = gql`
    query getFacebookPages($input: GetFacebookPagesInput) {
        getFacebookPages(input: $input) {
            id
            name
            key
        }
    }
`;

export const GET_UNIT_DETAILS = gql`
    query unitDetails($id: ID!) {
        getUnitDetails(id: $id) {
            id
            name
            isDemo
            users {
                id
                firstName
                lastName
                email
                phone
                verified
                verify
                mfa {
                    status
                    secret
                    otpauthUrl
                }
                userPermissions {
                    unitId
                    permissions {
                        name
                        permission
                    }
                }
            }
        }
    }
`;

export const GET_POWER_BI_REPORT_CONFIG_FOR_UNIT = gql`
    query getPowerBIReportConfig(
        $mangled: Boolean!
        $report: String
        $id: ID
        $reportId: ID
        $groupId: ID
        $unitId: ID
        $locale: String
    ) {
        getPowerBIReportConfig(
            mangled: $mangled
            report: $report
            id: $id
            reportId: $reportId
            groupId: $groupId
            unitId: $unitId
            locale: $locale
        ) {
            embeddedToken {
                embedUrl
                token
                filters {
                    ...pbiConfigFilter
                }
                isEditable
                settings {
                    ...pbiConfigSettings
                }
            }
            bookmarks
        }
    }
    ${FRAGMENT_PBI_CONFIG_SETTINGS}
    ${FRAGMENT_PBI_CONFIG_FILTER}
`;
