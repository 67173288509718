import React from 'react';

import { SvgIcon } from '@material-ui/core';

export default function Google(props) {
    return (
        <SvgIcon {...props}>
            <path d='M11.25 0.470002C10.55 0.420002 9.82 0.580002 9.17 0.960002C7.41 1.97 6.81 4.21 7.82 5.96L15.16 18.66C16.17 20.42 18.41 21 20.17 20C21.92 19 22.5 16.75 21.5 15L14.18 2.3C13.54 1.2 12.43 0.560002 11.25 0.470002ZM5.82 5.76L0.5 15C0.17583 15.5556 0.00340262 16.1867 0 16.83C0 17.312 0.0949273 17.7892 0.279362 18.2344C0.463797 18.6797 0.734127 19.0843 1.07492 19.4251C1.41571 19.7659 1.82029 20.0362 2.26555 20.2206C2.71082 20.4051 3.18805 20.5 3.67 20.5C4.3133 20.498 4.94478 20.327 5.50115 20.0041C6.05752 19.6811 6.51923 19.2176 6.84 18.66V18.67L10 13.19C8.65 10.89 7.27 8.6 6.03 6.23C5.95 6.08 5.88 5.92 5.83 5.76H5.82ZM5.82 5.76L15.4 4L5.82 5.76Z' />
        </SvgIcon>
    );
}
