import React from 'react';
import PropTypes from 'prop-types';

import { Box, Checkbox, FormControlLabel, Switch, Tooltip } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export const ResolutionSwitch = ({ name, checkBox, checked, onChecked, resolution }) => {
    const tooltip = `Resolution: ${resolution[0]}x${resolution[1]}`;

    const Toggler = checkBox ? Checkbox : Switch;

    return (
        <Box
            m={1}
            px={2}
            border={checkBox && 1}
            borderColor={checkBox && checked ? 'primary.main' : grey[400]}
            borderRadius={checkBox && 25}
        >
            <Tooltip title={tooltip}>
                <FormControlLabel
                    control={<Toggler checked={checked} onChange={onChecked} name={`switch-${name}`} color='primary' />}
                    label={name}
                />
            </Tooltip>
        </Box>
    );
};

ResolutionSwitch.propTypes = {
    name: PropTypes.string,
    checkBox: PropTypes.bool,
    checked: PropTypes.bool,
    onChecked: PropTypes.func,
    resolution: PropTypes.arrayOf(PropTypes.number)
};
