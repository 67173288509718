import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { TextField } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: 0,
        borderColor: grey[100],
        padding: 0,
        maxHeight: theme.spacing(6),
        height: theme.spacing(6),
        '&:not(hover):not($disabled):not($cssFocused):not($error) $notchedOutline': {
            borderColor: grey[100]
        },
        '&:hover:not($disabled):not($cssFocused):not($error) $notchedOutline': {
            borderColor: grey[300]
        },
        '&$cssFocused $notchedOutline': {
            borderColor: theme.palette.primary.main
        }
    },
    outlined: {
        transform: 'translate(14px, 18px) scale(1)'
    },
    notchedOutline: {},
    cssFocused: {},
    error: {},
    disabled: {}
}));
const InterestSearch = ({ input, onChange }) => {
    const classes = useStyles();

    const { t } = useTranslation();

    return (
        <TextField
            id='interest-search'
            value={input}
            label={t('Search')}
            fullWidth
            InputLabelProps={{
                classes: {
                    outlined: classes.outlined,
                    root: classes.cssLabel,
                    focused: classes.cssFocused
                }
            }}
            InputProps={{
                classes: {
                    root: classes.root,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline
                }
            }}
            onChange={(event) => onChange(event.target.value)}
            className={classes.textField}
            margin='none'
            variant='outlined'
        />
    );
};

InterestSearch.propTypes = {
    input: PropTypes.string,
    onChange: PropTypes.func
};

export default InterestSearch;
