import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import equals from 'validator/es/lib/equals';
import isLength from 'validator/es/lib/isLength';

import { Button, Grid, IconButton, InputAdornment, Paper, TextField, Typography } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { makeStyles } from '@material-ui/styles';

// import { validateRecoverToken } from './validateRecoverToken';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        align: 'center'
    },
    grid: {
        height: '100%'
    },

    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    contentHeader: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: theme.spacing(5),
        paddingBototm: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    contentBody: {
        flexGrow: 1,
        display: 'flex',
        align: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('lg')]: {
            justifyContent: 'center'
        }
    },
    form: {
        paddingLeft: 75,
        paddingRight: 75,
        paddingBottom: 125,
        flexBasis: 700,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            marginBottom: theme.spacing(2)
        }
    },
    title: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5)
    },

    textField: {
        marginBottom: theme.spacing(3),
        fullWidth: true,
        background: 'rgba(33, 33, 33, 0.08)'
    },
    paper: {
        marginTop: theme.spacing(15),
        alignContent: 'center'
    },
    capsLockText: {
        color: ' #B00020',
        textAlign: 'left',
        marginTop: -theme.spacing(1),
        fontSize: '11px',
        marginLeft: theme.spacing(1.5)
    },

    resetButton: {
        marginTop: theme.spacing(4)
    }
}));

const ResetPasswordForm = (props) => {
    const { t } = useTranslation();

    const classes = useStyles();

    const [formState, setFormState] = useState({
        isValid: false,
        values: { confirmPassword: '' },
        touched: {},
        errors: {},
        match: {}
    });

    const [capsLockOn, setCapsLockOn] = useState(false);

    const handleCapsLock = (e) => {
        const capsLockOn = e.getModifierState('CapsLock');
        if (capsLockOn === true) {
            setCapsLockOn(true);
        } else {
            setCapsLockOn(false);
        }
    };

    const handleChange = (event) => {
        event.persist();

        setFormState((formState) => ({
            ...formState,
            values: {
                ...formState.values,
                [event.target.name]: event.target.value
            },
            touched: {
                ...formState.touched,
                [event.target.name]: true
            },
            errors: {
                ...formState.errors,
                [event.target.name]: !isLength(event.target.value, { min: 6, max: 128 })
            },
            match: {
                ...formState.errors,
                isEqual:
                    event.target.name === 'confirmPassword' && equals(formState.values.password, event.target.value)
            }
        }));
    };
    const handleReset = (event) => {
        event.preventDefault();
        props.onReset({
            password: formState.values.password,
            confirmPassword: formState.values.confirmPassword
        });
    };
    const hasError = (field) => (formState.touched[field] && formState.errors[field] ? true : false);
    useEffect(() => {
        const interval = setInterval(() => {
            if (formState.values.current) {
                setFormState(formState.values.current.value);

                clearInterval(interval);
            }
        }, 100);
    });

    const [showPassword, setShowPassword] = useState(true);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    const handleResetPassword = (event) => {
        event.preventDefault();
        props.onResetPassword({
            password: formState.values.password,
            confirmPassword: formState.values.confirmPassword
        });
    };

    const passwordNotMatching = !formState.match.isEqual && formState.values.confirmPassword.length > 0;

    return (
        <div className={classes.root}>
            <Grid container alignItems='center' justifyContent='center'>
                <Grid className={classes.content} item lg={4} xs={8} xl={4} md={6} sm={8}>
                    <div>
                        <Paper className={classes.paper} elevation={8}>
                            <div className={classes.content}>
                                <div className={classes.contentBody}>
                                    <Grid>
                                        <form className={classes.form} onSubmit={handleReset}>
                                            <Typography className={classes.title} variant='h2' align='center'>
                                                <div className={classes.title}>{t('Reset password')}</div>
                                            </Typography>

                                            <TextField
                                                className={classes.textField}
                                                error={hasError('password')}
                                                fullWidth={true}
                                                helperText={
                                                    formState.errors.password ? (
                                                        <span>
                                                            {t('Password must contain between 6 and 128 characters')}
                                                        </span>
                                                    ) : (
                                                        ''
                                                    )
                                                }
                                                label={t('Set password')}
                                                name='password'
                                                onChange={handleChange}
                                                value={formState.values.password}
                                                variant='filled'
                                                onKeyDown={handleCapsLock}
                                                type={showPassword ? 'password' : 'text'}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position='end'>
                                                            <IconButton
                                                                aria-label='toggle password visibility'
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                            >
                                                                {showPassword ? (
                                                                    <VisibilityIcon />
                                                                ) : (
                                                                    <VisibilityOffIcon />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                            <TextField
                                                className={classes.textField}
                                                error={hasError('confirmPassword')}
                                                // error={hasError('confirmPassword')}
                                                fullWidth
                                                helperText={
                                                    passwordNotMatching ? (
                                                        <span style={{ color: '#B00020' }}>
                                                            {t('Confirmed password are not matching')}
                                                        </span>
                                                    ) : (
                                                        ''
                                                    )
                                                }
                                                label={t('Confirm new password')}
                                                name='confirmPassword'
                                                onChange={handleChange}
                                                variant='filled'
                                                value={formState.values.confirmPassword}
                                                onKeyDown={handleCapsLock}
                                                type={showPassword ? 'password' : 'text'}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position='end'>
                                                            <IconButton
                                                                aria-label='toggle password visibility'
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                            >
                                                                {showPassword ? (
                                                                    <VisibilityIcon />
                                                                ) : (
                                                                    <VisibilityOffIcon />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />

                                            {capsLockOn && (
                                                <div className={classes.capsLockText}>{t('Caps Lock is on')}</div>
                                            )}

                                            <Button
                                                className={classes.resetButton}
                                                onClick={handleResetPassword}
                                                color='primary'
                                                size='large'
                                                type='submit'
                                                variant='contained'
                                            >
                                                {t('Reset')}
                                            </Button>
                                        </form>
                                    </Grid>
                                </div>
                            </div>
                        </Paper>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

ResetPasswordForm.propTypes = {
    onReset: PropTypes.object,
    onResetPassword: PropTypes.func
};

export default ResetPasswordForm;
