import React from 'react';
import PropTypes from 'prop-types';

import { keywordModel } from 'common/models';

import KeywordSelectorWrapper from '../KeywordSelectorWrapper';
import { KeywordSelectorTextField } from './KeywordSelectorTextField';

export const KeywordsSelector = ({ keywords, onAdd, onRemove, targetingConditionType }) => {
    const handleAddKeywords = (addedKeywords) => {
        const newKeywords = [];
        addedKeywords.forEach((item) => {
            const found = keywords?.some((el) => el.text.toLowerCase() === item.text.toLowerCase());
            if (!found) newKeywords.push(item);
        });
        onAdd(newKeywords);
    };

    return (
        <KeywordSelectorWrapper
            onRemove={onRemove}
            selectedItems={keywords}
            handleSelectionChange={handleAddKeywords}
            targetingConditionType={targetingConditionType}
        >
            <KeywordSelectorTextField
                targetingConditionType={targetingConditionType}
                onAddKeywords={handleAddKeywords}
            />
        </KeywordSelectorWrapper>
    );
};

KeywordsSelector.propTypes = {
    keywords: PropTypes.arrayOf(PropTypes.shape(keywordModel)),
    onAdd: PropTypes.func,
    onRemove: PropTypes.func,
    targetingConditionType: PropTypes.string
};
