import React, { useContext, useEffect, useState } from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    FormGroup,
    Grid,
    List,
    ListItem,
    ListSubheader,
    Typography
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import FacebookIcon from '@material-ui/icons/Facebook';
import { makeStyles } from '@material-ui/styles';

import { BlueHeaderContainer } from 'common/components/BlueHeaderContainer/BlueHeaderContainer';
import { Context as activeUnitContext } from 'context/units/activeUnitContext';

import { SearchPages } from '../SearchPages';
import HeaderConnections from './HeaderConnections';
import SelectPages from './SelectPages';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        '& .MuiListItem-root': {
            paddingTop: 0,
            paddingBottom: 0
        }
    },
    formGroup: {
        display: 'flex'
    },

    FacebookIcon: {
        marginRight: theme.spacing(1),
        color: theme.palette.action.active
    },

    search: {
        maxWidth: theme.spacing(42)
    },

    subContent: {
        marginTop: theme.spacing(7)
    },
    alignTextWithIcon: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: -theme.spacing(2.5)
    },
    backButton: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(2)
    },

    verticalLine: {
        borderRight: '2px solid #F0F0F0',
        overflow: 'hidden'
    },

    limitHeightOfList: {
        maxHeight: theme.spacing(50),
        overflow: 'scroll'
    },
    textLabel: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    }
}));

const AccountFBConnectedPages = ({ connectedPages, fbPages, responseFacebook, refetch }) => {
    const { t } = useTranslation();
    const {
        state: { units }
    } = useContext(activeUnitContext);
    const classes = useStyles();
    const history = useHistory();
    const [filters, setFilters] = useState({ searchText: '' });
    const [searchResults, setSearchResults] = useState({ connected: [], notConnected: [] });

    const [selectedPages, setSelectedPages] = useState([]);
    const handleSelectOne = (event, id) => {
        const selectedIndex = selectedPages.indexOf(id);
        let newSelectedPages = [];

        if (selectedIndex === -1) {
            newSelectedPages = newSelectedPages.concat(selectedPages, id);
        } else if (selectedIndex === 0) {
            newSelectedPages = newSelectedPages.concat(selectedPages.slice(1));
        } else if (selectedIndex === selectedPages.length - 1) {
            newSelectedPages = newSelectedPages.concat(selectedPages.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelectedPages = newSelectedPages.concat(
                selectedPages.slice(0, selectedIndex),
                selectedPages.slice(selectedIndex + 1)
            );
        }
        setSelectedPages(newSelectedPages);
    };

    const handleSelectAll = () => {
        if (selectedPages.length !== connectedPages.length + fbPages.length) {
            const pageIds = connectedPages.map((page) => page.id);
            const diconnectedPages = fbPages.map((fbPage) => fbPage.id);
            setSelectedPages(pageIds.concat(diconnectedPages));
        } else {
            setSelectedPages([]);
        }
    };

    const deselectPages = () => {
        setSelectedPages([]);
    };

    const handleGoBack = () => {
        history.push('/unit/plugins');
    };

    useEffect(() => {
        const isConnected = (pageId) => !!connectedPages.find((page) => page.key === pageId);
        if (filters.searchText) {
            setSearchResults({
                connected:
                    connectedPages.filter((page) =>
                        page.name?.toLowerCase().includes(filters.searchText?.toLocaleLowerCase())
                    ) || [],
                notConnected:
                    fbPages.filter((page) =>
                        page.name?.toLowerCase().includes(filters.searchText?.toLocaleLowerCase())
                    ) || []
            });
        } else {
            setSearchResults({
                connected: connectedPages,

                notConnected: fbPages.filter((page) => !isConnected(page.id))
            });
        }
    }, [filters, connectedPages, fbPages]);

    return (
        <div className={classes.root}>
            <Grid container spacing={1}>
                <Grid className={classes.content} item lg={12} xs={12} xl={12} md={12} sm={12}>
                    <HeaderConnections />
                    <div className={classes.subContent}>
                        <BlueHeaderContainer
                            title={'Facebook'}
                            titleLineAction={
                                <FacebookLogin
                                    appId='1702572803339249'
                                    fields='name,email,picture'
                                    scope='pages_manage_ads'
                                    auth_type='rerequest'
                                    version='8.0'
                                    render={(renderProps) => (
                                        <Button
                                            className={classes.connectButton}
                                            startIcon={<AddIcon />}
                                            size='small'
                                            color='primary'
                                            onClick={renderProps.onClick}
                                        >
                                            {t('Add page')}
                                        </Button>
                                    )}
                                    callback={responseFacebook}
                                />
                            }
                        >
                            <Grid container>
                                <Grid item xl={4} lg={4} md={4} sm={12} xs={12} className={classes.verticalLine}>
                                    <Button
                                        startIcon={<ArrowBackIcon />}
                                        onClick={handleGoBack}
                                        className={classes.backButton}
                                    >
                                        {t('Back')}
                                    </Button>
                                    <div className={classes.search}>
                                        <SearchPages
                                            onChange={(searchText) => {
                                                setFilters({ ...filters, searchText });
                                            }}
                                            value={filters.searchText}
                                        />
                                    </div>

                                    <div className={classes.alignTextWithIcon} style={{ marginLeft: 4 }}>
                                        <Checkbox
                                            checked={
                                                selectedPages &&
                                                selectedPages.length === connectedPages.length + fbPages.length
                                            }
                                            color='primary'
                                            indeterminate={
                                                selectedPages.length > 0 &&
                                                selectedPages.length < connectedPages.length + fbPages.length
                                            }
                                            onChange={handleSelectAll}
                                        />

                                        <Typography variant='subtitle2' color='textPrimary'>
                                            {t('Select all').toUpperCase()}
                                        </Typography>
                                    </div>
                                    <Divider />
                                    <FormGroup className={classes.formGroup}>
                                        <List className={classes.root}>
                                            {connectedPages.length > 0 && (
                                                <ListSubheader>{t('Connected pages')}</ListSubheader>
                                            )}
                                            <div className={classes.limitHeightOfList}>
                                                {connectedPages
                                                    .filter((page) =>
                                                        searchResults.connected.find((res) => res.id === page.id)
                                                    )
                                                    .map((page) => {
                                                        return (
                                                            <ListItem key={page.id}>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={
                                                                                selectedPages.indexOf(page.id) !== -1
                                                                            }
                                                                            color='primary'
                                                                            onChange={(event) =>
                                                                                handleSelectOne(event, page.id)
                                                                            }
                                                                        />
                                                                    }
                                                                />
                                                                <div
                                                                    className={`${classes.alignTextWithIcon} `}
                                                                    style={{ width: '100%' }}
                                                                >
                                                                    <FacebookIcon className={classes.FacebookIcon} />

                                                                    <Typography
                                                                        variant='subtitle2'
                                                                        color='textPrimary'
                                                                        className={classes.textLabel}
                                                                    >
                                                                        {page.name.toUpperCase()}
                                                                    </Typography>
                                                                </div>
                                                            </ListItem>
                                                        );
                                                    })}
                                            </div>
                                            {fbPages && fbPages.length > 0 && (
                                                <ListSubheader>{t('Not connected pages')}</ListSubheader>
                                            )}
                                            <div className={classes.limitHeightOfList}>
                                                {fbPages &&
                                                    fbPages
                                                        .filter((page) =>
                                                            searchResults.notConnected.find((res) => res.id === page.id)
                                                        )
                                                        .map((page) => {
                                                            return (
                                                                <ListItem key={page.id}>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                checked={
                                                                                    selectedPages.indexOf(page.id) !==
                                                                                    -1
                                                                                }
                                                                                color='primary'
                                                                                onChange={(event) =>
                                                                                    handleSelectOne(event, page.id)
                                                                                }
                                                                            />
                                                                        }
                                                                    />

                                                                    <div
                                                                        className={`${classes.alignTextWithIcon} `}
                                                                        style={{ width: '100%' }}
                                                                    >
                                                                        <FacebookIcon
                                                                            className={classes.FacebookIcon}
                                                                        />

                                                                        <Typography
                                                                            variant='subtitle2'
                                                                            color='textPrimary'
                                                                            className={classes.textLabel}
                                                                        >
                                                                            {page.name.toUpperCase()}
                                                                        </Typography>
                                                                    </div>
                                                                </ListItem>
                                                            );
                                                        })}
                                            </div>
                                        </List>
                                    </FormGroup>
                                </Grid>

                                <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                                    <div>
                                        {selectedPages.length > 0 && (
                                            <React.Fragment key={selectedPages.length}>
                                                <SelectPages
                                                    connected={connectedPages}
                                                    notConnected={fbPages}
                                                    selectedPages={selectedPages}
                                                    units={units}
                                                    deselectPages={deselectPages}
                                                    refetch={refetch}
                                                    setSelectedPages={setSelectedPages}
                                                />
                                            </React.Fragment>
                                        )}
                                    </div>
                                </Grid>
                            </Grid>
                        </BlueHeaderContainer>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};
AccountFBConnectedPages.propTypes = {
    fbPages: PropTypes.array,
    connectedPages: PropTypes.array,
    responseFacebook: PropTypes.func,
    refetch: PropTypes.func
};
export default AccountFBConnectedPages;
