import gql from 'graphql-tag';

export const GET_UNIT_CAMPAIGN_MAPPING_LIST = gql`
    query getUnitCampaignIdentifierMappingList($unitId: ID!) {
        getUnitCampaignIdentifierMappingList(unitId: $unitId) {
            id
            name
            identifier
            trackingId
            date
            createdBy
            unitId
        }
    }
`;
