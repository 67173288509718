import { useQuery } from '@tanstack/react-query';

const getEmbedConfig = async ({ reportId, versionNumber, unitId }) => {
    const url = new URL(
        `${process.env.REACT_APP_API_URL}/unit/${unitId}/powerBIReport/${reportId}/version/${versionNumber}/embedConfig`
    );

    const response = await fetch(url.toString(), {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
    });
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const data = await response.json();

    return data;
};

const useGetPowerBIReportEmbedConfigForUnit = (params) => {
    return useQuery(['unit/powerBIReport/version/embedConfig', params], () => getEmbedConfig(params), {
        enabled: !!params.reportId && !!params.versionNumber && !!params.unitId,
        refetchInterval: (data) => {
            if (!data?.embedConfig?.embedTokenExpiry) return false;

            const expiryDate = new Date(data.embedConfig.embedTokenExpiry).getTime();
            const expiryTime = expiryDate - Date.now();
            const adjustedExpiryTime = expiryTime - 5 * 60 * 1000; // Subtract 5 minutes (300,000 milliseconds)

            return adjustedExpiryTime > 0 ? adjustedExpiryTime : 0;
        }
    });
};

export default useGetPowerBIReportEmbedConfigForUnit;
