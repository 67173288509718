import React from 'react';

import { Box, Grid, Hidden } from '@material-ui/core';

import { BudgetSum } from 'common/components';
import { CAMPAIGN_ACTION_TYPE, reduceCampaign, useCampaign } from 'context/campaign/CampaignContext';
import NetworkContext from 'context/NetworkContext';

import { CampaignAdCreatives } from './components/CampaignAdCreatives/CampaignAdCreatives';
import { CampaignAdSettings } from './components/CampaignAdSettings/CampaignAdSettings';
import { CampaignAdTargeting } from './components/CampaignAdTargeting/CampaignAdTargeting';
import { CampaignSectionTabs } from './components/CampaignSectionTabs/CampaignSectionTabs';
import CampaignTopbar from './components/CampaignTopbar/CampaignTopbar';
import { TiledCardSelector } from './components/TiledCardSelector/TiledCardSelector';
import { UnitMultiSelector } from './components/UnitMultiSelector/UnitMultiSelector';

export const CampaignForm = () => {
    const {
        state: { name, availableUnits, campaigns, selectedCampaign: selectedCampaignIndex },
        dispatch
    } = useCampaign();

    if (typeof selectedCampaignIndex === 'undefined') {
        return null;
    }

    const selectedCampaign = reduceCampaign(campaigns[selectedCampaignIndex]);
    const unitsSelected = selectedCampaign.units.filter((unit) => unit.selected).length;

    return (
        <>
            <CampaignTopbar name={name} />
            <Box width={1} display='flex' padding={2} paddingBottom={20}>
                <Grid container>
                    <TiledCardSelector
                        list={campaigns}
                        activeCardIdentifier={selectedCampaign.id}
                        onClickCard={(id) => dispatch({ type: CAMPAIGN_ACTION_TYPE.SELECT_CAMPAIGN, campaignId: id })}
                        onAdd={() => dispatch({ type: CAMPAIGN_ACTION_TYPE.SELECT_CAMPAIGN, campaignId: undefined })}
                        onCopy={(id) => dispatch({ type: CAMPAIGN_ACTION_TYPE.COPY_CAMPAIGN, campaignId: id })}
                        onDelete={(id) => dispatch({ type: CAMPAIGN_ACTION_TYPE.DELETE_CAMPAIGN, campaignId: id })}
                    />
                    <Box width={1} marginY={2}>
                        <UnitMultiSelector units={selectedCampaign.units} availableUnits={availableUnits} />
                        {unitsSelected > 0 && (
                            <NetworkContext.Provider value={selectedCampaign.network}>
                                <Box marginY={2}>
                                    <CampaignSectionTabs
                                        settings={
                                            <CampaignAdSettings
                                                ad={selectedCampaign.ad}
                                                unitsSelected={unitsSelected}
                                            />
                                        }
                                        creatives={
                                            <CampaignAdCreatives
                                                ad={selectedCampaign.ad}
                                                adType={selectedCampaign.adGroups[0].adType}
                                                units={selectedCampaign.units}
                                                campaign={selectedCampaign}
                                            />
                                        }
                                        targeting={
                                            <CampaignAdTargeting
                                                ad={selectedCampaign.ad}
                                                adType={selectedCampaign.adGroups[0].adType}
                                            />
                                        }
                                    />
                                </Box>
                            </NetworkContext.Provider>
                        )}
                    </Box>
                </Grid>
                <Hidden smDown>
                    <Box marginLeft={2} minWidth={200} maxWidth={200}>
                        <BudgetSum campaigns={campaigns} currency='NOK' />
                    </Box>
                </Hidden>
            </Box>
        </>
    );
};
