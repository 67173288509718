import React from 'react';
import PropTypes from 'prop-types';

import { styled } from '@material-ui/core';

const trackHeight = 3;
const thumbHeight = 25;

const StyledTrack = styled('div')(({ left, width }) => ({
    backgroundColor: '#f86b23',
    height: `${trackHeight}px`,
    position: 'absolute',
    zIndex: 1,
    pointerEvents: 'none',
    left: left,
    width: width
}));

const StyledTrackHotSpot = styled('div')(({ left, width }) => ({
    height: `${thumbHeight}px`,
    top: `${thumbHeight * -0.5}px`,
    position: 'absolute',
    cursor: 'pointer',
    left: left,
    width: width
}));

const SliderRail = ({ source, target, getTrackProps }) => {
    const left = `${source.percent}%`;
    const width = `${target.percent - source.percent}%`;

    return (
        <>
            <StyledTrack left={left} width={width} />
            <StyledTrackHotSpot left={left} width={width} {...getTrackProps()} />
        </>
    );
};

SliderRail.propTypes = {
    source: PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        percent: PropTypes.number.isRequired
    }).isRequired,
    target: PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        percent: PropTypes.number.isRequired
    }).isRequired,
    getTrackProps: PropTypes.func.isRequired
};

export default SliderRail;
