import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button, ButtonGroup, Grid, InputLabel, Tooltip, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Warning } from '@material-ui/icons';

import { Genders } from 'helpers/targeting';

export const GenderSelector = ({ gender, setGender }) => {
    const isArray = Array.isArray(gender);
    const { t } = useTranslation();

    return (
        <Grid container spacing={1} alignItems='flex-end'>
            <Grid item>
                <InputLabel htmlFor='gender-selectors-label' style={{ marginBottom: '8px' }}>
                    <Typography variant='h6'>{t('Gender')}</Typography>
                </InputLabel>
                <ButtonGroup id='gender-selectors-label' color='primary' aria-label='primary button group'>
                    <Button
                        onClick={() => setGender(Genders.All)}
                        variant={(isArray ? gender.indexOf(Genders.All) !== -1 : gender === Genders.All) && 'contained'}
                    >
                        {t('All')}
                    </Button>
                    <Button
                        onClick={() => setGender(Genders.Female)}
                        variant={
                            (isArray ? gender.indexOf(Genders.Female) !== -1 : gender === Genders.Female) && 'contained'
                        }
                    >
                        {t('Female')}
                    </Button>
                    <Button
                        onClick={() => setGender(Genders.Male)}
                        variant={
                            (isArray ? gender.indexOf(Genders.Male) !== -1 : gender === Genders.Male) && 'contained'
                        }
                    >
                        {t('Male')}
                    </Button>
                </ButtonGroup>
            </Grid>
            <Grid item>
                <Tooltip
                    title={t(
                        'Warning: Changing these values will change the selected gender for all advertisers of this ad'
                    )}
                    aria-label={t(
                        'Warning: Changing these values will change the selected gender for all advertisers of this ad'
                    )}
                >
                    <Warning style={{ color: grey[600] }} />
                </Tooltip>
            </Grid>
        </Grid>
    );
};

GenderSelector.propTypes = {
    gender: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.oneOf([...[''], ...Object.values(Genders)])),
        PropTypes.string
    ]),
    setGender: PropTypes.func
};
