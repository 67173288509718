import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ScrollBar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';

import { Box, CircularProgress, Grid } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { interestModel } from 'common/models';
import { onlyUnique } from 'helpers/array';
import { TargetingConditionType } from 'helpers/targeting';
import useDebounce from 'hooks/useDebounce';

import InterestSearch from './InterestSearch';
import { InterestTreeItemComponent } from './InterestTreeItemComponent';
import { InterestTreeViewComponent } from './InterestTreeViewComponent';
import SelectedInterests from './SelectedInterest';

const useStyles = makeStyles((theme) => ({
    root: {
        border: 0,
        backgroundColor: grey[50],
        fontSize: theme.typography.fontSize
    },
    interestSearchContainer: {
        borderColor: '#FF0000',
        maxHeight: theme.spacing(6),
        height: theme.spacing(6)
    },
    treeViewContainer: {
        border: '1px solid',
        borderColor: grey[100],
        maxHeight: theme.spacing(54),
        height: theme.spacing(54)
    },
    selectedInterestContainer: {
        border: '1px solid',
        borderColor: grey[100],
        maxHeight: theme.spacing(30),
        height: theme.spacing(30)
    }
}));

const useTreeViewStyles = makeStyles((theme) => ({
    root: {
        paddingRight: theme.spacing(2)
    },
    selected: {
        backgroundColor: 'transparent'
    }
}));

export const InterestsSelector = ({
    networkInterests,
    networkInterestFlat,
    onSelectIncludeInterest,
    onRemoveIncludeInterest,
    onSelectExcludeInterest,
    onRemoveExcludeInterest,
    onRemoveAllInterests,
    isLoading,
    selectedIncludeInterests,
    selectedExcludeInterests
}) => {
    const MIN_SEARCH_TERM_LENGTH = 2;
    const SEARCH_DEBOUNCE_TIME = 250;

    const classes = useStyles();
    const treeViewClasses = useTreeViewStyles();
    let scrollBarRef = useRef(null);
    const [expanded, setExpanded] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [filteredNetworkInterests, setFilteredNetworkInterests] = useState(networkInterests);
    const debouncedSearchTerm = useDebounce(searchInput, SEARCH_DEBOUNCE_TIME);

    useEffect(() => {
        setFilteredNetworkInterests(networkInterests);
    }, [networkInterests]);

    const allSelectedIncludeInterests = useMemo(() => {
        const array = [];
        if (selectedIncludeInterests) selectedIncludeInterests.forEach((interest) => array.push(interest.key));
        if (selectedExcludeInterests) selectedExcludeInterests.forEach((interest) => array.push(interest.key));

        return array;
    }, [selectedIncludeInterests, selectedExcludeInterests]);

    const filterNetworkInterests = useCallback(() => {
        const newExpandedNetworkInterests = [];

        const getNodes = (result, object) => {
            if (object?.name?.toLowerCase().includes(debouncedSearchTerm?.toLowerCase().trim())) {
                result.push(object);

                return result;
            }
            if (Array.isArray(object.children)) {
                const children = object.children.reduce(getNodes, []);
                if (children.length) {
                    result.push({ ...object, children });
                    newExpandedNetworkInterests.push(object.key);
                }
            }

            return result;
        };
        const filteredNetworkInterests = networkInterests.reduce(getNodes, []).filter(onlyUnique);

        return { filteredNetworkInterests, newExpandedNetworkInterests };
    }, [debouncedSearchTerm, networkInterests]);

    useEffect(() => {
        if (debouncedSearchTerm?.length > MIN_SEARCH_TERM_LENGTH) {
            const { filteredNetworkInterests, newExpandedNetworkInterests } = filterNetworkInterests();
            setFilteredNetworkInterests(filteredNetworkInterests);
            setExpanded(newExpandedNetworkInterests);
        } else {
            if (filteredNetworkInterests.length !== networkInterests.length) {
                setFilteredNetworkInterests(networkInterests);
                setExpanded([]);
            }
        }
    }, [debouncedSearchTerm, filterNetworkInterests, filteredNetworkInterests.length, networkInterests]);

    const handleToggle = (event, nodeIds) => {
        setExpanded(nodeIds);
    };

    const handleSelectIncludeInterest = (event, item) => {
        event.preventDefault();
        const selectedExcludeInterestsIndex = selectedExcludeInterests?.findIndex(
            (interest) => interest.key === item.key
        );

        if (selectedExcludeInterestsIndex > -1) onRemoveExcludeInterest(item.key);
        if (!selectedIncludeInterests?.some((interest) => interest.key === item.key)) {
            onSelectIncludeInterest(item);
        } else onRemoveIncludeInterest(item.key);
    };

    const handleSelectExcludeInterest = (event, item) => {
        event.preventDefault();
        const selectedIncludeInterestsIndex = selectedIncludeInterests?.findIndex(
            (interest) => interest.key === item.key
        );

        if (selectedIncludeInterestsIndex > -1) onRemoveIncludeInterest(item.key);
        if (!selectedExcludeInterests?.some((interest) => interest.key === item.key)) {
            onSelectExcludeInterest(item);
        } else onRemoveExcludeInterest(item.key);
    };

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={6}>
                    <div className={classes.interestSearchContainer}>
                        <InterestSearch input={searchInput} onChange={setSearchInput} />
                    </div>
                    <div className={classes.treeViewContainer}>
                        <ScrollBar
                            options={{ wheelSpeed: 0.2, wheelPropagation: false }}
                            ref={(ref) => {
                                scrollBarRef = ref;
                            }}
                        >
                            {isLoading ? (
                                <Box display='flex' width='auto' height='100%'>
                                    <Box m='auto'>
                                        <CircularProgress size={48} />
                                    </Box>
                                </Box>
                            ) : (
                                <InterestTreeViewComponent
                                    expanded={expanded}
                                    onNodeToggle={handleToggle}
                                    classes={{ root: treeViewClasses.root }}
                                    defaultCollapseIcon={<ExpandMoreIcon />}
                                    defaultExpandIcon={<ChevronRightIcon />}
                                    selected={allSelectedIncludeInterests}
                                    updateScroll={() => {
                                        scrollBarRef.updateScroll();
                                    }}
                                >
                                    {filteredNetworkInterests &&
                                        filteredNetworkInterests.map((interest) => (
                                            <InterestTreeItemComponent
                                                key={`treeRootItem_${interest.parentKey}_${interest.key}`}
                                                handleSelectIncludeInterest={handleSelectIncludeInterest}
                                                handleSelectExcludeInterest={handleSelectExcludeInterest}
                                                interest={interest}
                                                selectedIncludeInterests={selectedIncludeInterests.map(
                                                    (interest) => interest.key
                                                )}
                                                onRemoveIncludeInterest={onRemoveIncludeInterest}
                                                selectedExcludeInterests={selectedExcludeInterests.map(
                                                    (interest) => interest.key
                                                )}
                                                onRemoveExcludeInterest={onRemoveExcludeInterest}
                                            />
                                        ))}
                                </InterestTreeViewComponent>
                            )}
                        </ScrollBar>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    {isLoading ? (
                        <Box display='flex' width='auto' height='100%'>
                            <Box m='auto'>
                                <CircularProgress size={48} />
                            </Box>
                        </Box>
                    ) : (
                        <React.Fragment>
                            <div className={classes.selectedInterestContainer}>
                                <SelectedInterests
                                    selectedInterests={selectedIncludeInterests.map((selected) => selected.key)}
                                    networkInterests={networkInterests}
                                    networkInterestFlat={networkInterestFlat}
                                    targetingType={TargetingConditionType.Include}
                                    onRemove={onRemoveIncludeInterest}
                                    onRemoveAll={() => onRemoveAllInterests(TargetingConditionType.Include)}
                                />
                            </div>
                            <div className={classes.selectedInterestContainer}>
                                <SelectedInterests
                                    selectedInterests={selectedExcludeInterests.map((selected) => selected.key)}
                                    networkInterests={networkInterests}
                                    networkInterestFlat={networkInterestFlat}
                                    targetingType={TargetingConditionType.Exclude}
                                    onRemove={onRemoveExcludeInterest}
                                    onRemoveAll={() => onRemoveAllInterests(TargetingConditionType.Exclude)}
                                />
                            </div>
                        </React.Fragment>
                    )}
                </Grid>
            </Grid>
        </div>
    );
};

InterestsSelector.propTypes = {
    selectedIncludeInterests: PropTypes.arrayOf(PropTypes.shape(interestModel)),
    selectedExcludeInterests: PropTypes.arrayOf(PropTypes.shape(interestModel)),
    networkInterestFlat: PropTypes.arrayOf(PropTypes.shape(interestModel)),
    networkInterests: PropTypes.arrayOf(PropTypes.shape(interestModel)),
    isLoading: PropTypes.bool,
    onRemoveAllInterests: PropTypes.func,
    onSelectIncludeInterest: PropTypes.func,
    onRemoveIncludeInterest: PropTypes.func,
    onSelectExcludeInterest: PropTypes.func,
    onRemoveExcludeInterest: PropTypes.func
};
