import { useQuery } from '@tanstack/react-query';

const fetchReportsForUnit = async (unitId) => {
    const url = new URL(`${process.env.REACT_APP_API_URL}/powerBIReport/unit/${unitId}/list`);

    const response = await fetch(url.toString(), {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
    });
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const data = await response.json();

    return data;
};

const usePowerBIReportGetReportsForUnit = ({ unitId }) => {
    return useQuery(['powerBIReport/list', 'unit', unitId], () => fetchReportsForUnit(unitId), { enabled: !!unitId });
};

export default usePowerBIReportGetReportsForUnit;
