import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';

import { Grid, IconButton, InputAdornment, TextField } from '@material-ui/core';
import { RotateLeft } from '@material-ui/icons';

import { validURL } from 'helpers/url';

export const ItemFieldWrapper = ({ fieldName, children }) => (
    <Grid item xs={12} key={`item-field-${fieldName}`}>
        {children}
    </Grid>
);

ItemFieldWrapper.propTypes = {
    fieldName: PropTypes.string,
    children: PropTypes.element
};

export const CreativeContentTextField = ({
    fieldName,
    maxLength,
    value,
    tempValue,
    hasProduct,
    dispatch,
    dispatchType,
    onChange,
    required,
    onReset
}) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    // ensure safe string operations
    value = value ? value.toString() : '';
    tempValue = tempValue ? tempValue.toString() : '';

    // enforce 1000 as hard max input length
    maxLength = maxLength || 1000;

    let error = value.length > maxLength || (required && value.length === 0);

    if (['url', 'link'].some((urlKey) => fieldName.includes(urlKey))) {
        error = (value.length > 0 && !validURL(value)) || (required && value.length === 0);
    }

    const currentInputLength = `${value.length}/${maxLength}`;
    const helperText = value.length > 0 ? currentInputLength : t('No input');

    const handleEnterPress = (e) => {
        if (!e.shiftKey && (e.key === 'Enter' || e.keyCode === 13)) {
            enqueueSnackbar('Use SHIFT + ENTER to insert new lines', { variant: 'info' });
            document.activeElement.blur();
        }
    };

    return (
        <TextField
            label={t(`backend.adTypes.${fieldName}`)}
            type='text'
            variant='filled'
            InputLabelProps={{
                shrink: true
            }}
            fullWidth
            multiline
            maxLength={maxLength}
            error={error}
            helperText={helperText}
            onChange={(e) => onChange(e, fieldName)}
            onBlur={(e) => !error && dispatch(dispatchType, fieldName, e.target.value)}
            onKeyDown={handleEnterPress}
            value={value}
            InputProps={{
                endAdornment: hasProduct && (
                    <InputAdornment position='end'>
                        {tempValue !== value && (
                            <IconButton onMouseDown={onReset}>
                                <RotateLeft />
                            </IconButton>
                        )}
                    </InputAdornment>
                )
            }}
        />
    );
};

CreativeContentTextField.propTypes = {
    fieldName: PropTypes.string.isRequired,
    maxLength: PropTypes.number,
    value: PropTypes.string,
    tempValue: PropTypes.string,
    hasProduct: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    dispatchType: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onReset: PropTypes.func,
    required: PropTypes.bool
};
