import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Grow, IconButton, Tooltip, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Cancel } from '@material-ui/icons';
import { TreeItem } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';

import { interestModel } from 'common/models';

const useStyles = makeStyles((theme) => ({
    root: {
        '&$selected > $content $label': {
            color: theme.palette.primary.main,
            backgroundColor: 'transparent'
        }
    },
    content: {},
    group: {},
    expanded: {},
    selected: {},
    label: {
        backgroundColor: 'transparent !important',
        cursor: 'default'
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center'
    },
    notSelectedInterest: {
        color: grey[400]
    },
    headerContainer: {
        border: '1 solid',
        borderColor: grey[100],
        marginLeft: theme.spacing(2),
        flexGrow: 1
    },
    iconButton: {
        marginRight: theme.spacing(1)
    }
}));

export const SelectedInterestTreeItemComponent = ({ interest, onRemove }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <TreeItem
            key={interest.key}
            nodeId={interest.key}
            label={
                <Grow in={true} appear={true}>
                    <div className={classes.labelRoot}>
                        <div className={classes.headerContainer}>
                            <Typography
                                variant='body2'
                                className={!interest.selected ? classes.notSelectedInterest : ''}
                            >
                                {interest?.name}
                            </Typography>
                        </div>
                        <div className={classes.iconButton}>
                            {interest.selected && (
                                <Tooltip title={t('Remove')}>
                                    <IconButton
                                        color='default'
                                        onClick={() => onRemove(interest.key)}
                                        size='small'
                                        aria-label='remove interest'
                                    >
                                        <Cancel />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </div>
                    </div>
                </Grow>
            }
            classes={{
                root: classes.root,
                content: classes.content,
                expanded: classes.expanded,
                selected: classes.selected,
                group: classes.group,
                label: classes.label
            }}
        >
            {interest.children &&
                interest.children.length > 0 &&
                interest.children.map((childItem) => (
                    <SelectedInterestTreeItemComponent
                        key={'childTreeItem_' + childItem.parentKey + '_' + childItem.key}
                        interest={childItem}
                        nodeId={childItem.key}
                        onRemove={onRemove}
                    />
                ))}
        </TreeItem>
    );
};

SelectedInterestTreeItemComponent.propTypes = {
    interest: PropTypes.shape(interestModel),
    onRemove: PropTypes.func
};
