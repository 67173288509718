import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { styled } from '@material-ui/core';

import Histogram from './component/Histogram';
import RangeSlider from './component/RangeSlider';

const Div = styled('div')(() => ({
    padding: '5px',
    '& canvas': {
        height: '150px !important',
        width: '100% !important'
    }
}));

const Space = styled('div')(() => ({
    height: '20px'
}));

const RangeFilter = ({ fieldId, items, setSelectedFilters }) => {
    items.sort((a, b) => {
        return a.from - b.from;
    });

    const maxData = items[items.length - 1].to;

    const countData = [];
    const priceData = [];
    const [values, setvalues] = useState([]);

    for (let i = 0; i < items.length; i += 1) {
        const thisPrice = items[i].from ? items[i].from : 0;
        const thisCount = items[i].count;
        countData.push(thisCount || 0);
        priceData.push(thisPrice || 0);
    }
    countData[countData.length] = countData[countData.length - 1];
    priceData[priceData.length] = maxData;

    const range = [0, countData.length - 1];
    const domain = range; // defulat value
    const defaultInputValue = [Number(priceData[0]), Number(priceData[priceData.length - 1])];

    // eslint-disable-next-line no-unused-vars
    const [inputValue, setInputValue] = useState(defaultInputValue);

    const onChangeCallBack = (v) => {
        const _inputValue = [].concat(Number(priceData[v[0]]), Number(priceData[v[1]]));
        const thisFilter = { value: _inputValue };
        setvalues(() => thisFilter.value);
        updateValues();
    };

    const updateValue = [];
    const updateValues = () => {
        //inputValue[0] = values[0];
        for (let i = 0; i < priceData.length; i += 1) {
            if (Number(priceData[i]) > inputValue[0]) {
                updateValue[0] = i - 1;
                break;
            }
        }
        for (let i = 0; i < priceData.length; i += 1) {
            if (Number(priceData[i]) > inputValue[1]) {
                updateValue[1] = i;
                break;
            } else {
                updateValue[1] = priceData.length;
            }
        }
    };
    updateValues();

    /*  const resetFn = () => {
        setInputValue(defaultInputValue);
    }; */

    useEffect(() => {
        setSelectedFilters({ field: fieldId, criterion: { gte: values[0], lte: values[1] }, selected: values });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);

    return (
        <Div>
            <Histogram data={countData} highlight={updateValue} domain={domain} />
            <RangeSlider values={updateValue} mode={2} step={1} domain={domain} onChange={onChangeCallBack} />
            <Space />
            {/* <RangeInput inputRange={inputValue} onChange={v => setInputValue(v)} /> */}
            {/* <button onClick={resetFn}>Reset</button> */}
        </Div>
    );
};

RangeFilter.propTypes = {
    fieldId: PropTypes.any,
    items: PropTypes.any,
    setSelectedFilters: PropTypes.any
};

export default RangeFilter;
