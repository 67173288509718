import { endOfYesterday, isBefore, isEqual, isPast, isToday, isValid, parse } from 'date-fns';

export const daysEnum = Object.freeze({
    Monday: 'MONDAY',
    Tuesday: 'TUESDAY',
    Wednesday: 'WEDNESDAY',
    Thursday: 'THURSDAY',
    Friday: 'FRIDAY',
    Saturday: 'SATURDAY',
    Sunday: 'SUNDAY'
});

export const DATE_FORMAT = 'dd. MMMM yyyy';
export const DATE_FORMAT_SHORT = 'dd. MMM yyyy';

export function startValid(start, allowPast) {
    // allow earlier timestamped dates today
    return isValid(start) && (isToday(start) || allowPast ? true : !isPast(start));
}

export function rangeValid(start, end, allowPast) {
    // allow singular day ranges
    return startValid(start, allowPast) && isValid(end) && (isBefore(start, end) || isEqual(start, end));
}

export function beforeYesterday(date) {
    return isBefore(date, endOfYesterday());
}

export const validateInput = (input, continuous, allowPast) => {
    if (input.split('-').length === 2) {
        let [start, end] = input.split('-');

        start = parse(start.trim(), DATE_FORMAT, new Date());
        end = parse(end.trim(), DATE_FORMAT, new Date());

        if (rangeValid(start, end, allowPast)) {
            return [start, end];
        }
    } else if (continuous) {
        const start = parse(input.trim(), DATE_FORMAT, new Date());
        if (startValid(start, allowPast)) {
            return [start, null];
        }
    }

    return [null, null];
};
