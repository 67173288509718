import { createTheme } from '@material-ui/core';

import overrides from './overrides';
import palette from './palette';
import typography from './typography';

const theme = createTheme({
    palette,
    typography: {
        fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
        ...typography
    },
    overrides,
    zIndex: {
        appBar: 1200,
        drawer: 1100
    },
    size: {
        input: {
            normal: 300,
            slim: 135,
            wide: 500
        },
        elementHeight: {
            normal: 42,
            short: 20,
            tall: 100
        },
        elementWidth: {
            normal: 600,
            slim: 135,
            wide: 960
        }
    }
});

export default theme;
