import createDataContext from '../createDataContext';
import { DESELECT_ALL_PRODUCTS, DESELECT_PRODUCT, SELECT_ALL_PRODUCTS, SELECT_PRODUCT } from './actionTypes';

const contextReducer = (state, action) => {
    switch (action.type) {
        case SELECT_PRODUCT:
            return { ...state, selected: [...state.selected, action.payload.id] };
        case DESELECT_PRODUCT:
            return {
                ...state,
                selected: state.selected.filter((pid) => pid !== action.payload.id)
            };
        case SELECT_ALL_PRODUCTS:
            return { ...state, selected: [...action.payload.map(({ id }) => id)] };
        case DESELECT_ALL_PRODUCTS:
            return { selected: [] };
        default:
            return state;
    }
};

const selectProduct = (dispatch) => (product) => {
    dispatch({ type: SELECT_PRODUCT, payload: product });
};

const deSelectProduct = (dispatch) => (product) => {
    dispatch({ type: DESELECT_PRODUCT, payload: product });
};

const selectAllProducts = (dispatch) => (products) => {
    dispatch({ type: SELECT_ALL_PRODUCTS, payload: products });
};

const deSelectAllProducts = (dispatch) => () => {
    dispatch({ type: DESELECT_ALL_PRODUCTS });
};

export const { Provider, Context } = createDataContext(
    contextReducer,
    {
        selectProduct,
        deSelectProduct,
        selectAllProducts,
        deSelectAllProducts
    },
    {
        selected: []
    }
);
