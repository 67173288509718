import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/styles/makeStyles';

import { PasswordVisibilityContext } from '../../context/settings';
import Account from './components/Account';
import Password from './components/Password';

const useStyles = makeStyles(() => ({
    root: {
        //padding: theme.spacing(4)
    }
}));

const Settings = () => {
    const classes = useStyles();
    const [passwordVisibility, setPasswordVisibility] = useState(false);

    return (
        <PasswordVisibilityContext.Provider value={{ passwordVisibility, setPasswordVisibility }}>
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item lg={8} md={8} xl={8} xs={12}>
                        <Account />
                    </Grid>
                    <Grid item lg={4} md={4} xl={4} xs={12}>
                        {passwordVisibility && <Password />}
                    </Grid>
                </Grid>
            </div>
        </PasswordVisibilityContext.Provider>
    );
};

export default Settings;
