import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button, Grid, makeStyles, styled } from '@material-ui/core';
import { DeleteForever } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';

import DeleteBudgetDialog from '../dialogs/DeleteBudgetDialog';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        backgroundColor: theme.palette.primary[300],
        padding: theme.spacing(1)
    },
    iconMargin: {
        marginRight: theme.spacing(2)
    }
}));

const WhiteButton = styled(Button)({
    color: 'white'
});

const Footer = ({ showButtons, handleAdd, handleSave, handleCancel, handleDelete }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    return (
        <Grid justify='space-between' container alignItems='center' className={classes.wrapper}>
            <DeleteBudgetDialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
                onConfirm={handleDelete}
            />
            <Grid container item xs={2}>
                <WhiteButton onClick={handleAdd}>
                    <AddIcon /> {t('Add unit')}
                </WhiteButton>
            </Grid>

            <Grid justify='flex-end' container item xs={10}>
                {showButtons && (
                    <>
                        <WhiteButton onClick={handleSave}>
                            <DoneOutlineIcon style={{ marginRight: 6 }} />
                            {t('Save')}
                        </WhiteButton>
                        <WhiteButton onClick={handleCancel}>
                            <CloseIcon />
                            {t('Cancel')}
                        </WhiteButton>
                    </>
                )}
                <WhiteButton onClick={() => setDeleteDialogOpen(true)}>
                    <DeleteForever />
                    {t('Delete')}
                </WhiteButton>
            </Grid>
        </Grid>
    );
};

Footer.propTypes = {
    showButtons: PropTypes.bool,
    handleAdd: PropTypes.func,
    handleSave: PropTypes.func,
    handleCancel: PropTypes.func,
    handleDelete: PropTypes.func
};

export default Footer;
