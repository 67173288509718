import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ScrollBar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';

import { Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Cancel, InfoOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

import { interestModel } from 'common/models';
import { TargetingConditionType } from 'helpers/targeting';

import { SelectedInterestTreeViewComponent } from './SelectedInterestTreeViewComponent';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxHeight: theme.spacing(25),
        height: theme.spacing(25)
    },
    headerContainer: {
        border: '1 solid',
        borderColor: grey[100],
        marginLeft: theme.spacing(2),
        flexGrow: 1
    },
    labelRoot: {
        backgroundColor: ({ targetingType }) =>
            targetingType === TargetingConditionType.Include ? '#DBEDF9' : '#F7E2E6',
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0.5, 0)
    },
    labelText: {
        color: ({ targetingType }) =>
            targetingType === TargetingConditionType.Include
                ? `${theme.palette.primary.main} !important`
                : `${theme.palette.error.main} !important`,
        fontWeight: 900,
        fontSize: '12px'
    },
    iconButton: {
        marginRight: theme.spacing(1)
    }
}));

const SelectedInterestTreeViewWrapper = ({ children, updateScroll, ...props }) => {
    useEffect(() => {
        updateScroll();
    }, [updateScroll]);

    return <SelectedInterestTreeViewComponent {...props}>{children}</SelectedInterestTreeViewComponent>;
};

SelectedInterestTreeViewWrapper.propTypes = {
    children: PropTypes.node,
    updateScroll: PropTypes.func
};

const SelectedInterests = ({ targetingType, onRemove, onRemoveAll, networkInterestFlat, selectedInterests }) => {
    const classes = useStyles({ targetingType });
    const { t } = useTranslation();
    let scrollBarRef = useRef(null);

    return (
        <React.Fragment>
            <div className={classes.labelRoot}>
                <div className={classes.headerContainer}>
                    <Grid container alignItems='center' spacing={1}>
                        <Grid item>
                            <Typography variant='overline' className={classes.labelText}>
                                {t('genericLabel.' + targetingType + 'd')}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Tooltip
                                title={t(`targeting.${targetingType}InterestPara`)}
                                placement='right-end'
                                disableHoverListener
                            >
                                <InfoOutlined color='disabled' fontSize='small' />
                            </Tooltip>
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.iconButton}>
                    <Tooltip title={t('Remove all')} disableHoverListener>
                        <span>
                            <IconButton
                                color='default'
                                size='small'
                                aria-label='remove all interest'
                                disabled={selectedInterests.length === 0}
                                onClick={onRemoveAll}
                            >
                                <Cancel />
                            </IconButton>
                        </span>
                    </Tooltip>
                </div>
            </div>
            <div className={classes.root}>
                <ScrollBar
                    options={{ wheelSpeed: 0.2, wheelPropagation: false }}
                    ref={(ref) => {
                        scrollBarRef = ref;
                    }}
                >
                    <Grid container>
                        <Grid item xs={12}>
                            <SelectedInterestTreeViewWrapper
                                disableSelection={true}
                                selectedInterests={selectedInterests}
                                onRemove={onRemove}
                                networkInterestFlat={networkInterestFlat}
                                updateScroll={() => {
                                    scrollBarRef.updateScroll();
                                }}
                            />
                        </Grid>
                    </Grid>
                </ScrollBar>
            </div>
        </React.Fragment>
    );
};

export default SelectedInterests;

SelectedInterests.propTypes = {
    selectedInterests: PropTypes.arrayOf(PropTypes.string),
    targetingType: PropTypes.shape(TargetingConditionType),
    onRemove: PropTypes.func,
    onRemoveAll: PropTypes.func,
    networkInterestFlat: PropTypes.arrayOf(PropTypes.shape(interestModel))
};
