import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button, DialogActions, DialogContent, IconButton, Typography } from '@material-ui/core';

const RemovePages = ({ ModalState, setModalState, removeFacebookPagesFromUnits, deselectPages, refetch }) => {
    const { t } = useTranslation();

    return (
        <div>
            <IconButton onClick={() => ModalState(false)}></IconButton>
            <DialogContent>
                <Typography> {t('Are you sure you want to disconnect this Facebook page?')}</Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    color='primary'
                    onClick={() => {
                        removeFacebookPagesFromUnits();
                        deselectPages();
                        setModalState(false);
                        refetch = { refetch };
                    }}
                >
                    {t('Confirm')}
                </Button>
                <Button onClick={() => setModalState(false)} color='primary'>
                    {t('Cancel')}
                </Button>
            </DialogActions>
        </div>
    );
};
RemovePages.propTypes = {
    ModalState: PropTypes.bool,
    setModalState: PropTypes.func,
    removeFacebookPagesFromUnits: PropTypes.func,
    deselectPages: PropTypes.func,
    refetch: PropTypes.func
};

export default RemovePages;
