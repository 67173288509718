import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';

export default {
    black,
    white,
    primary: {
        contrastText: white,
        dark: '#040251',
        main: '#3f3d93',
        light: '#e4ecff',
        50: '#e4ecff',
        100: '#cfd8f5',
        200: '#b5c2e6',
        300: '#898dc8',
        400: '#6463aa',
        500: '#3f3d93',
        600: '#2a2a87',
        700: '#19127c',
        800: '#0d0368',
        900: '#040251'
    },
    secondary: {
        contrastText: black,
        dark: '#F19A0A',
        main: '#F4AB0E',
        light: '#FEF5E2',
        50: '#FEF5E2',
        100: '#FCE6B7',
        200: '#FAD587',
        300: '#F7C456',
        400: '#F6B832',
        500: '#F4AB0E',
        600: '#F3A40C',
        700: '#F19A0A',
        800: '#EF9108',
        900: '#EC8004'
    },
    error: {
        contrastText: white,
        main: '#B00020'
    },
    success: {
        main: colors.green[500]
    },
    text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600],
        light: colors.blueGrey[300],
        link: colors.blue[600],
        mediumEmphasis: 'rgba(0, 0, 0, 0.6)'
    },
    background: {
        default: white,
        paper: white,
        empty: colors.grey[100],
        filled: colors.grey[200],
        grey: '#F4F6F8'
    },
    location: {
        radius: colors.grey[700]
    },
    sidebar: {
        active: '#EC417A'
    },
    icon: colors.blueGrey[600]
};
