import React from 'react';

import { Dialog } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const styles = () => ({
    dialogPaper: {
        minHeight: '80vh',
        maxHeight: '80vh'
    }
});

export const StyledProductCreateEditFormDialog = withStyles(styles)(({ classes, children, ...rest }) => {
    return (
        <Dialog {...rest} classes={{ paper: classes.dialogPaper }}>
            {children}
        </Dialog>
    );
});
