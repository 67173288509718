import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useMutation } from '@apollo/client';
import {
    Button,
    DialogActions,
    DialogContent,
    Grid,
    makeStyles,
    Paper,
    Step,
    StepLabel,
    Stepper,
    Typography
} from '@material-ui/core';

import { UnitSelector } from 'common/components';
import { userModel } from 'common/models';
import { Context as unitContext } from 'context/units/activeUnitContext';
import { ADD_UNITS_TO_USER } from 'graphql/mutations/units';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    backButton: {
        marginRight: theme.spacing(1)
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary
    }
}));

function AddUnitsToUser({ setAddUnitModalState, user, setEditingUser, refetch }) {
    const { t } = useTranslation();

    const [addUnitsToUser] = useMutation(ADD_UNITS_TO_USER, {
        onCompleted: (data) => {
            setEditingUser(data.addUnitsToUser);
            refetch();
        }
    });

    const {
        state: { units }
    } = useContext(unitContext);
    const [selectedUnits, setSelectedUnits] = useState([]);
    const addableUnits = units.filter((unit) => !user.units.map((unit) => unit.id).includes(unit.id));

    const onSubmit = (e) => {
        e.preventDefault();

        addUnitsToUser({
            variables: {
                userId: user.id,
                units: selectedUnits
            }
        });
        setAddUnitModalState(false);
    };

    function getStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:
                return (
                    <UnitSelector
                        setSelectedUnits={setSelectedUnits}
                        selectedUnits={selectedUnits}
                        units={addableUnits}
                    />
                );
            case 1:
                return units
                    .filter((unit) => selectedUnits.includes(unit.id))
                    .map((u) => (
                        <Grid container spacing={3} key={u.id}>
                            <Grid x item xs={12}>
                                <Paper className={classes.paper}>{u.name}</Paper>
                            </Grid>
                        </Grid>
                    ));
            default:
                return '';
        }
    }
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const steps = [
        <span key='1'>{t('Select units')}</span>,
        <span key='2'>{t('Selected units')}</span>,
        <span key='3'>{t('Finish')}</span>
    ];

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <div>
            <DialogContent
                aria-describedby='scroll-dialog-description'
                aria-labelledby='scroll-dialog-title'
                fullWidth
                scroll='paper'
            >
                <div className={classes.root}>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <div>
                        <div>
                            <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
                            <div>
                                <Button disabled={activeStep === 0} onClick={handleBack} className={classes.backButton}>
                                    {t('Back')}
                                </Button>
                                <Button variant='contained' color='primary' onClick={handleNext}>
                                    {activeStep === steps.length - 1 ? (
                                        <Button onClick={onSubmit}> {t('Add units')}</Button>
                                    ) : (
                                        <Button>{t('Next')}</Button>
                                    )}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setAddUnitModalState(false)}>{t('Cancel')}</Button>
            </DialogActions>
        </div>
    );
}

AddUnitsToUser.propTypes = {
    setAddUnitModalState: PropTypes.func,
    user: PropTypes.shape(userModel),
    setEditingUser: PropTypes.func,
    refetch: PropTypes.func
};

export default AddUnitsToUser;
