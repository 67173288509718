import PropTypes from 'prop-types';

export const fieldTypeModel = Object.freeze({
    TEXT: 'TEXT',
    NUMBER: 'NUMBER',
    CHECKBOX: 'CHECKBOX',
    URL: 'URL',
    IMAGE: 'IMAGE'
});

export const productFieldModel = {
    id: PropTypes.string,
    fieldId: PropTypes.string,
    fieldType: PropTypes.string,
    visibility: PropTypes.bool
};

export const productModel = {
    id: PropTypes.string,
    productTypeId: PropTypes.string,
    readOnly: PropTypes.bool,
    fields: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

export const productTypeModel = {
    id: PropTypes.string,
    name: PropTypes.string,
    unitId: PropTypes.string,
    fields: PropTypes.arrayOf(PropTypes.shape(productFieldModel))
};

export const productFilterModel = {
    fieldId: PropTypes.string,
    id: PropTypes.string,
    items: PropTypes.array,
    type: PropTypes.string
};
