import gql from 'graphql-tag';

export const FRAGMENT_AGE_GROUP = gql`
    fragment ageGroup on AgeGroup {
        from
        to
    }
`;

export const FRAGMENT_LOCATION = gql`
    fragment location on Location {
        latitude
        longitude
        label
        radius
        address
    }
`;

export const FRAGMENT_INTEREST = gql`
    fragment interest on Interest {
        key
        parentKey
        name
        networkId
        isDeleted
        isDeprecated
    }
`;

export const FRAGMENT_KEYWORD = gql`
    fragment keyword on keyword {
        text
        matchType
    }
`;

export const FRAGMENT_TARGETING = gql`
    fragment targeting on Targeting {
        id
        unitId
        name
        age {
            ...ageGroup
        }
        gender
        locations {
            ...location
        }
        interests {
            ...interest
        }
        excludeInterests {
            ...interest
        }
        status
        keywords {
            ...keyword
        }
        excludeKeywords {
            ...keyword
        }
    }
    ${FRAGMENT_AGE_GROUP}
    ${FRAGMENT_LOCATION}
    ${FRAGMENT_INTEREST}
    ${FRAGMENT_KEYWORD}
`;
