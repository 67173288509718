import gql from 'graphql-tag';

export const FRAGMENT_DATE_INTERVAL = gql`
    fragment dateInterval on DateInterval {
        startDate
        endDate
    }
`;

export const FRAGMENT_DAY = gql`
    fragment day on Day {
        id
        day
        startTime
        endTime
    }
`;

export const FRAGMENT_SCHEDULE = gql`
    fragment schedule on Schedule {
        id
        startDate
        endDate
        startTime
        endTime
        days {
            ...day
        }
    }
    ${FRAGMENT_DAY}
`;
