import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import {
    Box,
    Checkbox,
    Grid,
    IconButton,
    TableCell,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import { BudgetPeriodModel } from 'common/models/budget';

import { parseNumericInput } from '../helpers';

const useStyles = makeStyles((theme) => ({
    blueBackground: {
        backgroundColor: theme.palette.primary
    },
    budgetTextField: {
        '& .MuiInput-input': {
            textAlign: 'right'
        }
    },
    aligIcon: {
        display: 'flex',
        alignItem: 'center',
        minWidth: '250px'
    },
    checkboxCell: {
        paddingLeft: 0
    },
    firstCell: {
        paddingLeft: '42px'
    },
    coloredRow: {
        background: theme.palette.secondary.light
    },
    cell: {
        padding: 0,
        minWidth: '100px',
        '& p': {
            marginRight: theme.spacing(1)
        }
    },
    boldText: {
        color: '#67ABE2',
        fontWeight: 'bold'
    }
}));

const SelectionRow = ({ numSelected, budgets, handleUpdate }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const [mixedBudgets, setMixedBudgets] = useState([]);

    useEffect(() => {
        setMixedBudgets(budgets);
    }, [budgets]);

    const handleSelectedChange = (budgetIndex, valueIndex, newValue) => {
        newValue = parseNumericInput(newValue);
        const mixed = [...mixedBudgets];
        mixed[budgetIndex].values[valueIndex] = newValue;
        setMixedBudgets(mixed);
        handleUpdate(budgetIndex, valueIndex, newValue);
    };

    return (
        <>
            <TableRow className={classes.coloredRow}>
                <TableCell className={classes.firstCell} colSpan={13}>
                    <Typography variant='body2' color='primary' component='div'>
                        <Box mr={1} fontWeight='fontWeightBold'>
                            {`${t('Selected')} (${numSelected})`}
                        </Box>
                    </Typography>
                </TableCell>
            </TableRow>
            {mixedBudgets.map((mixedBudget, budgetIndex) => (
                <TableRow key={`mixed-budget-${budgetIndex}`} className={classes.coloredRow}>
                    <>
                        <TableCell className={classes.firstCell}>{mixedBudget.budgetType}</TableCell>
                        {mixedBudget.values.map((budgetValue, valueIndex) => (
                            <TableCell
                                key={`summed-budget-${budgetIndex}-${valueIndex}`}
                                className={classes.cell}
                                scope='row'
                                align='center'
                            >
                                <TextField
                                    className={classes.budgetTextField}
                                    onChange={(event) => {
                                        handleSelectedChange(budgetIndex, valueIndex, event.target.value);
                                    }}
                                    color={Number.isInteger(parseInt(budgetValue)) ? 'primary' : 'secondary'}
                                    value={budgetValue}
                                />
                            </TableCell>
                        ))}
                    </>
                </TableRow>
            ))}
        </>
    );
};

SelectionRow.propTypes = {
    numSelected: PropTypes.number,
    budgets: PropTypes.arrayOf(
        PropTypes.shape({
            budgetType: PropTypes.string,
            values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string]))
        })
    ),
    handleUpdate: PropTypes.func
};

const HeaderRow = ({ name, total, checked, setChecked, handleDuplicate, handleDelete }) => {
    const classes = useStyles(checked);
    const { t } = useTranslation();

    const handleChecked = (event) => {
        setChecked(event.target.checked);
    };

    return (
        <TableRow className={checked ? classes.coloredRow : ''}>
            <TableCell className={classes.checkboxCell}>
                <Grid container justify='space-between' alignItems='center'>
                    <Grid item xs={2}>
                        <Checkbox checked={checked} onChange={handleChecked} />
                    </Grid>
                    <Grid item xs={checked ? 10 : 6}>
                        {name}
                    </Grid>
                    {!checked && (
                        <>
                            <Grid item xs={2}>
                                <Tooltip title={t('Delete entry')} aria-label='delete'>
                                    <IconButton color='secondary' onClick={handleDelete}>
                                        <DeleteForeverIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={2}>
                                <Tooltip title={t('Duplicate entry to unit')} aria-label='copy'>
                                    <IconButton color='secondary' onClick={handleDuplicate}>
                                        <FileCopyIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </>
                    )}
                </Grid>
            </TableCell>
            {total.map((t, idx) => (
                <TableCell className={classes.cell} key={`table-header-cell-${idx}`} scope='row' align='center'>
                    <Typography align='right' variant='body2' color='primary' component='div'>
                        <Box mr={1} fontWeight={checked ? 'fontWeightBold' : 'fontWeightRegular'}>
                            {t}
                        </Box>
                    </Typography>
                </TableCell>
            ))}
        </TableRow>
    );
};

HeaderRow.propTypes = {
    name: PropTypes.string,
    total: PropTypes.arrayOf(PropTypes.number),
    checked: PropTypes.bool,
    setChecked: PropTypes.func,
    handleDuplicate: PropTypes.func,
    handleDelete: PropTypes.func
};

const BudgetRow = ({ name, period, updateBudgetRow, selected, minified }) => {
    const classes = useStyles();

    const [localPeriod, setLocalPeriod] = useState(period);

    useEffect(() => {
        setLocalPeriod(period);
    }, [period]);

    const updatePeriod = (val, idx) => {
        const newPeriod = [...localPeriod];
        newPeriod[idx] = val;
        updateBudgetRow(newPeriod);
    };

    const handleChange = (event, idx) => {
        const val = parseNumericInput(event.target.value);
        const local = [...localPeriod];
        local[idx] = val;
        setLocalPeriod(local);
        updatePeriod(val, idx);
    };

    return (
        <TableRow>
            {!minified && <TableCell className={classes.firstCell}>{name}</TableCell>}
            {localPeriod.map((value, idx) => (
                <TableCell className={classes.cell} key={`table-cell-${idx}`} scope='row' align='center'>
                    <TextField
                        className={classes.budgetTextField}
                        onChange={(event) => handleChange(event, idx)}
                        value={value}
                        disabled={selected}
                    />
                </TableCell>
            ))}
        </TableRow>
    );
};

BudgetRow.propTypes = {
    period: PropTypes.oneOfType([PropTypes.arrayOf(BudgetPeriodModel), PropTypes.arrayOf(PropTypes.number)]),
    name: PropTypes.string,
    updateBudgetRow: PropTypes.func,
    selected: PropTypes.bool,
    minified: PropTypes.bool
};

export { BudgetRow, HeaderRow, SelectionRow };
