import PropTypes from 'prop-types';

export const ageRangeModel = {
    from: PropTypes.number,
    to: PropTypes.number
};

export const locationModel = {
    label: PropTypes.string,
    radius: PropTypes.number,
    latitude: PropTypes.number,
    longitude: PropTypes.number
};

export const interestModel = {
    key: PropTypes.string,
    parentKey: PropTypes.string,
    name: PropTypes.string,
    networkId: PropTypes.string,
    selectable: PropTypes.bool,
    isDeprecated: PropTypes.bool,
    isDeleted: PropTypes.bool
};

export const keywordModel = {
    text: PropTypes.string,
    matchType: PropTypes.string
};
