import gql from 'graphql-tag';

export const FRAGMENT_DATA_SOURCES = gql`
    fragment dataSources on DataSources {
        bidtheatre
        deltaProjects
        hubspot
        facebookAds
        facebookPages
        googleAds
        googleAnalyticsGA4
        googleAnalyticsGAU
        googleDisplayAndVideo360
        instagramPages
        keywordPlanner
        linkedinAds
        linkedinPages
        microsoftAds
        pinterestAds
        pinterestPages
        piwikPro
        searchConsole
        seRankings
        snapchatAds
        snapchatPages
        stripe
        tiktokAds
        tiktokPages
        twitterX
        xandr
    }
`;
