import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useQuery } from '@apollo/client';
import {
    Button,
    Dialog,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { GET_AVAILABLE_LEGITIMATE_USERS } from 'graphql/queries/user';

import AddUser from './components/AddUser';
import EditUser from './components/EditUser';
import RemoveUser from './components/RemoveUser';
import UserList from './components/UserList';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto'
    }
}));

const TotalUsers = () => {
    const [modalState, setModalState] = useState(false);
    const [removeModalState, setRemoveModalState] = useState(false);
    const [addmodalState, setAddModalState] = useState(false);
    const [editingUser, setEditingUser] = useState({});
    const [removeUser, setRemoveUser] = useState({});

    const classes = useStyles();

    const { t } = useTranslation();

    const { data, loading, error, refetch } = useQuery(GET_AVAILABLE_LEGITIMATE_USERS, {
        onCompleted: () => {}
    });

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error found</p>;

    return (
        <div className={classes.root}>
            <TableContainer component={Paper} className={classes.paper}>
                <Table arial-label='Userlist table'>
                    <TableHead>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={(e) => {
                                e.preventDefault();
                                setAddModalState(!addmodalState);
                            }}
                        >
                            {t('Add user')}
                        </Button>
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell align='right'>{t('First name')}</TableCell>
                                <TableCell align='right'>{t('Last name')}</TableCell>
                                <TableCell align='right'>{t('E-mail address')}</TableCell>
                                <TableCell align='right'>{t('Phone number')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.getUsers.map((user) => (
                                <UserList
                                    key={user.id}
                                    user={user}
                                    modalState={modalState}
                                    removeModalState={removeModalState}
                                    setRemoveUser={setRemoveUser}
                                    setEditingUser={setEditingUser}
                                    setRemoveModalState={setRemoveModalState}
                                    setModalState={setModalState}
                                    refetch={refetch}
                                />
                            ))}
                        </TableBody>
                        <Dialog open={removeModalState} onClose={() => setRemoveModalState(false)}>
                            <RemoveUser
                                user={removeUser}
                                refetch={refetch}
                                modalState={removeModalState}
                                setModalState={setRemoveModalState}
                            ></RemoveUser>
                        </Dialog>
                        <Dialog open={addmodalState} onClose={() => setAddModalState(false)}>
                            <AddUser
                                modalState={addmodalState}
                                setModalState={setAddModalState}
                                refetch={refetch}
                            ></AddUser>
                        </Dialog>
                        <Dialog open={modalState} onClose={() => setModalState(false)}>
                            <EditUser
                                key={editingUser.id}
                                refetch={refetch}
                                user={editingUser}
                                setModalState={setModalState}
                            ></EditUser>
                        </Dialog>
                    </TableHead>
                </Table>
            </TableContainer>
        </div>
    );
};

export default TotalUsers;
