import React from 'react';
import PropTypes from 'prop-types';

import { Accordion, AccordionDetails, AccordionSummary, makeStyles, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { ArrowRight } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    panelRoot: {
        border: `1px solid ${theme.palette.divider}`,
        top: '-1px',
        '&.Mui-expanded': {
            margin: 0
        }
    },
    borderless: {
        border: 0
    },
    panelSummaryRoot: {
        minHeight: 40,
        height: 40,
        paddingLeft: 4,
        paddingRight: 4,
        backgroundColor: grey[100],
        '&.Mui-expanded': {
            minHeight: 40
        }
    },
    panelSummaryContent: {
        marginLeft: theme.spacing(2),
        '&.Mui-expanded': {
            margin: '12px 0'
        }
    },
    panelSummaryHeader: {
        fontSize: theme.typography.pxToRem(12),
        letterSpacing: '0.4px'
    },
    expandIcon: {
        order: -1,
        '&.Mui-expanded': {
            transform: 'rotate(90deg)'
        }
    }
}));

export const GreyExpansionPanel = ({ title, children, borderless = false, ...props }) => {
    const classes = useStyles();

    return (
        <Accordion
            square={true}
            elevation={0}
            className={`${classes.panelRoot} ${borderless && classes.borderless}`}
            {...props}
        >
            <AccordionSummary
                expandIcon={<ArrowRight />}
                aria-controls='panel-footer-content'
                id='panel-footer'
                classes={{
                    root: classes.panelSummaryRoot,
                    content: classes.panelSummaryContent,
                    expandIcon: classes.expandIcon
                }}
            >
                <Typography variant='caption'>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>{children}</AccordionDetails>
        </Accordion>
    );
};

GreyExpansionPanel.propTypes = {
    title: PropTypes.string,
    children: PropTypes.element,
    borderless: PropTypes.bool
};
