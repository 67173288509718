import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useMutation } from '@apollo/client';
import { Box, Button, Dialog, DialogActions, DialogContent, Grid } from '@material-ui/core';
import { Check, ImageAspectRatio } from '@material-ui/icons';
import SettingsOverscanIcon from '@material-ui/icons/SettingsOverscan';

import { DialogTitleClose, ImageCropWithZoom, PreviewImageGrid, ProductImageGrid } from 'common/components';
import { templateMediaDimensionModel } from 'common/models/advertisement';
import { CREATE_IMAGE_CROP } from 'graphql/mutations/image';
import { resetCropperStorage } from 'helpers/campaign';

import { getInitialResolutions } from './helpers';

export const ImageCropper = ({
    productImages,
    onBlur,
    mediaDimension,
    minimumResolution,
    recommendedResolution,
    sizeLimit
}) => {
    const { t } = useTranslation();
    const [cropping, setCropping] = useState(false);
    const [selectedImageIndex, setImageIndex] = useState(0);
    const [selectedImage, setImage] = useState(0);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [resolutions, setResolutions] = useState([100, 100]);

    useEffect(() => {
        if (productImages) {
            setImage(productImages[selectedImageIndex]);
            setResolutions(getInitialResolutions(recommendedResolution, minimumResolution, mediaDimension));
        }
    }, [mediaDimension, minimumResolution, productImages, recommendedResolution, selectedImageIndex]);

    useEffect(() => {
        resetCropperStorage();
    }, [selectedImageIndex]);

    const [createImageCrop] = useMutation(CREATE_IMAGE_CROP, {
        onCompleted: (data) => {
            if (data.createImageCrop && onBlur) {
                onBlur(data.createImageCrop);
            }
            setDialogOpen(false);
        }
    });

    const handleImageLoaded = (directlyToCrop) => {
        setCropping(directlyToCrop); // if an image is selected, open it in editor directly
        setDialogOpen(true);
    };

    const onDialogClose = () => {
        setDialogOpen(false);
    };

    const onDialogConfirm = (completedCrop) => {
        const background = completedCrop?.background || { r: 255, g: 255, b: 255, alpha: 1.0 };
        const rotation = completedCrop?.rotation || 0;
        const maxSize = sizeLimit || 1000 * 1000 * 50; // 50 MB (arbitrary default size)
        const flip = completedCrop?.flop === -1 || false; // the backend library (sharp) handles flip as flop - and vice versa.
        const flop = completedCrop?.flip === -1 || false;

        createImageCrop({
            variables: {
                input: {
                    url: selectedImage,
                    x: completedCrop.x,
                    y: completedCrop.y,
                    width: completedCrop.width,
                    height: completedCrop.height,
                    whitespace: completedCrop.whitespace,
                    rotation,
                    background,
                    flip,
                    flop,
                    sizeLimit: maxSize
                }
            }
        });
    };

    return (
        <>
            {productImages && productImages.length > 0 && (
                <>
                    <Grid container direction='column' justifyContent='space-between' spacing={2}>
                        <Grid container item xs={12}>
                            <PreviewImageGrid
                                images={productImages}
                                activeIndex={selectedImageIndex}
                                setActiveIndex={(previewIndex) => {
                                    onBlur(productImages[previewIndex]);
                                    setImageIndex(previewIndex);
                                }}
                                onClickMore={() => {
                                    setImageIndex(-1);
                                    handleImageLoaded(false);
                                }}
                            />
                        </Grid>
                        <Grid container alignItems='center' justify='center' item xs={12}>
                            <Button
                                variant='contained'
                                color='primary'
                                startIcon={<SettingsOverscanIcon />}
                                onClick={() => handleImageLoaded(true)}
                            >
                                {t('Open image editor')}
                            </Button>
                        </Grid>
                    </Grid>
                    <Dialog fullScreen open={dialogOpen} onClose={onDialogClose} aria-labelledby='crop-image-dialog'>
                        {!cropping && (
                            <DialogTitleClose
                                onClose={onDialogClose}
                                id='crop-image-dialog-title'
                                style={{ zIndex: 1 }}
                            >
                                {t('Select image')}
                            </DialogTitleClose>
                        )}
                        <DialogContent>
                            <Box paddingBottom={2}>
                                {cropping ? (
                                    <ImageCropWithZoom
                                        image={selectedImage}
                                        resolutions={resolutions}
                                        mediaDimension={mediaDimension}
                                        onClickBack={() => setCropping(false)}
                                        onClickCrop={onDialogConfirm}
                                        onClickCancel={() => setDialogOpen(false)}
                                    />
                                ) : (
                                    <>
                                        <ProductImageGrid
                                            images={productImages}
                                            activeIndex={selectedImageIndex}
                                            setActiveIndex={setImageIndex}
                                        />
                                        <DialogActions>
                                            <Box display='flex' justifyContent='center' width={1}>
                                                <Button
                                                    style={{ marginRight: 8 }}
                                                    disabled={selectedImageIndex === -1}
                                                    variant='contained'
                                                    color='primary'
                                                    onClick={() => setCropping(!cropping)}
                                                    startIcon={<ImageAspectRatio />}
                                                >
                                                    {t('Edit image')}
                                                </Button>
                                                <Button
                                                    disabled={selectedImageIndex === -1}
                                                    variant='contained'
                                                    color='primary'
                                                    onClick={onDialogClose}
                                                    startIcon={<Check />}
                                                >
                                                    {t('Use image')}
                                                </Button>
                                            </Box>
                                        </DialogActions>
                                    </>
                                )}
                            </Box>
                        </DialogContent>
                    </Dialog>
                </>
            )}
        </>
    );
};

ImageCropper.propTypes = {
    mediaDimension: PropTypes.shape(templateMediaDimensionModel),
    minimumResolution: PropTypes.arrayOf(PropTypes.number),
    onBlur: PropTypes.func,
    productImages: PropTypes.arrayOf(PropTypes.string),
    recommendedResolution: PropTypes.arrayOf(PropTypes.number),
    sizeLimit: PropTypes.number
};
