import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

import {
    Box,
    FormControlLabel,
    Grid,
    IconButton,
    ListItemText,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Switch,
    Typography
} from '@material-ui/core';
import { Add, Delete, EditOutlined } from '@material-ui/icons';

import { CAMPAIGN_ACTION_TYPE, useCampaignDispatch } from 'context/campaign/CampaignContext';

export const LinkExtension = ({ linkExtensions, linkInput, campaign }) => {
    const selectedExtensionIDs = campaign.linkExtensions;
    const campaignID = campaign.id;
    const { t } = useTranslation();
    const dispatch = useCampaignDispatch();
    const [selectedExtensions, setSelectedExtensions] = useState(() =>
        linkExtensions.filter((ext) => selectedExtensionIDs?.includes(ext._id))
    );
    const [availableExtensions, setAvailableExtensions] = useState(() =>
        linkExtensions.filter((ext) => !selectedExtensionIDs?.includes(ext._id))
    );
    const [isActive, setIsActive] = useState(selectedExtensions.length > 0);
    const [prevCampaignID, setPrevCampaignID] = useState(campaign);
    if (prevCampaignID !== campaignID) {
        setPrevCampaignID(campaignID);
        const newSelectedExtensions = linkExtensions.filter((ext) => selectedExtensionIDs?.includes(ext._id));
        setSelectedExtensions(newSelectedExtensions);
        setAvailableExtensions(linkExtensions.filter((ext) => !selectedExtensionIDs?.includes(ext._id)));
        setIsActive(newSelectedExtensions.length > 0);
    }

    const saveLinkExtensions = debounce((linkExtensions) => {
        const linkExtensionIDs = linkExtensions.map((ext) => ext._id);
        dispatch({
            type: CAMPAIGN_ACTION_TYPE.UPDATE_LINK_EXTENSION,
            linkExtensions: linkExtensionIDs,
            campaignID
        });
    }, 200);

    const handleAddExtension = (extension) => {
        const newSelectedExtensions = [...selectedExtensions, extension];
        setSelectedExtensions(newSelectedExtensions);
        setAvailableExtensions(availableExtensions.filter((e) => e._id !== extension._id));
        saveLinkExtensions(newSelectedExtensions);
    };

    const handleDeleteExtension = (extension) => {
        const newSelectedExtensions = selectedExtensions.filter((e) => e._id !== extension._id);
        setSelectedExtensions(newSelectedExtensions);
        setAvailableExtensions([...availableExtensions, extension]);
        saveLinkExtensions(newSelectedExtensions);
    };

    const handleSwitchExtension = (oldExtension, newExtension) => {
        const newSelectedExtensions = selectedExtensions.map((ext) =>
            ext._id === oldExtension._id ? newExtension : ext
        );
        setSelectedExtensions(newSelectedExtensions);
        setAvailableExtensions(availableExtensions.map((ext) => (ext._id === newExtension._id ? oldExtension : ext)));
        saveLinkExtensions(newSelectedExtensions);
    };

    return (
        <>
            <RadioGroup
                row
                aria-label='link-type'
                defaultValue='useProduct'
                // onChange={(e) => {}}
            >
                <FormControlLabel
                    value='useProduct'
                    control={<Radio color='primary' />}
                    label={t('Use product link')}
                    labelPlacement='end'
                />
                <FormControlLabel
                    value='setManually'
                    control={<Radio color='primary' />}
                    label={t('Set manually')}
                    labelPlacement='end'
                />
            </RadioGroup>
            {linkInput}
            <Grid container justify='space-between'>
                <FormControlLabel
                    control={
                        <Switch
                            checked={isActive}
                            onChange={(e) => {
                                if (selectedExtensions.length === 0) {
                                    handleAddExtension(availableExtensions[0]);
                                } else {
                                    saveLinkExtensions(e.target.checked ? selectedExtensions : []);
                                }
                                setIsActive(e.target.checked);
                            }}
                            name={t('Link extension')}
                            color='primary'
                        />
                    }
                    label='Link extension'
                />
                {isActive && (
                    <IconButton
                        onClick={() => {
                            const extensionIDs = selectedExtensions.map((ext) => ext._id);
                            const firstUnused = availableExtensions.find(
                                (extension) => !extensionIDs.includes(extension._id)
                            );
                            firstUnused && handleAddExtension(firstUnused);
                        }}
                        color='primary'
                    >
                        <Add />
                    </IconButton>
                )}
            </Grid>
            {isActive && (
                <Box padding='16px' bgcolor='#E1EFFA'>
                    {selectedExtensions.map((extension) => (
                        <ExtensionSelector
                            key={extension._id}
                            selectedExtension={extension}
                            extensions={availableExtensions}
                            showDelete={selectedExtensions.length > 1}
                            deleteExtension={handleDeleteExtension}
                            switchExtension={handleSwitchExtension}
                        />
                    ))}
                    <Typography style={{ fontSize: '9px', color: '#00000099' }}>
                        {selectedExtensions.map((ext) => ext.value).join('&')}
                    </Typography>
                </Box>
            )}
        </>
    );
};

const ExtensionSelector = ({ selectedExtension, extensions, showDelete, deleteExtension, switchExtension }) => {
    return (
        <Grid container>
            <Box style={{ width: '340px' }}>
                <Select
                    fullWidth
                    value={selectedExtension}
                    renderValue={(extension) => extension.name}
                    onChange={(e) => switchExtension(selectedExtension, e.target.value)}
                >
                    {[selectedExtension, ...extensions].map((ext) => (
                        <MenuItem key={ext._id} value={ext}>
                            <ListItemText primary={ext.name} />
                        </MenuItem>
                    ))}
                </Select>
            </Box>
            <Box style={{ marginLeft: '30px' }}>
                <IconButton color='primary'>
                    <EditOutlined />
                </IconButton>
            </Box>
            {showDelete && (
                <Grid container item xs direction='column' alignItems='flex-end' justify='space-evenly'>
                    <IconButton onClick={() => deleteExtension(selectedExtension)} color='primary'>
                        <Delete />
                    </IconButton>
                </Grid>
            )}
        </Grid>
    );
};

LinkExtension.propTypes = {
    linkExtensions: PropTypes.array,
    linkInput: PropTypes.any,
    campaign: PropTypes.any
};

ExtensionSelector.propTypes = {
    selectedExtension: PropTypes.any.isRequired,
    extensions: PropTypes.any.isRequired,
    showDelete: PropTypes.bool,
    deleteExtension: PropTypes.func.isRequired,
    switchExtension: PropTypes.func.isRequired
};
