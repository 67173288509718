import React from 'react';
import PropTypes from 'prop-types';

import { Fab, Grid, Paper, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

const Header = ({ title, subtitle, handleAdd, ...props }) => {
    return (
        <Paper variant='outlined' {...props}>
            <Grid container wrap='nowrap' spacing={2}>
                <Grid container xs={8} direction='column' item>
                    <Typography variant='h3' color='primary'>
                        {title}
                    </Typography>
                    <Typography variant='subtitle1'>{subtitle}</Typography>
                </Grid>
                <Grid container alignItems='center' justify='flex-end' xs={4} item>
                    <Fab
                        aria-controls='add-budget-menu'
                        aria-haspopup='true'
                        aria-label='add'
                        color='primary'
                        onClick={handleAdd}
                    >
                        <AddIcon />
                    </Fab>
                </Grid>
            </Grid>
        </Paper>
    );
};

Header.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    handleAdd: PropTypes.func
};

export default Header;
