import { isNumeric } from '../utils';
import { Enum } from './enum';

export class CampaignServingStatus extends Enum {
    static items = [];

    static UNDEFINED = new CampaignServingStatus('', -1);
    static UNSPECIFIED = new CampaignServingStatus('UNSPECIFIED', 0);
    static UNKNOWN = new CampaignServingStatus('UNKNOWN', 1);
    static SERVING = new CampaignServingStatus('SERVING', 2);
    static NONE = new CampaignServingStatus('NONE', 3);
    static ENDED = new CampaignServingStatus('ENDED', 4);
    static PENDING = new CampaignServingStatus('PENDING', 5);
    static SUSPENDED = new CampaignServingStatus('SUSPENDED', 6);
    static FAILED = new CampaignServingStatus('ERRORED', 7);
    static IN_PROGRESS = new CampaignServingStatus('IN PROGRESS', 8);
    static REQUIRES_YOUR_INTERVENTION = new CampaignServingStatus('MIXED STATUS', 9);
    static PUBLISHED = new CampaignServingStatus('PUBLISHED', 10);

    /**
     *
     */

    constructor(name, value) {
        super(name, value);

        CampaignServingStatus.items.push(this);
    }

    /**
     *
     */

    equals(other) {
        return this.value === other?.value && this.name === other?.name;
    }

    /**
     *
     */

    static find(criteria) {
        if (isNumeric(criteria)) {
            return CampaignServingStatus.items.find((v) => v.value === Number(criteria));
        }

        return CampaignServingStatus.items.find((v) => v.name === criteria);
    }

    /**
     *
     */

    static contains(criteria) {
        const status = CampaignServingStatus.find(criteria);

        return !!status;
    }

    /**
     *
     */

    static get(criteria) {
        return CampaignServingStatus.find(criteria) || CampaignServingStatus.UNDEFINED;
    }
}
