import createDataContext from '../createDataContext';
import { SET_EXPANDED_CAMPAIGNS, SET_SELECTED_CAMPAIGNS } from './actionTypes';

const contextReducer = (state, action) => {
    switch (action.type) {
        case SET_SELECTED_CAMPAIGNS:
            //state.selected = action.payload
            return { selected: action.payload, expanded: state.expanded };
        case SET_EXPANDED_CAMPAIGNS:
            if (action.payload.expanded) {
                state.expanded.push(action.payload.id);
            } else {
                state.expanded = state.expanded.filter((e) => e !== action.payload.id);
            }

            return state;
        default:
            return state;
    }
};
const setSelected = (dispatch) => (ids) => {
    dispatch({ type: SET_SELECTED_CAMPAIGNS, payload: ids });
};
const setExpanded = (dispatch) => (row) => {
    dispatch({ type: SET_EXPANDED_CAMPAIGNS, payload: row });
};

export const { Provider, Context } = createDataContext(
    contextReducer,
    {
        setSelected,
        setExpanded
    },
    {
        selected: [],
        expanded: []
    }
);
