import React from 'react';

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { MarkdownTypography } from 'common/components';

import cookie_policy from './cookie_policy_text';

const { REACT_APP_SITE_NAME } = process.env;

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4)
        // maxWidth: '34rem',
    }
}));

const Cookie = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Typography variant='h1' className={classes.heading}>
                Cookie policy {REACT_APP_SITE_NAME}
            </Typography>

            <MarkdownTypography source={cookie_policy} />
        </div>
    );
};

export default Cookie;
