import { groupBy } from 'lodash';

import createDataContext from '../createDataContext';
import { SET_ACTIVE_UNIT, SET_ALL_UNITS, SET_ME_PERMISSIONS, UPDATE_ACTIVE_UNIT } from './actionTypes';

const unitsReducer = (state, action) => {
    switch (action.type) {
        case SET_ACTIVE_UNIT: {
            const landingPage = '/unit/profile';

            return {
                ...state,
                activeUnit: {
                    ...action.payload,
                    ...{
                        productType: action.payload?.productTypes?.[0]
                    },
                    landingPage
                },
                activePermissions: computePermissions(action.payload || null, state.mePermissions || [])
            };
        }
        case SET_ALL_UNITS:
            return { ...state, units: [...action.payload] };
        case UPDATE_ACTIVE_UNIT:
            if (state.activeUnit.id === action.payload.id) {
                return {
                    ...state,
                    activeUnit: { ...state.activeUnit, ...action.payload }
                };
            }

            return state;
        case SET_ME_PERMISSIONS:
            return {
                ...state,
                //isPermissionSet: true,
                mePermissions: [...action.payload]
            };

        default:
            return state;
    }
};

const setActiveUnit = (dispatch) => (unit) => {
    dispatch({ type: SET_ACTIVE_UNIT, payload: unit });
};

const setAllUnits = (dispatch) => (units) => dispatch({ type: SET_ALL_UNITS, payload: units });

const updateActiveUnit = (dispatch) => (unit) => {
    dispatch({ type: UPDATE_ACTIVE_UNIT, payload: unit });
};

const setMePermissions = (dispatch) => (permissions) => {
    dispatch({ type: SET_ME_PERMISSIONS, payload: permissions });
};

const computePermissions = (activeUnit = null, mePermissions) => {
    if (activeUnit && mePermissions) {
        const { id } = activeUnit;
        const result = mePermissions.find((p) => {
            return id && p.unitId.toString() === id.toString();
        });

        return result?.permissions || [];
    }

    return [];
};

export const { Provider, Context } = createDataContext(
    unitsReducer,
    {
        setActiveUnit,
        updateActiveUnit,
        setMePermissions,
        setAllUnits
    },
    {
        activeUnit: null,
        activePermissions: [],
        mePermissions: [],
        units: []
    }
);
