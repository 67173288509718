import React from 'react';
import PropTypes from 'prop-types';

import { Box, ButtonBase, GridList, GridListTile, isWidthUp, Typography, withWidth } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

const imageGridStyle = (image, selectedIndex, color) => ({
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
    backgroundImage: image && `url(${image})`,
    backgroundPosition: 'center center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    border: selectedIndex ? `3px solid ${color}` : null,
    borderRadius: '3px'
});

export const PreviewImageGrid = withWidth()(({ images, activeIndex, setActiveIndex, onClickMore, width }) => {
    const theme = useTheme();

    const getGridListCols = () => {
        let cols = 1;

        if (isWidthUp('md', width)) {
            cols = 7;
        } else if (isWidthUp('sm', width)) {
            cols = 4;
        } else if (isWidthUp('xs', width)) {
            cols = 1;
        }

        return cols;
    };

    // preview only the first N (calculated by cols) and show e.g. "+6" in a gray box
    const nCols = getGridListCols();
    const firstN = images.slice(0, nCols);
    const rest = images.length > firstN.length ? images.length - nCols : 0;

    return (
        <Box width={1} height={1} overflow='hidden'>
            <GridList cols={nCols + 1} cellHeight={48}>
                {firstN.map((image, index) => (
                    <GridListTile id='image-box' key={`${image}-${index}`}>
                        <ButtonBase
                            color='primary'
                            style={imageGridStyle(image, activeIndex === index, theme.palette.secondary.main)}
                            onClick={() => {
                                if (index === activeIndex) {
                                    setActiveIndex(-1);
                                } else {
                                    setActiveIndex(index);
                                }
                            }}
                        />
                    </GridListTile>
                ))}
                {rest > 0 && (
                    <GridListTile id='more-box'>
                        <Box
                            width={1}
                            height={1}
                            display='flex'
                            borderRadius={3}
                            alignItems='center'
                            justifyContent='center'
                            bgcolor={theme.palette.background.grey}
                        >
                            <ButtonBase style={{ width: '100%', height: '100%' }} onClick={onClickMore}>
                                <Typography variant='body1'>{`+${rest}`}</Typography>
                            </ButtonBase>
                        </Box>
                    </GridListTile>
                )}
            </GridList>
        </Box>
    );
});

PreviewImageGrid.propTypes = {
    images: PropTypes.arrayOf(PropTypes.string),
    activeIndex: PropTypes.number,
    setActiveIndex: PropTypes.func,
    onClickMore: PropTypes.func,
    width: PropTypes.string
};
