/* eslint-disable */
export const FIGMA_MOCK = {
    CAMPAIGN_GROUPS_MOCK: [
        {
            id: 'Campaign group id 1',
            name: 'Campaign group name 1',
            status: 'Published',
            notice: 'In review',
            progress: 'Published (active)',
            campaignCount: 1,
            adSetCount: 1,
            adsCount: 1,
            dateInterval: {
                startDate: new Date(2021, 4, 20),
                endDate: new Date(2021, 7, 31)
            },
            stats: {
                clicks: 1507,
                impressions: 76000,
                renevue: 590000,
                spending: 700000,
                budget: 900000
            },
            networks: [
                {
                    name: 'Linkedin',
                    fail: false
                },
                {
                    name: 'Google',
                    fail: false
                },
                {
                    name: 'Facebook',
                    fail: false
                }
            ]
        },
        {
            id: 'Campaign group id 2',
            name: 'Campaign group name 2',
            status: 'Published',
            notice: 'Warning! some errors',
            progress: 'Publiched (paused)',
            campaignCount: 1,
            adSetCount: 1,
            adsCount: 1,
            dateInterval: {
                startDate: new Date(2021, 4, 20),
                endDate: new Date(2021, 7, 31)
            },
            stats: {
                clicks: 1507,
                impressions: 76000,
                renevue: 590000,
                spending: 700000,
                budget: 900000
            },
            networks: [
                {
                    name: 'Linkedin',
                    fail: false
                },
                {
                    name: 'Google',
                    fail: false
                },
                {
                    name: 'Facebook',
                    fail: true
                }
            ]
        },
        {
            id: 'Campaign group id 3',
            name: 'Campaign group name 3',
            status: 'Published',
            notice: 'Failed',
            progress: 'Failed',
            campaignCount: 1,
            adSetCount: 1,
            adsCount: 1,
            dateInterval: {
                startDate: new Date(2021, 11, 20),
                endDate: new Date(2021, 11, 31)
            },
            stats: {
                clicks: 0,
                impressions: 0,
                renevue: 0,
                spending: 0,
                budget: 0
            },
            networks: [
                {
                    name: 'Linkedin',
                    fail: true
                },
                {
                    name: 'Google',
                    fail: true
                },
                {
                    name: 'Facebook',
                    fail: true
                }
            ]
        },
        {
            id: 'Campaign group id 4',
            name: 'Campaign group name 4',
            status: 'Published',
            notice: 'In review',
            progress: 'Publishing (in process)',
            campaignCount: 1,
            adSetCount: 1,
            adsCount: 1,
            dateInterval: {
                startDate: new Date(2021, 4, 20),
                endDate: new Date(2021, 7, 31)
            },
            stats: {
                clicks: 1507,
                impressions: 76000,
                renevue: 590000,
                spending: 700000,
                budget: 900000
            },
            networks: [
                {
                    name: 'Linkedin',
                    fail: false
                },
                {
                    name: 'Google',
                    fail: false
                },
                {
                    name: 'Facebook',
                    fail: false
                }
            ]
        },
        {
            id: 'Campaign group id 5',
            name: 'Campaign group name 5',
            status: 'Published',
            notice: 'all good',
            progress: 'Published (active)',
            campaignCount: 4,
            adSetCount: 6,
            adsCount: 6,
            dateInterval: {
                startDate: new Date(2021, 4, 20),
                endDate: new Date(2021, 7, 31)
            },
            stats: {
                clicks: 1507,
                impressions: 76000,
                renevue: 590000,
                spending: 700000,
                budget: 900000
            },
            networks: [
                {
                    name: 'Google',
                    fail: false
                },
                {
                    name: 'Facebook',
                    fail: false
                }
            ]
        },
        {
            id: 'Campaign group id 6',
            name: 'Campaign group name 6',
            status: 'Draft',
            notice: 'Edit draft',
            progress: 'Draft',
            campaignCount: 1,
            adSetCount: 1,
            adsCount: 1,
            dateInterval: {
                startDate: new Date(2021, 4, 20),
                endDate: new Date(2021, 7, 31)
            },
            networks: [
                {
                    name: 'Linkedin',
                    fail: false
                },
                {
                    name: 'Google',
                    fail: false
                },
                {
                    name: 'Facebook',
                    fail: false
                }
            ]
        }
    ],
    CAMPAIGN_MOCK: [
        {
            id: 'Campaign id 1',
            name: 'Campaign name 1',
            status: 'Draft',
            notice: 'Edit draft',
            progress: 'Published (active)',
            campaignGroupParentId: 'Campaign group id 1',
            adSetCount: 1,
            adsCount: 1,
            dateInterval: {
                startDate: new Date(2021, 4, 20),
                endDate: new Date(2021, 7, 31)
            },
            network: {
                name: 'Linkedin',
                fail: false
            }
        },
        {
            id: 'Campaign id 2',
            name: 'Campaign name 2',
            status: 'Draft',
            notice: 'Edit draft',
            progress: 'Published (active)',
            campaignGroupParentId: 'Campaign group id 1',
            adSetCount: 1,
            adsCount: 1,
            dateInterval: {
                startDate: new Date(2021, 4, 20),
                endDate: new Date(2021, 7, 31)
            },
            network: {
                name: 'Linkedin',
                fail: false
            }
        },
        {
            id: 'Campaign id 3',
            name: 'Campaign name 3',
            status: 'Draft',
            notice: 'Edit draft',
            progress: 'Published (active)',
            campaignGroupParentId: 'Campaign group id 2',
            adSetCount: 1,
            adsCount: 1,
            dateInterval: {
                startDate: new Date(2021, 4, 20),
                endDate: new Date(2021, 7, 31)
            },
            network: {
                name: 'Linkedin',
                fail: false
            }
        },
        {
            id: 'Campaign id 4',
            name: 'Campaign name 4',
            status: 'Draft',
            notice: 'Edit draft',
            progress: 'Published (active)',
            campaignGroupParentId: 'Campaign group id 2',
            adSetCount: 1,
            adsCount: 1,
            dateInterval: {
                startDate: new Date(2021, 4, 20),
                endDate: new Date(2021, 7, 31)
            },
            network: {
                name: 'Linkedin',
                fail: false
            }
        },
        {
            id: 'Campaign id 5',
            name: 'Campaign name 5',
            status: 'Draft',
            notice: 'Edit draft',
            progress: 'Published (active)',
            campaignGroupParentId: 'Campaign group id 2',
            adSetCount: 1,
            adsCount: 1,
            dateInterval: {
                startDate: new Date(2021, 4, 20),
                endDate: new Date(2021, 7, 31)
            },
            network: {
                name: 'Linkedin',
                fail: false
            }
        },
        {
            id: 'Campaign id 6',
            name: 'Campaign name 6',
            status: 'Draft',
            notice: 'Edit draft',
            progress: 'Published (active)',
            campaignGroupParentId: 'Campaign group id 3',
            adSetCount: 1,
            adsCount: 1,
            dateInterval: {
                startDate: new Date(2021, 4, 20),
                endDate: new Date(2021, 7, 31)
            },
            network: {
                name: 'Linkedin',
                fail: false
            }
        },
        {
            id: 'Campaign id 7',
            name: 'Campaign name 7',
            status: 'Draft',
            notice: 'Edit draft',
            progress: 'Published (active)',
            campaignGroupParentId: 'Campaign group id 4',
            adSetCount: 1,
            adsCount: 1,
            dateInterval: {
                startDate: new Date(2021, 4, 20),
                endDate: new Date(2021, 7, 31)
            },
            network: {
                name: 'Linkedin',
                fail: false
            }
        },
        {
            id: 'Campaign id 8',
            name: 'Campaign name 8',
            status: 'Draft',
            notice: 'Edit draft',
            progress: 'Published (active)',
            campaignGroupParentId: 'Campaign group id 4',
            adSetCount: 1,
            adsCount: 1,
            dateInterval: {
                startDate: new Date(2021, 4, 20),
                endDate: new Date(2021, 7, 31)
            },
            network: {
                name: 'Linkedin',
                fail: false
            }
        },
        {
            id: 'Campaign id 9',
            name: 'Campaign name 9',
            status: 'Draft',
            notice: 'Edit draft',
            progress: 'Published (active)',
            campaignGroupParentId: 'Campaign group id 4',
            adSetCount: 1,
            adsCount: 1,
            dateInterval: {
                startDate: new Date(2021, 4, 20),
                endDate: new Date(2021, 7, 31)
            },
            network: {
                name: 'Linkedin',
                fail: false
            }
        },
        {
            id: 'Campaign id 10',
            name: 'Campaign name 10',
            status: 'Draft',
            notice: 'Edit draft',
            progress: 'Published (active)',
            campaignGroupParentId: 'Campaign group id 4',
            adSetCount: 1,
            adsCount: 1,
            dateInterval: {
                startDate: new Date(2021, 4, 20),
                endDate: new Date(2021, 7, 31)
            },
            network: {
                name: 'Linkedin',
                fail: false
            }
        },
        {
            id: 'Campaign id 11',
            name: 'Campaign name 11',
            status: 'Draft',
            notice: 'Edit draft',
            progress: 'Published (active)',
            campaignGroupParentId: 'Campaign group id 5',
            adSetCount: 1,
            adsCount: 1,
            dateInterval: {
                startDate: new Date(2021, 4, 20),
                endDate: new Date(2021, 7, 31)
            },
            network: {
                name: 'Linkedin',
                fail: false
            }
        },
        {
            id: 'Campaign id 12',
            name: 'Campaign name 12',
            status: 'Draft',
            notice: 'Edit draft',
            progress: 'Published (active)',
            campaignGroupParentId: 'Campaign group id 5',
            adSetCount: 1,
            adsCount: 1,
            dateInterval: {
                startDate: new Date(2021, 4, 20),
                endDate: new Date(2021, 7, 31)
            },
            network: {
                name: 'Linkedin',
                fail: false
            }
        },
        {
            id: 'Campaign id 13',
            name: 'Campaign name 13',
            status: 'Draft',
            notice: 'Edit draft',
            progress: 'Published (active)',
            campaignGroupParentId: 'Campaign group id 6',
            adSetCount: 1,
            adsCount: 1,
            dateInterval: {
                startDate: new Date(2021, 4, 20),
                endDate: new Date(2021, 7, 31)
            },
            network: {
                name: 'Linkedin',
                fail: false
            }
        }
    ]
};

const STATUS_ENUM = ['Published', 'Draft'];

const NOTICE_ENUM = ['In review', 'Warning! some errors', 'Failed', 'all good', 'Edit draft'];

const PROGRESS_ENUM = ['Publishing (in process)', 'Published (active)', 'Publiched (paused)', 'Failed', 'Draft'];

const NETWORK_ENUM = ['Linkedin', 'Google', 'Facebook'];

const getChildrenNoticeWarnFailRevIndexes = (parentNotice, childrenCount) => {
    let warningIndexes = [];
    let failedIndexes = [];
    let inReviewIndexes = [];
    if (parentNotice === 'Warning! some errors') {
        const warningCount = Math.ceil(Math.random() * (childrenCount - 1) + 1);
        const failedCount = Math.round(Math.random() * (childrenCount - warningCount));
        let recentWarningCounter = warningCount;
        let recentFailedCounter = failedCount;
        let recentOtherCounter = childrenCount - warningCount - failedCount;
        for (let j = 0; j < childrenCount; ++j) {
            if (recentWarningCounter + recentFailedCounter) {
                if (recentWarningCounter && recentFailedCounter && recentOtherCounter) {
                    let r = Math.random();
                    if (r > 0.66) {
                        warningIndexes.push(j);
                        --recentWarningCounter;
                    } else if (r > 0.33) {
                        failedIndexes.push(j);
                        --recentFailedCounter;
                    } else {
                        --recentOtherCounter;
                    }
                } else if (recentWarningCounter && recentFailedCounter) {
                    let r = Math.random();
                    if (r > 0.5) {
                        warningIndexes.push(j);
                        --recentWarningCounter;
                    } else {
                        failedIndexes.push(j);
                        --recentFailedCounter;
                    }
                } else if (recentWarningCounter && recentOtherCounter) {
                    let r = Math.random();
                    if (r > 0.5) {
                        warningIndexes.push(j);
                        --recentWarningCounter;
                    } else {
                        --recentOtherCounter;
                    }
                } else if (recentFailedCounter && recentOtherCounter) {
                    let r = Math.random();
                    if (r > 0.5) {
                        failedIndexes.push(j);
                        --recentFailedCounter;
                    } else {
                        --recentOtherCounter;
                    }
                } else if (recentWarningCounter) {
                    warningIndexes.push(j);
                    --recentWarningCounter;
                } else if (recentFailedCounter) {
                    failedIndexes.push(j);
                    --recentFailedCounter;
                }
            } else break;
        }
    } else if (parentNotice === 'In review') {
        const inReviewCount = Math.ceil(Math.random() * (childrenCount - 1) + 1);
        let recentInReviewCounter = inReviewCount;
        let recentOtherCounter = childrenCount - recentInReviewCounter;
        for (let j = 0; j < childrenCount; ++j) {
            if (recentInReviewCounter) {
                if (recentInReviewCounter && recentOtherCounter) {
                    let r = Math.random();
                    if (r > 0.5) {
                        inReviewIndexes.push(j);
                        --recentInReviewCounter;
                    } else {
                        --recentOtherCounter;
                    }
                } else {
                    inReviewIndexes.push(j);
                    --recentInReviewCounter;
                }
            } else break;
        }
    }

    return {
        warningIndexes,
        failedIndexes,
        inReviewIndexes
    };
};

const statSummary = (summary, r) => {
    const { clicks, impressions, renevue, spending, budget } = summary;
    const { stats } = r;

    return {
        clicks: clicks + (+stats?.clicks || 0),
        impressions: impressions + (+stats?.impressions || 0),
        renevue: renevue + (+stats?.renevue || 0),
        spending: spending + (+stats?.spending || 0),
        budget: budget + (+stats?.budget || 0)
    };
};

export const generateMockData = (count = 1000, childMaxCount = 10) => {
    let campaignGroups = [],
        campaigns = [],
        adSet = [],
        ads = [];

    const minDate = +new Date(2021, 0, 0);
    const maxDate = +new Date(2022, 0, 0);

    for (let i = 0; i < count; ++i) {
        const id = `Campaign group id ${i + 1}`;
        const name = `Campaign group name ${i + 1}`;
        const status = STATUS_ENUM[Math.random() > 0.15 ? 0 : 1];
        const notice = NOTICE_ENUM[status === 'Draft' ? 4 : Math.floor(Math.random() * 4)];
        const progress =
            PROGRESS_ENUM[
                status === 'Draft'
                    ? 4
                    : notice === 'Failed'
                    ? 3
                    : notice === 'In review'
                    ? Math.random() > 0.5
                        ? 0
                        : 1
                    : Math.floor(Math.random() * 3)
            ];
        const startDate = new Date(Math.round(Math.random() * (maxDate - minDate) + minDate));
        const endDate = new Date(Math.round(Math.random() * (maxDate - +startDate) + +startDate));
        const dateInterval = {
            startDate,
            endDate
        };

        const campaignCount = Math.ceil(Math.random() * NETWORK_ENUM.length);

        // const { warningIndexes, failedIndexes, inReviewIndexes } = getChildrenNoticeWarnFailRevIndexes(notice, campaignCount);
        const campaignNoticeWarnFailRevIndexes = getChildrenNoticeWarnFailRevIndexes(notice, campaignCount);

        let networks = [];
        let recentNetEnum = [...NETWORK_ENUM];
        for (let j = 0; j < campaignCount; ++j) {
            const netIndex = Math.floor(Math.random() * recentNetEnum.length);
            const netName = recentNetEnum[netIndex];
            recentNetEnum.splice(netIndex, 1);
            const netFail =
                notice === 'Failed' ? true : notice === 'Warning! some errors' ? !!Math.round(Math.random()) : false;
            const network = {
                name: netName,
                fail: netFail
            };
            networks.push(network);

            const campaignGroupParentId = id;
            const campaignId = `Campaign id ${i + 1}-${j + 1}`;
            const campaignName = `Campaign name ${i + 1}-${j + 1}`;
            let campaignNotice = notice;
            if (notice === 'Warning! some errors') {
                if (campaignNoticeWarnFailRevIndexes.failedIndexes.includes(j)) {
                    campaignNotice = 'Failed';
                } else if (campaignNoticeWarnFailRevIndexes.warningIndexes.includes(j)) {
                    campaignNotice = 'Warning! some errors';
                } else {
                    const r = Math.random();
                    if (r > 0.5) {
                        campaignNotice = 'In review';
                    } else {
                        campaignNotice = 'all good';
                    }
                }
            } else if (notice === 'In review') {
                if (campaignNoticeWarnFailRevIndexes.inReviewIndexes.includes(j)) {
                    campaignNotice = 'In review';
                } else {
                    campaignNotice = 'all good';
                }
            }

            const adSetCount = Math.ceil(Math.random() * childMaxCount);

            // const { warningIndexes, failedIndexes, inReviewIndexes } = getChildrenNoticeWarnFailRevIndexes(campaignNotice, adSetCount);
            const adSetNoticeWarnFailRevIndexes = getChildrenNoticeWarnFailRevIndexes(campaignNotice, adSetCount);

            for (let k = 0; k < adSetCount; ++k) {
                const campaignParentId = campaignId;
                const adSetId = `adSet id ${i + 1}-${j + 1}-${k + 1}`;
                const adSetName = `adSet name ${i + 1}-${j + 1}-${k + 1}`;
                let adSetNotice = campaignNotice;
                if (campaignNotice === 'Warning! some errors') {
                    if (adSetNoticeWarnFailRevIndexes.failedIndexes.includes(j)) {
                        adSetNotice = 'Failed';
                    } else if (adSetNoticeWarnFailRevIndexes.warningIndexes.includes(j)) {
                        adSetNotice = 'Warning! some errors';
                    } else {
                        const r = Math.random();
                        if (r > 0.5) {
                            adSetNotice = 'In review';
                        } else {
                            adSetNotice = 'all good';
                        }
                    }
                } else if (campaignNotice === 'In review') {
                    if (adSetNoticeWarnFailRevIndexes.inReviewIndexes.includes(j)) {
                        adSetNotice = 'In review';
                    } else {
                        adSetNotice = 'all good';
                    }
                }

                const adsCount = Math.ceil(Math.random() * childMaxCount);

                // const { warningIndexes, failedIndexes, inReviewIndexes } = getChildrenNoticeWarnFailRevIndexes(adSetNotice, adsCount);
                const adsNoticeWarnFailRevIndexes = getChildrenNoticeWarnFailRevIndexes(adSetNotice, adsCount);

                for (let l = 0; l < adsCount; ++l) {
                    const adsetParentId = adSetId;
                    const adsId = `ads id ${i + 1}-${j + 1}-${k + 1}-${l + 1}`;
                    const adsName = `ads name ${i + 1}-${j + 1}-${k + 1}-${l + 1}`;
                    let adsNotice = adSetNotice;
                    if (adSetNotice === 'Warning! some errors') {
                        if (adsNoticeWarnFailRevIndexes.failedIndexes.includes(j)) {
                            adsNotice = 'Failed';
                        } else if (adsNoticeWarnFailRevIndexes.warningIndexes.includes(j)) {
                            adsNotice = 'Warning! some errors';
                        } else {
                            const r = Math.random();
                            if (r > 0.5) {
                                adsNotice = 'In review';
                            } else {
                                adsNotice = 'all good';
                            }
                        }
                    } else if (adSetNotice === 'In review') {
                        if (adsNoticeWarnFailRevIndexes.inReviewIndexes.includes(j)) {
                            adsNotice = 'In review';
                        } else {
                            adsNotice = 'all good';
                        }
                    }

                    const stats = status === 'Published' && {
                        clicks: adsNotice === 'Failed' ? 0 : Math.ceil(Math.random() * 100000),
                        impressions: adsNotice === 'Failed' ? 0 : Math.ceil(Math.random() * 100000),
                        renevue: adsNotice === 'Failed' ? 0 : Math.ceil(Math.random() * 100000),
                        spending: adsNotice === 'Failed' ? 0 : Math.ceil(Math.random() * 100000),
                        budget: adsNotice === 'Failed' ? 0 : Math.ceil(Math.random() * 100000)
                    };

                    ads.push({
                        id: adsId,
                        name: adsName,
                        status,
                        notice: adsNotice,
                        progress,
                        campaignGroupParentId,
                        campaignParentId,
                        adsetParentId,
                        dateInterval,
                        stats,
                        network
                    });
                }

                const stats =
                    status === 'Published' &&
                    ads
                        .filter((ad) => ad.adsetParentId === adSetId)
                        .reduce(statSummary, {
                            clicks: 0,
                            impressions: 0,
                            renevue: 0,
                            spending: 0,
                            budget: 0
                        });

                adSet.push({
                    id: adSetId,
                    name: adSetName,
                    status,
                    notice: adSetNotice,
                    progress,
                    campaignGroupParentId,
                    campaignParentId,
                    adsCount,
                    dateInterval,
                    stats,
                    network
                });
            }

            const stats =
                status === 'Published' &&
                adSet
                    .filter((ad) => ad.campaignParentId === campaignId)
                    .reduce(statSummary, {
                        clicks: 0,
                        impressions: 0,
                        renevue: 0,
                        spending: 0,
                        budget: 0
                    });

            campaigns.push({
                id: campaignId,
                name: campaignName,
                status,
                notice: campaignNotice,
                progress,
                campaignGroupParentId,
                adSetCount,
                adsCount: ads.length,
                dateInterval,
                stats,
                network
            });
        }

        const stats =
            status === 'Published' &&
            campaigns
                .filter((campaign) => campaign.campaignGroupParentId === id)
                .reduce(statSummary, {
                    clicks: 0,
                    impressions: 0,
                    renevue: 0,
                    spending: 0,
                    budget: 0
                });

        campaignGroups.push({
            id,
            name,
            status,
            notice,
            progress,
            campaignCount,
            adSetCount: adSet.length,
            adsCount: ads.length,
            dateInterval,
            stats,
            networks
        });
    }

    return {
        campaignGroups,
        campaigns,
        adSet,
        ads
    };
};
