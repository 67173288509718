export const AdType = Object.freeze({
    Carousel: 'carousel',
    GoogleDisplay: 'DISPLAY',
    Image: 'image',
    Search: 'SEARCH',
    Shopping: 'SHOPPING',
    Video: 'video',
    HostedDisplay: 'Karusell',
    HostedFireplace: 'fireplace'
}); // TODO: Clean up these so that we get unique keys

export const AdTypeFieldType = Object.freeze({
    SELECT: 'Select',
    IMAGE: 'Image',
    URL: 'Url',
    TEXT: 'String'
});
