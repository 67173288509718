import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { QRCodeSVG } from 'qrcode.react';
import * as validator from 'validator';

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Grid,
    IconButton,
    InputAdornment,
    Link,
    Paper,
    TextField,
    Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { makeStyles } from '@material-ui/styles';

import { MFAStatus } from 'common/enums';

const useStyles = makeStyles((theme) => ({
    content: {
        height: '100%'
    },
    contentBody: {
        flexGrow: 1,
        display: 'flex',
        align: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('lg')]: {
            justifyContent: 'center'
        }
    },
    title: {
        fontSize: '13px'
    },
    textField: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    signInButton: {
        margin: theme.spacing(2, 0),
        marginBottom: -theme.spacing(10)
    },
    paper: {
        marginTop: theme.spacing(15)
    },
    capsLockText: {
        color: ' #B00020',
        textAlign: 'left',
        fontSize: '11px',
        marginTop: -theme.spacing(1),
        marginLeft: theme.spacing(1.5)
    },
    upperContent: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    mfaInstructions: {
        listStyle: 'none',
        display: 'block',
        fontSize: '11px',
        textAlign: 'left'
    },
    mfaInstruction: {
        margin: '5px 0'
    }
}));

const SignIn = ({ onSignIn, setMFA, mfa }) => {
    const [mfaToken, setMFAToken] = useState('');
    const { t } = useTranslation();
    const classes = useStyles();
    const [showPassword, setShowPassword] = useState(true);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    const [formState, setFormState] = useState({
        // isValid: false,
        values: { email: '' },
        touched: {},
        errors: {}
    });

    const [capsLockOn, setCapsLockOn] = useState(false);

    const isEmail = validator.isEmail(formState.values.email);

    const handleCapsLock = (e) => {
        const capsLockOn = e.getModifierState('CapsLock');
        if (capsLockOn === true) {
            setCapsLockOn(true);
        } else {
            setCapsLockOn(false);
        }
    };

    const handleChange = (event) => {
        event.persist();

        if (mfa) {
            setMFA(null);
        }

        setFormState((formState) => ({
            ...formState,
            values: {
                ...formState.values,
                [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value
            },
            touched: {
                ...formState.touched,
                [event.target.name]: true
            }
        }));
    };

    const handleSignIn = (event) => {
        event.preventDefault();
        onSignIn({
            email: formState.values.email,
            password: formState.values.password,
            mfaToken
        });
    };

    const hasError = (field) => (formState.touched[field] && formState.errors[field] ? true : false);

    return (
        <Box height={1} align='center'>
            <Grid container item lg={4} xs={8} xl={4} md={6} sm={8}>
                <Paper className={classes.paper} elevation={8}>
                    <Box mx='10%' display='flex' align='center' alignItems='center' justifyContent='center'>
                        <form onSubmit={handleSignIn}>
                            <Box my={4}>
                                <Typography variant='h4' align='center'>
                                    {t('Log in')}
                                </Typography>
                            </Box>
                            <TextField
                                autoCapitalize='none'
                                autoCorrect='off'
                                spellCheck='false'
                                className={classes.textField}
                                error={!isEmail && formState.values.email.length > 0}
                                fullWidth
                                helperText={
                                    !isEmail && formState.values.email.length > 0
                                        ? t('Is not a valid email').toLowerCase()
                                        : ''
                                }
                                label={t('E-mail address')}
                                name='email'
                                onChange={handleChange}
                                type='text'
                                value={formState.values.email || ''}
                                variant='filled'
                            />
                            <TextField
                                className={classes.textField}
                                error={hasError('password')}
                                fullWidth
                                helperText={formState.errors.password ? <span>{t('Wrong password')}</span> : ''}
                                label={t('Password')}
                                name='password'
                                onChange={handleChange}
                                value={formState.values.password || ''}
                                variant='filled'
                                onKeyDown={handleCapsLock}
                                type={showPassword ? 'password' : 'text'}
                                InputProps={{
                                    // <-- This is where the toggle button is added.
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            {formState.errors.password ? (
                                                <IconButton
                                                    aria-label='toggle password visibility'
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {showPassword ? (
                                                        <VisibilityIcon style={{ color: '#B00020' }} />
                                                    ) : (
                                                        <VisibilityOffIcon style={{ color: '#B00020' }} />
                                                    )}
                                                </IconButton>
                                            ) : (
                                                <IconButton
                                                    aria-label='toggle password visibility'
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                </IconButton>
                                            )}
                                        </InputAdornment>
                                    )
                                }}
                            />

                            {capsLockOn && <div className={classes.capsLockText}>{t('Caps Lock is on')}</div>}

                            {(mfa?.status === MFAStatus.verified?.value || mfa?.status === MFAStatus.invalid.value) && (
                                <Grid container direction='column'>
                                    <Grid item className={classes.upperContent}>
                                        <Accordion>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                <Typography className={classes.title} align='left'>
                                                    QR Code
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <QRCodeSVG
                                                    id='secret-qrcode'
                                                    value={mfa?.otpauthUrl}
                                                    size={105}
                                                    includeMargin={true}
                                                    level='Q'
                                                />
                                                <ul className={classes.mfaInstructions}>
                                                    <li className={classes.mfaInstruction}>
                                                        1. Install the Microsoft/Google authenticator app
                                                    </li>
                                                    <li className={classes.mfaInstruction}>
                                                        2. In the app, add account{' '}
                                                    </li>
                                                    <li className={classes.mfaInstruction}>3. Scan the QR Code </li>
                                                    <li className={classes.mfaInstruction}>
                                                        4. App displays a six digit code, use it to Continue
                                                    </li>
                                                </ul>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            required
                                            focused
                                            variant='filled'
                                            fullWidth
                                            id='qr-code-token'
                                            className={classes.mfaToken}
                                            label={t('Insert one-time code from your app')}
                                            value={mfaToken}
                                            onChange={(e) => setMFAToken(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                            )}

                            <Box mt={4}>
                                <Link component={RouterLink} to='/recover' variant='h6'>
                                    <Typography color='primary' variant='body1'>
                                        {t('Forgot your password?')}{' '}
                                    </Typography>
                                </Link>
                            </Box>
                            <Box mt={6} mb={8}>
                                <Button
                                    fullWidth
                                    color='primary'
                                    //disabled={formState.errors.email}
                                    size='large'
                                    type='submit'
                                    variant='contained'
                                >
                                    {t('Log in')}
                                </Button>
                            </Box>
                        </form>
                    </Box>
                </Paper>
            </Grid>
        </Box>
    );
};

SignIn.propTypes = {
    onSignIn: PropTypes.func.isRequired,
    setMFA: PropTypes.func.isRequired,
    mfa: PropTypes.object
};

export default SignIn;
