import React from 'react';
import PropTypes from 'prop-types';

import { styled } from '@material-ui/core';
import { TreeItem } from '@material-ui/lab';

import { unitModel } from 'common/models';

import { TreeItemContent } from './TreeItemContent';

const StyledTreeItem = styled(TreeItem)(({ theme }) => ({
    color: theme.palette.text.secondary,
    fontSize: theme.typography.h6,

    '&:hover': {
        backgroundColor: theme.palette.action.hover
    }
}));

export const RecursiveTreeItem = ({ unitTree, selectSingleUnit, onSelectUnit, selectedUnits }) => {
    return (
        <React.Fragment>
            {unitTree.map((unit) => {
                return (
                    <StyledTreeItem
                        tabIndex='0'
                        key={unit.id}
                        nodeId={unit.id}
                        label={
                            <TreeItemContent
                                onClick={(e) => {
                                    e.stopPropagation();
                                    selectSingleUnit(unit);
                                }}
                                unit={unit}
                                onSelectUnit={onSelectUnit}
                                selectedUnits={selectedUnits}
                            />
                        }
                    >
                        {unit.treeChildren && (
                            <RecursiveTreeItem
                                unitTree={unit.treeChildren}
                                onSelectUnit={onSelectUnit}
                                selectedUnits={selectedUnits}
                                selectSingleUnit={selectSingleUnit}
                            />
                        )}
                    </StyledTreeItem>
                );
            })}
        </React.Fragment>
    );
};

RecursiveTreeItem.propTypes = {
    unitTree: PropTypes.arrayOf(PropTypes.shape(unitModel)),
    selectSingleUnit: PropTypes.func,
    selectedUnits: PropTypes.arrayOf(PropTypes.string),
    onSelectUnit: PropTypes.func
};
