import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';

import { KeywordsSelector } from 'common/components/InterestsAndKeywordsSelector/KeywordsSelector/KeywordsSelector';
import { keywordModel } from 'common/models';
import { TARGETING_ACTION_TYPE } from 'context/campaign/targetingReducer';
import { TargetingConditionType } from 'helpers/targeting';

export const KeywordsSelectorTabPanel = ({ dispatch, keywords, excludeKeywords }) => {
    return (
        <Grid container spacing={1}>
            <Grid item md={6} xs={12}>
                <KeywordsSelector
                    keywords={keywords}
                    onAdd={(keywords) => dispatch({ type: TARGETING_ACTION_TYPE.ADD_KEYWORDS, keywords })}
                    onRemove={(text) => dispatch({ type: TARGETING_ACTION_TYPE.REMOVE_KEYWORD, text })}
                    targetingConditionType={TargetingConditionType.Include}
                />
            </Grid>

            <Grid item md={6} xs={12}>
                <KeywordsSelector
                    keywords={excludeKeywords}
                    onAdd={(excludeKeywords) =>
                        dispatch({ type: TARGETING_ACTION_TYPE.ADD_EXCLUDE_KEYWORDS, excludeKeywords })
                    }
                    onRemove={(text) => dispatch({ type: TARGETING_ACTION_TYPE.REMOVE_EXCLUDE_KEYWORD, text })}
                    targetingConditionType={TargetingConditionType.Exclude}
                />
            </Grid>
        </Grid>
    );
};

KeywordsSelectorTabPanel.propTypes = {
    dispatch: PropTypes.func,
    keywords: PropTypes.arrayOf(PropTypes.shape(keywordModel)),
    excludeKeywords: PropTypes.arrayOf(PropTypes.shape(keywordModel))
};
