// import { map, sum, unzip } from 'lodash';
import { format } from 'date-fns';
import { map, sum, unzip, zip } from 'lodash';

export function sumByColumns(arrays) {
    return map(unzip(arrays), sum);
}

export function monthArray() {
    return Array.from(Array(12).keys());
}

export function emptyMonthArray() {
    return [...Array(12).fill(0)];
}

export function fillPeriodZeroValues(period) {
    /* if a period list contains e.g. data for month 1, 2 and 3,
     * return a full list from 1 to 12 with zero-values where applicable
     */
    const twelveMonths = emptyMonthArray();

    period.forEach((data, idx) => {
        if (typeof data === 'number') {
            twelveMonths[idx] = parseInt(data);
        } else {
            twelveMonths[data.month] = parseInt(data.value);
        }
    });

    return twelveMonths;
}

function asPeriodTuple(field) {
    const [month, value, cut] = field;

    return {
        month: month,
        value: value,
        cut: cut
    };
}

export function arrayToPeriod(values, cut) {
    if (!values) {
        values = emptyMonthArray();
    }

    return map(zip(monthArray(), values, cut), asPeriodTuple);
}

export function getMixedValues(arrays, translation = 'mixed') {
    /*
    input:

    [
        [1,2,3,...],
        [3,9,3,...]
    ]

    output:
    [ mixed, mixed, 3 ]
    */
    const mixedArray = map(unzip(arrays), (array) => {
        if (sum(array) / array.length === array[0]) {
            return array[0];
        } else {
            return translation;
        }
    });

    return mixedArray;
}

export function parseNumericInput(input) {
    const re = /[0-9]/g;
    const match = input.match(re, '');
    if (!match) {
        return 0;
    }

    return parseInt(match.join(''));
}

export function formatDate(date) {
    return format(date, 'dd/MM/yy HH:mm');
}
