import gql from 'graphql-tag';

import { FRAGMENT_PBI_REPORTS_CATEGORY } from './pbi.category';
/**
 *
 */

export const FRAGMENT_PBI_DATASOURCES = gql`
    fragment pbiDataSource on PbiDataSource {
        id
        name
        description
        sequence
        authorized
        status
        category {
            ...pbiCategory
        }
    }
    ${FRAGMENT_PBI_REPORTS_CATEGORY}
`;
