import React from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    topbar: {
        backgroundColor: theme.palette.background.paper,
        borderTopColor: theme.palette.primary.main,
        borderTopStyle: 'solid',
        borderTopWidth: theme.spacing(1),
        height: '100px',
        minHeight: '100px',
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4)
    }
}));

export const TopBar = ({ children }) => {
    const { topbar } = useStyles();

    return (
        <Grid className={topbar} container justify='space-between' alignItems='center'>
            {children}
        </Grid>
    );
};

TopBar.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
};
