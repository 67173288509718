import React from 'react';
import CountUp from 'react-countup';
import PropTypes from 'prop-types';

import { Box, Grid, IconButton, makeStyles, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { ArrowDownward, ArrowUpward, InfoOutlined } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';

import { formatIntWithSpaces, numberToMillion } from 'helpers/number';

import { CircularProgressIcon } from '../CircularProgressIcon/CircularProgressIcon';

const useStyles = makeStyles((theme) => ({
    parent: {
        backgroundColor: (props) => (props.slim ? grey[50] : theme.palette.common.white),
        height: (props) => (props.slim ? 104 : 124),
        border: `1px solid ${theme.palette.divider}`,
        borderTop: (props) => !props.borderTop && 'none',
        borderBottom: (props) => !props.borderBottom && 'none',
        borderLeft: (props) => !props.borderLeft && 'none',
        borderRight: (props) => !props.borderRight && 'none',
        minWidth: 256,
        width: '100%'
    },
    info: {
        fontSize: 16,
        color: theme.palette.grey.A200
    }
}));

export const GraphHeaderCard = ({
    percentage,
    icon,
    color,
    colorLight,
    borderLeft,
    borderRight,
    borderTop = false,
    borderBottom = true,
    comparisonPercentage,
    comparisonDirection,
    animationOn = true,
    title,
    amount,
    subtitle,
    slim,
    showInfo = true,
    loading
}) => {
    const { parent, info } = useStyles({
        borderLeft,
        borderRight,
        borderTop,
        borderBottom,
        comparisonDirection,
        slim
    });

    const numberFormatting = (number) =>
        number >= 1000000 ? numberToMillion(number, true) : formatIntWithSpaces(number);

    const comparisonColor = comparisonDirection === 'up' ? 'primary' : 'error';

    return (
        <Grid container className={parent} justify='space-between' alignItems='center'>
            <Box pl={2}>
                <Grid item xs={4}>
                    <CircularProgressIcon
                        percentage={!loading ? percentage : 0}
                        icon={icon}
                        color={color}
                        colorLight={colorLight}
                    />
                </Grid>
            </Box>
            <Grid item xs={6}>
                <Typography variant='overline'>{title}</Typography>
                <Typography variant={slim ? 'h6' : 'h4'}>
                    {loading ? (
                        <Skeleton animation='wave' />
                    ) : (
                        <CountUp
                            formattingFn={numberFormatting}
                            delay={animationOn ? 0.5 : 0}
                            duration={animationOn ? 1.5 : 0}
                            preserveValue
                            end={amount || 0}
                        />
                    )}
                </Typography>
                <Typography variant='caption'>{loading ? <Skeleton animation='wave' /> : subtitle}</Typography>
            </Grid>
            <Box height={1}>
                <Grid item container xs={2} direction='column' justify='space-between' alignItems='flex-end'>
                    {showInfo && (
                        <IconButton>
                            <InfoOutlined className={info} />
                        </IconButton>
                    )}
                    <Box mb={5} pr={1.5}>
                        <Grid container alignItems='center' justify='flex-end'>
                            <Typography variant='caption' color={comparisonColor}>
                                {!loading && comparisonPercentage && `${comparisonPercentage}%`}
                            </Typography>
                            {!loading && comparisonDirection && comparisonDirection === 'up' ? (
                                <ArrowUpward color={comparisonColor} />
                            ) : comparisonDirection === 'down' ? (
                                <ArrowDownward color={comparisonColor} />
                            ) : (
                                <React.Fragment />
                            )}
                        </Grid>
                    </Box>
                    <span />
                </Grid>
            </Box>
        </Grid>
    );
};

GraphHeaderCard.propTypes = {
    percentage: PropTypes.number,
    icon: PropTypes.element,
    color: PropTypes.string,
    colorLight: PropTypes.string,
    borderLeft: PropTypes.bool,
    borderRight: PropTypes.bool,
    borderTop: PropTypes.bool,
    borderBottom: PropTypes.bool,
    comparisonDirection: PropTypes.oneOf(['up', 'down']),
    comparisonPercentage: PropTypes.number,
    title: PropTypes.string,
    amount: PropTypes.number,
    subtitle: PropTypes.string,
    slim: PropTypes.bool,
    loading: PropTypes.bool,
    animationOn: PropTypes.bool,
    showInfo: PropTypes.bool
};
