import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
    ClickAwayListener,
    FormControl,
    InputLabel,
    makeStyles,
    MenuItem,
    Paper,
    Popper,
    Select
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

import { materialUnexposed } from 'layouts/theme/customColor';

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: '100%',
        height: 56
    },
    selector: {
        '&.Mui-disabled': {
            color: theme.palette.text.primary,
            backgroundColor: (props) => (props.click ? materialUnexposed.backgroundHover : grey[50]),
            cursor: 'pointer'
        },
        '&.Mui-disabled:focus': {
            outline: 'none',
            backgroundColor: materialUnexposed.backgroundHover
        },
        '&.Mui-disabled:hover': {
            backgroundColor: materialUnexposed.backgroundHover
        },
        '&.MuiFilledInput-underline.Mui-disabled:before': {
            borderBottomStyle: 'solid',
            borderBottomColor: (props) => (props.click ? theme.palette.primary.main : theme.palette.divider)
        }
    },
    select: {
        '&.Mui-disabled': {
            cursor: 'pointer'
        }
    },
    selectorIcon: {
        transform: (props) => props.click && 'rotate(180deg)',
        '&.Mui-disabled': {
            color: theme.palette.text.secondary
        }
    },
    labelStyle: {
        '&.Mui-disabled': {
            color: (props) => props.click && theme.palette.primary.main
        }
    },
    popperContent: {
        width: (props) => props.anchorWidth,
        height: 736,
        overflowY: 'auto'
    }
}));

export const PopperSelect = ({ label, inputText, children, materialTable }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { formControl, selector, selectorIcon, select, labelStyle, popperContent } = useStyles({
        click: Boolean(anchorEl),
        anchorWidth: anchorEl && anchorEl.offsetWidth
    });

    const handleClick = (event) => setAnchorEl(anchorEl ? null : event.currentTarget);
    const handleKeyPress = (event) => event.key === 'Enter' && setAnchorEl(anchorEl ? null : event.currentTarget);
    const handleClickAway = (e) => {
        if (materialTable) {
            switch (e.target.nodeName) {
                case 'INPUT':
                case 'SPAN':
                case 'BUTTON':
                case 'BODY':
                case 'svg':
                case 'path':
                    break;
                default:
                    setAnchorEl(null);
            }
        } else {
            setAnchorEl(null);
        }
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div style={{ width: '100%' }}>
                <FormControl
                    className={formControl}
                    variant='filled'
                    disabled
                    onKeyDown={handleKeyPress}
                    onClick={handleClick}
                >
                    <InputLabel shrink id='multiple-select-filter' className={labelStyle}>
                        {label}
                    </InputLabel>
                    <Select
                        labelId='multiple-select-filter'
                        value={inputText}
                        tabIndex={0}
                        classes={{ icon: selectorIcon, select: select }}
                        className={selector}
                    >
                        <MenuItem value={inputText}>{inputText}</MenuItem>
                    </Select>
                </FormControl>
                <Popper
                    id={anchorEl ? `popper-${label}` : undefined}
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    style={{ zIndex: 1 }}
                    modifiers={{ flip: { enabled: false } }}
                >
                    <Paper square={true} elevation={4} className={popperContent}>
                        {children}
                    </Paper>
                </Popper>
            </div>
        </ClickAwayListener>
    );
};

PopperSelect.propTypes = {
    inputText: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    children: PropTypes.element.isRequired,
    materialTable: PropTypes.bool
};
