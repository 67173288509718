import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { useMutation, useQueryClient } from '@tanstack/react-query';

const updateUnitReportConfig = async ({ unitId, reportId, versionNumber, reportVersionConfig }) => {
    const response = await fetch(
        `${process.env.REACT_APP_API_URL}/unit/${unitId}/powerBIReport/${reportId}/version/${versionNumber}/embedConfig`,
        {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(reportVersionConfig)
        }
    );
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const data = await response.json();

    return data;
};

const useUpdatePowerBIReportVersionEmbedConfigForUnit = () => {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    return useMutation(
        ({ unitId, reportId, versionNumber, reportVersionConfig }) =>
            updateUnitReportConfig({ unitId, reportId, versionNumber, reportVersionConfig }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(
                    (query) => query.queryKey[0] === 'unit/powerBIReport/version/embedConfig'
                );

                enqueueSnackbar(t('Report selection have been updated'), { variant: 'success' });
            },
            onError: (error) => {
                console.error(error);
                enqueueSnackbar(t('Could not update report selection'), { variant: 'error' });
            }
        }
    );
};

export default useUpdatePowerBIReportVersionEmbedConfigForUnit;
