import gql from 'graphql-tag';

export const FRAGMENT_FIELD_VALUE = gql`
    fragment fieldValue on FieldValue {
        fieldId
        dataJSON
        type
    }
`;

export const FRAGMENT_PRODUCT = gql`
    fragment product on Product {
        id
        readOnly
        fields {
            ...fieldValue
        }
    }
    ${FRAGMENT_FIELD_VALUE}
`;

export const FRAGMENT_PRODUCT_FIELD = gql`
    fragment productField on ProductField {
        id
        name
        fieldId
        label
        visibility
        fieldType
        field
    }
`;

export const FRAGMENT_PRODUCT_TYPE = gql`
    fragment productType on ProductType {
        id
        name
        fields {
            ...productField
        }
    }
    ${FRAGMENT_PRODUCT_FIELD}
`;

export const FRAGMENT_FEED = gql`
    fragment feed on Feed {
        id
        unitId
        name
        url
        fields {
            ...productField
        }
    }
    ${FRAGMENT_PRODUCT_FIELD}
`;
