import i18n from 'i18n';

const { REACT_APP_GOOGLE_MAP_API_KEY } = process.env;

export const MAP_CONSTANTS = Object.freeze({
    latKmDegree: 0.0089 // 1km in lat degree => 1 / 111.32km = 0.0089 for Google maps
});

export const mapConfig = {
    googleMapsApiKey: REACT_APP_GOOGLE_MAP_API_KEY,
    language: i18n.language,
    version: 'weekly',
    libraries: ['places']
};

export const kilometerToLatDegree = (km) => km * MAP_CONSTANTS.latKmDegree;
