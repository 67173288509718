import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { IconButton, Typography } from '@material-ui/core';
import { AddCircleOutline, Cancel, RemoveCircleOutline } from '@material-ui/icons';
import { TreeItem } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';

import { interestModel } from 'common/models';
import { selectedTypes } from 'helpers/targeting';

const useTreeItemStyles = makeStyles((theme) => ({
    root: {
        '&$selected > $content $label': {
            color: theme.palette.primary.main,
            backgroundColor: 'transparent'
        }
    },
    content: {},
    group: {},
    expanded: {},
    selected: {
        backgroundColor: 'transparent'
    },
    selectedInterest: {
        color: ({ selectedType }) =>
            selectedType === selectedTypes.Include
                ? theme.palette.primary.main
                : selectedType === selectedTypes.Exclude
                ? theme.palette.error.main
                : 'inherit'
    },
    label: {
        backgroundColor: 'transparent !important',
        cursor: 'default'
    },
    labelRoot: {
        backgroundColor: 'transparent',
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0.5, 0)
    },
    labelText: {
        color: 'inherit',
        flexGrow: 1
    },
    excludeButton: {
        color: theme.palette.error.main
    },
    iconButtons: {
        marginRight: theme.spacing(2)
    }
}));

export const InterestTreeItemComponent = ({
    interest,
    handleSelectIncludeInterest,
    handleSelectExcludeInterest,
    selectedIncludeInterests,
    onRemoveIncludeInterest,
    selectedExcludeInterests,
    onRemoveExcludeInterest
}) => {
    const getSelectedType = useCallback(() => {
        if (selectedIncludeInterests.indexOf(interest.key) !== -1) return selectedTypes.Include;
        else if (selectedExcludeInterests.indexOf(interest.key) !== -1) return selectedTypes.Exclude;
        else return selectedTypes.None;
    }, [selectedIncludeInterests, selectedExcludeInterests, interest.key]);
    const [selectedType, setSelectedType] = useState(getSelectedType());
    const classes = useTreeItemStyles({ selectedType });

    useEffect(() => {
        setSelectedType(getSelectedType());
    }, [getSelectedType]);

    return (
        <TreeItem
            key={interest.parentKey + interest.key}
            classes={{
                root: classes.root,
                content: classes.content,
                expanded: classes.expanded,
                selected: classes.selected,
                group: classes.group,
                label: classes.label
            }}
            nodeId={interest.key}
            label={
                <div className={classes.labelRoot}>
                    <div className={classes.labelText}>
                        <Typography variant='body2' className={classes.selectedInterest}>
                            {interest?.name?.split('/').pop()}
                        </Typography>
                    </div>
                    <div className={classes.iconButtons}>
                        {selectedType && selectedType === selectedTypes.Include ? (
                            <IconButton
                                color='default'
                                onClick={(e) => {
                                    e.preventDefault();
                                    onRemoveIncludeInterest(interest.key);
                                }}
                                size='small'
                                aria-label='remove interest'
                            >
                                <Cancel />
                            </IconButton>
                        ) : (
                            <IconButton
                                color='primary'
                                size='small'
                                disabled={selectedType === selectedTypes.Include || !interest.selectable}
                                onClick={(event) => {
                                    setSelectedType(selectedTypes.Include);
                                    handleSelectIncludeInterest(event, interest);
                                }}
                                aria-label='add interest'
                            >
                                <AddCircleOutline />
                            </IconButton>
                        )}

                        {selectedType && selectedType === selectedTypes.Exclude ? (
                            <IconButton
                                color='default'
                                onClick={(e) => {
                                    e.preventDefault();
                                    onRemoveExcludeInterest(interest.key);
                                }}
                                size='small'
                                aria-label='remove interest'
                            >
                                <Cancel />
                            </IconButton>
                        ) : (
                            <IconButton
                                size='small'
                                className={classes.excludeButton}
                                disabled={selectedType === selectedTypes.Exclude || !interest.selectable}
                                onClick={(event) => {
                                    setSelectedType(selectedTypes.Exclude);
                                    handleSelectExcludeInterest(event, interest);
                                }}
                                aria-label='add interest'
                            >
                                <RemoveCircleOutline />
                            </IconButton>
                        )}
                    </div>
                </div>
            }
        >
            {interest.children &&
                interest.children.length > 0 &&
                interest.children.map((childItem) => (
                    <InterestTreeItemComponent
                        key={'childTreeItem_' + childItem.parentKey + '_' + childItem.key}
                        handleSelectIncludeInterest={handleSelectIncludeInterest}
                        handleSelectExcludeInterest={handleSelectExcludeInterest}
                        interest={childItem}
                        selectedIncludeInterests={selectedIncludeInterests}
                        onRemoveIncludeInterest={onRemoveIncludeInterest}
                        selectedExcludeInterests={selectedExcludeInterests}
                        onRemoveExcludeInterest={onRemoveExcludeInterest}
                    />
                ))}
        </TreeItem>
    );
};

InterestTreeItemComponent.propTypes = {
    interest: PropTypes.shape(interestModel),
    selectedIncludeInterests: PropTypes.arrayOf(PropTypes.string),
    selectedExcludeInterests: PropTypes.arrayOf(PropTypes.string),
    onRemoveIncludeInterest: PropTypes.func,
    onRemoveExcludeInterest: PropTypes.func,
    handleSelectIncludeInterest: PropTypes.func,
    handleSelectExcludeInterest: PropTypes.func
};
