import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import {
    Avatar,
    Box,
    Checkbox,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemText,
    Typography,
    useMediaQuery
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import { getInitials, getName } from 'helpers/name';

import { NewUserInvite } from './User/NewUserInvite';
import Profile from './User/Profile';

const useStyles = makeStyles((theme) => ({
    paper: {
        maxHeight: 690,
        overflow: 'auto',
        height: '100%',
        width: '100%',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    section2: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(1, 1),
        minHeight: 200
    },

    root: {
        display: 'flex',
        marginLeft: 4,
        marginRight: 4
    },
    avatar: {
        marginRight: theme.spacing(1),
        fontSize: 12,
        marginTop: theme.spacing(1)
    },
    selectall: {
        marginTop: -4,
        marginLeft: theme.spacing(1.4)
    },

    errorIcon: {
        color: theme.palette.warning.light,
        fontSize: 40
    },

    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        height: 'auto'
    }
}));
const UserList = ({ users, searchResults, unitName, unitId, refetch }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [selectedUsers, setSelectedUsers] = useState([]);

    useEffect(() => {
        setSelectedUsers([]);
    }, [searchResults]);

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            setSelectedUsers([...users]);
        } else {
            setSelectedUsers([]);
        }
    };

    const handleSelectOne = (user) => {
        setSelectedUsers((prevSelected) => {
            if (prevSelected.some((prevUser) => prevUser.id === user.id)) {
                return prevSelected.filter((prevUser) => prevUser.id !== user.id);
            }

            return [...prevSelected, user];
        });
    };

    const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));

    return (
        <>
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item sm={12} xs={12}>
                        <Grid
                            container
                            spacing={1}
                            style={{ background: '#F5F5F5' }}
                            direction={matches ? 'row' : 'column-reverse'}
                        >
                            <Grid item xs={12} sm={3}>
                                <Checkbox
                                    className={classes.selectall}
                                    checked={selectedUsers.length === users.length}
                                    color='primary'
                                    indeterminate={selectedUsers.length > 0 && selectedUsers.length < users.length}
                                    onChange={handleSelectAll}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3} align={matches ? 'right' : 'left'}>
                                <Typography style={{ marginTop: 8, marginLeft: 16 }}>
                                    {t('Showing')} {selectedUsers.length} {t('Of').toLowerCase()} {users.length}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={6} align={matches ? 'right' : 'left'}>
                                <NewUserInvite unitId={unitId} unitName={unitName} refetch={refetch} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>

            <Grid container spacing={1}>
                <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                    <Box display='flex' flexDirection='row' height='100%'>
                        <List className={classes.paper}>
                            {searchResults.map((user) => {
                                return (
                                    <ListItem key={user.id}>
                                        <ListItemIcon>
                                            <Checkbox
                                                checked={selectedUsers.some(
                                                    (selectedUser) => selectedUser.id === user.id
                                                )}
                                                color='primary'
                                                onChange={() => handleSelectOne(user)}
                                            />
                                        </ListItemIcon>
                                        <ListItemAvatar>
                                            {user.verified.toString() === 'false' ? (
                                                <ErrorOutlineIcon className={classes.errorIcon} />
                                            ) : (
                                                <Avatar className={classes.avatar} src={user.avatarUrl}>
                                                    {getInitials(user)}
                                                </Avatar>
                                            )}
                                        </ListItemAvatar>
                                        <ListItemText primary={getName(user)} secondary={user.email} />
                                    </ListItem>
                                );
                            })}
                        </List>
                        <Divider className={classes.divider} orientation='vertical' />
                    </Box>
                </Grid>

                <Grid item xs>
                    {selectedUsers.length > 0 && (
                        <Box marginTop={2} padding={1}>
                            <Profile
                                selectedUsers={selectedUsers}
                                refetch={refetch}
                                unitId={unitId}
                                handleUnselect={handleSelectOne}
                                setSelectedUsers={setSelectedUsers}
                            />
                        </Box>
                    )}
                </Grid>
            </Grid>
        </>
    );
};

UserList.propTypes = {
    users: PropTypes.array.isRequired,
    searchResults: PropTypes.array,
    unitName: PropTypes.string,
    unitId: PropTypes.string,
    refetch: PropTypes.func
};

export default UserList;
