import React from 'react';
import PropTypes from 'prop-types';

import { Chip } from '@material-ui/core';
import { AddCircleOutline, RemoveCircleOutline } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

import { TargetingConditionType } from 'helpers/targeting';

const useStyles = makeStyles((theme) => ({
    chipContainer: {
        margin: theme.spacing(1)
    },
    chip: {
        margin: theme.spacing(0.2),
        maxWidth: '100%'
    },
    includeIcon: {
        color: theme.palette.primary.main
    },
    excludeIcon: {
        color: theme.palette.error.main
    }
}));

export const TargetingChipContainer = ({ selectedItems, targetingConditionType, onRemove }) => {
    const classes = useStyles();

    const icon =
        targetingConditionType === TargetingConditionType.Include ? (
            <AddCircleOutline className={classes.includeIcon} />
        ) : (
            <RemoveCircleOutline className={classes.excludeIcon} />
        );

    return (
        <div className={classes.chipContainer}>
            {selectedItems &&
                selectedItems.map((item) => (
                    <Chip
                        icon={icon}
                        className={classes.chip}
                        size='small'
                        key={'chip_' + targetingConditionType + '_' + (item.key || item.text)}
                        label={item.name || item.text}
                        onDelete={() => onRemove(item.text)}
                    />
                ))}
        </div>
    );
};

TargetingChipContainer.propTypes = {
    onRemove: PropTypes.func,
    selectedItems: PropTypes.array,
    targetingConditionType: PropTypes.string
};
