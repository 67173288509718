import React from 'react';
import PropTypes from 'prop-types';

import { styled } from '@material-ui/core';

const trackHeight = 3;
const thumbHeight = 25;

const StyledSliderRail = styled('div')(() => ({
    backgroundColor: '#c8ced5',
    width: '100%',
    height: `${trackHeight}px`,
    position: 'absolute',
    pointerEvents: 'none'
}));

const StyledRailHotSpot = styled('div')(() => ({
    width: '100%',
    height: `${thumbHeight * 2}px`,
    top: `${thumbHeight * -1}px`,
    position: 'absolute',
    cursor: 'pointer'
}));

const SliderRail = ({ getRailProps }) => {
    return (
        <>
            <StyledRailHotSpot {...getRailProps()} />
            <StyledSliderRail />
        </>
    );
};

SliderRail.propTypes = {
    getRailProps: PropTypes.func.isRequired
};

export default SliderRail;
