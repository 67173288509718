import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Box, Grid } from '@material-ui/core';

import { BlueHeaderContainer } from 'common/components';
import { GreyExpansionPanel } from 'common/components/GreyExpansionPanel/GreyExpansionPanel';
import { adTypeModel, adtypeTemplateModel } from 'common/models/advertisement';
import { AdType } from 'helpers/ad';

import { CreativeContent } from './CreativeContent';

export const CampaignAdCreativesContentContainer = ({
    adType,
    units,
    campaign,
    unitIds,
    creative,
    dispatch,
    selectedFormats
}) => {
    const { t } = useTranslation();

    const multipleItems =
        adType.name === AdType.Carousel ||
        //adType.name === AdType.GoogleDisplay ||
        adType.name === AdType.HostedDisplay ||
        adType.name === AdType.HostedFireplace
            ? true
            : false;

    return (
        <Box mt='-1px'>
            <BlueHeaderContainer title={t('Ad content')}>
                <Box p={2} width={1}>
                    <CreativeContent
                        adType={adType}
                        units={units}
                        campaign={campaign}
                        creative={creative}
                        unitIds={unitIds}
                        dispatch={dispatch}
                        multipleItems={multipleItems}
                        selectedFormats={selectedFormats}
                        showAdvanced={false}
                    />
                </Box>
            </BlueHeaderContainer>
            {
                <Grid container>
                    <Grid item xs={12}>
                        <GreyExpansionPanel title={t('Advanced')}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <CreativeContent
                                        adType={adType}
                                        units={units}
                                        campaign={campaign}
                                        creative={creative}
                                        unitIds={unitIds}
                                        dispatch={dispatch}
                                        multipleItems={multipleItems}
                                        selectedFormats={selectedFormats}
                                        showAdvanced={true}
                                    />
                                </Grid>
                            </Grid>
                        </GreyExpansionPanel>
                    </Grid>
                </Grid>
            }
        </Box>
    );
};

CampaignAdCreativesContentContainer.propTypes = {
    adType: PropTypes.shape(adTypeModel),
    units: PropTypes.array,
    campaign: PropTypes.object,
    creative: PropTypes.object,
    dispatch: PropTypes.func,
    selectedFormats: PropTypes.shape(adtypeTemplateModel),
    unitIds: PropTypes.arrayOf(PropTypes.string)
};
