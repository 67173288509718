import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { withSnackbar } from 'notistack';

import { useMutation } from '@apollo/client';
import { makeStyles, Paper, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import { RECOVER_ACCOUNT } from 'graphql/mutations/object';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        align: 'center',
        width: '100%'
    },
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    paper: {
        marginTop: theme.spacing(10),
        alignContent: 'center'
    },
    flexRow: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    button: {
        margin: theme.spacing(2, 0),
        marginBottom: -theme.spacing(10),
        marginTop: theme.spacing(5)
    },
    textField: {
        margin: theme.spacing(5),
        marginBottom: theme.spacing(2),
        background: 'rgba(33, 33, 33, 0.08)'
    },
    form: {
        paddingLeft: 100,
        paddingRight: 100,
        paddingBottom: 125,
        flexBasis: 700,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2)
        }
    },
    title: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
        color: '#0578D3',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '24px'
    },
    contentBody: {
        flexGrow: 1,
        display: 'flex',
        align: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('lg')]: {
            justifyContent: 'center'
        }
    },
    typo2: {
        fonFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        marginBottom: theme.spacing(2)
    },
    typo3: {
        marginTop: theme.spacing(15),
        marginBottom: -theme.spacing(5),
        marginRight: theme.spacing(0.5)
    },
    typo4: {
        marginTop: theme.spacing(15),
        marginBottom: -theme.spacing(5),
        marginRight: theme.spacing(0.5),
        color: '#0578D3'
    },
    help: {
        display: 'flex',
        justifyContent: 'center'
    }
}));

const validate = (values) => {
    const errors = {};

    if (!values.email) {
        errors.email = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address';
    }

    return errors;
};

const AccountRecovery = ({ onClose, enqueueSnackbar }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [recoverAccount, setRecoverAccount] = useState(false);
    const [createInvitedUser] = useMutation(RECOVER_ACCOUNT, {
        onCompleted: ({ recoverAccountWithEmail }) => {
            if (recoverAccountWithEmail) {
                const { found, sent } = recoverAccountWithEmail;

                console.warn(recoverAccount);
                setRecoverAccount(false);

                if (sent) {
                    //updateActiveUnit(data.createInvitedUser);
                    window.location.href = '/recover/confirmation';
                }

                if (!found) {
                    enqueueSnackbar(t('User with this email address does not exist!'), {
                        variant: 'error'
                    });
                } else if (!sent) {
                    enqueueSnackbar(
                        t('System unable to recover given email address please try again or contact help!'),
                        {
                            variant: 'error'
                        }
                    );
                }
            } else {
                enqueueSnackbar(t('System unable to recover given email address please try again or contact help!'), {
                    variant: 'error'
                });
            }
        },
        onError: () => {
            enqueueSnackbar(t('System unable to recover given email address please try again or contact help!'), {
                variant: 'error'
            });
        }
    });

    const formik = useFormik({
        initialValues: {
            email: ''
        },
        validate,
        onSubmit: (values) => {
            createInvitedUser({ variables: { ...values } });

            onClose();
        }
    });

    return (
        <div className={classes.root}>
            <Grid container justifyContent='center' alignItems='center'>
                <Grid className={classes.content} item lg={4} xs={8} xl={4} md={6} sm={8}>
                    <div>
                        <Paper className={classes.paper} elevation={8}>
                            <div className={classes.contentBody}>
                                <form
                                    autoComplete='off'
                                    noValidate
                                    onSubmit={formik.handleSubmit}
                                    className={classes.form}
                                >
                                    <Typography className={classes.title} variant='h2' align='center'>
                                        {t('Account recovery')}
                                    </Typography>
                                    <Typography gutterBottom h3 className={classes.typo2}>
                                        {t(
                                            "Enter your email address that you used to register. We'll send you an email with a link to reset your password."
                                        )}
                                    </Typography>

                                    <TextField
                                        fullWidth
                                        className={classes.textfield}
                                        label={t('E-mail address')}
                                        error={formik.errors.email}
                                        margin='dense'
                                        name='email'
                                        onChange={formik.handleChange}
                                        required
                                        value={formik.values.email}
                                        variant='filled'
                                    />

                                    <Button
                                        className={classes.button}
                                        color='primary'
                                        onClick={formik.handleSubmit}
                                        variant='contained'
                                        type='submit'
                                    >
                                        {t('Send')}
                                    </Button>
                                    <div className={classes.help}>
                                        <Typography className={classes.typo3}>
                                            {' '}
                                            {t('Still need help? Contact us')}{' '}
                                        </Typography>

                                        <a href='mailto:apps@brandcom.ai?subject=Problems logging in&bcc=ketil@brandcom.ai'>
                                            <Typography className={classes.typo4}>
                                                {' '}
                                                {t('Here').toLowerCase()}
                                            </Typography>
                                        </a>
                                    </div>
                                </form>
                            </div>
                        </Paper>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

AccountRecovery.propTypes = {
    onClose: PropTypes.any,
    enqueueSnackbar: PropTypes.any
};

export default withSnackbar(AccountRecovery);
