import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import arrayToTree from 'array-to-tree';

import { TreeView } from '@material-ui/lab';

import { interestModel } from 'common/models';
import { uniqueArrayOfObject } from 'helpers/array';

import { SelectedInterestTreeItemComponent } from './SelectedInterestTreeItemComponent';

export const SelectedInterestTreeViewComponent = ({
    selectedInterests,
    networkInterestFlat,
    disableSelection,
    onRemove
}) => {
    const ROOT_PARENT_KEY = '0';

    const constructedSelectedInterestTree = useMemo(() => {
        const array = [];

        const getNode = (key) => {
            return networkInterestFlat.find((interest) => interest.key === key);
        };

        const addRootPathToArray = (path, node) => {
            if (selectedInterests.includes(node.key)) path.unshift({ ...node, selected: true });
            else path.unshift({ ...node, selected: false });

            if (node.parentKey && node.parentKey !== ROOT_PARENT_KEY) {
                const parentNode = getNode(node.parentKey);
                addRootPathToArray(path, parentNode);
            }

            return path;
        };

        selectedInterests.forEach((sInterest) => {
            const node = getNode(sInterest);
            if (node) addRootPathToArray(array, node);
        });

        const onlyUniqueArray = uniqueArrayOfObject(array, 'key');

        return arrayToTree(onlyUniqueArray, {
            parentProperty: 'parentKey',
            customID: 'key',
            childrenProperty: 'children'
        });
    }, [selectedInterests, networkInterestFlat]);

    return (
        <React.Fragment>
            <TreeView
                expanded={networkInterestFlat.map((interest) => interest.key)}
                selected={selectedInterests}
                disableSelection={disableSelection}
            >
                {constructedSelectedInterestTree &&
                    constructedSelectedInterestTree.map((interest) => (
                        <SelectedInterestTreeItemComponent
                            key={interest.key}
                            nodeId={interest.key}
                            interest={interest}
                            onRemove={onRemove}
                        ></SelectedInterestTreeItemComponent>
                    ))}
            </TreeView>
        </React.Fragment>
    );
};

SelectedInterestTreeViewComponent.propTypes = {
    selectedInterests: PropTypes.arrayOf(PropTypes.string),
    disableSelection: PropTypes.bool,
    networkInterestFlat: PropTypes.arrayOf(PropTypes.shape(interestModel)),
    onRemove: PropTypes.func
};
