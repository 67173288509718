import gql from 'graphql-tag';
/**
 *
 */
export const CREATE_CAMPAIGN_IDENTIFIER_MAPPING = gql`
    mutation createCampaignIdentifierMapping($input: CampaignIdentifierMappingInput) {
        createCampaignIdentifierMapping(input: $input) {
            id
            name
            identifier
            trackingId
            date
            createdBy
            unitId
        }
    }
`;

/**
 *
 *
 */
export const REMOVE_CAMPAIGN_IDENTIFIER_MAPPING = gql`
    mutation removeCampaignIdentifierMapping($id: ID!, $unitId: ID!) {
        removeCampaignIdentifierMapping(id: $id, unitId: $unitId) {
            id
            name
            identifier
            trackingId
            date
            createdBy
            unitId
        }
    }
`;
