import React from 'react';
import PropTypes from 'prop-types';

import { CircularProgress, Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    progress: {
        position: 'absolute',
        color: (props) => props.color
    },
    parent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative'
    },
    icon: {
        position: 'absolute',
        color: (props) => props.color,
        top: 18
    },
    bottom: {
        color: (props) => props.colorLight
    }
}));

export const CircularProgressIcon = ({ percentage, icon, color, colorLight }) => {
    const classes = useStyles({ color, colorLight });

    return (
        <Grid container alignItems='center'>
            <div className={classes.root}>
                <div className={classes.parent}>
                    <CircularProgress variant='determinate' value={100} className={classes.bottom} size={60} />
                    <CircularProgress className={classes.progress} variant='static' value={percentage} size={60} />
                    <div className={classes.icon}>{icon}</div>
                </div>
            </div>
        </Grid>
    );
};

CircularProgressIcon.propTypes = {
    percentage: PropTypes.number,
    icon: PropTypes.element,
    color: PropTypes.string,
    colorLight: PropTypes.string
};
