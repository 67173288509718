import React from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Divider, List } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { ArrowForwardIos as ArrowForward } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';

import NavItem from './NavItem';

const useStyles = makeStyles((theme) => ({
    sidebar: {
        paddingTop: 39
    },
    active: {
        color: theme.palette.sidebar.active
    },
    arrowButton: {
        borderRadius: 4,
        padding: 15
    }
}));

function SidebarNav({ pages, className, open, handleSidebarOpen, onClose, ...rest }) {
    const classes = useStyles();
    const loc = useLocation();

    return (
        <>
            <div className={classes.sidebar}>
                {open ? (
                    <IconButton className={classes.arrowButton} color='primary' onClick={onClose} variant='contained'>
                        <CloseIcon />
                    </IconButton>
                ) : (
                    <IconButton
                        color='primary'
                        className={classes.arrowButton}
                        onClick={handleSidebarOpen}
                        variant='contained'
                    >
                        <ArrowForward />
                    </IconButton>
                )}
            </div>
            <Divider />
            <List {...rest} disablePadding dense className={className}>
                {pages.map((page, i) => (
                    <NavItem
                        key={page + i}
                        page={page}
                        handleSidebarOpen={handleSidebarOpen}
                        isOpenSidebar={open}
                        currentPath={loc.pathname}
                    />
                ))}
            </List>
        </>
    );
}

SidebarNav.defaultProps = {
    pages: []
};

SidebarNav.propTypes = {
    pages: PropTypes.array,
    className: PropTypes.string,
    open: PropTypes.bool,
    handleSidebarOpen: PropTypes.func,
    onClick: PropTypes.func,
    onClose: PropTypes.func
};

export default SidebarNav;
