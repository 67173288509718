import PropTypes from 'prop-types';

import { arrayToPeriod } from 'views/UnitBudget/helpers';

export const budgetModel = {
    name: PropTypes.string,
    periods: {
        id: PropTypes.string,
        yearFrom: PropTypes.number,
        yearTo: PropTypes.number,
        monthFrom: PropTypes.number,
        monthTo: PropTypes.number,
        budget: PropTypes.number,
        fee: PropTypes.number
    }
};

export const BudgetPeriodModel = PropTypes.shape({
    month: PropTypes.number,
    value: PropTypes.number,
    cut: PropTypes.number
});

export const BudgetLineModel = PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    period: PropTypes.arrayOf(BudgetPeriodModel)
});

export const BudgetUpdateModel = PropTypes.shape({
    user: PropTypes.string,
    date: PropTypes.date,
    description: PropTypes.string
});

export const BudgetEntryModel = PropTypes.shape({
    unitId: PropTypes.string,
    updates: PropTypes.arrayOf(BudgetUpdateModel),
    budgetLines: PropTypes.arrayOf(BudgetLineModel)
});

export const UnitBudgetModel = PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    year: PropTypes.string,
    budgetEntries: PropTypes.arrayOf(BudgetEntryModel)
});

export const FormattedBudgetModel = PropTypes.shape({
    'ad spending': PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
    'handling cost': PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))
});

export function createBudgetEntry(unitId, userId) {
    return {
        unitId: unitId,
        updates: [
            {
                user: userId,
                date: new Date().toISOString(),
                description: 'User update'
            }
        ],
        budgetLines: [
            {
                name: 'Ad spending',
                type: 'BudgetLine',
                period: arrayToPeriod()
            },
            {
                name: 'Handling cost',
                type: 'BudgetLine',
                period: arrayToPeriod()
            }
        ]
    };
}
