import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button, Card, CardActions, CardContent, CardHeader, CardMedia, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import FallbackImage from 'assets/fallbackimage.png';
import { NetworkLogo } from 'common/components';
import { adTypeModel } from 'common/models/advertisement';

const useStyles = makeStyles((theme) => ({
    header: {
        minHeight: theme.spacing(5),
        maxHeight: theme.spacing(5),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    headerContent: {
        flex: '1 1 auto',
        width: '100px'
    },
    media: {
        minHeight: theme.spacing(24),
        maxHeight: theme.spacing(24),
        backgroundColor: theme.palette.background.grey,
        objectFit: 'contain'
    }
}));

const CampaignTypeCardDescription = ({ children, updateScroll }) => {
    useEffect(() => {
        updateScroll();
    }, [updateScroll]);

    return (
        <Typography variant='body2' color='textSecondary' component='p'>
            {children}
        </Typography>
    );
};

CampaignTypeCardDescription.propTypes = {
    children: PropTypes.node,
    updateScroll: PropTypes.func
};

export const CampaignTypeCard = ({ adType, media, onClick }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const handleImageError = (e) => {
        e.target.onerror = null;
        e.target.src = FallbackImage;
    };
    const name = t(`backend.adTypes.main.${adType.name.toLowerCase()}`);

    return (
        <Card>
            {adType && (
                <>
                    <CardHeader
                        avatar={<NetworkLogo networkName={adType.networks[0].name} />}
                        title={
                            <Typography variant='h5' noWrap>
                                {name}
                            </Typography>
                        }
                        subheader={adType.networks[0].name}
                        className={classes.header}
                        classes={{ content: classes.headerContent }}
                    />
                    <CardMedia
                        className={classes.media}
                        src={media}
                        component='img'
                        onError={handleImageError}
                        title={`${name} image`}
                    />
                    <CardContent>
                        <Typography variant='body2' color='textSecondary'>
                            {adType.description}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button color='primary' variant='contained' fullWidth onClick={onClick}>
                            {t('Create campaign')}
                        </Button>
                    </CardActions>
                </>
            )}
        </Card>
    );
};

CampaignTypeCard.propTypes = {
    adType: PropTypes.shape(adTypeModel),
    onClick: PropTypes.func,
    media: PropTypes.string
};
