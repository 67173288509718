export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const toCamelCase = (string) => {
    return string
        .replace(/\s(.)/g, function ($1) {
            return $1.toUpperCase();
        })
        .replace(/\s/g, '')
        .replace(/^(.)/, function ($1) {
            return $1.toLowerCase();
        });
};

export function formatBytes(a, b = 2) {
    if (0 === a) return '0 Bytes';
    const c = 0 > b ? 0 : b,
        d = Math.floor(Math.log(a) / Math.log(1024));

    return (
        parseFloat((a / Math.pow(1024, d)).toFixed(c)) +
        ' ' +
        ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d]
    );
}

export const truncate = (input, length) => (input.length > length ? `${input.substring(0, length)}...` : input);

// return a set of two resolutions as 500x400, with the option of a leading text (res: 500x400)
export function renderResolution(res) {
    const WxH = `${res.width}x${res.height}`;
    if (res.description) {
        return `${res.description}:\t${WxH}`;
    } else {
        return WxH;
    }
}

export function renderAspect(aspect) {
    return `${aspect}:1`;
}

function componentToHex(c) {
    const hex = c.toString(16);

    return hex.length === 1 ? '0' + hex : hex;
}

export function rgbToHex(rgbObject) {
    const r = rgbObject.r;
    const g = rgbObject.g;
    const b = rgbObject.b;

    return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export function backgroundToRGBA(rgbObject) {
    const r = rgbObject.r;
    const g = rgbObject.g;
    const b = rgbObject.b;
    const a = rgbObject.a;

    return `rgba(${r}, ${g}, ${b}, ${a})`;
}

export function parseBackgroundCSS(rgbObject) {
    return {
        r: rgbObject.r,
        g: rgbObject.g,
        b: rgbObject.b,
        a: rgbObject.alpha
    };
}

export function parseCSSbackgroundToAlpha(rgbObject) {
    return {
        r: rgbObject.r,
        g: rgbObject.g,
        b: rgbObject.b,
        alpha: rgbObject.a
    };
}
