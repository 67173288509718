import gql from 'graphql-tag';

import { FRAGMENT_CAMPAIGN } from 'graphql/fragments/campaign';

import { FRAGMENT_AD_TYPE, FRAGMENT_CREATIVE_MAP, FRAGMENT_ITEM_MAP } from '../fragments/ads';
import { FRAGMENT_PRODUCT, FRAGMENT_PRODUCT_TYPE } from '../fragments/products';

export const OBJECTS = gql`
    query objects($customerId: ID, $first: Int, $after: Int) {
        objects(customerId: $customerId, first: $first, after: $after) {
            count
            objects {
                id
                title
                description
                feedName
                price
                brand
                images {
                    url
                }
            }
        }
    }
`;

export const OBJECTS_BY_IDS = gql`
    query objectsById($customerId: ID, $ids: [ID]) {
        objectsById(customerId: $customerId, ids: $ids) {
            count
            objects {
                id
                title
                description
                feedName
                price
                brand
                images {
                    url
                }
            }
        }
    }
`;
export const OBJECTS_BY_ID = gql`
    query objectsById($customerId: ID, $id: [ID]) {
        objectsById(customerId: $customerId, id: $ids) {
            count
            objects {
                id
                title
                description
                feedName
                price
                brand
                images {
                    url
                }
            }
        }
    }
`;

export const CATEGORIES = gql`
    query categories($customerId: ID, $level: Int, $name: String) {
        categories(customerId: $customerId, level: $level, name: $name) {
            name
        }
    }
`;

export const GET_FEED_FILTERS = gql`
    query getFeedFilters($feedId: ID) {
        getFeedFilters(feedId: $feedId) {
            name
            field
            type
            criterion
            items {
                id
                name
                count
                children
            }
        }
    }
`;
export const GET_FEED_FILTERS2 = gql`
    query getFeedFilters2($feedId: String) {
        getFeedFilters2(feedId: $feedId) {
            name
        }
    }
`;

// export const GET_PRODUCTS = gql`
// query getProducts($feedId: ID,$limit: Int,$skip:Int,$sort:SortInput,$filters:[FilterInput]){
//   getProducts(feedId:$feedId,limit:$limit,skip:$skip,sort:$sort,filters:$filters){
//     count
//     products{
//       id
//       fields{
//         name
//         label
//         dataJSON
//       }
//     }
//     count
//   }
// }
// `

export const GET_CAMPAIGN_TYPES = gql`
    query getCampaignTypes {
        getCampaignTypes {
            id
            name
        }
    }
`;

export const GET_CAMPAIGN_ADTYPES = gql`
    query getCampaignAdTypes($campaignTypeId: ID!, $unitId: ID!) {
        getCampaignAdTypes(campaignTypeId: $campaignTypeId, unitId: $unitId) {
            ...adType
        }
    }
    ${FRAGMENT_AD_TYPE}
`;

export const GET_NETWORK_INTERESTS = gql`
    query getNetworkInterests($networkId: ID!) {
        getNetworkInterests(networkId: $networkId) {
            key
            parentKey
            name
            networkId
            selectable
        }
    }
`;

export const GET_PRODUCT_TYPE = gql`
    query getProductType($id: ID!) {
        getProductType(id: $id) {
            id
            name
            unitId
            fields {
                id
                name
                fieldType
            }
        }
    }
`;

export const GET_PRODUCT_TYPES = gql`
    query getProductTypes($unitId: ID!) {
        getProductTypes(unitId: $unitId) {
            id
            name
            unitId
            fields {
                id
                name
                fieldType
            }
        }
    }
`;

export const GET_PRODUCTS = gql`
    query getProducts(
        $unitId: ID!
        $productTypeId: ID!
        $limit: Int
        $skip: Int
        $search: String
        $sort: [SortInput]
        $filters: [FilterInput]
        $productIds: [ID]
        $skipProductIds: [ID]
    ) {
        getProducts(
            unitId: $unitId
            productTypeId: $productTypeId
            limit: $limit
            skip: $skip
            search: $search
            sort: $sort
            filters: $filters
            productIds: $productIds
            skipProductIds: $skipProductIds
        ) {
            products {
                ...product
            }
            productType {
                ...productType
            }
            fields {
                id
                name
                label
                visibility
                type
            }
            filters {
                id
                fieldId
                type
                items {
                    id
                    name
                    count
                    children
                    from
                    to
                }
            }
            count
        }
    }
    ${FRAGMENT_PRODUCT}
    ${FRAGMENT_PRODUCT_TYPE}
`;

export const GET_FEEDS = gql`
    query getFeeds($unitId: ID) {
        getFeeds(unitId: $unitId) {
            id
            unitId
            name
            url
            fields {
                name
                label
                type
            }
        }
    }
`;
export const GET_PRODUCT_FIELDS = gql`
    query getProductFields($unitId: ID) {
        getProductFields(unitId: $unitId) {
            name
            label
            type
            filter {
                type
                filterId
            }
        }
    }
`;

export const GET_TREE_FILTER = gql`
    query getTreeFilter($filterId: ID) {
        getTreeFilter(filterId: $filterId) {
            id
            name
            count
            children
        }
    }
`;
export const GET_RANGE_FILTER = gql`
    query getRangeFilter($filterId: ID) {
        getRangeFilter(filterId: $filterId) {
            from
            to
            count
        }
    }
`;

export const GET_CHECKBOX_FILTER = gql`
    query getCheckboxFilter($filterId: ID) {
        getCheckboxFilter(filterId: $filterId) {
            name
            count
        }
    }
`;

export const GET_CAMPAIGN = gql`
    query getCampaign($campaignId: ID!) {
        getCampaign(campaignId: $campaignId) {
            id
            advertiserName
            title
            adType {
                id
                name
                description
                thumbnailUrl
                biddingTypes
                creativeFields {
                    name
                    type
                    maxLength
                }
                itemFields {
                    name
                    type
                    maxLength
                }
            }
            startDate
            endDate
            duration
            adGroups {
                id
                name
                ads {
                    id
                    advertiserName
                    title
                    startDate
                    endDate
                }
            }
        }
    }
`;

export const GET_CAMPAIGN_GROUP = gql`
    query getCampaignGroup($campaignGroupId: ID!) {
        getCampaignGroup(campaignGroupId: $campaignGroupId) {
            id
            name
            campaigns {
                ...campaign
            }
        }
    }
    ${FRAGMENT_CAMPAIGN}
`;

export const GET_NETWORKS = gql`
    query getNetworks($unitId: ID!) {
        getNetworks(unitId: $unitId) {
            id
            name
            adtypes {
                id
                name
                description
                biddingTypes
                thumbnailUrl
                description
                creativeFields {
                    name
                    type
                    maxLength
                }
                itemFields {
                    name
                    type
                    maxLength
                }
            }
        }
    }
`;

export const INIT_CREATIVE = gql`
    query initCreative(
        $adTypeId: ID!
        $productIds: [ID]
        $unitIds: [ID]!
        $creativeGroupingId: String
        $templateId: Int
    ) {
        initCreative(
            adTypeId: $adTypeId
            productIds: $productIds
            unitIds: $unitIds
            creativeGroupingId: $creativeGroupingId
            templateId: $templateId
        ) {
            ...creativeMap
        }
    }
    ${FRAGMENT_CREATIVE_MAP}
`;

export const INIT_ITEM = gql`
    query initItem($adTypeId: ID!, $productId: ID, $unitIds: [ID]!, $creativeGroupingId: String, $templateId: Int) {
        initItem(
            adTypeId: $adTypeId
            productId: $productId
            unitIds: $unitIds
            creativeGroupingId: $creativeGroupingId
            templateId: $templateId
        ) {
            ...itemMap
        }
    }
    ${FRAGMENT_ITEM_MAP}
`;
