import React from 'react';
import PropTypes from 'prop-types';

import { Box, ButtonBase, GridList, GridListTile, isWidthUp, withWidth } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

const imageGridStyle = (image, selectedIndex, color) => ({
    width: '100%',
    height: '100%',
    backgroundColor: '#777',
    backgroundImage: `url(${image})`,
    backgroundPosition: 'center center',
    backgroundSize: 'contain',
    padding: selectedIndex ? 5 : 0,
    backgroundRepeat: 'no-repeat',
    border: selectedIndex ? `5px solid ${color}` : null,
    borderRadius: '5px',
    transition: 'background-size 0.1s, border 0.1s'
});

export const ProductImageGrid = withWidth()(({ images, activeIndex, setActiveIndex, width }) => {
    const theme = useTheme();

    const getGridListCols = () => {
        let cols = 1;

        if (isWidthUp('xl', width)) {
            cols = 6;
        } else if (isWidthUp('lg', width)) {
            cols = 5;
        } else if (isWidthUp('md', width)) {
            cols = 4;
        } else if (isWidthUp('sm', width)) {
            cols = 3;
        } else if (isWidthUp('xs', width)) {
            cols = 2;
        }

        return cols;
    };

    return (
        <Box display='flex' flexWrap='wrap' justifyContent='space-around' bgcolor={theme.palette.background.paper}>
            <Box width={1} height={1} overflow='hidden'>
                <GridList cols={getGridListCols()} cellHeight={200}>
                    {images.map((image, index) => (
                        <GridListTile id='image-box' key={`${image}-${index}`}>
                            <ButtonBase
                                color='primary'
                                style={imageGridStyle(image, activeIndex === index, theme.palette.secondary.main)}
                                onClick={() => {
                                    if (index === activeIndex) {
                                        setActiveIndex(-1);
                                    } else {
                                        setActiveIndex(index);
                                    }
                                }}
                            />
                        </GridListTile>
                    ))}
                </GridList>
            </Box>
        </Box>
    );
});

ProductImageGrid.propTypes = {
    images: PropTypes.arrayOf(PropTypes.string),
    activeIndex: PropTypes.number,
    setActiveIndex: PropTypes.func,
    width: PropTypes.string
};
