import gql from 'graphql-tag';

export const UPDATE_USER = gql`
    mutation updateUser($userId: ID!, $input: UpdateUserInput) {
        updateUser(userId: $userId, input: $input) {
            id
            firstName
            lastName
            email
            phone
            report
        }
    }
`;

export const SIGNIN_WITH_GOOGLE = gql`
    mutation signinWithGoogle($input: AuthInput) {
        signInWithGoogle(input: $input) {
            user {
                id
                email
            }
            token
        }
    }
`;

export const SIGN_IN = gql`
    mutation login($input: LoginCredentials) {
        login(input: $input) {
            user {
                id
                email
                firstName
                lastName
                mfa {
                    secret
                    otpauthUrl
                    status
                }
            }
            token
        }
    }
`;
export const FB_SIGN_IN = gql`
    mutation fbLogin($input: FacebookCredentials) {
        fbLogin(input: $input) {
            user {
                id
                email
                firstName
                lastName
                phone
            }
            token
        }
    }
`;

export const SIGN_UP = gql`
    mutation signup($input: SignupCredentials) {
        signup(input: $input) {
            user {
                id
                email
                firstName
                lastName
            }
            token
        }
    }
`;

export const RESET_PASSWORD = gql`
    mutation resetPassword($token: String!, $password: String!, $confirmPassword: String!) {
        verifyAccountRecovery(recoverCode: $token, password: $password, verifyPassword: $confirmPassword)
    }
`;

export const VALIDATE_RECOVER_TOKEN = gql`
    mutation validateRecoverToken($recover: String!) {
        validateRecoverToken(recover: $recover)
    }
`;

export const REQUEST_PASSWORD = gql`
    mutation recoverAccount($email: String) {
        recoverAccount(email: $email)
    }
`;

export const CREATE_USER = gql`
    mutation createUser($firstName: String, $lastName: String, $password: String, $email: String, $phone: String) {
        createUser(firstName: $firstName, lastName: $lastName, email: $email, phone: $phone, password: $password) {
            id
            firstName
            lastName
            email
            phone
        }
    }
`;

export const REMOVE_USER = gql`
    mutation removeUser($userId: ID) {
        removeUser(userId: $userId)
    }
`;

export const UPDATE_USER_PASSWORD = gql`
    mutation password($password: String, $verify: String) {
        password(password: $password, verify: $verify)
    }
`;

export const INVITE_USERS = gql`
    mutation inviteUsers($input: InviteUsers) {
        inviteUsers(input: $input) {
            id
            email
        }
    }
`;

export const RESEND_INVITE = gql`
    mutation sendInvite($userId: ID) {
        sendInvite(userId: $userId)
    }
`;

export const SEND_INVITE_MAIL = gql`
    mutation sendInviteMail($email: String) {
        sendInviteMail(email: $email) {
            found
            sent
        }
    }
`;

export const VALIDATE_VERIFY_TOKEN = gql`
    mutation validateVerifyToken($verify: String!) {
        validateVerifyToken(verify: $verify) {
            valid
            email
        }
    }
`;

export const CHANGE_USER_PERMISSIONS_FOR_UNIT = gql`
    mutation changeUserPermissionsForUnit($input: ChangeUserPermissionsForUnitInput) {
        changeUserPermissionsForUnit(input: $input)
    }
`;

export const ENABLE_MFA_FOR_USER = gql`
    mutation enableMFA($userId: ID) {
        enableMFA(userId: $userId) {
            secret
            otpauthUrl
            userId
            status
        }
    }
`;

export const DISABLE_MFA_FOR_USER = gql`
    mutation disableMFA($userId: ID) {
        disableMFA(userId: $userId) {
            secret
            otpauthUrl
            userId
            status
        }
    }
`;

export const VERIFY_MFA_TOKEN_FOR_USER = gql`
    mutation verifyMFAToken($userId: ID, $token: String) {
        verifyMFAToken(userId: $userId, token: $token) {
            secret
            otpauthUrl
            userId
            status
        }
    }
`;

export const VALIDATE_MFA_TOKEN_FOR_USER = gql`
    mutation validateMFAToken($userId: ID, $token: String) {
        validateMFAToken(userId: $userId, token: $token) {
            secret
            otpauthUrl
            userId
            status
        }
    }
`;
