import React from 'react';
import PropTypes from 'prop-types';

import { styled } from '@material-ui/core';

const thumbHeight = 25;

const StyledSliderHandle = styled('div')(() => ({
    backgroundColor: '#ffffff',
    marginLeft: `${thumbHeight * -0.5}px`,
    marginTop: `${thumbHeight * -0.5}px`,
    width: `${thumbHeight}px`,
    height: `${thumbHeight}px`,
    border: '1px solid #f86b23',
    borderRadius: '50%',
    whiteSpace: 'nowrap',
    position: 'absolute',
    zIndex: 2,
    cursor: 'pointer'
}));

const SliderHandle = ({ domain: [min, max], handle: { id, value, percent }, getHandleProps }) => (
    <StyledSliderHandle
        role='slider'
        aria-valuemin={min}
        aria-valuemax={max}
        aria-valuenow={value}
        style={{ left: `${percent}%` }}
        {...getHandleProps(id)}
    />
);

SliderHandle.propTypes = {
    domain: PropTypes.array.isRequired,
    handle: PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        percent: PropTypes.number.isRequired
    }).isRequired,
    getHandleProps: PropTypes.func.isRequired
};

export default SliderHandle;
