import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Box, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';

import { BlueHeaderContainer } from 'common/components/BlueHeaderContainer/BlueHeaderContainer';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(3)
    },
    radioGroup: {
        flexDirection: 'row',
        marginBottom: theme.spacing(3),
        '& :not(.Mui-checked):not(.Mui-disabled).MuiRadio-root': {
            color: theme.palette.text.mediumEmphasis
        }
    },
    textField: {
        width: '100%',

        '& .MuiFormLabel-root': {
            color: theme.palette.text.disabled
        }
    },
    totalSpendingContainer: {
        backgroundColor: theme.palette.secondary[50],
        height: '100%',
        padding: theme.spacing(1.5)
    },
    iconContainer: {
        marginRight: theme.spacing(1.5),
        display: 'flex',
        '& .MuiSvgIcon-root': {
            fill: theme.palette.secondary.main
        }
    },
    totalSpendingText: {
        marginRight: theme.spacing(1.5)
    },
    totalBudgetDescription: {
        width: 'fit-content'
    }
}));

export const AdBudgeting = ({
    // budgetType,
    // setBudgetType,
    spending,
    setSpending,
    totalSpending,
    numUnits
    // disabledBudgetType
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    /*

    const handleBudgetType = (e) => {
        if (!disabledBudgetType) {
            setBudgetType(e.target.value);
        }
    };

    */

    const handleSpending = (e) => {
        const value = Number.parseInt(e.target.value);

        if (!Number.isNaN(value)) {
            setSpending(value);
        } else {
            setSpending(0);
        }
    };

    return (
        <BlueHeaderContainer title={t('Budget')}>
            <Box padding={3} width='100%'>
                <Grid container spacing={7}>
                    <Grid item xs={6}>
                        <TextField
                            label={t('Spending')}
                            variant='filled'
                            className={classes.textField}
                            value={spending}
                            error={spending !== 'mixed' && !/^[0-9]+$/.test(spending)}
                            onChange={handleSpending}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Grid className={classes.totalSpendingContainer} container alignItems='center'>
                            <Grid item className={classes.iconContainer}>
                                <AccountBalanceWalletOutlinedIcon />
                            </Grid>
                            <Grid item className={classes.totalSpendingText}>
                                <Typography variant='h6'>
                                    {
                                        totalSpending.toLocaleString(
                                            'nb-NB'
                                        ) /* TODO: Fix this when we have configured i18n and formatters correctly*/
                                    }
                                </Typography>
                            </Grid>
                            <Grid item container direction='column' className={classes.totalBudgetDescription}>
                                <Box mb={1}>
                                    <Typography variant='overline'>{t('Total budget')}</Typography>
                                </Box>
                                <Typography variant='caption'>
                                    {t('For the {{count}} selected advertisers', { count: numUnits })}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </BlueHeaderContainer>
    );
};

AdBudgeting.propTypes = {
    // budgetType: PropTypes.oneOf(['total', 'daily']),
    // setBudgetType: PropTypes.func,
    spending: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    setSpending: PropTypes.func,
    totalSpending: PropTypes.number,
    numUnits: PropTypes.number
    // disabledBudgetType: PropTypes.bool
};
