import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Input, makeStyles, Paper } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: '4px',
        alignItems: 'center',
        padding: theme.spacing(1),
        display: 'flex',
        flexBasis: 420
    },
    icon: {
        marginRight: theme.spacing(1),
        color: theme.palette.text.secondary
    },
    input: {
        flexGrow: 1,
        fontSize: '14px',
        lineHeight: '16px',
        letterSpacing: '-0.05px'
    }
}));

export const SearchInput = (props) => {
    const { className, onChange, paperStyle, iconStyle, textStyle, ...rest } = props;

    const classes = useStyles();

    return (
        <Paper {...rest} className={clsx(classes.root, className)} style={paperStyle}>
            <SearchIcon className={classes.icon} style={iconStyle} />
            <Input {...rest} className={classes.input} disableUnderline onChange={onChange} style={textStyle} />
        </Paper>
    );
};

SearchInput.propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func,
    paperStyle: PropTypes.object,
    iconStyle: PropTypes.object,
    textStyle: PropTypes.object
};
