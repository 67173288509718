import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import {
    Button,
    CircularProgress,
    Grid,
    IconButton,
    InputAdornment,
    makeStyles,
    TextField,
    Typography
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Add, Clear, Search, Update } from '@material-ui/icons';
import { useTheme } from '@material-ui/styles';
import { Circle, GoogleMap, Marker, StandaloneSearchBox, useLoadScript } from '@react-google-maps/api';

import { locationModel } from 'common/models';
import { logError } from 'helpers/error';
import { mapConfig } from 'helpers/map';

import { LocationList } from './LocationList';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%'
    },
    buttonContainer: {
        position: 'absolute',
        zIndex: 900
    },
    button: {
        bottom: theme.spacing(7) + 'px',
        right: theme.spacing(3) + 'px',
        borderRadius: 32
    },
    mapContainer: {
        position: 'relative'
    },
    locationListContainer: {
        paddingRight: theme.spacing(2)
    },
    searchBox: {
        width: '100%',
        backgroundColor: grey[50],

        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
        },

        '& .MuiSvgIcon-root': {
            fill: grey[600]
        }
    }
}));

export const LocationSelector = ({
    locations,
    addLocation,
    updateLocation,
    removeLocation,
    setRadius,
    defaultLatLong = { latitude: 63.4289338, longitude: 10.3923282 }
}) => {
    const { root, button, buttonContainer, mapContainer, locationListContainer, searchBox } = useStyles();
    const DEFAULT_RADIUS = 5;
    const searchboxRef = useRef(null);
    const theme = useTheme();
    const { t } = useTranslation();
    const [mapPosition, setMapPosition] = useState((locations && locations[0]) || defaultLatLong);
    const [markerPosition, setMarkerPosition] = useState((locations && locations[0]) || defaultLatLong);
    const { isLoaded, loadError } = useLoadScript(mapConfig);

    const [selectedLocationIndex, setSelectedLocationIndex] = useState(locations && locations.length > 0 ? 0 : null);
    const [searchBoxValue, setSearchBoxValue] = useState('');

    useEffect(() => {
        if (locations && locations.length > 0 && !selectedLocationIndex) {
            const idx = 0;
            const coordinates = {
                latitude: locations[idx].latitude,
                longitude: locations[idx].longitude
            };
            setSelectedLocationIndex(idx);
            setMapPosition(coordinates);
            setMarkerPosition(coordinates);
        }
    }, [locations, selectedLocationIndex]);

    const geocodePromise = () => {
        const location = { lat: markerPosition.latitude, lng: markerPosition.longitude };

        const geocoder = new window.google.maps.Geocoder();

        return new Promise((resolve) => {
            geocoder.geocode({ location }, (results, status) => {
                if (status === 'OK') {
                    resolve(results[0].formatted_address);
                } else {
                    logError(new Error('Failed reverse geocoding latitude and longitude'));
                }
            });
        });
    };

    const handleAddLocation = () => {
        const newLocation = {
            latitude: markerPosition.latitude,
            longitude: markerPosition.longitude,
            radius: DEFAULT_RADIUS
        };
        geocodePromise()
            .then((newAddress) => {
                newLocation['label'] = newAddress;
                setSearchBoxValue(newAddress);
            })
            .then(() => {
                addLocation(newLocation);
                if (locations.length > 0) {
                    setSelectedLocationIndex(locations.length - 1);
                } else {
                    setSelectedLocationIndex(0);
                }
            });
    };

    const handleSetRadius = (radius) => {
        setRadius(selectedLocationIndex, radius);
    };

    const changeCenter = (e) => {
        setMapPosition({ latitude: e.latLng.lat(), longitude: e.latLng.lng() });
        setMarkerPosition({ latitude: e.latLng.lat(), longitude: e.latLng.lng() });
    };

    const handleSearch = () => {
        const result = searchboxRef.current.getPlaces()[0];
        setMapPosition({ latitude: result.geometry.location.lat(), longitude: result.geometry.location.lng() });
        setMarkerPosition({ latitude: result.geometry.location.lat(), longitude: result.geometry.location.lng() });
        setSearchBoxValue(result.formatted_address);
    };

    const onLoad = (ref) => {
        searchboxRef.current = ref;
    };

    const handleUpdateSelectedLocation = () => {
        geocodePromise().then((newAddress) => {
            updateLocation(selectedLocationIndex, {
                label: newAddress,
                latitude: markerPosition.latitude,
                longitude: markerPosition.longitude
            });
        });
    };

    const handleRemoveLocation = (locationIndex) => () => {
        removeLocation(locationIndex);
    };

    const selectLocation = (locationIndex) => () => {
        setSelectedLocationIndex(locationIndex);
        setMapPosition({
            latitude: locations[locationIndex].latitude,
            longitude: locations[locationIndex].longitude
        });
        setMarkerPosition({
            latitude: locations[locationIndex].latitude,
            longitude: locations[locationIndex].longitude
        });
    };

    if (!isLoaded) {
        return <CircularProgress color='primary' />;
    } else if (loadError) {
        logError(new Error('Not able to load map'));

        return <Typography>Not able to load map</Typography>;
    }

    const mapOptions = {
        mapTypeControl: false,
        streetViewControl: false,
        gestureHandling: 'cooperative',
        styles: [
            {
                stylers: [{}] // Makes the google logo white for some reason
            }
        ],
        zoomControl: true,
        zoomControlOptions: {
            position: window.google.maps.ControlPosition.LEFT_BOTTOM
        }
    };

    return (
        <Grid container className={root}>
            <Grid item xs={12} md={4} className={locationListContainer}>
                <StandaloneSearchBox onLoad={onLoad} onPlacesChanged={handleSearch}>
                    <TextField
                        type='text'
                        variant='outlined'
                        placeholder={t('Search')}
                        value={searchBoxValue}
                        onChange={(e) => setSearchBoxValue(e.target.value)}
                        className={searchBox}
                        InputProps={{
                            endAdornment: (
                                <React.Fragment>
                                    {searchBoxValue === '' || (
                                        <InputAdornment position='end'>
                                            <IconButton
                                                onClick={() => setSearchBoxValue('')}
                                                aria-label='Clear search input'
                                            >
                                                <Clear />
                                            </IconButton>
                                        </InputAdornment>
                                    )}
                                    <InputAdornment position='end'>
                                        <Search />
                                    </InputAdornment>
                                </React.Fragment>
                            )
                        }}
                    />
                </StandaloneSearchBox>
                <LocationList
                    locations={locations}
                    selectedLocationIndex={selectedLocationIndex}
                    removeLocation={handleRemoveLocation}
                    showLocation={selectLocation}
                    setRadius={handleSetRadius}
                />
            </Grid>
            <Grid item xs={12} md={8} className={mapContainer}>
                <GoogleMap
                    mapContainerStyle={{ width: '100%', height: '100%', minHeight: '480px' }}
                    center={{
                        lat:
                            mapPosition?.latitude ||
                            locations[selectedLocationIndex]?.latitude ||
                            defaultLatLong['latitude'],
                        lng:
                            mapPosition?.longitude ||
                            locations[selectedLocationIndex]?.longitude ||
                            defaultLatLong['longitude']
                    }}
                    zoom={10}
                    options={mapOptions}
                >
                    <Marker
                        position={{
                            lat: markerPosition.latitude,
                            lng: markerPosition.longitude
                        }}
                        draggable={true}
                        onDragEnd={changeCenter}
                    />
                    {locations &&
                        locations.map((location, i) => {
                            const selected = i === selectedLocationIndex;

                            return (
                                <React.Fragment key={i}>
                                    <Circle
                                        center={{
                                            lat: location.latitude,
                                            lng: location.longitude
                                        }}
                                        radius={location.radius * 1000}
                                        options={{
                                            strokeColor: selected
                                                ? theme.palette.primary[900]
                                                : theme.palette.location.radius,
                                            strokeOpacity: selected ? 0.8 : 1.0,
                                            strokeWeight: 2,
                                            fillColor: selected
                                                ? theme.palette.primary[900]
                                                : theme.palette.location.radius,
                                            fillOpacity: selected ? 0.5 : 0.3,
                                            visible: true,
                                            clickable: false,
                                            zIndex: 1
                                        }}
                                    />
                                    {!selected && (
                                        <Circle
                                            center={{
                                                lat: location.latitude,
                                                lng: location.longitude
                                            }}
                                            radius={5 * 100}
                                            onClick={selectLocation(i)}
                                            options={{
                                                strokeColor: theme.palette.primary.main,
                                                strokeOpacity: 1,
                                                strokeWeight: 2,
                                                fillColor: theme.palette.primary.main,
                                                fillOpacity: 1,
                                                visible: true,
                                                clickable: true,
                                                zIndex: 2
                                            }}
                                        />
                                    )}
                                </React.Fragment>
                            );
                        })}
                </GoogleMap>
                <Grid container justify='flex-end' spacing={1} className={buttonContainer}>
                    <Grid item>
                        {locations.length > 0 && (
                            <Button
                                color='primary'
                                variant='contained'
                                onClick={handleUpdateSelectedLocation}
                                className={button}
                                startIcon={<Update />}
                            >
                                {t('Change location')}
                            </Button>
                        )}
                    </Grid>
                    <Grid item>
                        <Button
                            color='primary'
                            variant='contained'
                            onClick={handleAddLocation}
                            className={button}
                            startIcon={<Add />}
                        >
                            {t('Add')}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

LocationSelector.propTypes = {
    locations: PropTypes.arrayOf(PropTypes.shape(locationModel)),
    addLocation: PropTypes.func,
    updateLocation: PropTypes.func,
    removeLocation: PropTypes.func,
    setRadius: PropTypes.func,
    defaultLatLong: PropTypes.object
};
