import React from 'react';

import { SvgIcon } from '@material-ui/core';

export default function Facebook(props) {
    return (
        <SvgIcon {...props}>
            <path d='M10 0.0400085C4.5 0.0400085 0 4.53001 0 10.06C0 15.06 3.66 19.21 8.44 19.96V12.96H5.9V10.06H8.44V7.85001C8.44 5.34001 9.93 3.96001 12.22 3.96001C13.31 3.96001 14.45 4.15001 14.45 4.15001V6.62001H13.19C11.95 6.62001 11.56 7.39001 11.56 8.18001V10.06H14.34L13.89 12.96H11.56V19.96C13.9164 19.5879 16.0622 18.3855 17.6099 16.5701C19.1576 14.7546 20.0054 12.4457 20 10.06C20 4.53001 15.5 0.0400085 10 0.0400085Z' />
        </SvgIcon>
    );
}
