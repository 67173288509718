import gql from 'graphql-tag';

export const SET_TARGET_DEFAULT = gql`
    mutation ($unitId: ID!, $targetingId: ID!) {
        setDefaultTargeting(unitId: $unitId, targetingId: $targetingId)
    }
`;

export const CREATE_TARGETING_TEMPLATE = gql`
    mutation CreateTargetingTemplate($input: TargetingInput) {
        createTargeting(input: $input) {
            id
            name
        }
    }
`;

export const UPDATE_TARGETING_TEMPLATE = gql`
    mutation UpdateTargetingTemplate($targetingID: ID!, $targetingInput: TargetingInput!) {
        updateTargeting(targetingID: $targetingID, targetingInput: $targetingInput) {
            id
        }
    }
`;

export const DELETE_TARGETING_TEMPLATE = gql`
    mutation DeleteTargetingTemplate($targetingID: ID) {
        deleteTargeting(targetingID: $targetingID)
    }
`;
