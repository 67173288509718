import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';

export default function AddExternalReport({ onSave }) {
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    const [name, setName] = useState('');
    const [url, setUrl] = useState('');

    return (
        <>
            <Button
                variant='contained'
                color='primary'
                onClick={() => {
                    setOpen(true);
                }}
            >
                {t('Add report')}
            </Button>

            <Dialog
                open={open}
                onClose={() => {
                    setName('');
                    setUrl('');
                    setOpen(false);
                }}
                fullWidth
                maxWidth='md'
            >
                <DialogTitle> {t('External reports')}</DialogTitle>
                <DialogContent>
                    <TextField
                        label={t('Report name')}
                        variant='outlined'
                        value={name}
                        onChange={(event) => {
                            setName(event.target.value);
                        }}
                        fullWidth
                    />

                    <Box sx={{ my: 2 }} />
                    <TextField
                        label={t('Report link')}
                        variant='outlined'
                        value={url}
                        onChange={(event) => {
                            setUrl(event.target.value);
                        }}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setName('');
                            setUrl('');
                            setOpen(false);
                        }}
                        color='primary'
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        onClick={() => {
                            onSave({ name, url });
                            setName('');
                            setUrl('');
                            setOpen(false);
                        }}
                        variant='contained'
                        color='primary'
                    >
                        {t('Add')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

AddExternalReport.propTypes = {
    onSave: PropTypes.func.isRequired
};
