import gql from 'graphql-tag';

export const FRAGMENT_NETWORK_WITHOUT_REFS = gql`
    fragment network on Network {
        id
        name
    }
`;

export const FRAGMENT_NETWORK_ASSET = gql`
    fragment networkAsset on NetworkAsset {
        id
        name
        key
    }
`;

export const FRAGMENT_CONNECTION_ASSET = gql`
    fragment connectionAsset on ConnectionAsset {
        default {
            ...networkAsset
        }
        keys {
            ...networkAsset
        }
    }
    ${FRAGMENT_NETWORK_ASSET}
`;

export const FRAGMENT_CONNECTION_FACEBOOK = gql`
    fragment connectionFacebook on ConnectionFacebook {
        pages {
            ...connectionAsset
        }
    }
    ${FRAGMENT_CONNECTION_ASSET}
`;

export const FRAGMENT_CONNECTIONS = gql`
    fragment connections on Connections {
        facebook {
            ...connectionFacebook
        }
    }
    ${FRAGMENT_CONNECTION_FACEBOOK}
`;
