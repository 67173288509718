import React, { useContext, useState } from 'react';
import BlockUi from 'react-block-ui';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';

import { useMutation } from '@apollo/client';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';

import { PasswordVisibilityContext } from '../../../../context/settings';
import { UPDATE_USER_PASSWORD } from '../../../../graphql/mutations/user';

const Password = ({ enqueueSnackbar }) => {
    const { setPasswordVisibility } = useContext(PasswordVisibilityContext);
    const defaultValues = { password: '', verify: '' };
    const { t } = useTranslation();
    const [updateUserPassword, { loading: updateUserLoading }] = useMutation(UPDATE_USER_PASSWORD, {
        onError: (err) => {
            enqueueSnackbar('Error changing password'.concat(err.message), {
                variant: 'error'
            });
            setValues(defaultValues);
        },
        onCompleted: () => {
            enqueueSnackbar('Password change completed', { variant: 'success' });
            setValues(defaultValues);
            setPasswordVisibility(false);
        }
    });

    const [values, setValues] = useState(defaultValues);

    const handleChange = (event) =>
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });

    const handleSubmit = () => {
        const { password, verify } = values;

        if (password !== verify) {
            return;
        }

        updateUserPassword({ variables: { password, verify } });
    };

    const handleCancel = () => {
        setPasswordVisibility(false);
    };

    const { password, verify } = values;

    const disabled = !(verify && password && password === verify);

    return (
        <BlockUi tag='div' blocking={updateUserLoading} keepInView>
            <Card>
                <CardHeader subheader={t('Change password')} title={t('Password')} />
                <Divider />
                <CardContent>
                    <TextField
                        fullWidth
                        label={t('Password')}
                        name='password'
                        onChange={handleChange}
                        type='password'
                        value={password}
                        variant='filled'
                    />
                    <TextField
                        fullWidth
                        label={t('Confirm password')}
                        name='verify'
                        onChange={handleChange}
                        style={{ marginTop: '1rem' }}
                        type='password'
                        value={verify}
                        variant='filled'
                    />
                </CardContent>
                <Divider />
                <CardActions>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <Tooltip title={disabled ? 'Passordene er ikke like' : 'Passordene er like'}>
                            <span>
                                <Button color='primary' variant='outlined' onClick={handleSubmit} disabled={disabled}>
                                    {t('Change')}
                                </Button>
                            </span>
                        </Tooltip>
                        <Button color='primary' onClick={handleCancel} variant='contained'>
                            {t('Cancel')}
                        </Button>
                    </div>
                </CardActions>
            </Card>
        </BlockUi>
    );
};

Password.propTypes = {
    enqueueSnackbar: PropTypes.any
};

export default withSnackbar(Password);
