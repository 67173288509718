import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { QRCodeSVG } from 'qrcode.react';

import { useMutation, useQuery } from '@apollo/client';
import {
    Avatar,
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    makeStyles,
    TextField,
    Typography
} from '@material-ui/core';
import { EmailTwoTone, FileCopy } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { Alert } from '@material-ui/lab';

import { DialogTitleClose } from 'common/components/DialogCloser/DialogTitleClose';
import { MFAStatus } from 'common/enums';
import { Context as ActiveUnitContext } from 'context/units/activeUnitContext';
import { DISABLE_MFA_FOR_USER, ENABLE_MFA_FOR_USER, VERIFY_MFA_TOKEN_FOR_USER } from 'graphql/mutations/user';
import { GET_MFA_DETAILS_FOR_USER } from 'graphql/queries/user';
import { logError } from 'helpers/error';
import { getInitials, getName, getShortName } from 'helpers/name';

import Permissions from './Permissions';
import RemoveUser from './RemoveUser';

const useStyles = makeStyles((theme) => ({
    avatar: {
        fontSize: 60,
        height: 200,
        width: 200
    },
    subText: {
        display: 'flex',
        marginTop: theme.spacing(1.5)
    },

    icon: {
        color: theme.palette.primary[500],
        fontSize: 20,
        marginRight: theme.spacing(0.5)
    },
    phoneicon: {
        color: theme.palette.primary[500],
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(0.7)
    },
    errorIcon: {
        marginRight: theme.spacing(1)
    },
    divider: {
        marginTop: theme.spacing(3),
        marginLeft: -theme.spacing(3.6),
        [theme.breakpoints.down('md')]: {
            marginLeft: -theme.spacing(1.4)
        }
    },
    mfaButton: {
        color: theme.palette.primary,
        marginTop: theme.spacing(1)
    },
    removeButton: {
        color: theme.palette.error.main,
        marginTop: theme.spacing(1)
    },
    root2: {
        marginTop: theme.spacing(4),
        marginLeft: theme.spacing(3)
    },
    content2: {
        marginTop: -theme.spacing(1)
    },
    mfaToken: {
        fontSize: 14,
        lineHeight: 1,
        width: '100%'
    },
    dialogContainer: {
        height: 500,
        width: 650,
        maxWidth: 650,
        margin: 'auto'
    },
    dialogHeader: {
        width: '100%',
        fontSize: 20,
        color: '#3f3d93',
        fontWeight: 400,
        lineHeight: 1.5
    },
    dialogContentTitle: {
        fontSize: 20,
        fontWeight: 500,
        lineHeight: 1
    },
    dialogContentHeader: {
        fontSize: 22,
        fontWeight: 700,
        lineHeight: 1,
        color: '#3f3d93'
    },
    dialogContentBody: {
        fontSize: 14,
        lineHeight: 1.5,
        height: 200,
        width: 400,
        maxWidth: '100%'
    },
    dialogConsentButton: {
        '&:hover': {
            backgroundColor: '#3f3d93'
        },
        textTransform: 'uppercase',
        height: 30,
        paddingLeft: 10,
        backgroundColor: '#3f3d93',
        color: '#fff',
        borderRadius: 0,
        display: 'flex',
        justifyContent: 'flex-start'
    },
    dialogActionsContainer: {
        height: 50,
        paddingLeft: 16,
        paddingTop: 16,
        paddingBottom: 24,
        display: 'flex',
        justifyContent: 'flex-start'
    },
    upperContent: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    mfaInstructions: {
        listStyle: 'none',
        display: 'block',
        fontSize: '11px',
        textAlign: 'left'
    },
    mfaInstruction: {
        margin: '5px 0'
    },
    alert: {
        '& .MuiAlert-icon': {
            fontSize: 36
        }
    }
}));

const Profile = ({ selectedUsers, refetch, unitId, setSelectedUsers, handleUnselect }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const {
        state: { activePermissions }
    } = useContext(ActiveUnitContext);

    const isAdmin = activePermissions.find((o) => o.name === 'ADMIN');

    const [modalRemoveUserState, setModalRemoveUserState] = useState(false);
    const [modalEnableMFAState, setModalEnableMFAState] = useState(false);
    const [mfaQRCode, setMFAQRCode] = useState(null);
    const [mfaToken, setMFAToken] = useState('');
    const { enqueueSnackbar } = useSnackbar();
    const [mfaStatus, setMFAStatus] = useState({});
    const selectedId = selectedUsers[0]?.id ?? '';
    const [disableMFAButton, setDisableButton] = useState(true);

    useQuery(GET_MFA_DETAILS_FOR_USER, {
        variables: { userId: selectedId },
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (data.getMFADetails) {
                const { status } = data.getMFADetails;
                setDisableButton(false);
                setMFAStatus(status);
            }
        }
    });

    const [enableMFA] = useMutation(ENABLE_MFA_FOR_USER, {
        variables: { userId: selectedId },
        onCompleted: (data) => {
            if (data?.enableMFA) {
                const { otpauthUrl } = data.enableMFA;
                setMFAQRCode(otpauthUrl);
                setModalEnableMFAState(true);
            }
        }
    });

    const [disableMFA] = useMutation(DISABLE_MFA_FOR_USER, {
        variables: { userId: selectedId },
        onCompleted: (data) => {
            if (data?.disableMFA) {
                const { status } = data.disableMFA;
                setMFAStatus(status);
                enqueueSnackbar('Successfully MFA Disabled', { variant: 'success' });
            }
        }
    });

    const [verifyMFA] = useMutation(VERIFY_MFA_TOKEN_FOR_USER, {
        variables: {
            userId: selectedId,
            token: mfaToken
        },
        onCompleted: (data) => {
            if (data.verifyMFAToken) {
                const { status } = data.verifyMFAToken;
                if (MFAStatus.get(status) === MFAStatus.verified) {
                    setMFAStatus(status);
                    setModalEnableMFAState(false);
                    enqueueSnackbar('Successfully MFA Enabled', { variant: 'success' });
                } else {
                    enqueueSnackbar('Please try again enabling MFA failed', { variant: 'error' });
                }
            } else {
                enqueueSnackbar('Please try again enabling MFA failed', { variant: 'error' });
            }
        }
    });

    return (
        <>
            {selectedUsers.length === 1 ? (
                <>
                    {selectedUsers[0].verify && (
                        <Box mb={2}>
                            <Alert severity='warning' className={classes.alert}>
                                <Typography color='inherit' variant='h6'>
                                    {t('User is added but not verified')}
                                </Typography>
                                <Typography color='inherit'>
                                    {t('Copy this link and send it to the user to create and verify their account.')}
                                </Typography>

                                <Box mt={2} width='100%' maxWidth='88%'>
                                    <TextField
                                        multiline
                                        fullWidth
                                        value={`${window.location.origin}/verify/${selectedUsers[0].verify}`}
                                        InputProps={{
                                            endAdornment: (
                                                <IconButton
                                                    onClick={async () => {
                                                        await navigator.clipboard
                                                            .writeText(
                                                                `${window.location.origin}/verify/${selectedUsers[0].verify}`
                                                            )
                                                            .then(() => {
                                                                enqueueSnackbar(t('Link copied'), {
                                                                    variant: 'success'
                                                                });
                                                            })
                                                            .catch((err) => {
                                                                logError(err);
                                                                enqueueSnackbar('Failed to copy link', {
                                                                    variant: 'error'
                                                                });
                                                            });
                                                    }}
                                                >
                                                    <FileCopy className={classes.icon} />
                                                </IconButton>
                                            )
                                        }}
                                    />
                                </Box>
                            </Alert>
                        </Box>
                    )}

                    <Box mb={2}>
                        <Typography variant='h6' color='primary'>
                            {getName(selectedUsers[0])}
                        </Typography>
                        <div className={classes.subText}>
                            <MailOutlineIcon className={classes.icon} />
                            <Typography variant='body2'>{selectedUsers[0].email}</Typography>
                        </div>
                    </Box>

                    <Box display='flex' flexWrap='wrap'>
                        <Box mb={2} mr={4} flex={0}>
                            <Avatar variant='square' className={classes.avatar}>
                                {getInitials(selectedUsers[0])}
                            </Avatar>

                            <Box width='fit-content'>
                                <Button
                                    disabled={disableMFAButton}
                                    className={classes.mfaButton}
                                    variant='outlined'
                                    size='small'
                                    fullWidth
                                    startIcon={<LockOutlinedIcon />}
                                    onClick={() => {
                                        if (mfaStatus === MFAStatus.verified.value) {
                                            disableMFA();
                                        } else {
                                            enableMFA();
                                        }
                                    }}
                                >
                                    {MFAStatus.get(mfaStatus) === MFAStatus.verified
                                        ? t('Disable MFA')
                                        : t('Enable MFA')}
                                </Button>

                                <Button
                                    className={classes.removeButton}
                                    variant='outlined'
                                    startIcon={<CloseIcon />}
                                    size='small'
                                    fullWidth
                                    onClick={() => {
                                        setModalRemoveUserState(!modalRemoveUserState);
                                    }}
                                >
                                    {t('Remove user')}
                                </Button>
                            </Box>
                        </Box>

                        <Box flex={1}>
                            <Permissions selectedUsers={selectedUsers} unitId={unitId} refetch={refetch} />
                        </Box>
                    </Box>

                    <Dialog open={modalRemoveUserState} onClose={() => setModalRemoveUserState(false)}>
                        <RemoveUser
                            modalState={modalRemoveUserState}
                            setModalState={setModalRemoveUserState}
                            unitId={unitId}
                            selectedUserId={selectedUsers.map((user) => user.id)}
                            refetch={refetch}
                            setSelectedUsers={setSelectedUsers}
                        />
                    </Dialog>
                    <Dialog
                        open={modalEnableMFAState}
                        onClose={() => {
                            setModalEnableMFAState(!modalEnableMFAState);
                        }}
                        aria-describedby='scroll-dialog-description'
                        aria-labelledby='scroll-dialog-title'
                        className={classes.dialogContainer}
                    >
                        <DialogTitle fullWidth disableTypography></DialogTitle>
                        <DialogTitleClose
                            shrank={true}
                            onClose={() => setModalEnableMFAState(false)}
                            id='scroll-dialog-title'
                        />
                        <DialogContent
                            fullWidth
                            className={classes.dialogContentBody}
                            id='scroll-dialog-description'
                            dividers={false}
                        >
                            <Grid container direction='column'>
                                <Grid item className={classes.upperContent}>
                                    <QRCodeSVG
                                        id='secret-qrcode'
                                        value={mfaQRCode}
                                        size={105}
                                        includeMargin={true}
                                        level='Q'
                                    />
                                    <ul className={classes.mfaInstructions}>
                                        <li className={classes.mfaInstruction}>
                                            1. Install the Microsoft/Google authenticator app
                                        </li>
                                        <li className={classes.mfaInstruction}>2. In the app, add account </li>
                                        <li className={classes.mfaInstruction}>3. Scan the QR Code </li>
                                        <li className={classes.mfaInstruction}>
                                            4. App displays a six digit code, use it to Continue
                                        </li>
                                    </ul>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        required
                                        focused
                                        variant='filled'
                                        fullWidth
                                        id='qr-code-token'
                                        className={classes.mfaToken}
                                        label={t('Insert one-time code from your app')}
                                        value={mfaToken}
                                        onChange={(e) => setMFAToken(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions className={classes.dialogActionsContainer} disableTypography>
                            <Button
                                color='primary'
                                onClick={() => {
                                    setMFAToken('');
                                    verifyMFA();
                                }}
                            >
                                {t('Submit')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            ) : (
                <div className={classes.root2}>
                    <div className={classes.content2}>
                        <Box display='flex' flexDirection='row' alignItems='center' justifyContent='space-between'>
                            <Typography color='primary' variant='h6'>
                                {t('Selected users')} ({selectedUsers.length})
                            </Typography>

                            {isAdmin && (
                                <IconButton
                                    onClick={async () => {
                                        const emailList = selectedUsers.map((user) => user.email).join(';');

                                        await navigator.clipboard
                                            .writeText(emailList)
                                            .then(() => {
                                                enqueueSnackbar('Copied email list', {
                                                    variant: 'success'
                                                });
                                            })
                                            .catch((err) => {
                                                logError(err);
                                                enqueueSnackbar('Failed to copy email list', { variant: 'error' });
                                            });
                                    }}
                                >
                                    <EmailTwoTone className={classes.icon} />
                                </IconButton>
                            )}
                        </Box>
                        <Grid container>
                            {selectedUsers.map((user) => {
                                return (
                                    <Box key={user.id} mt={1} mr={1}>
                                        <Chip
                                            avatar={<Avatar src={user.avatarUrl}>{getInitials(user)}</Avatar>}
                                            label={getShortName(user)}
                                            onDelete={() => handleUnselect(user)}
                                            variant='outlined'
                                        />
                                    </Box>
                                );
                            })}
                        </Grid>
                    </div>
                    <Box mb={2}>
                        <Button
                            className={classes.removeButton}
                            variant='outlined'
                            startIcon={<CloseIcon />}
                            onClick={() => {
                                setModalRemoveUserState(!modalRemoveUserState);
                            }}
                        >
                            {t('Remove users')}
                        </Button>
                        <Dialog open={modalRemoveUserState} onClose={() => setModalRemoveUserState(false)}>
                            <RemoveUser
                                modalState={modalRemoveUserState}
                                setModalState={setModalRemoveUserState}
                                unitId={unitId}
                                selectedUserId={selectedUsers.map((user) => user.id)}
                                refetch={refetch}
                                setSelectedUsers={setSelectedUsers}
                            />
                        </Dialog>
                    </Box>

                    <Permissions selectedUsers={selectedUsers} unitId={unitId} refetch={refetch} />
                </div>
            )}
        </>
    );
};

Profile.propTypes = {
    selectedUsers: PropTypes.array,
    refetch: PropTypes.func,
    unitId: PropTypes.string,
    setSelectedUsers: PropTypes.func,
    handleUnselect: PropTypes.func
};

export default Profile;
