import enLocale from 'date-fns/locale/en-US';

import { dateFnsLocales } from './locales';

const locales = dateFnsLocales;

const formattedMonths = (language) => {
    const loc = locales[language] || enLocale;
    const localize = (monthIndex) => loc.localize.month(monthIndex, { width: 'abbreviated' });

    return Array.from(Array(12).keys()).map((month) => localize(month));
};

const weekdays = (locale) => {
    return locales[locale].localize.weekdays();
};

export { formattedMonths, weekdays };
