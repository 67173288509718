import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Box, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';

import { FilterBy } from './FilterBy';
import { SearchInput } from './SearchInput';
import { SortBy } from './SortBy';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        '& .MuiPaper-root': {
            marginTop: theme.spacing(0.2)
        }
    },
    button: {
        color: theme.palette.text.disabled,
        marginTop: theme.spacing(2)
    }
}));

const UserHeader = ({ filters, setFilters }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Box m={2} className={classes.root}>
            <Grid container direction='row' spacing={2}>
                <Grid item xs={12} sm={3}>
                    <SearchInput
                        onChange={(searchText) => {
                            setFilters({ ...filters, searchText });
                        }}
                        value={filters.searchText}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <FilterBy
                        onChange={(filterBy) => {
                            setFilters({ ...filters, filterBy: [...filterBy] });
                        }}
                        values={filters.filterBy}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <SortBy
                        onChange={(sortBy) => {
                            setFilters({ ...filters, sortBy });
                        }}
                        value={filters.sortBy}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Button
                        size='small'
                        className={classes.button}
                        startIcon={<CloseIcon />}
                        onClick={() => setFilters({ filterBy: [] })}
                    >
                        {t('Clear all')}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

UserHeader.propTypes = {
    filters: PropTypes.object,
    setFilters: PropTypes.func
};

export default UserHeader;
