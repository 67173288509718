import React from 'react';
import PropTypes from 'prop-types';

import { fieldTypeModel } from 'common/models/product';

export const ColumnType = Object.freeze({
    IMAGE: 'IMAGE',
    URL: 'URL',
    NUMBER: 'NUMBER',
    STRING: 'STRING',
    CHECKBOX: 'CHECKBOX',
    TEXT: 'TEXT'
});

export const ColumnTypeForMaterialTable = Object.freeze({
    NUMERIC: 'numeric',
    STRING: 'string',
    BOOLEAN: 'boolean'
});

export const SortOrder = Object.freeze({
    ASC: 1,
    DESC: -1
});

export const SortOrderForMaterialTable = Object.freeze({
    ASC: 'asc',
    DESC: 'desc'
});

export const formatColumnTypeForMaterialTable = (columnType) => {
    switch (columnType) {
        case ColumnType.IMAGE:
        case ColumnType.URL:
        case ColumnType.TEXT:
            return ColumnTypeForMaterialTable.STRING;
        case ColumnType.NUMBER:
            return ColumnTypeForMaterialTable.NUMERIC;
        case ColumnType.CHECKBOX:
            return ColumnTypeForMaterialTable.BOOLEAN;
        default:
            return columnType.toLowerCase();
    }
};

export const includeRenderFunctionIfColumnTypeIsImageForMaterialTable = (columnType) => {
    if (columnType === ColumnType.IMAGE) {
        // eslint-disable-next-line
        return (rowData) => <img alt='rowImg' src={rowData.imageUrl} style={{ width: 40, borderRadius: '10%' }} />;
    }
};

export const addCheckedPropertyToSelectedRows = (array, selectedRows) => {
    return array.map((product) =>
        selectedRows.find((selected) => selected.id === product.id)
            ? { ...product, tableData: { checked: true } }
            : product
    );
};

export const mapProductsForMaterialTableWithSelectedRows = (products, selectedRows, productType) => {
    const mappedProducts = products
        .filter(({ fields }) => fields && fields.length)
        .map(({ id, fields }) => {
            return Object.assign(
                ...fields.map((field) => {
                    if (
                        productType.fields.find((typeField) => typeField.name === field.fieldId)?.fieldType ===
                        fieldTypeModel.IMAGE
                    ) {
                        const image = JSON.parse(field.dataJSON)[0];

                        return { imageUrl: image };
                    }

                    return {
                        id: id,
                        [field.fieldId]: JSON.parse(field.dataJSON)
                    };
                })
            );
        })
        .map((product) => {
            return { ...product, imageUrl: product.imageUrl ? product.imageUrl : 'images/no-image.jpg' };
        });
    const productsWithSelectedRows = addCheckedPropertyToSelectedRows(mappedProducts, selectedRows);

    return productsWithSelectedRows;
};

export const mapColumnsForMaterialTable = (columns) => {
    const newColumns = columns.map((column) => {
        const mappedColumn = {
            title: column.label,
            field: column.name,
            hidden: !column.visibility,
            customFilterAndSearch: (term) => term,
            render: includeRenderFunctionIfColumnTypeIsImageForMaterialTable(column.type),
            type: formatColumnTypeForMaterialTable(column.type)
        };

        if (column.label.toLowerCase() === 'description') {
            mappedColumn['cellStyle'] = {
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                maxWidth: 150
            };
        }

        return mappedColumn;
    });

    return newColumns;
};

export const mapSortOrderForMaterialTable = (sortOrder, setCurrentSortOrder) => {
    let newSortOrder = '';
    if (sortOrder === SortOrderForMaterialTable.ASC) newSortOrder = SortOrder.ASC;
    if (sortOrder === SortOrderForMaterialTable.DESC) newSortOrder = SortOrder.DESC;
    setCurrentSortOrder(newSortOrder || undefined);
};

export const mapMaterialTableColumnsForUpdate = (columns) => {
    return columns.map((column) => {
        return {
            name: column.name,
            label: column.label,
            visibility: column.visibility,
            type: column.type
        };
    });
};

const tableHasRowActions = (props) => {
    return Object.values(props).some((func) => func !== undefined);
};

export const shouldAddEmptyFirstColumnFieldToHeaderEdit = (props) => {
    const { enableSelection, ...rest } = props;

    return enableSelection || (!enableSelection && tableHasRowActions(rest));
};

export const EmptyContainerWrapper = (props) => <React.Fragment>{props.children}</React.Fragment>;
EmptyContainerWrapper.propTypes = {
    children: PropTypes.any
};
