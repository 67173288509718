import React from 'react';
import { useTranslation } from 'react-i18next';

import {
    Checkbox,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    ListItemText,
    ListSubheader,
    MenuItem,
    Select,
    TextField
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 0
    },
    formControl: {
        width: '100%',
        height: 56
    },
    selector: {
        backgroundColor: theme.palette.background.empty
    },
    label: {
        color: theme.palette.text.hint
    },
    text: {
        textTransform: 'capitalize'
    }
}));

// TODO: Finish development
// Do not use this component yet
export const CampaignTypeFilter = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    const mappingList = [
        {
            title: 'Industry',
            items: [
                {
                    title: 'Cars'
                },
                {
                    title: 'Hagemøbler'
                },
                {
                    title: 'Mat'
                },
                {
                    title: 'Karusell'
                }
            ]
        },
        {
            title: 'Nettverk',
            items: [
                {
                    title: 'Facebook'
                },
                {
                    title: 'Google'
                },
                {
                    title: 'Instagram'
                },
                {
                    title: 'Linkedin'
                }
            ]
        }
    ];

    return (
        <Grid container alignItems='center' justify='space-between' spacing={2}>
            <Grid item sm={6} xs={12}>
                <TextField
                    variant='filled'
                    fullWidth
                    disabled
                    id='campaign-type-filter-search-input'
                    label={t('Search for campaign types')}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='end'>
                                <Search />
                            </InputAdornment>
                        )
                    }}
                />
            </Grid>
            <Grid item sm={3} xs={12}>
                <FormControl variant='filled' className={classes.formControl}>
                    <InputLabel id='campaign-type-filter-multi-select' className={classes.label}>
                        {t('Filter by')}
                    </InputLabel>
                    <Select
                        disabled
                        labelId='campaign-type-filter-multi-select'
                        className={classes.selector}
                        onChange={() => {}}
                        renderValue={(selected) =>
                            `${selected.length} ${t('Of').toLowerCase()} ${Object.keys(mappingList).length}`
                        }
                    >
                        {mappingList &&
                            mappingList.map((category, index) => {
                                return (
                                    <div key={'cat_' + category.title + index}>
                                        <ListSubheader>{category.title}</ListSubheader>
                                        <React.Fragment>
                                            {category &&
                                                category.items &&
                                                category.items.map((item, index) => {
                                                    return (
                                                        <MenuItem
                                                            key={'list_item_text_' + item.title + index}
                                                            value={category.title}
                                                        >
                                                            <Checkbox checked={true} color='primary' />
                                                            <ListItemText
                                                                className={classes.text}
                                                                primary={item.title}
                                                            />
                                                        </MenuItem>
                                                    );
                                                })}
                                        </React.Fragment>
                                    </div>
                                );
                            })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item sm={3} xs={12}>
                <FormControl variant='filled' className={classes.formControl}>
                    <InputLabel id='campaign-type-filter-sort-by-input' className={classes.label}>
                        {t('Sort by')}
                    </InputLabel>
                    <Select
                        disabled
                        labelId='campaign-type-filter-sort-by-input'
                        label={t('Sort by')}
                        id='demo-simple-select'
                        className={classes.selector}
                        value={''}
                        onChange={() => {
                            alert('handleChange');
                        }}
                    ></Select>
                </FormControl>
            </Grid>
        </Grid>
    );
};
