import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { AppBar, Toolbar } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/styles';

import { LanguageSelector } from 'common/components';

const useStyles = makeStyles(() => ({
    root: {
        boxShadow: 'none'
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between'
    }
}));

const MinimalTopbar = (props) => {
    const { className, ...rest } = props;
    const classes = useStyles();

    return (
        <AppBar {...rest} className={clsx(classes.root, className)} color='primary' position='relative'>
            <Toolbar className={classes.toolbar}>
                <Link component={RouterLink} to='/' color='inherit'>
                    <img src='/images/logos/PowerAnalyticsLogo.png' alt='' height='32px' />
                </Link>
                <LanguageSelector />
            </Toolbar>
        </AppBar>
    );
};

MinimalTopbar.propTypes = {
    className: PropTypes.string
};

export default MinimalTopbar;
