/* eslint-disable no-console */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { clone, isEmpty, upperFirst } from 'lodash';

import { useLazyQuery } from '@apollo/client';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    ListItemText,
    makeStyles,
    MenuItem,
    Select,
    Tab,
    Tabs,
    Typography
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { useTheme } from '@material-ui/styles';

import { AdPreview, BlueHeaderContainer, ImageCropper, SingleProductSelector } from 'common/components';
import { adTypeModel, adtypeTemplateModel } from 'common/models/advertisement';
import { CREATIVE_ACTION_TYPE } from 'context/campaign/creativesReducer';
import { GET_AD_PREVIEW_FROM_CREATIVE } from 'graphql/queries/adpreview';
import { INIT_ITEM } from 'graphql/queries/object';
import { AdTypeFieldType } from 'helpers/ad';
import { getImageResolutionRequirements, getImageSizeRequirements } from 'helpers/campaign';
import { logError } from 'helpers/error';
import { marshalCreativeInput } from 'helpers/marshal';
import { getProductImages } from 'helpers/product';

import { CreativeContentTextField, ItemFieldWrapper } from './CreativeContentField';
import { LinkExtension } from './LinkExtension';

const useStyles = makeStyles(() => ({
    rootMuiTabs: {
        minHeight: 'auto',
        height: 35
    },
    iconLabelWrapper: {
        textAlign: 'center'
    },
    labelContainer: {
        minWidth: 'auto',
        minHeight: 'auto',
        opacity: 1,
        cursor: 'auto',
        paddingBottom: 0
    },
    svg: {
        width: 18,
        height: 18,
        cursor: 'pointer'
    }
}));

export const CreativeContent = ({
    adType,
    units,
    campaign,
    creative,
    unitIds,
    dispatch,
    multipleItems,
    selectedFormats,
    showAdvanced
}) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const [slideState, setSlideState] = useState(0);
    const tempCreativeItemContent = creative?.items[slideState]?.content;
    const commonCreativeFields = adType.creativeFields.filter((creativeField) => !creativeField.advanced);
    const advancedCreativeFields = adType.creativeFields.filter((creativeField) => creativeField.advanced);
    const [creativeContent, setCreativeContent] = useState(creative.content || {});
    const [creativeItemContent, setCreativeItemContent] = useState(tempCreativeItemContent || {});
    const [previewFormat, setPreviewFormat] = useState('');
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [itemId, setItemId] = useState(null);

    const handleClose = () => {
        setOpen(false);
        setItemId(null);
    };

    const [initItem, { loading }] = useLazyQuery(INIT_ITEM, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            dispatch({
                type: CREATIVE_ACTION_TYPE.CREATIVE_ITEM_SET,
                formatIds: Object.values(selectedFormats).reduce(
                    (acc, format) => (format.selected ? [...acc, format.id] : acc),
                    []
                ),
                items: data.initItem
            });
        },
        onError: (err) => {
            logError(err);
        }
    });

    const [updateAdPreview, { data: adPreviewData, loading: adPreviewLoading }] = useLazyQuery(
        GET_AD_PREVIEW_FROM_CREATIVE,
        {
            variables: {
                input: {
                    adTypeId: adType.id,
                    creative: marshalCreativeInput({
                        ...creative,
                        formatId: Object.values(selectedFormats).find(
                            (format) => format.id === selectedFormats[previewFormat]?.id
                        )?.id
                    })
                }
            },
            onError: (error) => {
                logError(error);
            }
        }
    );

    useEffect(() => {
        if (selectedFormats) {
            const selected = Object.keys(selectedFormats).filter((key) => selectedFormats[key].selected);
            setPreviewFormat(selected[0]);
            updateAdPreview();
        }
    }, [selectedFormats, updateAdPreview]);

    useEffect(() => {
        setCreativeContent(creative.content || {});
        updateAdPreview();
    }, [creative.content, updateAdPreview]);

    useEffect(() => {
        setCreativeItemContent(tempCreativeItemContent || {});
        updateAdPreview();
    }, [tempCreativeItemContent, updateAdPreview]);

    const handleChangeCreative = (e, fieldName) => {
        e.stopPropagation();
        e.persist();
        const updated = clone(creativeContent);
        updated[fieldName] = e.target.value;
        setCreativeContent(updated);
    };

    const handleChangeCreativeItem = (e, fieldName) => {
        e.stopPropagation();
        e.persist();
        const updated = clone(creativeItemContent);
        updated[fieldName] = e.target.value;
        setCreativeItemContent(updated);
    };

    const handleSelectProduct = (productId) => {
        initItem({
            variables: {
                adTypeId: adType.id,
                productId: productId,
                unitIds: unitIds,
                creativeGroupingId: creative.items[slideState].creativeGroupingId
            }
        });
    };

    const createNewSlide = () => {
        initItem({
            variables: {
                adTypeId: adType.id,
                unitIds: unitIds
            }
        });
        setSlideState(slideState + 1);
    };

    const handleClickOpen = (itemId) => {
        setOpen(true);
        setItemId(itemId);
    };

    const handleDeleteItem = () => {
        dispatch({
            type: CREATIVE_ACTION_TYPE.CREATIVE_ITEM_DELETE,
            itemId,
            formatIds: Object.values(selectedFormats).reduce(
                (acc, format) => (format.selected ? [...acc, format.id] : acc),
                []
            ),
            items: creative.items
        });
        setOpen(false);
    };

    const handleResetField = (e, fieldName) => {
        e.stopPropagation();
        e.preventDefault();
        const updated = clone(creativeItemContent);
        updated[fieldName] = tempCreativeItemContent ? tempCreativeItemContent[fieldName] : '';
        setCreativeItemContent(updated);
    };

    const dispatchOnBlur = (type, field, value) => {
        const formatIds = Object.values(selectedFormats).reduce(
            (acc, format) => (format.selected ? [...acc, format.id] : acc),
            []
        );
        const creativeGroupingId = creative.items[slideState].creativeGroupingId;

        dispatch({ type, formatIds, creativeGroupingId, field, value });
    };

    const onPreviewFormatChange = (event) => {
        setPreviewFormat(event.target.value);
    };

    const adPreviewUrl = adPreviewData ? adPreviewData?.getAdPreviewFromCreative?.previews[0] : null;

    // image format specs
    let [minimumRes, recommendedRes] = [null, null];
    let sizeLimit;
    if (adType?.name && adType?.networks && adType?.networks[0]) {
        const supportedResolutions = getImageResolutionRequirements(adType.name, adType.networks[0].name);
        if (supportedResolutions) {
            [minimumRes, recommendedRes] = supportedResolutions;
        }
        sizeLimit = getImageSizeRequirements(adType.name, adType.networks[0].name);
    }

    const validCommonFields = !showAdvanced && commonCreativeFields?.length > 0 && commonCreativeFields;
    const validAdvancedFields = showAdvanced && advancedCreativeFields?.length > 0 && advancedCreativeFields;
    const validCreativeFields = [validCommonFields, validAdvancedFields].filter((fields) => Boolean(fields));

    const existingFormats =
        selectedFormats &&
        Object.values(selectedFormats)
            .filter((format) => format.name !== 'Default')
            .some((format) => format.selected);

    const itemFieldComponents = adType.itemFields.map((itemField) => {
        if (itemField.type === AdTypeFieldType.SELECT) {
            return (
                <ItemFieldWrapper key={itemField.type + itemField.name}>
                    <FormControl variant='filled' fullWidth>
                        <InputLabel id='creative-content-select'>{t(`backend.adTypes.${itemField.name}`)}</InputLabel>
                        <Select
                            labelId='creative-content-select'
                            value={creativeItemContent[itemField.name]}
                            onChange={(e) =>
                                dispatchOnBlur(
                                    CREATIVE_ACTION_TYPE.CREATIVE_ITEM_UPDATE_FIELD,
                                    itemField.name,
                                    e.target.value
                                )
                            }
                        >
                            {creativeItemContent[itemField.name] === 'mixed' && (
                                <MenuItem value='mixed'>
                                    <em>mixed</em>
                                </MenuItem>
                            )}
                            {itemField.options.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </ItemFieldWrapper>
            );
        } else if (itemField.type === AdTypeFieldType.IMAGE && creative.items[slideState]?.product) {
            return (
                <ItemFieldWrapper key={itemField.type + itemField.name}>
                    <Box
                        bgcolor={theme.palette.background.filled}
                        border='1px solid'
                        borderColor={theme.palette.divider}
                        padding={2}
                    >
                        <ImageCropper
                            minimumResolution={minimumRes}
                            recommendedResolution={recommendedRes}
                            sizeLimit={sizeLimit}
                            onSelectPreview={() => updateAdPreview()}
                            onBlur={(data) => {
                                dispatchOnBlur(CREATIVE_ACTION_TYPE.CREATIVE_ITEM_UPDATE_FIELD, itemField.name, data);
                            }}
                            productImages={getProductImages(creative.items[slideState]?.product)}
                            mediaDimension={
                                selectedFormats[previewFormat] && selectedFormats[previewFormat].mediaDimensions[0]
                            }
                        />
                    </Box>
                </ItemFieldWrapper>
            );
        } else {
            return (
                <ItemFieldWrapper key={itemField.type + itemField.name}>
                    <CreativeContentTextField
                        fieldName={itemField.name}
                        maxLength={itemField.maxLength}
                        value={creativeItemContent[itemField.name]}
                        tempValue={tempCreativeItemContent && tempCreativeItemContent[itemField.name]}
                        required={itemField.required}
                        hasProduct={!isEmpty(creative?.items[slideState]?.product)}
                        dispatch={dispatchOnBlur}
                        dispatchType={CREATIVE_ACTION_TYPE.CREATIVE_ITEM_UPDATE_FIELD}
                        onChange={handleChangeCreativeItem}
                        onReset={(e) => handleResetField(e, itemField.name)}
                    />
                </ItemFieldWrapper>
            );
        }
    });

    const uniqueExtensions = {};
    units?.forEach((unit) => {
        unit?.linkExtension?.extensions?.forEach((extension) => {
            if (extension._id) {
                uniqueExtensions[extension._id] = extension;
            }
        });
    });
    const linkExtensions = Object.values(uniqueExtensions);

    let linkInput;
    if (linkExtensions.length > 0) {
        linkInput = itemFieldComponents.splice(
            adType.itemFields.findIndex((field) => field.name === 'link'),
            1
        )[0];
    }

    return (
        <Grid container justify='space-between' spacing={2}>
            <Grid item sm={4} xs={12}>
                <Button onClick={() => updateAdPreview()}>PREVIEW</Button>
                {validCreativeFields.map((validCreativeField) => (
                    <React.Fragment key={validCreativeField}>
                        <Typography variant='h6'>{t('Common')}</Typography>
                        <Grid container spacing={2}>
                            {validCreativeField.map((creativeField) => (
                                <Grid item xs={12} key={`creative-field-${creativeField.name}`}>
                                    <CreativeContentTextField
                                        fieldName={creativeField.name}
                                        maxLength={creativeField.maxLength}
                                        value={creativeContent[creativeField.name]}
                                        dispatch={dispatchOnBlur}
                                        dispatchType={CREATIVE_ACTION_TYPE.CREATIVE_UPDATE_FIELD}
                                        onChange={handleChangeCreative}
                                        required={creativeField.required}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </React.Fragment>
                ))}
                {!showAdvanced && multipleItems && (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Tabs
                            value={slideState}
                            indicatorColor='primary'
                            textColor='primary'
                            variant='scrollable'
                            scrollButtons='auto'
                            classes={{
                                wrapper: classes.iconLabelWrapper,
                                root: classes.rootMuiTabs
                            }}
                            onChange={(_, value) => setSlideState(value)}
                            aria-label='slides picker'
                        >
                            {creative.items.map((item, index) => (
                                <Tab
                                    key={item.id + index}
                                    classes={{
                                        wrapper: classes.iconLabelWrapper,
                                        root: classes.labelContainer
                                    }}
                                    label={index + 1}
                                />
                            ))}
                        </Tabs>
                        <IconButton onClick={createNewSlide}>
                            <Add />
                        </IconButton>
                    </div>
                )}
                {!showAdvanced && (
                    <Box marginY={2}>
                        <Typography variant='h6'>{t('Product')}</Typography>
                        <SingleProductSelector
                            product={creative?.items[slideState]?.product || null}
                            itemId={creative?.items[slideState]?.id || null}
                            isLoading={loading}
                            handleDeleteItem={handleClickOpen}
                            setProductId={(productId) => handleSelectProduct(productId)}
                        />
                    </Box>
                )}
                {!showAdvanced && adType?.itemFields && adType.itemFields.length > 0 && (
                    <Grid container spacing={2}>
                        {itemFieldComponents}
                    </Grid>
                )}
                {!showAdvanced && linkExtensions.length > 0 && (
                    <LinkExtension linkExtensions={linkExtensions} linkInput={linkInput} campaign={campaign} />
                )}
            </Grid>
            {!showAdvanced && (
                <Grid item sm={8} xs={12}>
                    <BlueHeaderContainer
                        title={t('Preview')}
                        titleLineAction={
                            existingFormats ? (
                                <Box width='40%' bgcolor='white'>
                                    <Select
                                        fullWidth
                                        disableUnderline
                                        displayEmpty
                                        id='preview-selector'
                                        value={previewFormat}
                                        onChange={onPreviewFormatChange}
                                        style={{
                                            marginTop: -4,
                                            marginBottom: -4,
                                            paddingLeft: 8
                                        }}
                                    >
                                        <MenuItem value='' disabled>
                                            <ListItemText secondary={t('Select format')} />
                                        </MenuItem>
                                        {Object.entries(selectedFormats).map(
                                            ([key, { name, selected }]) =>
                                                selected && (
                                                    <MenuItem key={`format-preview-${key}`} value={key}>
                                                        <ListItemText primary={name} />
                                                    </MenuItem>
                                                )
                                        )}
                                    </Select>
                                </Box>
                            ) : null
                        }
                    >
                        {adPreviewLoading ? (
                            <CircularProgress />
                        ) : adPreviewUrl ? (
                            <AdPreview url={adPreviewUrl} />
                        ) : (
                            <Typography variant='h6'>{t('No preview')}</Typography>
                        )}
                    </BlueHeaderContainer>
                </Grid>
            )}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Delete product</DialogTitle>
                <DialogContent>
                    <DialogContentText>Are you sure you want to delete this product?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleDeleteItem}>Yes</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
};

CreativeContent.propTypes = {
    adType: PropTypes.shape(adTypeModel),
    units: PropTypes.array,
    campaign: PropTypes.object,
    creative: PropTypes.object,
    dispatch: PropTypes.func,
    multipleItems: PropTypes.bool,
    selectedFormats: PropTypes.shape(adtypeTemplateModel),
    showAdvanced: PropTypes.bool,
    unitIds: PropTypes.arrayOf(PropTypes.string)
};
