import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Button, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4),
        position: 'fixed',
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: 'rgba(0,0,0,0.9)',
        color: theme.palette.white
    },
    content: {
        maxWidth: '34rem',
        margin: '0 auto'
    }
}));

const CookieAccept = (props) => {
    const { className, ...rest } = props;

    const [accepted, setAccepted] = useState(window.localStorage.getItem('cookies') === 'accepted');

    const classes = useStyles();

    const handleAccept = () => {
        window.localStorage.setItem('cookies', 'accepted');
        setAccepted(true);
    };
    if (accepted) {
        return null;
    }

    return (
        <div {...rest} className={clsx(classes.root, className)}>
            <div className={classes.content}>
                <Typography variant='body1' color='inherit' component='p'>
                    Treats for your browser! We’re serving nice cookies to it so that your next visit to our website is
                    even more awesome. You can read{' '}
                    <Link component={RouterLink} to='/cookie-policy'>
                        more about cookies here.
                    </Link>
                </Typography>
                <Button variant='contained' color='primary' onClick={handleAccept}>
                    Accept Cookies
                </Button>
            </div>
        </div>
    );
};

CookieAccept.propTypes = {
    className: PropTypes.string
};

export default CookieAccept;
