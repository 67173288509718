import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';

import {
    Box,
    capitalize,
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField
} from '@material-ui/core';
import { Delete, Refresh, Search } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

import { BlueHeaderContainer } from 'common/components';
import { Context as activeUnitContext } from 'context/units/activeUnitContext';
import { usePowerBIReportDelete, usePowerBIReportList, usePowerBIReportRestore } from 'hooks/powerBIReport';
import useDebounce from 'hooks/useDebounce';

import CreateReport from './CreateReport';
import EditReport from './EditReport';

const useStyles = makeStyles((theme) => ({
    search: {
        maxWidth: 304
    },
    slimWidth: {
        width: '15%'
    },
    smallWidth: {
        width: '10%'
    },
    tinyWidth: {
        width: '5%'
    },
    tableCell: {
        padding: '4px',
        fontSize: '12px',
        lineHeight: '12px'
    },
    tableIcon: {
        padding: '4px'
    },
    tableRow: {
        borderBottom: `1px solid ${theme.palette.divider}`
    },
    tableContainer: {
        overflowX: 'auto'
    }
}));

function AdminReport() {
    const { enqueueSnackbar } = useSnackbar();
    const { i18n } = useTranslation();
    const classes = useStyles();
    const [search, setSearch] = useState('');
    const [showIsDeleted, setShowIsDeleted] = useState(false);
    const debouncedSearchTerm = useDebounce(search, 500);

    const {
        state: { units }
    } = useContext(activeUnitContext);

    const { data: pbReports } = usePowerBIReportList({ isDeleted: showIsDeleted });
    const reports = pbReports?.reports?.length ? pbReports.reports : [];

    const { mutateAsync: deleteReport } = usePowerBIReportDelete();
    const { mutateAsync: restoreReport } = usePowerBIReportRestore();

    return (
        <BlueHeaderContainer title='Report administration' padding={2}>
            <Grid container spacing={2}>
                <Grid container item xs={12} direction='row' justifyContent='space-between'>
                    <Box sx={{ display: 'flex', flexGrow: 1 }}>
                        <TextField
                            className={classes.search}
                            InputProps={{ endAdornment: <Search /> }}
                            fullWidth
                            label='Search'
                            variant='outlined'
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />

                        <FormControlLabel
                            style={{ marginLeft: 8 }}
                            control={
                                <Checkbox
                                    checked={showIsDeleted}
                                    onChange={(e) => setShowIsDeleted(e.target.checked)}
                                    color='primary'
                                />
                            }
                            label='Show deleted'
                        />
                    </Box>

                    <CreateReport units={units} />
                </Grid>
                <Grid item xs={12} className={classes.tableContainer}>
                    <Table>
                        <TableHead>
                            <TableRow className={classes.tableRow}>
                                <TableCell className={clsx(classes.tableCell)} title='(number of names)'>
                                    Name
                                </TableCell>
                                <TableCell className={clsx(classes.tableCell, classes.slimWidth)}>Note</TableCell>
                                <TableCell className={clsx(classes.tableCell, classes.tinyWidth)}>Version</TableCell>
                                <TableCell
                                    className={clsx(classes.tableCell, classes.slimWidth)}
                                    title='(number of units)'
                                >
                                    Permission
                                </TableCell>
                                <TableCell className={classes.tableCell}>Category</TableCell>
                                <TableCell
                                    className={clsx(classes.tableCell, classes.tinyWidth)}
                                    title='Number of datasources'
                                >
                                    Datasources
                                </TableCell>
                                <TableCell className={clsx(classes.tableCell, classes.smallWidth)} align='right'>
                                    Actions
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {reports
                                .filter((report) => {
                                    const regex = new RegExp(debouncedSearchTerm, 'i');

                                    return regex.test(report.name[i18n.language]);
                                })
                                .map((report) => (
                                    <TableRow key={report.id} className={classes.tableRow}>
                                        <TableCell className={clsx(classes.tableCell)}>
                                            {report.name[i18n.language]}
                                        </TableCell>
                                        <TableCell className={clsx(classes.tableCell, classes.slimWidth)}>
                                            {report.internalNote}
                                        </TableCell>
                                        <TableCell className={clsx(classes.tableCell, classes.tinyWidth)}>
                                            {Object.keys(report.versions).length}
                                        </TableCell>
                                        <TableCell className={clsx(classes.tableCell, classes.slimWidth)}>
                                            {capitalize(report.permission.type)} (
                                            {report.permission.type === 'global' ? '-' : ''}
                                            {report.permission.type === 'global'
                                                ? report.permission.excludedUnits.length
                                                : report.permission.units.length}
                                            )
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>{report.defaultCategory}</TableCell>
                                        <TableCell className={clsx(classes.tableCell, classes.tinyWidth)}>
                                            {report.dataSources.length}
                                        </TableCell>
                                        <TableCell
                                            className={clsx(classes.tableCell, classes.smallWidth)}
                                            align='right'
                                        >
                                            {report.metadata.isDeleted && (
                                                <IconButton
                                                    className={classes.tableIcon}
                                                    onClick={async () => {
                                                        const confirmed = confirm(
                                                            'Are you sure you want to restore this report?'
                                                        );

                                                        if (!confirmed) return;

                                                        await restoreReport(report.id, {
                                                            onSuccess: (data) => {
                                                                console.info('Report restored successfully:', data);
                                                                enqueueSnackbar('Report restored', {
                                                                    variant: 'success'
                                                                });
                                                            },
                                                            onError: (error) => {
                                                                console.error('Error restoring report:', error);
                                                                enqueueSnackbar('Could not restore report', {
                                                                    variant: 'error'
                                                                });
                                                            }
                                                        });
                                                    }}
                                                >
                                                    <Refresh />
                                                </IconButton>
                                            )}

                                            {!report.metadata.isDeleted && <EditReport report={report} units={units} />}

                                            {!report.metadata.isDeleted && (
                                                <IconButton
                                                    className={classes.tableIcon}
                                                    onClick={async () => {
                                                        const confirmed = confirm(
                                                            'Are you sure you want to delete this report?'
                                                        );

                                                        if (confirmed) {
                                                            await deleteReport(report.id, {
                                                                onSuccess: (data) => {
                                                                    console.info('Report deleted successfully:', data);
                                                                    enqueueSnackbar('Report deleted', {
                                                                        variant: 'success'
                                                                    });
                                                                },
                                                                onError: (error) => {
                                                                    console.error('Error deleting report:', error);
                                                                    enqueueSnackbar('Could not delete report', {
                                                                        variant: 'error'
                                                                    });
                                                                }
                                                            });
                                                        }
                                                    }}
                                                >
                                                    <Delete />
                                                </IconButton>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </BlueHeaderContainer>
    );
}

export default AdminReport;
