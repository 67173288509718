import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';

import { Grid } from '@material-ui/core';

import { useCampaignDispatch } from 'context/campaign/CampaignContext';
import { SETTINGS_ACTION_TYPE } from 'context/campaign/settingsReducer';
import { AdSchedulingWrapper } from 'views/Campaign/components/AdScheduling/AdSchedulingWrapper';

import { AdBudgeting } from '../AdBudgeting/AdBudgeting';

export const CampaignAdSettings = ({ ad, unitsSelected }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useCampaignDispatch();

    return (
        <Grid container item xs={12}>
            <AdSchedulingWrapper
                schedule={ad.schedule}
                setSchedule={(schedule) => {
                    enqueueSnackbar(t('Update campaign'), { variant: 'info', preventDuplicate: true });
                    dispatch({ type: SETTINGS_ACTION_TYPE.UPDATE_SCHEDULE, schedule });
                }}
            />
            <AdBudgeting
                spending={ad.budget}
                setSpending={(budget) => dispatch({ type: SETTINGS_ACTION_TYPE.UPDATE_BUDGET, budget })}
                totalSpending={ad.budgetTotal}
                numUnits={unitsSelected}
            />
        </Grid>
    );
};

CampaignAdSettings.propTypes = {
    ad: PropTypes.object,
    unitsSelected: PropTypes.number
};
