import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Checkbox, FormControlLabel, Grid, MenuItem, Select, TextField, Tooltip } from '@material-ui/core';

import { LogoUpload } from 'common/components/LogoUpload/logo.upload';
import { usePrevious } from 'hooks/usePrevious';

import { UnitContext } from '../unit.provider';

function UnitAccountSettings() {
    const { editingUnit, setCategories, setIsEdited, setEditingUnit, industries } = useContext(UnitContext);

    const { t } = useTranslation();

    const [selectedIndustry, setSelectedIndustry] = useState(0);

    const handleChange = (event) => {
        const { type, value, name, checked } = event.currentTarget;

        setEditingUnit({
            ...editingUnit,
            [name]: type === 'checkbox' ? checked : value
        });
        setIsEdited(true);
    };

    const handleIndustrySelect = (e) => {
        const { value } = e.target;

        if (value === 0) {
            setCategories(industries.flatMap((industry) => industry.categories) || []);
        } else {
            const industry = industries.find((industry) => industry.id === value);

            if (industry) {
                setCategories(industry.categories || []);
            }
        }

        setSelectedIndustry(value);
    };

    const prevEditingUnit = usePrevious(editingUnit);

    /* eslint-disable */
    useEffect(() => {
        if (prevEditingUnit && prevEditingUnit.id !== editingUnit.id) {
        }

        if (selectedIndustry === 0) {
            setCategories(industries.flatMap((industry) => industry.categories) || []);
        }
    }, [editingUnit, prevEditingUnit, selectedIndustry]);

    /* eslint-enable */
    return (
        <Box px={2} py={2}>
            <Grid container direction='column'>
                <Grid item xs={12} container spacing={4}>
                    <Grid item>
                        <Box width='fit-content'>
                            <LogoUpload
                                editingUnit={editingUnit}
                                setEditingUnit={setEditingUnit}
                                setIsEdited={setIsEdited}
                            />
                        </Box>

                        {editingUnit && (
                            <Box mb={2}>
                                <FormControlLabel
                                    control={
                                        <Checkbox name='isDemo' checked={editingUnit.isDemo} onChange={handleChange} />
                                    }
                                    label='Demo data'
                                    labelPlacement='end'
                                />
                            </Box>
                        )}

                        <Tooltip
                            title={t(
                                'If you get a forbidden message when opening the link. Try to right click and open the the link in incognito mode.'
                            )}
                        >
                            <a
                                href='https://brandcom.atlassian.net/servicedesk/customer/portal/36/group/47/create/84'
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                <Button variant='contained' color='primary' fullWidth>
                                    {t('Order report package')}
                                </Button>
                            </a>
                        </Tooltip>
                    </Grid>
                    <Grid item xs>
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <Box mb={2} pr={2}>
                                    <TextField
                                        id='customerId'
                                        name='customerId'
                                        label={t('Customer number')}
                                        type='text'
                                        variant='filled'
                                        value={editingUnit?.customerId}
                                        onChange={handleChange}
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box mb={2} pr={2}>
                                    <Select
                                        id='industries'
                                        name='industries'
                                        variant='filled'
                                        value={selectedIndustry}
                                        onChange={handleIndustrySelect}
                                        fullWidth
                                    >
                                        <MenuItem selected={true} key={0} value={0}>
                                            <em>All</em>
                                        </MenuItem>
                                        {industries.map((industry, i) => (
                                            <MenuItem key={i + 1} value={industry.id}>
                                                {industry.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Box>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Box mb={2} pr={2}>
                                    <TextField
                                        id='name'
                                        name='name'
                                        label={t('Name')}
                                        type='text'
                                        variant='filled'
                                        value={editingUnit?.name}
                                        onChange={handleChange}
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box mb={2} pr={2}>
                                    <TextField
                                        id='orgNr'
                                        name='orgNr'
                                        label={t('Org. No.')}
                                        type='text'
                                        variant='filled'
                                        value={editingUnit?.orgNr}
                                        onChange={handleChange}
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Box>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Box mb={2} pr={2}>
                                    <TextField
                                        id='email'
                                        name='email'
                                        label={t('Email')}
                                        type='text'
                                        variant='filled'
                                        value={editingUnit?.email}
                                        onChange={handleChange}
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box mb={2} pr={2}>
                                    <TextField
                                        id='phone'
                                        name='phone'
                                        label={t('Phone')}
                                        type='text'
                                        variant='filled'
                                        value={editingUnit?.phone}
                                        onChange={handleChange}
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}

export default UnitAccountSettings;
