const checked = (value, options) => {
    if (value !== true) {
        return options.message || 'must be checked';
    }
};

const EMAIL_REGEX = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

export function validEmail(email) {
    return EMAIL_REGEX.test(email);
}

// a function to detect whether the key indicates to swap focus: Enter/Tab/,
const FOCUS_KEYS = ['Enter', 'Tab', ','];
export function switchFocusKey(event) {
    return FOCUS_KEYS.includes(event.key);
}

export default {
    checked
};
