import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useQuery } from '@apollo/client';
import { Button, Grid } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/styles';

import { BlueHeaderContainer } from 'common/components/BlueHeaderContainer/BlueHeaderContainer';
import { Context as activeUnitContext } from 'context/units/activeUnitContext';
import { GET_UNIT } from 'graphql/queries/user';

import HeaderConnections from './components/AccountConnectedPages/HeaderConnections';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%'
    },

    connectionsContainer: {
        height: 500
    },

    avatar: {
        height: theme.spacing(7),
        width: theme.spacing(7)
    },

    subContent: {
        marginTop: theme.spacing(7)
    }
}));

const AccountNewConnection = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const {
        state: { activeUnit }
    } = useContext(activeUnitContext);

    const { data: getUnit } = useQuery(GET_UNIT, {
        variables: { id: activeUnit.id },
        onCompleted: () => {
            //setNewConnections([...res.getUnit.connections]);
        }
    });

    const connections = getUnit && getUnit.getUnit.connections;
    const pages = connections && connections.facebook.pages;
    const [selectedIndex, setSelectedIndex] = useState(1);

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
        history.push('/unit/plugins/facebook');
    };

    const handleClick = () => {
        history.push('/unit/plugins/browse');
    };

    return (
        <div className={classes.root}>
            <Grid>
                <Grid className={classes.content} item lg={12} xs={12} xl={12} md={12} sm={12}>
                    <div>
                        <HeaderConnections />
                        <div className={classes.subContent}>
                            <BlueHeaderContainer
                                title={t('Installed connections')}
                                titleLineAction={
                                    <Button startIcon={<AddIcon />} size='small' color='primary' onClick={handleClick}>
                                        {t('New connections')}
                                    </Button>
                                }
                            >
                                <div className={classes.connectionsContainer}>
                                    {pages && (
                                        <>
                                            <List component='nav' aria-label='main mailbox folders'>
                                                <ListItem
                                                    button
                                                    selected={selectedIndex === 0}
                                                    onClick={(event) => handleListItemClick(event, 0)}
                                                >
                                                    <ListItemIcon>
                                                        <img
                                                            className={classes.avatar}
                                                            alt='fb-icon'
                                                            src='/images/fbIcon.png'
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText primary={t('Facebook pages')} />
                                                </ListItem>
                                            </List>
                                        </>
                                    )}
                                </div>
                            </BlueHeaderContainer>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default AccountNewConnection;
