import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const { REACT_APP_SITE_NAME } = process.env;

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4)
    },
    iframe: {
        width: '100%',
        minHeight: 640,
        border: 0
    }
}));

const Home = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.root}>
            <Typography variant='h1'>Home {REACT_APP_SITE_NAME}</Typography>
            <Typography variant='body1' component='p'>
                {' '}
                {t('Welcome to our solution')}
            </Typography>
            <Typography variant='body1' component='p'>
                <Link to='/sign-in' component={RouterLink}>
                    {t('Log in')}
                </Link>{' '}
                {t('To get started').toLowerCase()}
            </Typography>
        </div>
    );
};

export default Home;
