import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';

import { validURL } from 'helpers/url';

export const AdPreview = ({ url }) => {
    const [height, setHeight] = useState(656);
    const [width, setWidth] = useState('100%');

    useEffect(() => {
        if (url && validURL(url)) {
            const parsedUrl = new URL(url);

            if (parsedUrl.searchParams.get('width')) {
                setWidth(parsedUrl.searchParams.get('width'));
            }

            if (parsedUrl.searchParams.get('height')) {
                setHeight(parsedUrl.searchParams.get('height'));
            }
        }
    }, [url]);

    return (
        <Box width={1} height={1}>
            {url && (
                <iframe
                    title='Ad preview'
                    src={url}
                    frameBorder='0'
                    width={width}
                    height={height}
                    marginHeight='0'
                    marginWidth='0'
                />
            )}
        </Box>
    );
};

AdPreview.propTypes = {
    url: PropTypes.string
};
