import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useMutation } from '@apollo/client';
import { Avatar, Box, Button, Dialog, Divider, List, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import { AddUnitModal } from 'common/components/AddUnitModal/AddUnitModal';
import { ADD_FACEBOOK_PAGES_TO_UNITS, REMOVE_FACEBOOK_PAGES_FROM_UNITS } from 'graphql/mutations/units';
import { formatName } from 'helpers/name';

import { SearchPages } from '../SearchPages';
import SelectedListItems from '../SelectedListItems';
import RemovePages from './RemovePages';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2.5),
        marginLeft: theme.spacing(2),
        minHeight: theme.spacing(75)
    },
    content: {
        display: 'flex',
        justifyContent: 'space-between',
        marginLeft: theme.spacing(-5),
        marginTop: theme.spacing(3)
    },

    removeButton: {
        color: theme.palette.error.main,
        marginRight: theme.spacing(4),
        padding: 6
    },

    unitselector: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: theme.spacing(4),
        marginLeft: theme.spacing(2)
    },
    divider: {
        marginTop: theme.spacing(5),
        marginRight: theme.spacing(4)
    },
    unitsSubheader: {
        marginTop: theme.spacing(2)
    },

    selectedUnit: {
        marginLeft: theme.spacing(5),
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        marginBottom: theme.spacing(3)
    },

    search: {
        maxWidth: theme.spacing(60)
    },

    addUnitButton: {
        marginRight: theme.spacing(4)
    },

    selectedPageheader: {
        marginLeft: theme.spacing(1),
        minWidth: theme.spacing(22.5)
    },
    contentItem: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginLeft: theme.spacing(4)
    },

    value: {
        display: 'flex'
    },

    avatar2: {
        marginTop: theme.spacing(0.5),
        fontSize: 12
    },
    list2: {
        overflow: 'auto',
        maxHeight: theme.spacing(37.5),
        maxWidth: theme.spacing(58),
        marginLeft: theme.spacing(2),
        borderLeft: '1px solid WhiteSmoke',
        borderBottom: '1px solid WhiteSmoke',
        borderRight: '1px solid WhiteSmoke'
    },

    fbShareedText: {
        marginLeft: theme.spacing(10)
    },
    textLabel: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    }
}));

export default function SelectPages({ selectedPages, notConnected, connected, units, deselectPages, refetch }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    const [ModalState, setModalState] = useState(false);
    const [pageIds, setPageIds] = useState([]);

    const [commonUnits, setCommonUnits] = useState([]);
    const [unCommonUnits, setUnCommonUnits] = useState([]);
    const [filters, setFilters] = useState({ searchText: '' });
    const handleClose = () => {
        setOpen(!open);
    };

    const [addFacebookPagesToUnits] = useMutation(ADD_FACEBOOK_PAGES_TO_UNITS, {
        onCompleted: () => {
            handleClose();
            refetch();
        }
    });
    const [removeFacebookPagesFromUnits] = useMutation(REMOVE_FACEBOOK_PAGES_FROM_UNITS, {
        onCompleted: () => {
            refetch();
        }
    });

    const handleremoveFacebookPages = () => {
        removeFacebookPagesFromUnits({
            variables: {
                input: {
                    unitIds: commonUnits.map((unit) => unit.id).concat(unCommonUnits.map((unit) => unit.id)),
                    pageIds: pageIds
                }
            }
        });
    };
    const handleDeleteUnit = (id) => {
        removeFacebookPagesFromUnits({
            variables: { input: { unitIds: [id], pageIds: pageIds } }
        });
    };

    const handleSubmit = (units) => {
        const unitIds = units?.map((unit) => {
            return unit.id;
        });
        addFacebookPagesToUnits({ variables: { input: { unitIds: unitIds, pageIds: pageIds } } });
        if (notConnected.some((page) => selectedPages.includes(page.id))) {
            deselectPages();
        }
    };

    useEffect(() => {
        const selectedPagesArray = connected.concat(notConnected).filter((page) => selectedPages.includes(page.id));

        const selectedUnitsData = selectedPagesArray.map((page) => page.units).filter((item) => !!item);

        const flat = selectedUnitsData.flatMap((x) => x);

        let commonUnitsData = flat;
        if (selectedPagesArray.length !== 1) {
            commonUnitsData = flat.filter((val, index, array) => array.indexOf(val) !== index);
        }

        const unCommonUnitsData = flat.filter((x) => !commonUnitsData.includes(x));

        setPageIds(
            selectedPagesArray.map((page) => {
                return page.key ? page.key : page.id;
            })
        );

        setCommonUnits([...new Set(commonUnitsData)]);
        setUnCommonUnits(unCommonUnitsData);
    }, [connected, notConnected, selectedPages]);

    let searchCommonUnits = [];
    let searchUnCommonUnits = [];

    if (filters.searchText) {
        searchCommonUnits = commonUnits.filter((unit) =>
            unit.name.toLowerCase().includes(filters.searchText.toLocaleLowerCase())
        );
        searchUnCommonUnits = unCommonUnits.filter((unit) =>
            unit.name.toLowerCase().includes(filters.searchText.toLocaleLowerCase())
        );
    } else {
        searchCommonUnits = commonUnits;
        searchUnCommonUnits = unCommonUnits;
    }
    const BoxStyle = ({ children }) => (
        <Box fontStyle='normal' fontWeight='500' fontSize='14px'>
            {children}
        </Box>
    );

    BoxStyle.propTypes = {
        children: PropTypes.object
    };

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <div className={classes.selectedPageheader}>
                    {selectedPages.length === 1 ? (
                        <span>
                            <div className={classes.contentItem}>
                                <Avatar className={classes.avatar} alt='fb-icon' src='/images/fbIcon.png' />
                                <Typography className={classes.selectedPageheader} component={'span'}>
                                    {connected
                                        .concat(notConnected)
                                        .filter((page) => selectedPages.includes(page.id))
                                        .map((page) => (
                                            <Typography variant='h5' color='primary' key={page.id}>
                                                {page.name}
                                            </Typography>
                                        ))}
                                </Typography>
                            </div>

                            <Typography className={classes.fbShareedText}>
                                {t('Facebook page is shared with the following advertiser units')}
                            </Typography>
                        </span>
                    ) : (
                        <span className={classes.selectedUnit}>
                            <Typography variant='h5' color='primary'>
                                {t('Selected pages')} ({selectedPages.length})
                            </Typography>
                            {connected
                                .concat(notConnected)
                                .filter((page) => selectedPages.includes(page.id))
                                .map((page) => {
                                    return (
                                        <div
                                            className={classes.value}
                                            key={page.id}
                                            style={{ textAlign: 'center', display: 'flex' }}
                                        >
                                            <Avatar className={classes.avatar2} src={page.avatarUrl}>
                                                {page.name && formatName(page.name)}
                                            </Avatar>

                                            <Typography style={{ margin: 12 }}>{page.name}</Typography>
                                        </div>
                                    );
                                })}
                        </span>
                    )}
                </div>
                <div>
                    {notConnected.some((page) => selectedPages.includes(page.id)) ? null : (
                        <Button
                            startIcon={<CloseIcon />}
                            size='small'
                            className={classes.removeButton}
                            variant='outlined'
                            onClick={() => setModalState(!ModalState)}
                        >
                            {selectedPages.length === 1 ? t('Disconnect page') : t('Disconnect selected pages')}
                        </Button>
                    )}
                </div>

                <Dialog open={ModalState} onClose={() => setModalState(false)}>
                    <RemovePages
                        ModalState={ModalState}
                        setModalState={setModalState}
                        removeFacebookPagesFromUnits={handleremoveFacebookPages}
                        deselectPages={deselectPages}
                        refetch={refetch}
                    />
                </Dialog>
            </div>

            <Divider className={classes.divider} />
            <div>
                <div className={classes.unitselector}>
                    <div>
                        <Typography variant='h4'>{t('Units')}</Typography>
                        <Typography gutterBottom className={classes.unitsSubheader}>
                            {t('The Facebook pages above are shared with the following advertiser units')}
                        </Typography>
                    </div>
                    <div>
                        <Button
                            variant='contained'
                            color='primary'
                            size='small'
                            className={classes.addUnitButton}
                            onClick={() => {
                                setOpen(true);
                            }}
                        >
                            {t('Add unit')}
                        </Button>
                    </div>
                </div>

                <AddUnitModal
                    availableUnits={units}
                    open={open}
                    onClose={() => {
                        setOpen(false);
                    }}
                    onSave={handleSubmit}
                />

                <div className={classes.search}>
                    {notConnected.some((page) => selectedPages.includes(page.id)) ? null : (
                        <SearchPages
                            onChange={(searchText) => {
                                setFilters({ ...filters, searchText });
                            }}
                            value={filters.searchText}
                        />
                    )}
                </div>
            </div>
            <div className={classes.list2}>
                {notConnected.some((page) => selectedPages.includes(page.id)) ? null : (
                    <List>
                        {searchCommonUnits.map((unit) => {
                            return (
                                <React.Fragment key={unit.id}>
                                    <SelectedListItems unit={unit} onRemove={handleDeleteUnit} />
                                    <Divider />
                                </React.Fragment>
                            );
                        })}

                        {searchUnCommonUnits.map((unit) => {
                            return (
                                <React.Fragment key={unit.id}>
                                    <SelectedListItems unit={unit} />
                                    <Divider />
                                </React.Fragment>
                            );
                        })}
                    </List>
                )}
            </div>
        </div>
    );
}

SelectPages.propTypes = {
    units: PropTypes.array,
    connected: PropTypes.array,
    notConnected: PropTypes.array,
    selectedPages: PropTypes.array,
    deselectPages: PropTypes.func,
    refetch: PropTypes.func
};
