import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import daDK from './locales/da-DK.json';
import deDE from './locales/de-DE.json';
import enUS from './locales/en-US.json';
import esES from './locales/es-ES.json';
import frFR from './locales/fr-FR.json';
import nbNO from './locales/nb-NO.json';
import svSE from './locales/sv-SE.json';
import { DEFAULT_LANGUAGE } from './utils';

const resources = {
    'de-DE': {
        translation: deDE
    },
    'da-DK': {
        translation: daDK
    },
    'en-US': {
        translation: enUS
    },
    'es-ES': {
        translation: esES
    },
    'fr-FR': {
        translation: frFR
    },
    'nb-NO': {
        translation: nbNO
    },
    'sv-SE': {
        translation: svSE
    }
};

i18n.use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        fallbackLng: DEFAULT_LANGUAGE,
        debug: false,
        keySeparator: false,
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
