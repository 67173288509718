import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useMutation } from '@apollo/client';
import { Button, DialogActions, DialogContent, IconButton, Typography } from '@material-ui/core';

import { REMOVE_USERS_FROM_UNIT } from 'graphql/mutations/units';

export default function RemoveUser({ modalState, setModalState, unitId, selectedUserId, refetch, setSelectedUsers }) {
    const { t } = useTranslation();
    const [removeUsers] = useMutation(REMOVE_USERS_FROM_UNIT, {
        variables: { input: { unitId: unitId, userIds: selectedUserId } },
        onCompleted: () => {
            refetch();
        }
    });

    return (
        <div>
            <IconButton onClick={() => modalState(false)}></IconButton>
            <DialogContent>
                <Typography>{t('Are you sure that you want to delete the user?')}</Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        removeUsers();
                        setModalState(false);
                        setSelectedUsers([]);
                    }}
                >
                    {t('Delete')}
                </Button>
                <Button onClick={() => setModalState(false)}>{t('Cancel')}</Button>
            </DialogActions>
        </div>
    );
}

RemoveUser.propTypes = {
    modalState: PropTypes.bool,
    setModalState: PropTypes.func,
    selectedUserId: PropTypes.array,
    unitId: PropTypes.any,
    refetch: PropTypes.func,
    setSelectedUsers: PropTypes.func
};
