import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useQuery } from '@apollo/client';
import { Dialog, DialogContent, makeStyles } from '@material-ui/core';

import { AdPreview, DialogTitleClose } from 'common/components';
import { advertisementModel } from 'common/models';
import { GET_AD_PREVIEW } from 'graphql/queries/adpreview';
import { logError } from 'helpers/error';

const useStyles = makeStyles(() => ({
    dialogPaper: {
        minHeight: '80vh',
        maxHeight: '80vh'
    }
}));

export const AdPreviewDialog = ({ setOpen, open, ad = {} }) => {
    const [urls, setUrls] = useState([]);
    const { dialogPaper } = useStyles();
    const { t } = useTranslation();
    const closeDialog = () => setOpen(false);

    const { loading } = useQuery(GET_AD_PREVIEW, {
        variables: { adId: ad && (ad.realId || ad.id) },
        onCompleted: (data) => {
            setUrls(data?.getAdPreview.previews || []);
        },
        onError: (error) => {
            logError(error);
        }
    });

    return (
        <Dialog onClose={closeDialog} open={open} fullWidth={true} maxWidth='lg' classes={{ paper: dialogPaper }}>
            <DialogTitleClose onClose={closeDialog} id='scroll-dialog-title'>
                {ad.name}
            </DialogTitleClose>
            <DialogContent id='scroll-dialog-description' dividers={true}>
                {!loading && urls.map((url) => <AdPreview key={url} url={url || ''} />)}
                {!loading && urls.length === 0 && t('No preview')}
            </DialogContent>
        </Dialog>
    );
};

AdPreviewDialog.propTypes = {
    setOpen: PropTypes.func,
    open: PropTypes.bool,
    ad: PropTypes.shape(advertisementModel)
};
