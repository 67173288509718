import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        align: 'center'
    },
    grid: {
        height: '100%'
    },

    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    contentHeader: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: theme.spacing(5),
        paddingBototm: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    contentBody: {
        flexGrow: 1,
        display: 'flex',
        align: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('lg')]: {
            justifyContent: 'center'
        }
    },
    form: {
        paddingLeft: 75,
        paddingRight: 75,
        paddingBottom: 125,
        flexBasis: 700,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            marginBottom: theme.spacing(2)
        }
    },
    title: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
        color: '#0578D3',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '24px'
    },
    paper: {
        marginTop: theme.spacing(15),
        alignContent: 'center'
    },
    typo2: {
        fonFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        marginBottom: -theme.spacing(11)
    }
}));

function InvalidRecoveryToken() {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.root}>
            <Grid>
                <Grid className={classes.content} item lg={4} xs={8} xl={4} md={6} sm={8}>
                    <div>
                        <Paper className={classes.paper} elevation={8}>
                            <div className={classes.contentBody}>
                                <form autoComplete='off' noValidate className={classes.form}>
                                    <Typography className={classes.title} variant='h2' align='center'>
                                        {t('Invalid recovery token')}
                                    </Typography>
                                    <Typography gutterBottom h3 className={classes.typo2}>
                                        {t('The recovery token is invalid')}
                                        {''}
                                        <Link to='/recover' style={{ color: '#0578D3' }}>
                                            {''} {t('Click here')} {''}
                                        </Link>
                                        {''}
                                        {t('To reset your password.').toLowerCase()}
                                    </Typography>
                                </form>
                            </div>
                        </Paper>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default InvalidRecoveryToken;
