import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';

import { useLazyQuery } from '@apollo/client';
import { LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { FormatSelector } from 'common/components/FormatSelector/FormatSelector';
import { adTypeModel } from 'common/models/advertisement';
import { useCampaignDispatch } from 'context/campaign/CampaignContext';
import { CREATIVE_ACTION_TYPE } from 'context/campaign/creativesReducer';
import { INIT_CREATIVE } from 'graphql/queries/object';
import { logError } from 'helpers/error';

import { CampaignAdCreativesContentContainer } from './CampaignAdCreativesContentContainer';

const mergeContent = (memo, current) => {
    for (const key in memo) {
        memo[key] = memo[key] === current[key] ? memo[key] : 'mixed';
    }

    return memo;
};

// This method mutates not only the memo but the current value as well,
// it's an intentional simplification to allow easier adding of items if
// the first creative doesn't have all of them.
const mergeItem = (memo, current) => {
    if (current === undefined) {
        memo.partial = true;

        return memo;
    }

    memo.id = memo.id === current.id ? memo.id : 'mixed ';
    memo.name = memo.name === current.name ? memo.name : 'mixed';
    memo.product = memo.product?.id === current.product?.id ? memo.product : 'mixed';
    memo.url = memo.url === current.url ? memo.url : 'mixed';
    memo.content = mergeContent(memo.content, current.content);

    current.merged = true;

    return memo;
};

const mergeItems = (memoItems, currentItems) =>
    memoItems
        .map((memoItem) =>
            mergeItem(
                memoItem,
                currentItems.find((currentItem) =>
                    memoItem.creativeGroupingId
                        ? memoItem.creativeGroupingId === currentItem.creativeGroupingId
                        : memoItem.id === currentItem.id
                )
            )
        )
        .concat(currentItems.filter((item) => !item.merged).map((item) => ({ ...item, partial: true })));

const mergeNAssets = (memoAssets, currentAssets) => ({
    facebookPage: { key: memoAssets?.facebookPage?.key ?? currentAssets?.facebookPage?.key }
});

const reduceCreativeMap = (formatIds) => {
    const selected = (creative) => formatIds.includes(creative.formatId);

    const reduceCreatives = (memo, creative) => {
        if (memo === undefined) {
            return cloneDeep(creative);
        }

        return {
            templateId: memo.templateId === creative.templateId ? memo.templateId : 'mixed',
            formatId: memo.formatId + creative.formatId,
            items: mergeItems(memo.items, creative.items),
            budget: memo.budget === creative.budget ? memo.budget : 'mixed',
            itemId: memo.itemId === creative.itemId ? memo.itemId : 'mixed',
            type: memo.type === creative.type ? memo.type : 'mixed',
            networkAssets: mergeNAssets(memo.networkAssets, creative.networkAssets),
            content: mergeContent(memo.content, creative.content)
        };
    };

    return (memo, [unitid, creatives]) => {
        const currentCreative = (creatives && creatives.filter(selected).reduce(reduceCreatives, undefined)) || {
            items: [],
            content: {}
        };
        const missing = creatives === undefined || !creatives.length ? unitid : false;

        if (memo === undefined) {
            currentCreative.missing = missing ? [missing] : [];

            return cloneDeep(currentCreative);
        }

        return {
            templateId: memo.templateId === currentCreative.templateId ? memo.templateId : 'mixed',
            formatId: memo.formatId === currentCreative.formatId ? memo.formatId : 'mixed',
            missing: missing ? [...memo.missing, unitid] : memo.missing,
            items: mergeItems(memo.items, currentCreative.items),
            itemId: memo.itemId === currentCreative.itemId ? memo.itemId : 'mixed',
            budget: memo.budget === currentCreative.budget ? memo.budget : 'mixed',
            type: memo.type === currentCreative.type ? memo.type : 'mixed',
            networkAssets: mergeNAssets(memo.networkAssets, currentCreative.networkAssets),
            content: mergeContent(memo.content, currentCreative.content)
        };
    };
};

const useStyles = makeStyles((theme) => ({
    root: {
        border: `1px solid ${theme.palette.divider}`,
        backgroundColor: theme.palette.background.paper
    },
    noBottomBorder: {
        borderBottom: 'none'
    },
    windowContainer: {
        borderBottom: `1px solid ${theme.palette.divider}`
    },
    tileCardSelector: {
        padding: theme.spacing(2)
    }
}));

export const CampaignAdCreatives = ({ ad, adType, units, campaign }) => {
    const { creativesContent } = useStyles();
    const [selectedFormats, setSelectedFormats] = useState(
        adType.supportedCreativeFormats.reduce(
            (acc, currentFormat) => ({ ...acc, [currentFormat.id]: { ...currentFormat, selected: true } }),
            {}
        )
    );
    const [creative, setCreative] = useState(undefined);

    const dispatch = useCampaignDispatch();

    const [initCreative] = useLazyQuery(INIT_CREATIVE, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => dispatch({ type: CREATIVE_ACTION_TYPE.CREATIVE_ADD, creativesMap: data.initCreative }),
        onError: (err) => {
            logError(err);
        }
    });

    useEffect(() => {
        const reducedCreatives = Object.entries(ad.creatives).reduce(
            reduceCreativeMap(
                Object.values(selectedFormats).reduce(
                    (acc, format) => (format.selected ? [...acc, format.id] : acc),
                    []
                )
            ),
            undefined
        );

        if (reducedCreatives.missing?.length) {
            initCreative({
                variables: {
                    adTypeId: adType.id,
                    productIds: [],
                    unitIds: reducedCreatives.missing,
                    creativeGroupingId: reducedCreatives.items[0]?.creativeGroupingId
                }
            });
            setCreative(undefined);
        } else {
            setCreative(reducedCreatives);
        }
    }, [ad, adType, selectedFormats, initCreative]);

    return (
        <>
            {creative ? (
                <>
                    {adType.supportedCreativeFormats.length > 1 && (
                        <FormatSelector formats={selectedFormats} onSelectedFormat={setSelectedFormats} />
                    )}
                    <CampaignAdCreativesContentContainer
                        selectedFormats={selectedFormats}
                        className={creativesContent}
                        creative={creative}
                        unitIds={[...ad.advertisers.values()]}
                        adType={adType}
                        units={units}
                        campaign={campaign}
                        dispatch={dispatch}
                    />
                </>
            ) : (
                <LinearProgress />
            )}
        </>
    );
};

CampaignAdCreatives.propTypes = {
    ad: PropTypes.object,
    adType: PropTypes.shape(adTypeModel),
    units: PropTypes.array,
    campaign: PropTypes.object
};
