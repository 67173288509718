import gql from 'graphql-tag';

import { FRAGMENT_DATA_SOURCES } from './dataSources';
import { FRAGMENT_CONNECTIONS } from './network';
import { FRAGMENT_PBI_REPORTS } from './pbi.reports';
import { FRAGMENT_FEED, FRAGMENT_PRODUCT_TYPE } from './products';
import { FRAGMENT_TAG } from './tags';

export const FRAGMENT_ADDRESS = gql`
    fragment address on Address {
        address1
        address2
        postalCode
        city
        county
        country
    }
`;

export const FRAGMENT_USER_WITHOUT_REFS = gql`
    fragment user on User {
        id
        firstName
        lastName
        email
        phone
        verified
    }
`;

export const FRAGMENT_UNIT_SIMPLE_REFS = gql`
    fragment unit on Unit {
        id
        name
        logo
        customerId
        orgNr
        email
        phone
        isDemo
        isDeleted
        parentId
        powerBIReportConfigs
        externalReports {
            name
            url
        }
        location {
            ...address
        }
        invoice {
            ...address
        }
        users {
            ...user
        }
        tags {
            ...tag
        }
        productFeeds {
            ...feed
        }
        productTypes {
            ...productType
        }
        connections {
            ...connections
        }
        dataSources {
            ...dataSources
        }
    }
    ${FRAGMENT_ADDRESS}
    ${FRAGMENT_USER_WITHOUT_REFS}
    ${FRAGMENT_TAG}
    ${FRAGMENT_PRODUCT_TYPE}
    ${FRAGMENT_FEED}
    ${FRAGMENT_CONNECTIONS}
    ${FRAGMENT_DATA_SOURCES}
`;
