import React, { useContext, useEffect, useState } from 'react';
import Favicon from 'react-favicon';
import { useTranslation } from 'react-i18next';
import reactManifest from 'react-manifest';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {
    AppBar,
    Avatar,
    Badge,
    Chip,
    Divider,
    IconButton,
    makeStyles,
    Menu,
    MenuItem,
    Toolbar,
    Typography
} from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';
import { Notifications } from '@material-ui/icons';

import { ActiveUnitButton } from 'common/components';
import { unitModel } from 'common/models';
import { UnitSelectorDialog } from 'common/modules';
import { AuthContext } from 'context/AuthContext';
import { clearAuthKeys } from 'helpers/auth';

export const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: theme.spacing(2),
        height: 56,
        minHeight: 56
    },
    appBarShift: {
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    avatar: {
        width: 32,
        height: 32
    },
    name: {
        color: 'white',
        fontSize: theme.typography.fontSize,
        fontWeight: 'medium'
    },
    appBar: {
        boxShadow: 'none',
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        height: 56
    },
    a: {
        color: 'black'
    },
    menuButton: {
        marginRight: theme.spacing(-4)
    },
    linkSpace: {
        margin: theme.spacing(0.2),
        color: grey[600]
    },
    textSize: {
        fontSize: '14px'
    },
    brandLogo: {
        width: 56,
        minWidth: 56,
        height: 56,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: theme.palette.primary.dark
    },
    aligningLink: {
        marginLeft: theme.spacing(2)
    },
    header: {
        minHeight: 56,
        paddingLeft: 0
    },
    logo: {
        width: 40,
        height: 40,
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        border: 0
    }
}));

export const Topbar = ({ activeUnit, units, firstName, lastName, onSidebarOpen }) => {
    const history = useHistory();
    const classes = useStyles();
    const { t } = useTranslation();

    const { setUser } = useContext(AuthContext);

    const [openUnitSelector, setOpenUnitSelector] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState('');
    const [logo, setLogo] = React.useState('');

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (activeUnit) {
            document.title = activeUnit?.name;

            let logo = null;

            if (activeUnit.logo) {
                logo = activeUnit.logo;
            } else {
                let unitId = activeUnit.parentId;

                while (unitId !== null) {
                    const unit = units.find((u) => u.id === unitId);

                    if (unit) {
                        if (unit.logo && unit.logo.length) {
                            logo = unit.logo;
                            break;
                        }

                        unitId = unit.parentId;
                    } else {
                        break;
                    }
                }
            }

            const icon = {
                sizes: '64x64 48x48 32x32 24x24 16x16',
                src: logo
            };

            switch (true) {
                //
                case /image\/svg/.test(logo):
                    icon.type = 'image/svg+xml';
                    icon.sizes = 'any';
                    icon.src = logo;
                    break;

                case /image\/jpg/.test(logo):
                case /image\/jpeg/.test(logo):
                    icon.type = 'image/jpg';
                    break;

                case /image\/png/.test(logo):
                    icon.type = 'image/png';
                    break;

                case /image\/webp/.test(logo):
                    icon.type = 'image/webp';
                    break;

                default:
                    icon.src = 'favicon.ico';
                    icon.type = 'image/x-icon';

                    break;
            }

            const manifestDetails = {
                short_name: activeUnit.name,
                name: activeUnit.name,
                icons: [icon]
            };

            reactManifest.update(manifestDetails, '#manifest-placeholder');

            setLogo(logo);
        }
    }, [activeUnit, units]);

    return (
        <AppBar
            className={clsx(classes.appBar, {
                [classes.appBarShift]: onSidebarOpen
            })}
        >
            <Favicon url={logo} />
            <Toolbar className={classes.header}>
                <div className={classes.brandLogo}>
                    <div className={classes.logo} style={{ backgroundImage: `url('${logo}')` }} />
                </div>

                <ActiveUnitButton activeUnit={activeUnit} onClick={() => setOpenUnitSelector(!openUnitSelector)} />
                <UnitSelectorDialog dialogOpen={openUnitSelector} setDialogOpen={setOpenUnitSelector} />
                {activeUnit?.isDemo && <Chip label='Demo' color='secondary' size='small' />}
                <div style={{ flexGrow: 1 }} />
                <IconButton color='inherit'>
                    <Badge badgeContent={0} color='error'>
                        <Notifications />
                    </Badge>
                </IconButton>
                <IconButton onClick={handleClick}>
                    <Avatar className={classes.avatar} variant='circle'>
                        <Typography className={classes.name}>
                            {firstName.charAt(0)}
                            {lastName.charAt(0)}
                        </Typography>
                    </Avatar>
                </IconButton>
                <Menu id='profile-menu' anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                    <MenuItem
                        onClick={() => {
                            handleClose();
                            history.push('/settings');
                        }}
                    >
                        {t('Profile settings')}
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={handleClose}>
                        <a
                            className={classes.a}
                            href='mailto:apps@brandcom.ai?subject=Feedback Poweranalytics&bcc=ketil@brandcom.ai'
                        >
                            {t('Give us feedback')}
                        </a>
                    </MenuItem>
                    <Divider />
                    <MenuItem
                        onClick={() => {
                            clearAuthKeys();
                            setUser(null);
                            history.push('/sign-in');
                        }}
                    >
                        {t('Log out')}
                    </MenuItem>
                </Menu>
            </Toolbar>
        </AppBar>
    );
};

Topbar.propTypes = {
    activeUnit: PropTypes.shape(unitModel),
    units: PropTypes.array,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    onSidebarOpen: PropTypes.func
};
