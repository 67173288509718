/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { useMutation, useSubscription } from '@apollo/client';
import { Box, Button, Tab, Tabs } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Add } from '@material-ui/icons';

// import { AdPreviewDialog } from 'common/modules/AdPreviewDialog/AdPreviewDialog';
import { Context as activeUnitContext } from 'context/units/activeUnitContext';
import { generateMockData } from 'graphql/mocks/campaignList';
import { CREATE_CAMPAIGN_GROUP, PUBLISH_CAMPAIGNGROUP } from 'graphql/mutations/object';
import { logError } from 'helpers/error';

import { CampaignFilters } from './components/CampaignFilters/CampaignFilters';
// import { CampaignTable } from './components/CampaignTable/CampaignTable';
import { CampaignXGrid } from './components/CampaignXGrid/CampaignXGrid';

import { useLazyQuery } from '@apollo/client';
import { GET_UNIT_ENTITIES_LIST } from 'graphql/queries/report';
import { ENTITY_STATUS_SUBSCRIPTION } from 'graphql/subscriptions/entity.status';
import { addHours, startOfMonth, startOfToday } from 'date-fns';

import {
    CampaignStatus,
    CampaignServingStatus,
    CampaignGroupStatus,
    AdGroupServingStatus,
    AdGroupStatus,
    AdStatus
} from '../../common/enums';

const TableTabs = withStyles((theme) => ({
    indicator: {
        backgroundColor: theme.palette.primary.dark
    }
}))(Tabs);

const TableTab = withStyles((theme) => ({
    root: {
        height: '48px',
        minWidth: '226px',
        textTransform: 'none',
        backgroundColor: 'white',
        marginRight: '2px',
        '&$selected': {
            color: theme.palette.primary.main
        }
    },
    selected: {}
}))(Tab);

const tabsEnum = ['campaignGroups', 'campaigns', 'adSet', 'ads'];

// DELETE THIS: This is a comment just to make a change on demo branch, to see if circleci runs
export const CampaignList = () => {
    const {
        state: { activeUnit }
    } = useContext(activeUnitContext);
    // const [selectedAd, setSelectedAd] = useState();
    // const [open, setOpen] = useState(false);
    const [selectedTab, setSelectedTab] = useState('campaignGroups');
    const [newCampaignLoading, setNewCampaignLoading] = useState(false);
    const [mock, setMock] = useState({
        campaignGroups: [],
        campaigns: [],
        adSet: [],
        ads: []
    });
    const [selection, setSelection] = useState({
        campaignGroups: [],
        campaigns: [],
        adSet: [],
        ads: []
    });
    const [selectedData, setSelectedData] = useState({
        campaignGroups: [],
        campaigns: [],
        adSet: [],
        ads: []
    });

    const [updatedData, setUpdatedData] = useState({
        campaignGroups: [],
        campaigns: [],
        adSets: [],
        ads: []
    });

    const [tableData, setTableData] = useState([]);
    const [selectedSummary, setSelectedSummary] = useState({ id: 'selectionSummary', count: 0 });
    const [totalSummary, setTotalSummary] = useState({ id: 'total' });

    const [dateRange, setDateRange] = useState([
        addHours(startOfMonth(startOfToday()), 12),
        addHours(startOfToday(), 12)
    ]);
    const [advertisers, setAdvertisers] = useState([]);
    // console.log('mock', mock);

    const [getUnitEntitiesList, { loading: LoadingDashboard }] = useLazyQuery(GET_UNIT_ENTITIES_LIST, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data.getUnitEntitiesList) {
                const { ads, advertisers, campaignGroups, campaigns } = data.getUnitEntitiesList;
                const tableData = buildTableData(campaignGroups, campaigns, advertisers, ads);
                setMock(tableData);
                // setTableData(tableData[selectedTab]);
            } else {
                enqueueSnackbar(t('Error while getting dashboard'), { variant: 'error' });
                logError(new Error('Error with getDashboard query got: ' + data.toString()));
            }
        },
        onError: (error) => {
            enqueueSnackbar(t('Error while getting dashboard'), { variant: 'error' });
            logError(error);
        }
    });

    const [updated, setUpdated] = useState(false);
    const { loading, error, data } = useSubscription(ENTITY_STATUS_SUBSCRIPTION, {
        onSubscriptionData: ({ subscriptionData }) => {
            const { data } = subscriptionData;

            if (data?.entityStatus) {
                const { campaignGroups, campaigns, adSets, ads } = data?.entityStatus;

                campaignGroups.forEach((campaignGroup) => {
                    const found = mock.campaignGroups?.find((mck) => mck.id === campaignGroup.id);

                    if (found) {
                        found.status = CampaignGroupStatus.get(campaignGroup?.status);
                        found.message = campaignGroup?.message;
                    }
                });

                campaigns.forEach((campaign) => {
                    const found = mock.campaigns.find((mck) => mck.id === campaign.id);

                    if (found) {
                        found.status = CampaignStatus.get(campaign.status);
                        found.message = campaign.message;
                    }
                });

                adSets.forEach((adSet) => {
                    const found = mock.adSet.find((mck) => mck.id === adSet.id);

                    if (found) {
                        found.status = AdGroupStatus.get(adSet.status);
                        found.servingStatus = AdGroupServingStatus.get(adSet.servingStatus);
                        found.message = adSet.message;
                    }
                });

                ads.forEach((ad) => {
                    const found = mock.ads.find((mck) => mck.id === ad.id);

                    if (found) {
                        found.status = AdStatus.get(ad.status);
                        found.message = ad.message;
                    }
                });
            }

            setMock({ ...mock });
        }
    });

    useEffect(() => {
        const total = tableData?.reduce(
            (summary, r) => {
                const { clicks, impressions, renevue, spending, budget } = summary;
                const { stats } = r;

                return {
                    clicks: clicks + (+stats?.clicks || 0),
                    impressions: impressions + (+stats?.impressions || 0),
                    renevue: renevue + (+stats?.renevue || 0),
                    spending: spending + (+stats?.spending || 0),
                    budget: budget + (+stats?.budget || 0)
                };
            },
            {
                clicks: 0,
                impressions: 0,
                renevue: 0,
                spending: 0,
                budget: 0
            }
        );
        setTotalSummary({
            id: 'total',
            stats: total
        });
    }, [tableData]);

    const { t } = useTranslation();

    // const {
    //     state: { activeUnit }
    // } = useContext(activeUnitContext);
    // console.log('activeUnit', activeUnit);

    // eslint-disable-next-line no-unused-vars
    // const campaignGroups = activeUnit.campaignGroups && activeUnit.campaignGroups;
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const editCampaignGroup = (id) => history.push('/campaigns/' + id);

    const handleFiltersChange = (advertisers) => {
        setAdvertisers(advertisers);
    };

    // eslint-disable-next-line no-unused-vars
    const [createCampaignGroup, { loading: isLoadingCreateCampaignGroup }] = useMutation(CREATE_CAMPAIGN_GROUP, {
        onCompleted: (res) => {
            enqueueSnackbar(t('New campaign group created'), { variant: 'success' });
            editCampaignGroup(res.createCampaignGroup.id);
        },
        onError: (error) => {
            enqueueSnackbar(t('Could not create new campaign group'), { variant: 'error' });
            logError(new Error('Error with createCampaignGroup mutation got: ' + error.toString()));
        }
    });

    const [publishCampaignGroup] = useMutation(PUBLISH_CAMPAIGNGROUP, {
        onCompleted: (data) => {
            if (data.publishCampaignGroup) {
                enqueueSnackbar(t('Starting publishing process'), { variant: 'success' });
            } else {
                enqueueSnackbar(t('Could not start publishing process'), { variant: 'error' });
            }
        },
        onError: (error) => {
            enqueueSnackbar(t('Could not start publishing process'), { variant: 'error' });
            logError(new Error('Error with publishCampaignGroup mutation got: ' + error.toString()));
        }
    });

    // if (isLoadingCreateCampaignGroup) {
    //     return null;
    // }

    useEffect(() => {
        // const mock = generateMockData(1000, 5);
        // setMock(mock);
        getUnitEntitiesList({
            variables: {
                unitIds: advertisers?.length ? advertisers : [activeUnit && activeUnit.id],
                fromDate: dateRange[0],
                toDate: dateRange[1]
            }
        });
        // setTableData(mock[selectedTab]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeUnit, dateRange, advertisers]);

    useEffect(() => {
        handleSelection([]);
        setTableData(mock[selectedTab] || []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mock]);

    useEffect(() => {
        const filteredCampaignGroups = mock.campaignGroups;
        const filteredCampapigns = selection.campaignGroups.length
            ? mock.campaigns.filter((campaign) => selection.campaignGroups.includes(campaign.campaignGroupParentId))
            : mock.campaigns;
        const filteredAdSet = selection.campaigns.length
            ? mock.adSet.filter((adSet) => selection.campaigns.includes(adSet.campaignParentId))
            : selection.campaignGroups.length
            ? mock.adSet.filter((adSet) => selection.campaignGroups.includes(adSet.campaignGroupParentId))
            : mock.adSet;
        const filteredAds = selection.adSet.length
            ? mock.ads.filter((ad) => selection.adSet.includes(ad.adsetParentId))
            : selection.campaigns.length
            ? mock.ads.filter((ad) => selection.campaigns.includes(ad.campaignParentId))
            : selection.campaignGroups.length
            ? mock.ads.filter((ad) => selection.campaignGroups.includes(ad.campaignGroupParentId))
            : mock.ads;
        const data = {
            campaignGroups: filteredCampaignGroups,
            campaigns: filteredCampapigns,
            adSet: filteredAdSet,
            ads: filteredAds
        };
        setSelectedData(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selection]);

    const handleSelection = (selectionModel) => {
        if (selection[selectedTab].length !== selectionModel.length) {
            if (selectedTab === 'campaignGroups') {
                const selectionCampaigns = selection.campaigns.filter((id) =>
                    selectedData.campaigns
                        .filter((campaign) => selectionModel.includes(campaign.campaignGroupParentId))
                        .map((campaign) => campaign.id)
                        .includes(id)
                );
                const selectionAdSet = selection.adSet.filter((id) =>
                    selectedData.adSet
                        .filter((adSet) => selectionModel.includes(adSet.campaignGroupParentId))
                        .map((adSet) => adSet.id)
                        .includes(id)
                );
                const selectionAds = selection.ads.filter((id) =>
                    selectedData.ads
                        .filter((ads) => selectionModel.includes(ads.campaignGroupParentId))
                        .map((ads) => ads.id)
                        .includes(id)
                );
                setSelection({
                    campaignGroups: selectionModel,
                    campaigns: selectionCampaigns,
                    adSet: selectionAdSet,
                    ads: selectionAds
                });
            } else if (selectedTab === 'campaigns') {
                const selectionAdSet = selection.adSet.filter((id) =>
                    selectedData.adSet
                        .filter((adSet) => selectionModel.includes(adSet.campaignParentId))
                        .map((adSet) => adSet.id)
                        .includes(id)
                );
                const selectionAds = selection.ads.filter((id) =>
                    selectedData.ads
                        .filter((ads) => selectionModel.includes(ads.campaignParentId))
                        .map((ads) => ads.id)
                        .includes(id)
                );
                setSelection({
                    ...selection,
                    campaigns: selectionModel,
                    adSet: selectionAdSet,
                    ads: selectionAds
                });
            } else if (selectedTab === 'adSet') {
                const selectionAds = selection.ads.filter((id) =>
                    selectedData.ads
                        .filter((ads) => selectionModel.includes(ads.adsetParentId))
                        .map((ads) => ads.id)
                        .includes(id)
                );
                setSelection({
                    ...selection,
                    adSet: selectionModel,
                    ads: selectionAds
                });
            } else if (selectedTab === 'ads') {
                setSelection({
                    ...selection,
                    ads: selectionModel
                });
            }
        } else {
            setSelection({
                ...selection,
                [selectedTab]: selectionModel
            });
        }

        const total = tableData
            ?.filter((r) => selectionModel.includes(r.id))
            .reduce(
                (summary, r) => {
                    const { count, clicks, impressions, renevue, spending, budget } = summary;
                    const { stats } = r;

                    return {
                        count: count + 1,
                        clicks: clicks + (+stats?.clicks || 0),
                        impressions: impressions + (+stats?.impressions || 0),
                        renevue: renevue + (+stats?.renevue || 0),
                        spending: spending + (+stats?.spending || 0),
                        budget: budget + (+stats?.budget || 0)
                    };
                },
                {
                    count: 0,
                    clicks: 0,
                    impressions: 0,
                    renevue: 0,
                    spending: 0,
                    budget: 0
                }
            );
        setSelectedSummary({
            id: 'selectionSummary',
            count: total.count,
            stats: total
        });
    };

    const handleTabChange = (tab) => {
        setSelectedTab(tab);

        const data = selectedData[tab];
        setTableData(data);
    };

    const renderTab = (label) => {
        const labels = {
            campaignGroups: t('Campaign folder'),
            campaigns: t('Campaign'),
            adSet: t('Adset'),
            ads: t('Ads')
        };

        const counts = {
            campaignGroups: selection.campaignGroups.length || 0,
            campaigns: selection.campaigns.length
                ? selection.campaigns.length
                : selection.campaignGroups.length
                ? selectedData.campaigns.length
                : 0,
            adSet: selection.adSet.length
                ? selection.adSet.length
                : selection.campaigns.length || selection.campaignGroups.length
                ? selectedData.adSet.length
                : 0,
            ads: selection.ads.length
                ? selection.ads.length
                : selection.adSet.length || selection.campaigns.length || selection.campaignGroups.length
                ? selectedData.ads.length
                : 0
        };

        return (
            <Box
                style={{
                    position: 'relative',
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    padding: '0 20px',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                {labels[label]}
                {!!counts[label] && (
                    <Box
                        style={{
                            position: 'absolute',
                            right: '0',
                            display: 'flex',
                            flexDirection: 'row',
                            height: '24px',
                            padding: '0 6px',
                            borderRadius: '12px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: '#E1EFFA',
                            color: '#0578D3'
                        }}
                    >
                        {counts[label]}
                    </Box>
                )}
            </Box>
        );
    };

    return (
        <>
            <CampaignFilters
                dateRange={dateRange}
                setDateRange={setDateRange}
                handleFiltersChange={handleFiltersChange}
            />
            {!newCampaignLoading && (
                <Box display='flex' flexDirection='column'>
                    <Box display='flex' flexDirection='row' justifyContent='space-between'>
                        <Box display='flex' flexDirection='row' alignItems='flex-end'>
                            <TableTabs value={selectedTab} onChange={(e, newValue) => handleTabChange(newValue)}>
                                {tabsEnum.map((tabKey) => (
                                    <TableTab key={tabKey} value={tabKey} label={renderTab(tabKey)} />
                                ))}
                            </TableTabs>
                        </Box>
                        <Box padding='12px 0'>
                            <Button
                                color='primary'
                                aria-label='add'
                                variant='contained'
                                onClick={() => {
                                    setNewCampaignLoading(true);
                                    createCampaignGroup({
                                        variables: {
                                            campaignGroupInput: {
                                                name: '',
                                                unitId: activeUnit.id
                                            }
                                        }
                                    });
                                }}
                            >
                                <Box display='flex' flexDirection='row' justifyContent='center'>
                                    <Add />
                                    {'New Campaign'}
                                </Box>
                            </Button>
                        </Box>
                    </Box>
                    <CampaignXGrid
                        data={LoadingDashboard ? [] : tableData}
                        selectedSummary={selectedSummary}
                        totalSummary={totalSummary}
                        selectionModel={selection[selectedTab]}
                        loading={LoadingDashboard}
                        handleSelection={handleSelection}
                        edit={editCampaignGroup}
                        publish={publishCampaignGroup}
                        selectedTab={selectedTab}
                    />
                </Box>
            )}
            {/* {selectedAd && <AdPreviewDialog open={open} setOpen={setOpen} ad={selectedAd} />} */}
        </>
    );
};

const buildTableData = (campaignGroups, campaigns, adset, ads) => {
    let tableCampaignGroups = campaignGroups.map((campaignGroup) => {
        const { id, name, status, budget, statusNotice, progress, dateInterval } = campaignGroup;

        return {
            id,
            name,
            status: CampaignGroupStatus.get(status),
            notice: statusNotice,
            progress,
            budget,
            campaignCount: 0,
            adSetCount: 0,
            adsCount: 0,
            dateInterval: {
                startDate: campaignGroup.dateInterval?.startDate,
                endDate: campaignGroup.dateInterval?.endDate
            },
            networks: campaignGroup.network?.map((nw) => {
                return {
                    name: nw.name
                };
            }),
            activity: campaignGroup.activity,
            activityStatus: campaignGroup.activityStatus
        };
    });
    let tableCampaigns = campaigns.map((campaign) => {
        const {
            id,
            name,
            status,
            details,
            statusNotice,
            progress,
            parentId,
            dateInterval,
            network,
            activity,
            budget,
            activityStatus
        } = campaign;

        return {
            id,
            name,
            status: CampaignStatus.get(status),
            details,
            notice: statusNotice,
            budget,
            progress,
            campaignGroupParentId: parentId,
            adSetCount: 0,
            adsCount: 0,
            dateInterval: {
                startDate: dateInterval?.startDate,
                endDate: dateInterval?.endDate
            },
            network: network?.length > 0 && {
                name: network[0].name
            },
            activity,
            activityStatus
        };
    });
    let tableAdset = adset.map(
        ({
            id,
            name,
            status,
            statusNotice,
            servingStatus,
            message,
            progress,
            parentId,
            dateInterval,
            network,
            activity,
            budget,
            activityStatus
        }) => {
            return {
                id,
                name,
                status: AdGroupStatus.get(status),
                servingStatus: AdGroupServingStatus.get(servingStatus),
                message,
                budget,
                notice: statusNotice,
                progress,
                campaignGroupParentId: tableCampaigns.find((campaign) => campaign.id === parentId)
                    ?.campaignGroupParentId,
                campaignParentId: parentId,
                adsCount: 0,
                dateInterval: {
                    startDate: dateInterval?.startDate,
                    endDate: dateInterval?.endDate
                },

                network: network?.length > 0 && {
                    name: network[0].name
                },
                activity,
                activityStatus
            };
        }
    );
    let tableAds = ads.flatMap((ad) => {
        return ad?.formats?.map(({ id, name, status, message }) => {
            return {
                id,
                name,
                status: AdStatus.get(status),
                message,
                budget: ad.budget,
                notice: ad.statusNotice,
                progress: ad.progress,
                campaignGroupParentId: tableCampaigns.find(
                    (campaign) => campaign.id === tableAdset.find((adset) => adset.id === ad.parentId)?.campaignParentId
                )?.campaignGroupParentId,
                campaignParentId: tableAdset.find((adset) => adset.id === ad.parentId)?.campaignParentId,
                adsetParentId: ad.parentId,
                dateInterval: {
                    startDate: ad.dateInterval?.startDate,
                    endDate: ad.dateInterval?.endDate
                },
                network: ad.network?.length > 0 && {
                    name: ad.network[0].name
                },
                activity: adset.activity,
                activityStatus: adset.activityStatus
            };
        });
    });
    tableAdset.forEach((adset) => {
        adset.adsCount = tableAds.filter((ad) => ad.adsetParentId === adset.id).length || 0;
    });
    tableCampaigns.forEach((campaign) => {
        campaign.adsCount = tableAds.filter((ad) => ad.campaignParentId === campaign.id).length || 0;
        campaign.adSetCount = tableAdset.filter((adset) => adset.campaignParentId === campaign.id).length || 0;
    });
    tableCampaignGroups.forEach((campaignGroup) => {
        campaignGroup.adsCount = tableAds.filter((ad) => ad.campaignGroupParentId === campaignGroup.id).length || 0;
        campaignGroup.adSetCount =
            tableAdset.filter((adset) => adset.campaignGroupParentId === campaignGroup.id).length || 0;
        campaignGroup.campaignCount =
            tableCampaigns.filter((campaign) => campaign.campaignGroupParentId === campaignGroup.id).lnegth || 0;
    });

    return {
        campaignGroups: tableCampaignGroups,
        campaigns: tableCampaigns,
        adSet: tableAdset,
        ads: tableAds
    };
};
