import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import { BarChart, DynamicFeed, Error, RecordVoiceOver, Search, ShoppingCartOutlined, Store } from '@material-ui/icons';

import TabPanel from 'common/components/TabPanel/TabPanel';
import { a11yProps } from 'helpers/a11yProps';

import { CampaignType } from '../../../helpers/campaign';
import { toCamelCase } from '../../../helpers/string';
import { campaignTypeModel } from '../../models/advertisement';
import { StyledTab } from '../StyledTab/StyledTab';

const useStyles = makeStyles((theme) => ({
    root: {
        borderRight: `1px solid ${theme.palette.divider}`,
        minHeight: theme.spacing(35)
    },
    container: {
        border: '1px solid',
        borderColor: theme.palette.divider,
        borderBottom: 0,
        backgroundColor: theme.palette.background.paper
    },
    tabPanel: {
        padding: theme.spacing(9)
    },
    tabLabel: {
        paddingBottom: '5px',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: '16px',
        letterSpazing: '0.5px'
    },
    headerWarning: {
        marginTop: theme.spacing(3),
        color: theme.palette.error.main
    }
}));

export const CampaignTypeTabSelector = ({ campaignTypes, onSelectCampaignType }) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);

    const handleChange = (_, newTabIndex) => {
        setSelectedTabIndex(newTabIndex);
        onSelectCampaignType(campaignTypes[newTabIndex]);
    };

    const CampaignTypeTab = ({ campaigntype, ...props }) => {
        let icon = <Error />;
        if (campaigntype.name === CampaignType.Shopping) icon = <ShoppingCartOutlined />;
        if (campaigntype.name.toLowerCase() === CampaignType.Promote.toLowerCase()) icon = <BarChart />;
        if (campaigntype.name.toLowerCase() === CampaignType.Search.toLowerCase()) icon = <Search />;
        if (campaigntype.name.toLowerCase() === CampaignType.LeadGeneration.toLowerCase()) icon = <RecordVoiceOver />;
        if (campaigntype.name.toLowerCase() === CampaignType.ProductCatalogue.toLowerCase()) icon = <DynamicFeed />;
        if (campaigntype.name.toLowerCase() === CampaignType.StoreVisits.toLowerCase()) icon = <Store />;

        return <StyledTab icon={icon} tabLabelStyle={classes.tabLabel} enableBottomBorder {...props} />;
    };

    CampaignTypeTab.propTypes = {
        campaigntype: PropTypes.shape(campaignTypeModel)
    };

    return (
        <Grid container className={classes.container}>
            <Grid className={classes.root} item sm={4} xs={12}>
                <Tabs
                    variant='fullWidth'
                    orientation='vertical'
                    value={selectedTabIndex}
                    onChange={handleChange}
                    aria-label='Campaign type selector tabs'
                    indicatorColor='secondary'
                >
                    {campaignTypes &&
                        campaignTypes.map((campaignType, index) => (
                            <CampaignTypeTab
                                key={'tab_ct_' + campaignType.id}
                                campaigntype={campaignType}
                                tabLabel={t(`campaign.type.${toCamelCase(campaignType.name)}.name`)}
                                {...a11yProps(index)}
                            />
                        ))}
                </Tabs>
            </Grid>
            <Grid item sm={8} xs={12}>
                {campaignTypes &&
                    campaignTypes.map((campaignType, index) => (
                        <TabPanel
                            key={'tabpanel_ct_' + campaignType.id}
                            className={classes.tabPanel}
                            value={selectedTabIndex}
                            index={index}
                        >
                            <Typography variant='h2'>
                                {t(`campaign.type.${toCamelCase(campaignType.name)}.title`)}
                            </Typography>
                            <Box mt={3}>
                                <Typography component='p'>
                                    {t(`campaign.type.${toCamelCase(campaignType.name)}.description`)}
                                </Typography>
                            </Box>
                            {i18n.exists(`campaign.type.${toCamelCase(campaignType.name)}.warning`) && (
                                <Box mt={3}>
                                    <Typography color='error' component='p'>
                                        {t(`campaign.type.${toCamelCase(campaignType.name)}.warning`)}
                                    </Typography>
                                </Box>
                            )}
                        </TabPanel>
                    ))}
            </Grid>
        </Grid>
    );
};

CampaignTypeTabSelector.propTypes = {
    campaignTypes: PropTypes.arrayOf(PropTypes.shape(campaignTypeModel)),
    campaignType: PropTypes.shape(campaignTypeModel),
    onSelectCampaignType: PropTypes.func
};
