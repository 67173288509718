import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Collapse, List, ListItem, ListItemText } from '@material-ui/core';
import { ArrowDropDown, ArrowRight } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    groupButton: {
        '& .MuiTypography-body2': {
            fontWeight: theme.typography.fontWeightBold,
            fontSize: 13
        }
    },
    activePage: {
        borderLeft: `2px solid ${theme.palette.sidebar.active}`,
        paddingLeft: '8px !important',
        color: `${theme.palette.primary.main} !important`,
        fontWeight: theme.typography.fontWeightMedium,

        '& .MuiTypography-body2': {
            color: theme.palette.primary.main,
            fontWeight: theme.typography.fontWeightMedium
        }
    },
    arrowButton: {
        color: theme.palette.primary.main
    },
    inactive: {
        fontSize: 13,

        '&:link': {
            color: 'inherit'
        },

        '&:visited': {
            color: 'inherit'
        }
    },
    iconMargin: {
        marginRight: 8
    },
    disableYPadding: { paddingTop: 0, paddingBottom: 0 }
}));

const NavMenuItem = ({ title, href, disabled, subDirectories, currentPath, isOpenSidebar }) => {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();

    const [open, setOpen] = useState(true);
    const [active, setActive] = useState(false);

    useEffect(() => {
        if (!subDirectories) {
            const currentlyActive = href === currentPath;
            setActive(currentlyActive);
        }
    }, [currentPath, subDirectories, href]);

    useEffect(() => {
        setOpen(isOpenSidebar);
    }, [isOpenSidebar]);

    if (!subDirectories)
        return (
            <ListItem button className={classes.disableYPadding} disabled={disabled} onClick={() => history.push(href)}>
                <div className={classes.iconMargin}>
                    <ArrowRight style={{ opacity: 0 }} />
                </div>
                <ListItemText primary={title} className={clsx(classes.inactive, active && classes.activePage)} />
            </ListItem>
        );

    return (
        <>
            <ListItem
                button
                className={classes.disableYPadding}
                onClick={() => {
                    if (!isOpenSidebar) handleSidebarOpen();
                    setOpen((prev) => !prev);
                }}
            >
                <div className={classes.iconMargin}>
                    {open ? (
                        <ArrowDropDown className={classes.arrowButton} />
                    ) : (
                        <ArrowRight className={classes.arrowButton} />
                    )}
                </div>
                <ListItemText className={clsx(classes.groupButton)} primary={isOpenSidebar ? t(`${title}`) : ''} />
            </ListItem>
            {isOpenSidebar && (
                <Collapse in={open} timeout='auto' unmountOnExit>
                    <List component='div' dense disablePadding>
                        {subDirectories?.map(({ title, href, disabled }) => (
                            <ListItem
                                key={title}
                                className={classes.disableYPadding}
                                button
                                onClick={() => history.push(href)}
                                disabled={disabled}
                            >
                                <div className={classes.iconMargin}>
                                    <ArrowRight style={{ opacity: 0 }} />
                                </div>
                                <ListItemText
                                    primary={
                                        <NavLink
                                            to={href}
                                            className={classes.inactive}
                                            activeClassName={clsx(classes.activePage)}
                                        >
                                            {title}
                                        </NavLink>
                                    }
                                />
                            </ListItem>
                        ))}
                    </List>
                </Collapse>
            )}
        </>
    );
};

NavMenuItem.propTypes = {
    title: PropTypes.any,
    href: PropTypes.any,
    subDirectories: PropTypes.any,
    currentPath: PropTypes.any,
    disabled: PropTypes.bool,
    isOpenSidebar: PropTypes.bool
};

export default NavMenuItem;
