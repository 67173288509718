import { isNumeric } from '../utils';
import { Enum } from './enum';

export class MFAStatus extends Enum {
    static items = [];

    static unkown = new MFAStatus('unknown', -1);
    static unspecified = new MFAStatus('unspecified', 0);
    static inactive = new MFAStatus('inactive', 1);
    static processing = new MFAStatus('processing', 2);
    static verified = new MFAStatus('verified', 3);
    static validated = new MFAStatus('validated', 4);
    static invalid = new MFAStatus('invalid', 5);

    /**
     *
     */

    constructor(name, value) {
        super(name, value);

        MFAStatus.items.push(this);
    }

    /**
     *
     */

    equals(other) {
        return this.value === other.value && this.name === other.name;
    }

    /**
     *
     */

    static find(criteria) {
        if (isNumeric(criteria)) {
            return MFAStatus.items.find((v) => v.value === criteria);
        }

        return MFAStatus.items.find((v) => v.name === criteria);
    }

    /**
     *
     */

    static contains(criteria) {
        const status = MFAStatus.find(criteria);

        return !!status;
    }

    /**
     *
     */

    static get(criteria) {
        return MFAStatus.find(criteria) || MFAStatus.unspecified;
    }
}
