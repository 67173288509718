import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Input, makeStyles, Paper } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: 4,
        alignItems: 'center',
        padding: theme.spacing(1),
        display: 'flex',
        marginTop: theme.spacing(2),
        height: theme.spacing(6.75),
        width: '100%'
    },
    icon: {
        marginRight: theme.spacing(1),
        color: theme.palette.text.secondary
    },
    input: {
        flexGrow: 1,
        fontSize: theme.typography.subtitle2.fontSize,
        lineHeight: theme.spacing(2),
        letterSpacing: '-0.05px'
    }
}));
export const SearchInput = ({ value, onChange, ...props }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { iconStyle, textStyle, ...rest } = props;
    const handleChange = (event) => {
        onChange(event.target.value);
    };

    return (
        <Paper {...rest} className={`${classes.root} ${classes.paperStyle}`}>
            <Input
                {...rest}
                className={classes.input}
                disableUnderline
                placeholder={t('Search')}
                style={textStyle}
                value={value}
                onChange={handleChange}
            />
            <SearchIcon className={classes.icon} style={iconStyle} />
        </Paper>
    );
};
SearchInput.propTypes = {
    className: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    paperStyle: PropTypes.object,
    iconStyle: PropTypes.object,
    textStyle: PropTypes.object
};
