import React from 'react';
import PropTypes from 'prop-types';

import { IconButton, styled, Typography } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
}));

const StyledMuiDialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
    zIndex: 1,
    margin: 0,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1)
}));

const StyledMuiDialogShrankTitle = styled(MuiDialogTitle)(({ theme }) => ({
    zIndex: 1,
    margin: 0,
    padding: theme.spacing(0),
    marginBottom: theme.spacing(1)
}));

export const DialogTitleClose = (props) => {
    const { children, onClose, shrank, ...other } = props;

    return (
        (shrank && (
            <StyledMuiDialogShrankTitle disableTypography {...other}>
                {children && <Typography variant='h5'>{children}</Typography>}
                {onClose ? (
                    <StyledIconButton aria-label='close' onClick={onClose}>
                        <CloseIcon />
                    </StyledIconButton>
                ) : null}
            </StyledMuiDialogShrankTitle>
        )) || (
            <StyledMuiDialogTitle disableTypography {...other}>
                {children && <Typography variant='h5'>{children}</Typography>}
                {onClose ? (
                    <StyledIconButton aria-label='close' onClick={onClose}>
                        <CloseIcon />
                    </StyledIconButton>
                ) : null}
            </StyledMuiDialogTitle>
        )
    );
};

DialogTitleClose.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    shrank: PropTypes.bool,
    onClose: PropTypes.func
};
