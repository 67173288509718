import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Grid, InputLabel, Slider, Tooltip, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Warning } from '@material-ui/icons';

import { ageRangeModel } from 'common/models';

export const AgeRangeSelector = ({ ageRange, setAgeRange }) => {
    const MIN = 18;
    const MAX = 65;
    const { t } = useTranslation();
    const [ageRangeState, setAgeRangeState] = useState([]);
    const handleChange = (event, newValue) => {
        setAgeRangeState(newValue);
    };

    const handleChangeCommitted = (event, newValue) => {
        setAgeRange(newValue);
    };

    useEffect(() => {
        setAgeRangeState([ageRange?.from ?? MIN, ageRange?.to ?? MAX]);
    }, [ageRange]);

    return (
        <Grid container spacing={1}>
            <Grid item md xs={12}>
                <InputLabel id='age-selectors-label' style={{ marginBottom: '8px' }}>
                    <Typography variant='h6'>{t('Age range')}</Typography>
                </InputLabel>
            </Grid>
            <Grid container item spacing={1} justifyContent='flex-start' alignItems='center'>
                <Grid item>
                    <Typography variant='subtitle1'>{ageRangeState[0]}</Typography>
                </Grid>
                <Grid item>
                    <div style={{ width: '200px' }}>
                        <Slider
                            value={ageRangeState}
                            min={MIN}
                            max={MAX}
                            onChange={handleChange}
                            onChangeCommitted={handleChangeCommitted}
                            valueLabelDisplay='auto'
                            aria-labelledby='age-selectors-labe'
                        />
                    </div>
                </Grid>
                <Grid item>
                    <Typography variant='subtitle1'>{ageRangeState[1]}</Typography>
                </Grid>
                <Grid item>
                    <Tooltip
                        title={t(
                            'Warning: Changing these values will change the selected age range for all advertisers of this ad'
                        )}
                        aria-label={t(
                            'Warning: Changing these values will change the selected age range for all advertisers of this ad'
                        )}
                    >
                        <Warning style={{ color: grey[600] }} />
                    </Tooltip>
                </Grid>
            </Grid>
        </Grid>
    );
};

AgeRangeSelector.propTypes = {
    ageRange: PropTypes.shape(ageRangeModel),
    setAgeRange: PropTypes.func
};
