import { useMutation, useQueryClient } from '@tanstack/react-query';

const deleteReport = async (reportId) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/powerBIReport/${reportId}`, {
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
    });
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    return response.json();
};

const usePowerBIReportDelete = () => {
    const queryClient = useQueryClient();

    return useMutation(deleteReport, {
        onSuccess: () => {
            queryClient.invalidateQueries((query) => query.queryKey[0] === 'powerBIReport/list');
        }
    });
};

export default usePowerBIReportDelete;
