import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Box, Grid } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { useTheme } from '@material-ui/styles';

import { ResolutionSwitch, SelectorButton } from 'common/components';
import { templateMediaDimensionModel } from 'common/models/advertisement';

import { BlueExpansionPanel } from '../BlueExpansionPanel/BlueExpansionPanel';
import FormatFilter from './FormatFilter';

export const FormatSelector = ({ formats = {}, onSelectedFormat }) => {
    const { t } = useTranslation();
    const theme = useTheme();

    const initialState = [];
    Object.entries(formats).forEach(([key, mediaDataObject]) => {
        if (mediaDataObject.selected) {
            initialState.push(key);
        }
    });

    const [selectedMedia, setSelectedMedia] = useState(initialState);

    const handleChange = (key, selected) => {
        const newFormats = { ...formats, [key]: { ...formats[key], selected } };
        onSelectedFormat(newFormats);
    };

    const handleChangeAll = (selected) => {
        const newFormats = { ...formats };
        for (const key in newFormats) {
            newFormats[key] = {
                ...newFormats[key],
                selected
            };
        }
        onSelectedFormat(newFormats);
    };

    const onToggleMedia = (key) => {
        let keys = selectedMedia;
        if (keys.includes(key)) {
            keys = keys.filter((mediaKey) => mediaKey !== key);
            handleChange(key, false);
        } else {
            keys.push(key);
            handleChange(key, true);
        }
        setSelectedMedia([...keys]);
    };

    const numSelectedString = `${selectedMedia.length}/${Object.keys(formats).length} ${t('Selected')}`.toLowerCase();

    const selections = selectedMedia.map((key) => formats[key]).map((media) => media.selected);

    return (
        <Grid container direction='column' justifyContent='space-between' alignItems='flex-start'>
            <BlueExpansionPanel
                title={t('Ad formats to use ({{ selected }})', { selected: numSelectedString })}
                defaultExpanded
            >
                <FormatFilter formats={formats} selectedMedia={selectedMedia} onChange={onToggleMedia} />
            </BlueExpansionPanel>
            <BlueExpansionPanel title={t('Ad formats to edit')} defaultExpanded>
                <Grid container item xs direction='column' justifyContent='flex-start' alignItems='flex-start'>
                    {selectedMedia.length > 0 && (
                        <Grid container>
                            <Box m={1}>
                                <SelectorButton
                                    onClick={() => handleChangeAll(true)}
                                    text={t('Enable all formats')}
                                    Icon={CheckIcon}
                                    color={theme.palette.success.main}
                                    disabled={selections.every((s) => s)}
                                />
                            </Box>
                            <Box m={1}>
                                <SelectorButton
                                    onClick={() => handleChangeAll(false)}
                                    text={t('Disable all formats')}
                                    Icon={ClearIcon}
                                    color={theme.palette.error.main}
                                    disabled={selections.every((s) => !s)}
                                />
                            </Box>
                        </Grid>
                    )}

                    <Grid container justify='flex-start' alignItems='flex-start'>
                        {selectedMedia.map((key) => {
                            const media = formats[key];
                            const dimension = media.mediaDimensions[0];

                            return (
                                <ResolutionSwitch
                                    checkBox
                                    key={`resolution-switch-${key}`}
                                    name={media.name}
                                    checked={media.selected}
                                    resolution={[dimension.width, dimension.height]}
                                    onChecked={() => handleChange(key, !media.selected)}
                                />
                            );
                        })}
                    </Grid>
                </Grid>
            </BlueExpansionPanel>
        </Grid>
    );
};

FormatSelector.propTypes = {
    onSelectedFormat: PropTypes.func,
    formats: PropTypes.objectOf(PropTypes.shape(templateMediaDimensionModel))
};
