import React, { useState } from 'react';
import PropTypes from 'prop-types';
import arrayToTree from 'array-to-tree';

import { useQuery } from '@apollo/client';
import Grid from '@material-ui/core/Grid';

import { InterestsSelector } from 'common/components/InterestsAndKeywordsSelector/InterestsSelector/InterestsSelector';
import { interestModel } from 'common/models';
import { TARGETING_ACTION_TYPE } from 'context/campaign/targetingReducer';
import NetworkContext from 'context/NetworkContext';
import { GET_NETWORK_INTERESTS } from 'graphql/queries/object';
import { logError } from 'helpers/error';

export const InterestsSelectorTabPanel = ({ dispatch, interests, excludeInterests }) => {
    const network = React.useContext(NetworkContext);

    const [networkInterests, setNetworkInterests] = useState([]);
    const [networkInterestFlat, setNetworkInterestFlat] = useState([]);

    const { loading } = useQuery(GET_NETWORK_INTERESTS, {
        variables: {
            networkId: network?.id
        },
        onCompleted: (data) => {
            if (data?.getNetworkInterests) {
                setNetworkInterests(
                    arrayToTree(data.getNetworkInterests, {
                        parentProperty: 'parentKey',
                        customID: 'key',
                        childrenProperty: 'children'
                    })
                );
                setNetworkInterestFlat(data.getNetworkInterests);
            }
        },
        onError: (error) => {
            return logError(error);
        }
    });

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <InterestsSelector
                    networkInterests={networkInterests}
                    networkInterestFlat={networkInterestFlat}
                    isLoading={loading}
                    selectedIncludeInterests={interests}
                    selectedExcludeInterests={excludeInterests}
                    onSelectIncludeInterest={(interest) =>
                        dispatch({ type: TARGETING_ACTION_TYPE.ADD_INTEREST, interest })
                    }
                    onRemoveIncludeInterest={(key) => dispatch({ type: TARGETING_ACTION_TYPE.REMOVE_INTEREST, key })}
                    onSelectExcludeInterest={(excludeInterest) =>
                        dispatch({ type: TARGETING_ACTION_TYPE.ADD_EXCLUDE_INTEREST, excludeInterest })
                    }
                    onRemoveExcludeInterest={(key) =>
                        dispatch({ type: TARGETING_ACTION_TYPE.REMOVE_EXCLUDE_INTEREST, key })
                    }
                    onRemoveAllInterests={(targetingConditionType) =>
                        dispatch({ type: TARGETING_ACTION_TYPE.REMOVE_ALL_INTERESTS_OF_TYPE, targetingConditionType })
                    }
                />
            </Grid>
        </Grid>
    );
};

InterestsSelectorTabPanel.propTypes = {
    dispatch: PropTypes.func,
    interests: PropTypes.arrayOf(PropTypes.shape(interestModel)),
    excludeInterests: PropTypes.arrayOf(PropTypes.shape(interestModel))
};
