import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Grid, makeStyles } from '@material-ui/core';

import { AdvertiserSelector, BlueExpansionPanel, DropdownListFilter } from 'common/components';
import DatePickerWithRange from 'common/components/Pickers/Date';

import { SearchInput } from './SearchInput';

const useStyles = makeStyles((theme) => ({
    searchGrid: {
        marginTop: theme.spacing(2),
        paddingRight: theme.spacing(3)
    },
    periodGrid: {
        marginTop: theme.spacing(2)
    },
    dropdownFilterGrid: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2)
    },
    advertiserFilter: {
        [theme.breakpoints.up('sm')]: {
            paddingRight: theme.spacing(3)
        }
    },
    networkFilter: {
        marginTop: theme.spacing(4),
        [theme.breakpoints.up('sm')]: {
            paddingRight: theme.spacing(3),
            paddingLeft: theme.spacing(3),
            marginTop: 0
        }
    },
    statusFilter: {
        marginTop: theme.spacing(4),
        [theme.breakpoints.up('sm')]: {
            paddingRight: theme.spacing(3),
            paddingLeft: theme.spacing(3),
            marginTop: 0
        }
    },
    tagsFilter: {
        marginTop: theme.spacing(4),
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(3),
            marginTop: 0
        }
    }
}));

export const CampaignFilters = ({ dateRange, setDateRange, handleFiltersChange }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [expanded, setExpanded] = useState(true);
    const [searchV, setSearchV] = useState('');
    const [advertisers, setAdvertisers] = useState([]);

    const advertiserSelectorUpd = (selected) => {
        setAdvertisers(selected);
    };

    useEffect(() => {
        handleFiltersChange(advertisers);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [advertisers]);

    return (
        <BlueExpansionPanel title={t('Campaign filter')} expanded={expanded} onChange={() => setExpanded(!expanded)}>
            <Grid container item xs={12}>
                <Grid container item xs={12}>
                    <Grid item xs={6} className={classes.searchGrid}>
                        <SearchInput value={searchV} onChange={setSearchV} />
                    </Grid>
                    <Grid container item xs={6} justify='flex-end'>
                        <Grid item xs={9} className={classes.periodGrid}>
                            <DatePickerWithRange dateRange={dateRange} setDateRange={setDateRange} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item xs={12} className={classes.dropdownFilterGrid}>
                    <Grid item xs={3} className={classes.advertiserFilter}>
                        <AdvertiserSelector updFunc={advertiserSelectorUpd} />
                    </Grid>
                    <Grid item xs={3} className={classes.networkFilter}>
                        <DropdownListFilter
                            values={[]}
                            setValues={() => {}}
                            mappingList={{}}
                            label={t('Network and adtypes')}
                        />
                    </Grid>
                    <Grid item xs={3} className={classes.statusFilter}>
                        <DropdownListFilter values={[]} setValues={() => {}} mappingList={{}} label={t('Status')} />
                    </Grid>
                    <Grid item xs={3} className={classes.tagsFilter}>
                        <DropdownListFilter values={[]} setValues={() => {}} mappingList={{}} label={t('Tags')} />
                    </Grid>
                </Grid>
            </Grid>
        </BlueExpansionPanel>
    );
};

CampaignFilters.propTypes = {
    dateRange: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
    setDateRange: PropTypes.func,
    handleFiltersChange: PropTypes.func
};
