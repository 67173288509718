import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';

import { SingleMetricInfoBox } from '../SingleMetricInfoBox/SingleMetricInfoBox';

export const BudgetSum = ({ campaigns, currency }) => {
    const { t } = useTranslation();

    return (
        <SingleMetricInfoBox
            label={t('Total budget')}
            icon={<AccountBalanceWalletIcon />}
            weight={600}
            value={campaigns.reduce(
                (memo, { ads }) => memo + [...ads.values()].reduce((adMemo, ad) => adMemo + ad.budget, 0),
                0
            )}
            subtext={t('Total for all campaigns')}
            formatOptions={{ style: 'currency', currency, currencyDisplay: 'narrowSymbol' }}
        />
    );
};

BudgetSum.propTypes = {
    campaigns: PropTypes.arrayOf(PropTypes.object),
    currency: PropTypes.string
};
