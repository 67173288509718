import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
    root: {
        paddingTop: 64,
        height: '100%',
        width: '100%'
    },
    content: {
        height: '100%'
    },
    footer: {
        width: '100%',
        height: '50%',
        backgroundImage: ' url(/images/adtubebackground.jpg)'
    }
}));

const Welcome = (props) => {
    const { children } = props;
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <main className={classes.content}>{children}</main>
            <min className={classes.footer}></min>
        </div>
    );
};

Welcome.propTypes = {
    children: PropTypes.node
};

export default Welcome;
