import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { compareAsc } from 'date-fns';

import { Box, Grid, IconButton, TextField, Tooltip, Typography } from '@material-ui/core';
import { RotateLeft } from '@material-ui/icons';

const TimeBox = ({ label, onChange, value }) => (
    <Box width={135} marginX={1}>
        <TextField label={label} variant='filled' fullWidth type='time' value={value} onChange={onChange} />
    </Box>
);

TimeBox.propTypes = {
    label: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string
};

const AdScheduleTimeSelector = ({
    startTime,
    defaultStartTime,
    setStartTime,
    endTime,
    defaultEndTime,
    setEndTime,
    day
}) => {
    const { t } = useTranslation();

    const handleSetStartTime = (e) => {
        if (!e.target.value) return;
        const startDateTime = new Date(2000, 1, 1, e.target.value.split(':')[0], e.target.value.split(':')[1]);
        let endDateTime;
        if (endTime) endDateTime = new Date(2000, 1, 1, endTime.split(':')[0], endTime.split(':')[1]);
        if (compareAsc(startDateTime, endDateTime) > 0) {
            setEndTime(e.target.value, day);
        }

        setStartTime(e.target.value, day);
    };
    const handleSetEndTime = (e) => {
        if (!e.target.value) return;
        let startDateTime;
        if (startTime) startDateTime = new Date(2000, 1, 1, startTime.split(':')[0], startTime.split(':')[1]);
        const endDateTime = new Date(2000, 1, 1, e.target.value.split(':')[0], e.target.value.split(':')[1]);
        if (compareAsc(startDateTime, endDateTime) > 0) {
            setStartTime(e.target.value, day);
        }

        setEndTime(e.target.value, day);
    };

    const handleClickReset = () => {
        setStartTime(null, day);
        setEndTime(null, day);
    };
    const handleMouseDownReset = (e) => {
        e.preventDefault();
    };

    return (
        <Grid container direction='row' justifyContent='flex-start' alignItems='center' spacing={1}>
            <Grid container item xs={2}>
                <Typography>{day ? t('time.' + day) : t('Default')}</Typography>
            </Grid>
            <Grid item xs={8}>
                <Box display='flex'>
                    <TimeBox
                        label={t('Start time')}
                        value={startTime ? startTime : defaultStartTime}
                        onChange={(e) => handleSetStartTime(e)}
                    />
                    <TimeBox
                        label={t('End time')}
                        value={endTime ? endTime : defaultEndTime}
                        onChange={(e) => handleSetEndTime(e)}
                    />
                </Box>
            </Grid>
            <Grid item xs>
                {day && (startTime || endTime) && (
                    <Tooltip title={t('Reset to original')} aria-label='reset time selection'>
                        <IconButton onClick={handleClickReset} onMouseDown={handleMouseDownReset}>
                            <RotateLeft />
                        </IconButton>
                    </Tooltip>
                )}
            </Grid>
        </Grid>
    );
};

AdScheduleTimeSelector.propTypes = {
    startTime: PropTypes.string,
    defaultStartTime: PropTypes.string,
    setStartTime: PropTypes.func,
    endTime: PropTypes.string,
    defaultEndTime: PropTypes.string,
    setEndTime: PropTypes.func,
    day: PropTypes.string
};

export default AdScheduleTimeSelector;
