import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { useSnackbar } from 'notistack';

import { useMutation } from '@apollo/client';
import { Button, Grid, makeStyles, TextField } from '@material-ui/core';
import { Cancel, SaveOutlined } from '@material-ui/icons';

import { TopBar } from 'common/components/TopBar/TopBar';
import { CAMPAIGN_ACTION_TYPE, useCampaignDispatch } from 'context/campaign/CampaignContext';
import { PUBLISH_CAMPAIGNGROUP, UPDATE_CAMPAIGN_GROUP } from 'graphql/mutations/object';

const useStyles = makeStyles((theme) => ({
    publish: {
        height: '56px',
        letterSpacing: '1.25px'
    },
    save: {
        letterSpacing: '1.25px'
    },
    cancel: {
        color: theme.palette.error.main,
        letterSpacing: '1.25px'
    }
}));

// eslint-disable-next-line react/prop-types
const CampaignTopbar = ({ name }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const dispatch = useCampaignDispatch();
    const [campaignGroupName, setCampaignGroupName] = useState(name);

    const [publishCampaignGroup] = useMutation(PUBLISH_CAMPAIGNGROUP, {
        onCompleted: (data) => {
            if (data.publishCampaignGroup) {
                enqueueSnackbar(t('Starting publishing process'), { variant: 'success' });
                dispatch({ type: CAMPAIGN_ACTION_TYPE.PUBLISH_SUCCESS, history });
            } else {
                enqueueSnackbar(t('Could not start publishing process'), { variant: 'error' });
                dispatch({ type: CAMPAIGN_ACTION_TYPE.PUBLISH_ERROR });
            }
        },
        onError: () => {
            enqueueSnackbar(t('Could not start publishing process'), { variant: 'error' });
            dispatch({ type: CAMPAIGN_ACTION_TYPE.PUBLISH_ERROR });
        }
    });

    const [updateCampaignGroup] = useMutation(UPDATE_CAMPAIGN_GROUP, {
        onError: () => {
            enqueueSnackbar(t('Could not update campaign'), { variant: 'error' });
            dispatch({ type: CAMPAIGN_ACTION_TYPE.SAVE_ERROR });
        },
        onCompleted: () => {
            enqueueSnackbar(t('Campaign updated'), { variant: 'success' });
            dispatch({ type: CAMPAIGN_ACTION_TYPE.SAVE_SUCCESS, history });
        }
    });

    return (
        <TopBar>
            <Grid container item xs={2}>
                <Button
                    color='primary'
                    size='large'
                    variant='contained'
                    onClick={() => dispatch({ type: CAMPAIGN_ACTION_TYPE.PUBLISH, mutation: publishCampaignGroup })}
                    className={classes.publish}
                >
                    {t('Publish campaign')}
                </Button>
            </Grid>
            <Grid container item xs={7} justify='flex-start'>
                <TextField
                    label={t('Campaign name')}
                    onChange={(event) => {
                        const val = event.target.value;
                        setCampaignGroupName(val);
                        debounce(() => dispatch({ type: CAMPAIGN_ACTION_TYPE.UPDATE_NAME, name: val }), 200)();
                    }}
                    fullWidth
                    variant='filled'
                    value={campaignGroupName}
                />
            </Grid>
            <Grid container item xs direction='column' alignItems='flex-end' justify='space-evenly'>
                <Button
                    color='primary'
                    size='medium'
                    variant='text'
                    endIcon={<SaveOutlined />}
                    onClick={() => dispatch({ type: CAMPAIGN_ACTION_TYPE.SAVE, mutation: updateCampaignGroup })}
                    className={classes.save}
                >
                    {t('Save as draft')}
                </Button>
                <Button
                    color='inherit'
                    size='medium'
                    variant='text'
                    endIcon={<Cancel />}
                    onClick={() => dispatch({ type: CAMPAIGN_ACTION_TYPE.CLOSE, history })}
                    className={classes.cancel}
                >
                    {t('Cancel')}
                </Button>
            </Grid>
        </TopBar>
    );
};

export default CampaignTopbar;
