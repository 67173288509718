import { addDays, format, startOfTomorrow } from 'date-fns';
import { v4, validate } from 'uuid';

import { AdType } from './ad';

const defaultTargeting = {
    interests: [],
    excludeInterests: [],
    age: null,
    locations: [],
    gender: null,
    educations: [],
    work: [],
    behaviors: [],
    demographics: [],
    keywords: [],
    excludeKeywords: []
};

export const marshalMediaInput = ({ id, url }) => ({ id: validate(id) ? undefined : id, url });

export const marshalItemInput = ({ id, name, creativeGroupingId, product, media, url, content }) => ({
    id: validate(id) ? undefined : id,
    name,
    productId: product?.id,
    url,
    creativeGroupingId,
    content: JSON.stringify(content || {}),
    media: media?.map(marshalMediaInput)
});

export const marshalNetworkAssetInputFromNetworkAssets = ({ facebookPage }) => ({
    facebookPageId: facebookPage?.key
});

export const marshalCreativeInput = ({ id, name, items, formatId, status, budget, type, content, networkAssets }) => ({
    content: JSON.stringify(content || {}),
    id: validate(id) ? undefined : id,
    name,
    items: items ? items.map(marshalItemInput) : [],
    status,
    budget,
    type,
    formatId,
    networkAssets: networkAssets ? marshalNetworkAssetInputFromNetworkAssets(networkAssets) : undefined
});

export const marshalAdvertiserInputFromUnit = ({ id, name }) => ({ id, name });

export const marshalDayInput = ({ id, day, startTime, endTime }) => ({
    id: validate(id) ? undefined : id,
    day,
    startTime,
    endTime
});

export const marshalDayFromDayInput = ({ day }) => day;

export const marshalScheduleInput = ({ id, startDate, endDate, startTime, endTime, days }) => ({
    id: validate(id) ? undefined : id,
    startDate,
    endDate,
    startTime,
    endTime,
    days: days ? days.map(marshalDayInput) : []
});

export const marshalKeywordInput = ({ text, matchType }) => ({ text, matchType });

export const marshalLocationInput = ({ latitude, longitude, label, radius, address }) => ({
    latitude,
    longitude,
    label,
    radius,
    address
});

export const marshalAgeInput = ({ from, to }) => ({ from, to });

export const marshalInterestToID = ({ key }) => key;

export const marshalTargetingInput = ({
    id,
    name,
    gender,
    age,
    locations,
    interests,
    excludeInterests,
    unitId,
    keywords,
    excludeKeywords
}) => ({
    id: validate(id) ? undefined : id,
    name,
    gender,
    age: age ? marshalAgeInput(age) : undefined,
    locations: locations ? locations.map(marshalLocationInput) : [],
    interests: interests ? interests.map(marshalInterestToID) : [],
    excludeInterests: excludeInterests ? excludeInterests.map(marshalInterestToID) : [],
    unitId,
    keywords: keywords ? keywords.map(marshalKeywordInput) : [],
    excludeKeywords: excludeKeywords ? excludeKeywords.map(marshalKeywordInput) : []
});

export const marshalAdInput = ({
    id,
    schedule,
    name,
    budget,
    advertiser,
    creatives,
    targeting,
    duration,
    status,
    salesCountry,
    campaignPriority,
    enableLocal,
    biddingType
}) => ({
    id: validate(id) ? undefined : id,
    name: `${schedule?.startDate ? format(schedule?.startDate, 'dd.MM.yyyy') : ''}-${
        schedule?.endDate ? format(schedule?.endDate, 'dd.MM.yyyy') : ''
    } ${name}`,
    startDate: schedule ? schedule.startDate : undefined,
    endDate: schedule ? schedule.endDate : undefined,
    days: schedule && schedule.days ? schedule.days.map(marshalDayFromDayInput) : [],
    budget,
    schedule: schedule ? marshalScheduleInput(schedule) : {},
    advertiser: advertiser ? marshalAdvertiserInputFromUnit(advertiser) : undefined,
    creatives: creatives
        ? creatives.map((creative) =>
              marshalCreativeInput({
                  ...creative,
                  name: `${schedule?.startDate ? format(schedule?.startDate, 'dd.MM.yyyy') : ''}-${
                      schedule?.endDate ? format(schedule?.endDate, 'dd.MM.yyyy') : ''
                  } ${name}`
              })
          )
        : [],
    targeting: targeting ? marshalTargetingInput(targeting) : undefined,
    duration,
    status,
    salesCountry,
    campaignPriority,
    enableLocal,
    biddingType
});

/* TODO: fix this bidding type ASAP instead of using this hack */

const getHackyBiddingType = (adType) => {
    switch (adType.name) {
        case AdType.Search:
            return 'TARGET_SPEND';
        case AdType.Image:
        case AdType.Carousel:
            return 'LINK_CLICKS';
        default:
            return '';
    }
};

export const marshalAdGroupInput = ({ id, adType, name }, ads) => ({
    id: validate(id) ? undefined : id,
    name,
    adTypeId: adType?.id,
    ads: ads
        ? Array.from(ads.values()).map((ad) =>
              marshalAdInput({ ...ad, name, biddingType: getHackyBiddingType(adType) })
          )
        : []
});

export const marshalCampaignInput = ({ id, network, ads, adGroups, name, linkExtensions }) => ({
    id: validate(id) ? undefined : id,
    name,
    networkId: network?.id,
    adGroups: adGroups ? [marshalAdGroupInput({ ...adGroups[0], name }, ads)] : undefined,
    linkExtensions
});

export const marshalCampaignGroupInput = ({ id, name, unitId, campaigns }) => ({
    id: validate(id) ? undefined : id,
    name,
    unitId,
    campaigns: campaigns ? campaigns.map((campaign) => marshalCampaignInput({ ...campaign, name })) : []
});

export const marshalAdDefault = (adType, unit) => ({
    name: `${adType.networks[0].name} ${adType.name}`,
    advertiser: {
        id: unit.id,
        name: unit.name
    },
    budget: 0,
    creatives: [],
    schedule: {
        days: [],
        startDate: startOfTomorrow(),
        endDate: addDays(startOfTomorrow(), 7),
        startTime: '00:00',
        endTime: '23:59'
    },
    targeting: (unit.targeting && {
        ...defaultTargeting,
        ...unit.targeting
    }) || { unitId: unit.id, ...defaultTargeting }
});

export const marshalAdEntryDefault = (adType) => (unit) => [unit.id, marshalAdDefault(adType, unit)];

export const marshalCampaignDefault = (units, campaignType, adType, name) => ({
    id: v4(),
    campaignType,
    units,
    name: name,
    network: adType.networks[0],
    adGroups: [
        {
            name: `${adType.networks[0].name} ${name}`,
            adType: adType
        }
    ],
    ads: new Map(units.map(marshalAdEntryDefault(adType)))
});

export const marshalProductInput = (fields, productTypeId = null, unitId = null, productId = null) => {
    const productObj = {
        values: []
    };
    if (productTypeId) productObj['productTypeId'] = productTypeId;
    if (unitId) productObj['unitId'] = unitId;
    if (productId) productObj['productId'] = productId;

    for (const [key, value] of Object.entries(fields)) {
        productObj.values.push({
            fieldId: key,
            dataJSON: JSON.stringify(value)
        });
    }

    return productObj;
};

export const marshalProduct = (product) => {
    const productObj = {
        id: product.id,
        productTypeId: product.productTypeId,
        readOnly: product.readOnly,
        fields: {}
    };

    for (let i = 0; i < product.fields.length; i++) {
        const field = product.fields[i];
        productObj.fields[field.fieldId] = JSON.parse(field.dataJSON);
    }

    return productObj;
};
