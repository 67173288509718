import gql from 'graphql-tag';

import { FRAGMENT_DATE_INTERVAL } from './date';

export const FRAGMENT_UNIT_ENTITY_PRODUCT = gql`
    fragment unitEntityProduct on UnitEntityProduct {
        id
        name
        clicks
        imageUrl
        productId
    }
`;

export const FRAGMENT_UNIT_ENTITY = gql`
    fragment unitEntity on UnitEntity {
        id
        name
        message
        status
        servingStatus
        details
        type
        parentId
        groupId
        budget
        products {
            ...unitEntityProduct
        }
        network {
            id
            name
        }
        adType {
            id
            name
            networks {
                id
                name
            }
        }
        dateInterval {
            ...dateInterval
        }
        statusNotice {
            type
            message
        }

        formats {
            id
            name
            status
            message
        }

        activity
        activityStatus
    }
    ${FRAGMENT_UNIT_ENTITY_PRODUCT}
    ${FRAGMENT_DATE_INTERVAL}
`;
